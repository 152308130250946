import React, { useEffect } from "react";
import styled from "styled-components";
import Select from "react-select";
import countryList from "react-select-country-list";
import { createPortal } from "react-dom";
import ImageInput from "components/paymentCard/components/ImageInput";
import { usePaymentDetails } from "hooks/usePaymentDetails";
import { UserPaymentDetails } from "API";
import { IProfileImageUpload } from "state/profileSteps/profile.interface";
import { CircularProgress } from "@mui/material";

const Modal: React.FC<{ isOpen: boolean; onClose: () => void; children: React.ReactNode }> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return createPortal(
        <Backdrop onClick={onClose}>
            <ModalWrapper onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={onClose}>&times;</CloseButton>
                {children}
            </ModalWrapper>
        </Backdrop>,
        document.body
    );
};

type Props = {
    isOpen: boolean;
    onClose: () => void;
    userId: string | null;
    paymentDetails?: UserPaymentDetails | null;
    onSaveClose: () => void;
};

const EditPaymentDetailsModal: React.FC<Props> = ({ isOpen, onClose, userId, paymentDetails, onSaveClose }) => {
    const {
        formState,
        updateState,
        handleSubmit,
        documentImg,
        setDocumentImg,
        proofOfAddress,
        setProofOfAddress,
        confirmationLetter,
        setConfirmationLetter,
        isLoading,
        formError
    } = usePaymentDetails(userId, paymentDetails);

    const countryOptions: { value: string; label: string }[] = countryList().getData();
    const getImageURL = (upload: IProfileImageUpload | File): string | undefined => {
        if ("image" in upload) {
            return upload.image ?? undefined;
        }
        return undefined;
    };

    const handlePaymentDetialsSubmit = () => {
        handleSubmit(onSaveClose)
    }

    const handleDocumentImgChange = (
        file: File | null,
        type: "passport" | "letter" | "proofOfAddress"
    ) => {
        setDocumentImg(file ?? {});
    };

    const handleConfirmationLetterChange = (
        file: File | null,
        type: "passport" | "letter" | "proofOfAddress"
    ) => {
        setConfirmationLetter(file ?? {});
    };

    const handleProofOfAddressChange = (
        file: File | null,
        type: "passport" | "letter" | "proofOfAddress"
    ) => {
        setProofOfAddress(file ?? {});
    };

    useEffect(() => {
        console.log("formError", formError);

    }, [formError])


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalContent>
                <Title>PAYMENT DETAILS</Title>

                {isLoading ? (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "50px",
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <ScrollableContent>
                        {/* ✅ Full Name */}
                        <FormGroup>
                            <Label>Full Name</Label>
                            <Input
                                type="text"
                                name="fullName"
                                value={formState.fullName}
                                onChange={(e) => updateState("fullName", e.target.value)}
                                className={formError.fullName ? "border-red-500" : ""}
                            />
                            {formError.fullName && <ErrorText>{formError.fullName}</ErrorText>}
                        </FormGroup>

                        {/* ✅ First Line of Address */}
                        <FormGroup>
                            <Label>First Line of Address</Label>
                            <Input
                                type="text"
                                name="firstAddress"
                                value={formState.firstAddress}
                                onChange={(e) => updateState("firstAddress", e.target.value)}
                                className={formError.firstAddress ? "border-red-500" : ""}
                            />
                            {formError.firstAddress && <ErrorText>{formError.firstAddress}</ErrorText>}
                        </FormGroup>

                        {/* ✅ Second Line of Address */}
                        <FormGroup>
                            <Label>Second Line of Address</Label>
                            <Input
                                type="text"
                                name="secondAddress"
                                value={formState.secondAddress}
                                onChange={(e) => updateState("secondAddress", e.target.value)}
                                className={formError.secondAddress ? "border-red-500" : ""}
                            />
                            {formError.secondAddress && <ErrorText>{formError.secondAddress}</ErrorText>}
                        </FormGroup>

                        <AddressRow>
                            {/* ✅ Postal Code */}
                            <FormGroup>
                                <Label>Postal Code</Label>
                                <Input
                                    type="text"
                                    name="postCode"
                                    value={formState.postCode}
                                    onChange={(e) => updateState("postCode", e.target.value)}
                                    className={formError.postCode ? "border-red-500" : ""}
                                />
                                {formError.postCode && <ErrorText>{formError.postCode}</ErrorText>}
                            </FormGroup>

                            {/* ✅ Country Selector */}
                            <SelectWrapper>
                                <Label>Country</Label>
                                <StyledSelect
                                    options={countryOptions}
                                    value={countryOptions.find((c) => c.value === formState.country) || null}
                                    onChange={(selectedOption: { value: string; label: string } | null) =>
                                        updateState("country", selectedOption?.value || "")}
                                    placeholder="Select a country"
                                    classNamePrefix="react-select"
                                />
                                {formError.country && <ErrorText>{formError.country}</ErrorText>}
                            </SelectWrapper>
                        </AddressRow>

                        {/* ✅ Account Number */}
                        <FormGroup>
                            <Label>Account Number</Label>
                            <Input
                                type="text"
                                name="accountNumber"
                                value={formState.accountNumber}
                                onChange={(e) => updateState("accountNumber", e.target.value)}
                                className={formError.accountNumber ? "border-red-500" : ""}
                            />
                            {formError.accountNumber && <ErrorText>{formError.accountNumber}</ErrorText>}
                        </FormGroup>

                        {/* ✅ Swift / BIC Code */}
                        <FormGroup>
                            <Label>Swift / BIC Code</Label>
                            <Input
                                type="text"
                                name="swiftCode"
                                value={formState.swiftCode}
                                onChange={(e) => updateState("swiftCode", e.target.value)}
                                className={formError.swiftCode ? "border-red-500" : ""}
                            />
                            {formError.swiftCode && <ErrorText>{formError.swiftCode}</ErrorText>}
                        </FormGroup>

                        {/* ✅ Branch Code */}
                        <FormGroup>
                            <Label>Branch Code</Label>
                            <Input
                                type="text"
                                name="branchCode"
                                value={formState.branchCode}
                                onChange={(e) => updateState("branchCode", e.target.value)}
                                className={formError.branchCode ? "border-red-500" : ""}
                            />
                            {formError.branchCode && <ErrorText>{formError.branchCode}</ErrorText>}
                        </FormGroup>

                        <UploadContainer>
                            <ImageInput
                                label="Passport / ID"
                                id="passport"
                                imageURL={getImageURL(documentImg)}
                                handleChange={handleDocumentImgChange}
                                type="passport"
                                error={formError.documentID}
                            />
                            <ImageInput
                                label="Confirmation Letter"
                                id="letter"
                                imageURL={getImageURL(confirmationLetter)}
                                handleChange={handleConfirmationLetterChange}
                                type="letter"
                                error={formError.confirmationLetter}
                            />
                            <ImageInput
                                label="Proof of Address"
                                id="proof"
                                imageURL={getImageURL(proofOfAddress)}
                                handleChange={handleProofOfAddressChange}
                                type="proofOfAddress"
                                error={formError.proofOfAddress}
                            />


                        </UploadContainer>
                    </ScrollableContent>
                )}

                <ButtonContainer>
                    <CancelButton onClick={onClose}>CANCEL</CancelButton>
                    <SaveButton onClick={handlePaymentDetialsSubmit}>SAVE</SaveButton>
                </ButtonContainer>
            </ModalContent>
        </Modal>
    );
};

export default EditPaymentDetailsModal;

// 🔹 Styled Components

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const ModalWrapper = styled.div`
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 100%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0 15px 20px; 
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 20px;
`;

const FormGroup = styled.div`
  position: relative;
  margin-top:20px
`;

const Label = styled.label`
  position: absolute;
  top: -10px; 
  left: 12px;
  font-size: 14px;
  font-weight: 500;
  color: #999;
  transition: all 0.3s ease-in-out;
  background: white;
  padding: 0 5px;
  pointer-events: none;
  z-index: 999;
`;

const Input = styled.input`
  padding: 14px 12px;
  border: 1px solid #000;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  background: white;
  transition: all 0.3s ease-in-out;

  &.border-red-500 {
    border-color: red;
  }
`;
const AddressRow = styled.div`
  display: flex;
  width: 100%; /* ✅ Ensures it takes full width */

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px; /* Ensure enough spacing between inputs on mobile */
  }

  @media (min-width: 769px) {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%; /* Ensures consistency */

  @media (min-width: 769px) {
    max-width: 48%; /* Makes sure it's next to Postal Code */
    margin-top:20px
  }
`;

const StyledSelect = styled(Select)`
  .react-select__control {
    height: 53px;
    border-radius: 8px;
    border: 1px solid black;
    font-size: 14px;
    background: white;
    padding: 0 12px;
    transition: 0.3s ease-in-out;
    width: 100%;
    @media (max-width: 768px) {
      height: 53px; /* Adjusted for touch */
    }
  }

  .react-select__value-container {
    padding: 10px;
  }

  .react-select__indicator {
    color: black;
  }

  .react-select__menu {
    z-index: 9999;
    text-align: left;
    border: 0px;
  }
`;



const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background: white;
  border-top: 1px solid #ddd;
  position: sticky;
  bottom: 0;
`;

const SaveButton = styled.button`
  background: #ff872f;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  cursor: pointer;
`;

const CancelButton = styled.button`
  background: white;
  color: #ff872f;
  border: 1px solid #ff872f;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
`;
