import {
  CreateCreativeRequestEarningsInput,
  CreativeRequestEarnings,
  CREATIVE_REQUEST_EARNINGS_RESPONSE,
  UpdateCreativeRequestEarningsInput,
} from 'API';
import { StyledDataGrid } from 'components/DataGrid';
import { format } from 'date-fns';
import {
  UseAddCreativeEarning,
  UseGetCreativeEarningsByCreativeId,
  UseUpdateCreativeEarning,
} from 'hooks';
import { DATA_GRID_ROW_OPTIONS } from 'hooks/utils';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { withAdmin } from 'state/admin';
import { getISODate } from 'utils/utils';
import EarningsModal from './components/EarningsModal/EarningsModal';
import TableHead from './components/TableHeader/TableHeader';
import { IconLoader } from 'components/loader';
import { CircularProgress } from '@mui/material';

export interface IEarningsTabProps {
  creativeRequestId: string;
  userProfileId: string;
  isApproved: boolean;
}

const EarningsTab = ({
  creativeRequestId,
  userProfileId,
  isApproved
}: IEarningsTabProps) => {
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCreativeEarning, setSelectedCreativeEarning] =
    useState<CreativeRequestEarnings | null>(null);
  const [tableData, setTableData] = useState<CREATIVE_REQUEST_EARNINGS_RESPONSE[]>([]);
  // New state: ensure loader is visible for a minimum of 2 seconds.
  const [minLoader, setMinLoader] = useState(true);

  const { addEarning, loading: addLoading } = UseAddCreativeEarning();
  const { updateEarning, loading: updateLoading } = UseUpdateCreativeEarning();
  const { data, loading: earningsLoading, error, getEarningsByCreative } =
    UseGetCreativeEarningsByCreativeId();

  // Trigger data load when this page is accessed.
  useLayoutEffect(() => {
    if (!isInitiallyLoaded) {
      getEarningsByCreative({ variables: { creativeRequestId } });
      setIsInitiallyLoaded(true);
    }
  }, [isInitiallyLoaded, creativeRequestId, getEarningsByCreative]);

  // Ensure the loader is shown for at least 2 seconds.
  useEffect(() => {
    const timer = setTimeout(() => {
      setMinLoader(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  // Update table data when earnings data changes.
  useEffect(() => {
    const items = (data?.getCreativeEarningsByCreative?.items || []) as unknown as CREATIVE_REQUEST_EARNINGS_RESPONSE[];
    if (items) {
      const formattedItems = items.map((i, idx) => ({ ...i, id: idx }));
      setTableData(formattedItems);
    }
  }, [data]);

  const onReset = useCallback(async () => {
    setIsModalOpen(false);
    setSelectedCreativeEarning(null);
    await getEarningsByCreative({ variables: { creativeRequestId } });
  }, [creativeRequestId, getEarningsByCreative]);

  const onAddCreativeEarning = async (
    dataInput: CreateCreativeRequestEarningsInput | UpdateCreativeRequestEarningsInput
  ) => {
    if (selectedCreativeEarning) {
      await updateEarning({
        variables: {
          input: {
            creatorId: userProfileId,
            creativeRequestEarningId: selectedCreativeEarning.creativeRequestEarningId,
            amount: Number(((Number(dataInput.amount) || 0) * 0.1).toFixed(3)),
          },
        },
      });
      toast.success('Updated');
      return onReset();
    }

    const date = new Date();
    date.setMonth(dataInput.month as unknown as number);
    date.setDate(1);
    const formattedMonth = format(date, 'yyyy-MM-dd');

    const item = {
      creativeRequestId,
      ...(dataInput.toDate && { toDate: getISODate(dataInput.toDate) }),
      ...(dataInput.fromDate && { fromDate: getISODate(dataInput.fromDate) }),
      amount: Number((Number(dataInput.amount) * 0.1).toFixed(2)),
      month: formattedMonth,
    } as CreativeRequestEarnings;

    await addEarning({
      variables: { ...item, userProfileId },
    });
    await onReset();
    toast.success('Created successfully');
  };

  const onUpdateEarning = (earning: CreativeRequestEarnings) => {
    setIsModalOpen(true);
    setSelectedCreativeEarning(earning);
  };

  const onToggleEarningModal = () => {
    setSelectedCreativeEarning(null);
    setIsModalOpen(true);
  };

  const tableHead = TableHead({
    onAddEarning: onToggleEarningModal,
    onUpdateEarning,
  });

  // Show loader if earnings are loading or if the minimum loader time has not passed.
  if (earningsLoading || minLoader) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  // If there's an error, display an error message.
  if (error) {
    return (
      <div className="flex items-center justify-center h-full">
        <h2 className="text-center text-lg font-bold">
          There was an error loading earnings. Please, if this issue persists, contact support.
        </h2>
      </div>
    );
  }

  // If loading is complete and no earnings were found, display a message.
  if (!earningsLoading && tableData.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <h2 className="text-center text-lg font-bold">
          This user has no earning yet. If this is incorrect please retry the earning import.
        </h2>
      </div>
    );
  }

  if (!earningsLoading && !isApproved) {
    return (
      <div className="flex items-center justify-center h-full">
        <h2 className="text-center text-lg font-bold">
          Please ensure this user has been both admin and brand approved
        </h2>
      </div>
    );
  }

  return (
    <div>
      <StyledDataGrid
        className="max-w-[50%]"
        rows={tableData}
        columns={tableHead}
        paginationMode="client"
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 10,
            },
          },
          sorting: {
            sortModel: [
              { field: 'month', sort: 'desc' },
              { field: 'updatedAt', sort: 'desc' },
            ],
          },
        }}
        pageSizeOptions={DATA_GRID_ROW_OPTIONS}
        disableRowSelectionOnClick={true}
        disableColumnResize={true}
        disableAutosize={true}
        disableColumnMenu={true}
        autosizeOnMount={true}
        disableColumnSelector={true}
        autosizeOptions={{
          expand: true,
          includeHeaders: true,
          includeOutliers: true,
        }}
      />

      <EarningsModal
        onClose={onReset}
        isOpen={isModalOpen}
        onSubmit={onAddCreativeEarning}
        earning={selectedCreativeEarning}
        isLoading={addLoading || updateLoading}
      />
    </div>
  );
};

export default withAdmin(EarningsTab);
