import React from "react";

interface TermsAndConditionsProps {
    isChecked: boolean;
    onCheckChange: (checked: boolean) => void;
    onConfrim: () => void;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
    isChecked,
    onCheckChange,
    onConfrim,
}) => {
    return (
        <div className="inset-0 flex items-center justify-center">
            <div className="w-full max-w-md p-6 bg-white rounded-lg text-center">
                {/* Heading */}
                <h2 className="text-[18px] font-bold text-gray-800 mb-2" style={{ fontFamily: "Oswald, sans-serif" }}>
                    Final Step: Terms and Conditions
                </h2>

                {/* Instruction */}
                <p className="text-[15px] text-gray-700 mb-4" style={{ fontFamily: "Inter, sans-serif" }}>
                    Please review and accept our terms and conditions
                </p>

                {/* Checkbox */}
                <div className="flex items-center justify-center mb-4">
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => onCheckChange(e.target.checked)}
                        className="w-5 h-5 mr-2 cursor-pointer"
                        data-cy="terms-checkbox"
                    />
                    <p className="text-[15px] text-gray-700" style={{ fontFamily: "Inter, sans-serif" }}>
                        I agree to the{" "}
                        <span
                            className="text-blue-600 cursor-pointer underline"
                            onClick={() =>
                                window.open(
                                    `${process.env.REACT_APP_URL}/terms-and-conditions`,
                                    "_blank"
                                )
                            }
                        >
                            terms and conditions
                        </span>
                    </p>
                </div>

                {/* Confirm Button */}
                <button
                    className={`mt-4 px-6 py-3 text-lg font-semibold rounded-lg transition 
                        ${isChecked ? "bg-black text-white hover:bg-gray-800" : "bg-gray-400 text-gray-700 cursor-not-allowed"} 
                        w-full`}
                    disabled={!isChecked}
                    onClick={onConfrim}
                >
                    Confirm
                </button>
            </div>
        </div>
    );
};

export default TermsAndConditions;
