import { FC, useEffect, useMemo } from 'react';
import { withPayment } from 'state/payment';
import Table, { Tdata } from 'components/table/Table';
import { UnknownType } from 'utils';
import { UseListCreativeRequestEarnings } from '../../hooks';
import './paymentCard.css';

export type PaymentCardProps = {
  creatorId?: string;
};

const PaymentCard: FC<PaymentCardProps> = ({ creatorId }) => {
  const { getAllEarnings, allEarningsData, loading } = UseListCreativeRequestEarnings(creatorId);

  useEffect(() => {
    getAllEarnings();
  }, [getAllEarnings]);

  const transactions = useMemo(() => allEarningsData || [], [allEarningsData]);

  const filteredTransactions = transactions
    .filter((e: any) => e?.amount && e.amount > 0)
    .sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

  // Group payments by month and sum total earnings per month
  const groupedEarnings: Record<string, number> = filteredTransactions.reduce(
    (acc: Record<string, number>, transaction: any) => {
      const date = new Date(transaction.month);
      const monthKey = `${date.toLocaleString("en-GB", { month: "long" })} ${date.getFullYear()}`;
      if (!acc[monthKey]) {
        acc[monthKey] = 0;
      }
      acc[monthKey] += transaction.amount;
      return acc;
    },
    {} as Record<string, number>
  );

  const earningsTableData = Object.entries(groupedEarnings)
    .map(([month, totalAmount]) => ({
      date: month,
      amount: `$${(totalAmount ?? 0).toFixed(2)}`, // Ensures totalAmount is never undefined
    }))
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  // Define table header columns
  const TABLE_HEADER_CELLS = [
    {
      key: 'date',
      label: 'Month',
      className: 'text-right text-[14px] font-[700] font-oswald uppercase',
    },
    {
      key: 'amount',
      label: 'Total Earnings',
      className: 'text-right text-[14px] font-[700] font-oswald uppercase',
    },

  ];

  // Extract keys for the table rows
  const tableRowKeys = TABLE_HEADER_CELLS.map((col) => col.key);

  return (
    <Table
      data={earningsTableData as Tdata[]}
      mainlyData={earningsTableData as Tdata[]}
      rows={tableRowKeys}
      loading={loading}
      pagination={1}
      extended={false}
      borderColor="#FF872F"
      textPosition="text-right"
      noDataMessage="Data not available. If this is incorrect, please contact support."
      header={{
        title: 'Payment History',
        icon: '',
        search: true,
        tooltip: "Only earnings from 2025 onwards will be displayed.",
        tooltipPosition: "right"
      }}
    />
  );

};

export default withPayment(PaymentCard);
