import { Auth } from 'aws-amplify';
import { FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { withProfile } from 'state/profileSteps';
import {
  AuthProps,
  initialAuthContext,
  ProfileProps,
  UnAuthRoutes,
} from 'utils';
import { withAuth } from '../../state/auth';

const LogoutPage: FC<ProfileProps & AuthProps> = ({
  cleanProfileState,
  setAuthState,
}) => {
  const navigate = useNavigate();
  const hasLoggedOut = useRef(false); // 🔹 Prevent double execution in Strict Mode

  const logUserOut = async (): Promise<void> => {
    if (hasLoggedOut.current) return; // 🔹 Prevent duplicate calls
    hasLoggedOut.current = true;

    try {
      await Auth.signOut();
      console.log('User successfully logged out.');

      // ✅ Clear session storage so step 4 is shown again on next login
      sessionStorage.removeItem('hasSeenStep4');

      setAuthState({ ...initialAuthContext });
      cleanProfileState();

      navigate(UnAuthRoutes.Login); // 🔹 Only redirect after logout completes
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  useEffect(() => {
    logUserOut();
  }, []);

  return null; // 🔹 No need for <Fragment />, since nothing is rendered
};

export default withAuth(withProfile(LogoutPage));
