import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch, FC, SetStateAction } from 'react';

interface IInterestedModalProps {
    brandBrief?: { BriefName?: string };
    isOpen: boolean;
    onCancel?: () => void;
    onSubmit?: () => Promise<void>;
    setIsInterestedModalOpen: Dispatch<SetStateAction<boolean>>;
}

const InterestedModal: FC<IInterestedModalProps> = ({ isOpen, onCancel, onSubmit, setIsInterestedModalOpen }) => {
    const handleClose = () => {
        // No additional logic; just closes the modal
        setIsInterestedModalOpen(false)
    };

    return (
        <Dialog open={isOpen} fullWidth={true} maxWidth="sm" onClose={handleClose}>
            <DialogTitle
                sx={{
                    padding: '24px',
                    textAlign: 'left',
                    borderBottom: 'none',
                    position: 'relative', // Position relative for the close icon
                }}
            >
                <Typography
                    className="font-oswald"
                    sx={{
                        fontWeight: 600,
                        fontSize: '24px',
                        textTransform: 'uppercase',
                    }}
                >
                    Thanks for checking out this activation
                </Typography>

                {/* Close Icon */}
                <IconButton
                    aria-label="close"
                    onClick={() => { setIsInterestedModalOpen(false) }}
                    sx={{
                        position: 'absolute',
                        right: '16px',
                        top: '16px',
                        color: '#000', // Black color for the icon
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ textAlign: 'left', padding: '24px' }}>
                <Typography
                    sx={{
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '16px',
                        fontWeight: 400,
                    }}
                >
                    Are you interested in creating content for this activation?
                </Typography>
                <div
                    style={{
                        borderBottom: '1px solid #E0E0E0',
                        margin: '24px auto 0 auto', // Center the line and add spacing below
                        width: '100%', // Adjust the width to not reach the edges
                    }}
                ></div>
            </DialogContent>

            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '16px',
                }}
            >
                <Button
                    onClick={onSubmit}
                    sx={{
                        fontSize: '16px',
                        textTransform: 'uppercase',
                        fontFamily: 'Inter, sans-serif',
                        color: '#007BFF', // Blue for Yes
                    }}
                >
                    Yes
                </Button>
                <Button
                    onClick={onCancel}
                    sx={{
                        fontSize: '16px',
                        textTransform: 'uppercase',
                        fontFamily: 'Inter, sans-serif',
                        color: '#007BFF', // Blue for No
                    }}
                >
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InterestedModal;
