import OAuthButton from 'components/profileComponent/OAuthButton/OAuthButton';
import React from 'react';
import TikTokLogo from '../../assets/images/tiktok-log.png';

import {
  InputField,
  LinkingContainer,
  PlatformBody,
  PlatformHeading,
  PlatformInfo,
  PlatformInputContainer,
  PlatformLogo,
  PlatformName,
  PlatformRow,
} from './LinkingHandlerStyles';

interface LinkingHandlerProps {
  formState: any;
  isEditing: boolean;
  setFormState: (state: any) => void;
  userId?: string;
  showHeading?: boolean;
}

const LinkingHandler: React.FC<LinkingHandlerProps> = ({
  formState,
  isEditing,
  setFormState,
  userId,
  showHeading,
}) => {
  return (
    <LinkingContainer>
      {showHeading && (
        <>
          {/* 🎯 New Heading */}
          <PlatformHeading>TikTok Account Linking</PlatformHeading>
          <PlatformBody>
            To get the most out of your experience, link your TikTok account
            now. This helps us personalize your profile and provide better
            opportunities.
            <br /> If you'd prefer to do this later, you can skip for now and
            link it anytime from your profile settings.
          </PlatformBody>
        </>
      )}

      <PlatformRow>
        <PlatformLogo src={TikTokLogo} alt="TikTok" />
        <PlatformInfo>
          <PlatformInputContainer>
            <PlatformName>TikTok Handle</PlatformName>
            <InputField
              type="text"
              value={
                formState?.tiktok_username?.trim()
                  ? formState.tiktok_username
                  : 'N/A'
              }
              readOnly
              isEditing={false}
            />
            {isEditing && (
              <OAuthButton
                providerName="tiktok"
                userId={formState?.id || userId}
                isLinked={!!formState?.tiktok_username}
                setFormState={setFormState}
              />
            )}
          </PlatformInputContainer>
        </PlatformInfo>
      </PlatformRow>
    </LinkingContainer>
  );
};

export default LinkingHandler;
