import { GridColDef, GridSortItem } from '@mui/x-data-grid';

export const HEADER_CELLS: GridColDef[] = [
  { field: 'name', headerName: 'Name', flex: 1 },
  {
    field: 'createdAt',
    headerName: 'Sign Up',
    filterable: false,
    flex: 1,
    renderCell: (params) =>
      params.value ? new Date(params.value).toLocaleDateString() : 'N/A',
  },
  { field: 'uniqueId', headerName: 'Unique Id', flex: 1 },
  { field: 'email', headerName: 'Email Address', flex: 1 },
  { field: 'phoneNumber', headerName: 'Mobile Number', flex: 1 },
  { field: 'tiktok_video_count', headerName: 'Tiktok Video Count', flex: 1 },
  {
    field: 'tiktok_follower_count',
    headerName: 'Tiktok Follower Count',
    flex: 1,
  },
  {
    field: 'instagram_media_count',
    headerName: 'Instagram Media Count',
    flex: 1,
  },
  {
    field: 'instagram_followers_count',
    headerName: 'Instagram Followers Count',
    flex: 1,
  },
  {
    field: 'lastLoginDate',
    headerName: 'Last Login Date',
    flex: 1,
  },
  {
    headerAlign: 'center',
    align: 'center',
    field: 'userPaymentDetails',
    headerName: 'Bank details',
    flex: 1,
  },
  {
    field: 'details',
    headerName: 'Details',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
];

export const SORT_MODEL: GridSortItem[] = [
  { field: 'uniqueId', sort: 'desc' },
  { field: 'name', sort: 'desc' },
  { field: 'email', sort: 'desc' },
  { field: 'phoneNumber', sort: 'desc' },
  { field: 'lastLoginDate', sort: 'desc' },
];

export enum AdminTabs {
  General = 'general',
  Profile = 'profile',
}

export enum AdminCreativeRequestsTabs {
  General = 'general',
  Details = 'details',
  Earnings = 'earnings',
}
