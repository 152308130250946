import { Button, CircularProgress, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InterestedUser } from 'API';
import { Storage } from 'aws-amplify';
import { downloadFileFromUrl } from 'components';
import { UseGetBrandBriefById, UseUpdateBrandBrief } from 'hooks';
import AuthorizeTikTokHandler from 'pages/authorizeTikTok/authorizeTikTokHandler';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProfileContext } from 'state/profileSteps';
import BriefDetailsHeader from './components/BriefDetailsHeader/BriefDetailsHeader';
import CreativeInspirationsDisplay from './components/CreativeInspirationsDisplay/CreativeInspirationsDisplay';
import InformationDisplay from './components/InformationDisplay/InformationDisplay';
import InterestedModal from './components/InterestedModal/InterestedModal';
import BeforeYouStartFilming from 'components/BeforeYouStartFilming/BeforeYouStartFilming';
import { AuthRoutes } from 'utils';

const CreatorBriefDetails = () => {
  const [searchParams] = useSearchParams();
  const {
    getBrief,
    data: brandBrief,
    loading: briefLoading,
  } = UseGetBrandBriefById();
  const { updateBrief } = UseUpdateBrandBrief();

  const { profileState } = useContext(ProfileContext);
  const navigate = useNavigate();
  const [isInterestedModalOpen, setIsInterestedModalOpen] = useState(false);
  const [isContentSubmissionModalOpen, setIsContentSubmissionModalOpen] =
    useState(false);

  const [ExpandedTabs, setExpandedTabs] = useState<string[]>(["activation-overview"]);
  const [pauseVideo, setPauseVideo] = useState(false);

  const [isBeforeYouStartFilmingVisible, setIsBeforeYouStartFilmingVisible] = useState(false);

  const getBrandBrief = useCallback(() => {
    const briefId = searchParams.get('id');
    if (!briefId) {
      return;
    }

    getBrief({ variables: { id: briefId } });
  }, [getBrief, searchParams]);

  useEffect(() => {
    const isConfirmation = searchParams.get('isConfirmation') === 'true';
    const needsConfirmation = searchParams.get('needsConfirmation') === 'true';
    const userExistsInList = brandBrief?.interestedUsers?.some(
      (u) => u.id === profileState.data?.id && u.hasBeenShown
    );

    setIsInterestedModalOpen(
      isConfirmation && needsConfirmation && !userExistsInList
    );
  }, [searchParams, brandBrief, profileState.data?.id]);

  useLayoutEffect(() => {
    getBrandBrief();
  }, [getBrandBrief]);

  const handleInterestedness = useCallback(
    async (isInterested: boolean) => {
      if (!brandBrief?.id || !profileState?.data?.id) {
        return;
      }

      const updatedInterestedUsers = Object.values(

        (brandBrief?.interestedUsers || [])
          // @ts-ignore
          .map(({ __typename, ...u }) => u)
          .concat([
            { isInterested, hasBeenShown: true, id: profileState.data.id },
          ])
          .reduce((acc, v) => ({ ...acc, [v.id]: v }), {})
      ) as InterestedUser[];

      await updateBrief({
        variables: {
          input: {
            id: brandBrief.id,
            interestedUsers: updatedInterestedUsers,
          },
        },
        errorPolicy: 'ignore',
      });
      setIsInterestedModalOpen(false);
      if (isInterested) {
        setIsBeforeYouStartFilmingVisible(true)
      } else {
        navigate(AuthRoutes.BrandBrief);
      }

    },
    [
      brandBrief?.id,
      brandBrief?.interestedUsers,
      profileState?.data?.id,
      updateBrief,
    ]
  );

  const onFilesDownload = useCallback(async () => {
    if (brandBrief?.brandBriefFilesUrl) {
      const url = await Storage.get(brandBrief.brandBriefFilesUrl);
      downloadFileFromUrl(url);
    }
  }, [brandBrief?.brandBriefFilesUrl]);

  const onCloseContentSubmissionModal = () => {
    setIsContentSubmissionModalOpen(false);
  };

  const onSubmitContent = () => {
    setIsContentSubmissionModalOpen(true);
  };

  const isSubmitAvailable = useMemo(() => {
    const requiredTabs = ['activation-overview', 'messaging', 'dos-donts-maybes', "creative-inspiration"];
    return requiredTabs.every((tab) => ExpandedTabs.includes(tab));
  }, [ExpandedTabs]);

  //@ts-ignore
  const CustomTooltip = styled(({ className, ...props }) => (
    //@ts-ignore
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: '#FF872F',
      color: '#FFF',
      fontSize: '16px',
      fontFamily: 'Oswald, sans-serif',
      padding: '10px 20px',
      borderRadius: '8px',
      marginTop: '5px',
      top: "8px"
    },
    [`& .MuiTooltip-arrow`]: {
      color: '#FF872F',
    },
  }));

  return (
    <>
      {briefLoading ? (
        <div className="flex h-full w-full justify-center items-center">
          <CircularProgress className="w-8 h-8 text-main-teal" />
        </div>
      ) : (
        <>

          {isBeforeYouStartFilmingVisible && (
            <BeforeYouStartFilming
              isOpen={isBeforeYouStartFilmingVisible}
              onClose={() => setIsBeforeYouStartFilmingVisible(false)}
              brandBrief={brandBrief}
            />
          )}

          <div
            className="relative z-40 w-full h-full flex flex-col bg-white"
          >
            {/* Header Section */}
            <div className="flex lg:gap-x-[30px] gap-x-[20px] xl:gap-y-[0] gap-y-[20px] justify-between flex-wrap">
              <BriefDetailsHeader brandBrief={brandBrief} />
            </div>

            {/* Main Content Section */}
            <div
              className="lg:grid grid-cols-2 gap-[30px] overflow-auto flex-grow"
              style={{ paddingBottom: '100px' }}
            >
              <CreativeInspirationsDisplay brandBrief={brandBrief} pauseVideo={pauseVideo} />
              <InformationDisplay brandBrief={brandBrief} setExpandedTabs={setExpandedTabs} onFilesDownload={onFilesDownload} setPauseVideo={setPauseVideo} />
            </div>

            {/* Fixed Footer Section */}
            <div
              className="fixed left-0 w-full bg-white z-50 flex justify-center gap-x-[30px] shadow-md"
              style={{
                padding: '15px 0',
                borderTop: '1px solid #E0E0E0',
                bottom: window.innerWidth <= 1280 ? '60px' : '0px', // Shifts footer up on smaller screens
              }}
            >
              {/* Tooltip Wrapping the Submit Button */}
              {/* @ts-ignore */}
              <CustomTooltip
                title={
                  !isSubmitAvailable
                    ? 'Please read all sections of the brief before submitting.'
                    : ''
                }
                placement="top"
                arrow
              >
                <span>
                  <Button
                    id="submit-content-button"
                    disabled={!isSubmitAvailable}
                    disableRipple
                    onClick={onSubmitContent}
                    sx={{
                      textTransform: 'capitalize',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 500,
                      fontSize: '14px',
                      padding: '15px 100px',
                      borderRadius: '6px',
                      backgroundColor: isSubmitAvailable ? '#FF872F' : '#E0E0E0',
                      color: isSubmitAvailable ? '#FFF' : '#A0A0A0',
                      '&:hover': {
                        backgroundColor: isSubmitAvailable ? '#FF7600' : '#E0E0E0',
                      },
                    }}
                  >
                    Submit Content
                  </Button>
                </span>
              </CustomTooltip>
            </div>

          </div>

        </>
      )}

      {isContentSubmissionModalOpen && (
        <AuthorizeTikTokHandler
          onCross={onCloseContentSubmissionModal}
          briefId={brandBrief?.id || ''}
          BriefName={brandBrief?.BriefName || ''}
          brandName={brandBrief?.brandProfile?.userProfile?.name || ''}
          briefDescription={
            brandBrief?.overview || brandBrief?.brandBriefDetails
          }
          brandImageUrl={''}
          isSparkAds={!!brandBrief?.tikTokData?.tikTokSparkAds}
          disableBackground={true}
          isCreatorBrief={true}
        />
      )}

      <InterestedModal
        brandBrief={brandBrief}
        isOpen={isInterestedModalOpen}
        setIsInterestedModalOpen={setIsInterestedModalOpen}
        onSubmit={() => handleInterestedness(true)}
        onCancel={() => handleInterestedness(false)}
      />
    </>
  );
};

export default CreatorBriefDetails;
