import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { CreativeRequest } from 'API';
import {
  EditWithPencilIcon,
  MenuIcon5,
  OpenFolderIcon,
} from 'assets/icons/icons'; // Replace with your actual icon names
import RevisionContentModal from 'components/revisionContentModal/revisionContentModal';
import Video from 'components/Video/Video';
import React, { FC, useState } from 'react';
import ChatPanel from './ChatPannel/ChatPannel'; // ✅ Create this chat accordion as a separate component or inline if needed.
import CreatorDetailsHeader from './CreatorDetailsHeader/CreatorDetailsHeader';

interface CreativeDetailsComponentProps {
  creativeUniqueId: string;
  brandName: string;
  activationName: string;
  status: string;
  videoUrl?: string;
  earnings?: string;
  activationOverview?: string;
  messaging?: string;
  chatMessages?: any;
  setShowDetails?: any;
  adminStatus?: string;
  request: CreativeRequest;
  isLoading?: boolean;
}

const CreativeDetailsComponent: FC<CreativeDetailsComponentProps> = ({
  creativeUniqueId,
  brandName,
  activationName,
  status,
  videoUrl,
  earnings = 'Your earnings will appear here once approved.',
  activationOverview = 'No activation details available.',
  messaging = 'No messaging details available.',
  setShowDetails,
  adminStatus,
  request,
  isLoading,
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [showContent, setShowContent] = useState(false);
  const [selectedRequest, setSelectedRequest] =
    useState<CreativeRequest | null>(null);

  const buttonStyles = {
    backgroundColor: '#FF872F',
    textTransform: 'none',
    fontWeight: 600,
    px: '24px',
    py: '12px',
    borderRadius: '6px',
    fontSize: '16px',
    fontFamily: 'Oswald, sans-serif',
    width: '200px', // fixed button width
    '&:hover': { backgroundColor: '#e9761a' },
  };

  const disabledButtonStyles = {
    backgroundColor: '#e0e0e0',
    textTransform: 'none',
    fontWeight: 600,
    px: '24px',
    py: '12px',
    borderRadius: '6px',
    fontSize: '16px',
    fontFamily: 'Oswald, sans-serif',
    width: '200px',
    color: '#a1a1a1',
    cursor: 'not-allowed',
  };

  const accordionIcons: { [key: string]: React.ReactNode } = {
    earnings: <MenuIcon5 fillColor="#000" />,
    'activation-overview': <OpenFolderIcon fontSize="small" />,
    messaging: <EditWithPencilIcon fontSize="small" />,
  };

  const handleAccordionChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className="flex flex-col gap-6 p-6">
      {showContent && selectedRequest && (
        <div
          className="inspiration-panel pointer-events-auto z-[50]"
          onClick={() => setShowContent(false)}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="z-[99] md:w-full flex flex-col justify-center items-center w-[90%]"
          >
            <RevisionContentModal
              onClose={(): void => setShowContent(false)}
              request={selectedRequest}
              videoUrl={selectedRequest?.tiktokCreativeUrl || ''}
              createAdPayload={undefined}
              isSparkAds={false}
            />
          </div>
        </div>
      )}
      <div className="flex lg:gap-x-[30px] gap-x-[20px] xl:gap-y-[0] gap-y-[20px] justify-between flex-wrap">
        <CreatorDetailsHeader
          creativeUniqueId={creativeUniqueId}
          brandName={brandName}
          activationName={activationName}
          status={status}
        />
      </div>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '50px',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 mt-6">
          {/* Left column */}
          <div>
            {videoUrl && (
              <div className="relative min-h-[400px] bg-[#F5F1E8] p-4 rounded-3xl flex justify-center items-center mb-[30px]">
                <div>
                  <Video url={''} videoUrl={videoUrl} pauseVideo={true} />
                </div>
              </div>
            )}

            {[
              { panel: 'earnings', title: 'Earnings', content: earnings },
              {
                panel: 'activation-overview',
                title: 'The Activation Overview',
                content: activationOverview,
              },
              { panel: 'messaging', title: 'Messaging', content: messaging },
            ].map(({ panel, title, content }) => (
              <Accordion
                key={panel}
                expanded={expanded === panel}
                onChange={handleAccordionChange(panel)}
                sx={{
                  border: 'none',
                  boxShadow: 'none',
                  borderRadius: '16px',
                  mb: 2,
                  '&.Mui-expanded': {
                    border: '1px solid #E0E0E0',
                    borderRadius: '16px',
                  },
                  '&:before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    backgroundColor: '#F5F1E8',
                    borderRadius: '16px',
                    minHeight: '64px',
                    '&.Mui-expanded': {
                      borderRadius: '16px 16px 0 0',
                    },
                    '& .MuiAccordionSummary-content': {
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      fontSize: '18px',
                      fontFamily: 'Oswald, sans-serif',
                      fontWeight: 600,
                      textTransform: 'uppercase',
                    },
                  }}
                >
                  {accordionIcons[panel]}
                  {title}
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    background: '#fff',
                    padding: '20px',
                    borderRadius: '0 0 16px 16px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#1D1C1C',
                      fontFamily: 'Inter, sans-serif',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {content}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
            <div className=" xl:h-[100px] md:h-[0px]" />

            <div
              className="fixed bottom-0 left-0 w-full bg-white shadow-[0_-2px_10px_rgba(0,0,0,0.05)] flex justify-center gap-4 z-50 p-4
            xl:bottom-0
            ss:bottom-[60px]"
            >
              <Button
                variant="contained"
                sx={
                  adminStatus === 'Revision'
                    ? buttonStyles
                    : disabledButtonStyles
                }
                disabled={adminStatus !== 'Revision'}
                onClick={() => {
                  setSelectedRequest(request);
                  setShowContent(true);
                }}
              >
                Re-submit Content
              </Button>
              <Button
                variant="contained"
                sx={buttonStyles}
                onClick={() => setShowDetails(false)}
              >
                Back to submissions
              </Button>
            </div>
          </div>

          {/* Right column — Add the chat section here */}
          <div>
            <ChatPanel
              msgFrom={'user'}
              context={creativeUniqueId}
              creatorId={request?.creatorId ?? ''}
            />
          </div>
          <div className=" xl:h-[0px] md:h-[100px] ss:h-[120px] " />
        </div>
      )}
    </div>
  );
};

export default CreativeDetailsComponent;
