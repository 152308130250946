import { FC, useEffect, useState } from 'react';
import VerificationInput from 'react-verification-input';
import styled from 'styled-components';

type MobileOtpVerificationProps = {
    onComplete: (step: number) => void;
    resendOtp: () => void;
    verifyOtp: (otp: string) => Promise<boolean>
    loading?: boolean;
    mobileOtpError: string
    setMobileOtpError: (errorMsg: string) => void;
};

export const MobileOtpVerification: FC<MobileOtpVerificationProps> = ({
    onComplete,
    resendOtp,
    verifyOtp,
    loading,
    mobileOtpError,
    setMobileOtpError
}) => {
    const [mobileOTP, setMobileOtp] = useState<string>('');
    const [mobileTimer, setMobileTimer] = useState<number>(30);
    const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);
    const [isVerifyDisabled, setIsVerifyDisabled] = useState<boolean>(true);

    // Countdown for mobile OTP
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (mobileTimer > 0) {
            timer = setTimeout(() => setMobileTimer(mobileTimer - 1), 1000);
        } else {
            setIsResendDisabled(false);
        }
        return () => clearTimeout(timer);
    }, [mobileTimer]);

    const handleResendOtp = () => {
        resendOtp();
        setIsResendDisabled(true);
        setMobileTimer(30); // Reset timer
        setMobileOtpError(""); // Clear error on resend
    };

    const handleOtpChange = (otp: string) => {
        setMobileOtpError("");
        setMobileOtp(otp);
        if (otp.length === 4) {
            setMobileOtpError("");
            setIsVerifyDisabled(false);
        } else {
            setIsVerifyDisabled(true);
        }
    };

    const handleVerifyOtp = async () => {
        if (mobileOTP.length !== 4) {
            setMobileOtpError('Please enter a valid 4-digit OTP.');
            return;
        }

        if (await verifyOtp(mobileOTP)) {
            setMobileOtpError("");
            onComplete(3);
        } else {
            setMobileOtp('');
            setMobileOtpError('Invalid OTP. Please try again.');
        }
    };

    return (
        <Wrapper>
            <h2 className="text-[18px] font-bold text-gray-800 mb-2" style={{ fontFamily: "Oswald, sans-serif" }}>
                Step 2: Mobile OTP
            </h2>
            <p className="text-[15px] text-gray-700 mb-4" style={{ fontFamily: "Inter, sans-serif" }}>
                A verification code has been sent to your mobile number via Whatsapp.
            </p>

            {/* OTP Input Box */}
            <VerificationContainer>
                <VerificationInput
                    classNames={{
                        container: 'container',
                        character: 'character',
                        characterInactive: 'character--inactive',
                        characterSelected: 'character--selected',
                    }}
                    length={4} // Restrict to 4 characters
                    onChange={handleOtpChange}
                    value={mobileOTP}
                />
                {mobileOtpError && <ErrorText>{mobileOtpError}</ErrorText>}
            </VerificationContainer>

            <VerifyButton
                onClick={handleVerifyOtp}
                disabled={isVerifyDisabled || loading}
            >
                {loading ? 'Verifying...' : 'Verify OTP'}
            </VerifyButton>

            <ResendWrapper>
                {isResendDisabled ? (
                    <span>We can send you another in ({mobileTimer}s)</span>
                ) : (
                    <ResendButton onClick={handleResendOtp} disabled={isResendDisabled || loading}>
                        Resend Mobile OTP
                    </ResendButton>
                )}
            </ResendWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const VerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`;

const ErrorText = styled.span`
  color: #c0241a;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
`;

const ResendWrapper = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: #333;
`;

const ResendButton = styled.button`
  margin-top: 4px;
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;

const VerifyButton = styled.button`
  margin-top: 16px;
  background-color: #101010;
  color: white;
  font-size: 14px;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export default MobileOtpVerification;
