import { UserProfile, USER_TYPES } from 'API';
import { Auth } from 'aws-amplify';
import LinkingHandler from 'components/LinkingHandler/LinkingHandler';
import MobileNumberInput from 'components/onboardingProcess/MobileNumberInput/MobileNumberInput';
import MobileOtpVerification from 'components/onboardingProcess/MobileOtpVerification/MobileOtpVerification';
import TermsAndConditions from 'components/onboardingProcess/TermsAndConditions/TermsAndConditions';
import { UseSearchCreativeUsers } from 'hooks/query/useAdminActions';
import { useWhatsApp } from 'hooks/useWhatsApp';
import AdminDashboard from 'pages/adminDashboard/adminDashboard';
import BrandDashboard from 'pages/brandDashboard/brandDashboard';
import CreatorDashboard from 'pages/creatorDashboard/creatorDashboard';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withProfile } from 'state/profileSteps';
import { AuthRoutes, ProfileProps } from 'utils';
import Modal from '../../components/authentication/TermsAndConditions/modal';

const Dashboard: React.FC<ProfileProps> = ({
  profileState: { data },
  editCreatorProfile,
}) => {
  const navigate = useNavigate();
  const [isShowModal, setIsShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [isChecked, setIsChecked] = useState(false);
  const [formState, setFormState] = useState<UserProfile>({} as UserProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckLoading, setIsCheckLoading] = useState(false);
  const [step, setStep] = useState(1); // Steps: 1 = Phone Input, 2 = OTP, 3 = T&Cs, 4 = TikTok Handle

  const { generateWhatsappOTP, verifyWhatsappOTP, loading } = useWhatsApp();
  const { searchCreativeUsers, creativeUsers } = UseSearchCreativeUsers(); // Import search function

  const [searchCompleted, setSearchCompleted] = useState(false);

  const handleValidPhoneNumber = async (phone: string) => {
    setPhoneNumber(phone);
    setError(''); // Reset any previous errors
    setSearchCompleted(false); // Reset flag before starting search

    try {
      // 🔍 Step 1: Search for the user by phone number
      setIsCheckLoading(true);
      await searchCreativeUsers({
        variables: {
          page: 0,
          pageSize: 10, // Only need to check one match
          search: phone,
        },
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
      });

      setSearchCompleted(true); // Mark search as completed after API call
    } catch (err) {
      setIsCheckLoading(false);
      console.error('Error searching for phone number:', err);
      setError('An error occurred. Please try again.');
    }
  };

  // 🔎 Step 2: Check if the number exists in creativeUsers **after data is fetched**
  useEffect(() => {
    if (!searchCompleted || !creativeUsers) return;
    setIsCheckLoading(false);
    const existingUser = creativeUsers?.find(
      (user: any) => user.phoneNumber === phoneNumber
    );

    if (existingUser?.isValid) {
      // 🚨 Step 3: If `isValid` is true, prevent usage of this number
      setError(
        'This phone number is already in use. Please use another one. If this is incorrect please contact EDCSquared Support'
      );
      return;
    }

    // ✅ Step 4: If `isValid` is false or user not found, proceed with OTP
    (async () => {
      try {
        const { response, error } = await generateWhatsappOTP(
          data?.id || '',
          phoneNumber,
          'generate'
        );

        if (error) {
          setError(error);
          setStep(1);
        }

        if (response?.success) {
          setError('');
          setStep(2);
        }
      } catch (err) {
        console.error('Error generating OTP:', err);
        setError('An error occurred. Please try again.');
      }
    })();
  }, [searchCompleted, creativeUsers]); // ✅ Run only when search completes and creativeUsers updates

  const handleVerifyOtp = async (otp: string): Promise<boolean> => {
    const { response, error } = await verifyWhatsappOTP(
      data?.id || '',
      phoneNumber,
      'check',
      otp
    );
    if (error) {
      setError(error);
      return false;
    }
    return response?.success && response?.data.valid;
  };

  const resendOtp = async (phone: string) => {
    const { response, error } = await generateWhatsappOTP(
      data?.id || '',
      phone,
      'generate'
    );
    if (error) setError(error);
    if (response?.success) setError('');
  };

  const onConfirm = () => {
    editCreatorProfile({
      country,
      termsAndConditions: true,
      phoneNumber,
      isValid: true,
    });
    setIsShowModal(false);
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (data?.userType === USER_TYPES.CREATIVE_USER) {
      const isEmpty = (value: any) =>
        value === null || value === undefined || value === '';

      if (
        isEmpty(data.termsAndConditions) ||
        isEmpty(data.country) ||
        isEmpty(data.phoneNumber) ||
        isEmpty(data.isValid) ||
        data.isValid === false
      ) {
        setIsShowModal(true);
      } else if (
        isEmpty(data.tiktok_username) &&
        !sessionStorage.getItem('hasSeenStep4')
      ) {
        setIsShowModal(true);
        setStep(4);
      }
    }
  }, [data]);

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      setFormState((prev) => ({
        ...prev,
        family_name: user.attributes.family_name || '',
        name: user.attributes.name || '',
        email: user.attributes.email || '',
        phone_number: user.attributes['custom:phoneNumber'] || '',
      }));
    } catch (error) {
      console.error('Error fetching user:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (formState.tiktok_username) {
      editCreatorProfile({
        tiktok_username: formState.tiktok_username,
      });

      setIsShowModal(false);
    }
  }, [formState]);

  useEffect(() => {
    getUser();
  }, []);

  // ✅ Skip TikTok step & save in session storage
  const handleSkipStep4 = () => {
    setIsShowModal(false);
    sessionStorage.setItem('hasSeenStep4', 'true');
  };

  if (data?.userType === USER_TYPES.CREATIVE_USER && isShowModal) {
    return (
      <Modal
        withCheckbox={step === 4} // Updated step count
        isOpen={isShowModal}
        title={
          step === 1 || step === 2
            ? 'Mobile Verification'
            : step === 3
            ? 'Terms & Conditions'
            : step === 4
            ? 'Account Linking'
            : 'Quick Update Required'
        }
        isLoading={isLoading}
        canSkip={step === 4}
        onSkip={handleSkipStep4} // ✅ Skip TikTok linking once per login
        customComponent={
          <>
            {/* Step 1: Mobile Number Input */}
            {step === 1 && (
              <MobileNumberInput
                onValidNumberChange={handleValidPhoneNumber}
                getCountry={setCountry}
                error={error}
                setError={setError}
                isLoading={isCheckLoading}
              />
            )}

            {/* Step 2: OTP Verification */}
            {step === 2 && (
              <MobileOtpVerification
                onComplete={setStep}
                resendOtp={() => resendOtp(phoneNumber)}
                verifyOtp={handleVerifyOtp}
                mobileOtpError={error}
                setMobileOtpError={setError}
              />
            )}

            {/* Step 3: Terms & Conditions */}
            {step === 3 && (
              <TermsAndConditions
                isChecked={isChecked}
                onCheckChange={setIsChecked}
                onConfrim={onConfirm}
              />
            )}

            {/* Step 4: TikTok Handle Verification */}
            {step === 4 && (
              <div style={{ marginLeft: '35px' }}>
                <LinkingHandler
                  userId={data?.id}
                  formState={formState}
                  setFormState={setFormState}
                  isEditing={true}
                  showHeading={true}
                />
              </div>
            )}
          </>
        }
        handleClose={() => navigate(AuthRoutes.Logout)}
        type="creator"
      />
    );
  }

  if (data?.userType === USER_TYPES.CREATIVE_USER)
    return <CreatorDashboard data={data} />;
  if (data?.userType === USER_TYPES.BRAND_USER) return <BrandDashboard />;
  if (data?.userType === USER_TYPES.ADMIN_USER) return <AdminDashboard />;

  return <Fragment />;
};

export default withProfile(Dashboard);
