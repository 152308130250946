/* =======================
   📌 PLATFORM STYLES
======================= */

import styled from 'styled-components';

export const PlatformRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const LinkingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* ✅ Align all child elements to the left */
  text-align: left; /* ✅ Ensures text inside follows left alignment */
  padding: 25px;
  width: 100%;
`;

export const PlatformLogo = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;

  @media (max-width: 600px) {
    align-self: center;
  }
`;

export const PlatformInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 250px;
  min-width: 250px;
  width: 100%;
  gap: 8px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
`;

export const PlatformInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;

  @media (max-width: 600px) {
    align-items: center;
    text-align: center;
  }
`;

export const PlatformName = styled.label`
  position: absolute;
  top: -10px;
  left: 12px;
  background: white;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  z-index: 1;
  transition: 0.2s ease-in-out;
`;

export const ActionButton = styled.button`
  background: #000;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: #444;
  }
`;

export const StatusIcon = styled.span`
  font-size: 18px;
  margin-left: 8px;
  color: ${(props) => (props.children === '✅' ? 'green' : 'red')};
`;

export const PlatformHeading = styled.h2`
  font-family: 'Oswald', sans-serif;
  color: #000; /* Ensures the text color is black */
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 8px;
  text-align: left; /* ✅ Left align the heading */
`;

export const PlatformBody = styled.h3`
  font-family: 'Inter', sans-serif;
  color: #000; /* Ensures the text color is black */
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: left; /* ✅ Left align the body text */
`;

export const InputField = styled.input<{ isEditing: boolean }>`
  padding: 12px;
  border: 1px solid #000;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  color: #333;
  background: white;
  pointer-events: ${(props) => (props.isEditing ? 'auto' : 'none')};
  user-select: ${(props) => (props.isEditing ? 'auto' : 'none')};

  &:focus {
    outline: none;
    border: 1px solid #000;
  }
`;
