import { useState } from 'react';

const API_URL: string =
  process.env.REACT_APP_WHATSAPP_API_URL ||
  'https://eeizhsuc10.execute-api.us-east-1.amazonaws.com/live/whatsappOtp';

interface WhatsAppResponse {
  success: boolean;
  message?: string;
  data?: any;
}

export const useWhatsApp = () => {
  const [loading, setLoading] = useState(false);

  const getFriendlyErrorMessage = (
    status: number | undefined,
    message: string
  ) => {
    if (!status) return 'An unexpected error occurred. Please try again.';
    switch (status) {
      case 400:
        return 'Invalid request. Please check your details and try again.';
      case 401:
        return 'Unauthorized request. Please check your credentials.';
      case 403:
        return 'Access denied. You do not have permission.';
      case 404:
        return 'The requested resource was not found.';
      case 500:
        return 'Something went wrong on our end. Please try again later.';
      default:
        return message || 'An error occurred. Please try again.';
    }
  };

  const generateWhatsappOTP = async (
    creatorId: string,
    number: string,
    directive: 'generate'
  ) => {
    setLoading(true);
    try {
      const res = await fetch(API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ creatorId, number, directive }),
      });
      const data = await res.json();
      setLoading(false);
      if (!res.ok)
        return {
          response: null,
          error: getFriendlyErrorMessage(res.status, data.message),
        };
      return { response: { success: true, data }, error: null };
    } catch (err) {
      setLoading(false);
      console.error('WhatsApp API Error:', err);
      return {
        response: null,
        error: 'An error occurred. Please try again or contact support.',
      };
    }
  };

  const verifyWhatsappOTP = async (
    creatorId: string,
    number: string,
    directive: 'check',
    otp: string
  ) => {
    setLoading(true);
    try {
      const res = await fetch(API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ creatorId, number, directive, otp }),
      });
      const data = await res.json();
      setLoading(false);
      if (!res.ok)
        return {
          response: null,
          error: getFriendlyErrorMessage(res.status, data.message),
        };
      return { response: { success: true, data }, error: null };
    } catch (err) {
      setLoading(false);
      console.error('WhatsApp API Error:', err);
      return { response: null, error: 'Network error. Please try again.' };
    }
  };

  // ✅ New function: send WhatsApp message
  const sendWhatsAppMessage = async (
    creatorId: string,
    number: string,
    uniqueId: string,
    notes: string
  ) => {
    setLoading(true);
    try {
      const res = await fetch(API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          creatorId,
          number,
          directive: 'message',
          messageType: 'comms',
          messageVariables: { uniqueId, notes },
        }),
      });
      const data = await res.json();
      setLoading(false);
      if (!res.ok)
        return {
          response: null,
          error: getFriendlyErrorMessage(res.status, data.message),
        };
      return { response: { success: true, data }, error: null };
    } catch (err) {
      setLoading(false);
      console.error('WhatsApp API Message Error:', err);
      return {
        response: null,
        error: 'An error occurred while sending the message.',
      };
    }
  };

  // ✅ New function: get WhatsApp messages
  const getWhatsAppMessages = async (creatorId: string, number: string) => {
    setLoading(true);
    try {
      const res = await fetch(API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          creatorId,
          number,
          directive: 'message',
          messageType: 'getMessages',
          messageVariables: {},
        }),
      });
      const data = await res.json();
      setLoading(false);
      if (!res.ok)
        return {
          response: null,
          error: getFriendlyErrorMessage(res.status, data.message),
        };
      return { response: { success: true, data }, error: null };
    } catch (err) {
      setLoading(false);
      console.error('WhatsApp API Get Messages Error:', err);
      return {
        response: null,
        error: 'An error occurred while retrieving messages.',
      };
    }
  };

  return {
    generateWhatsappOTP,
    verifyWhatsappOTP,
    sendWhatsAppMessage,
    getWhatsAppMessages,
    loading,
  };
};
