import {
  ManualDataInput,
  MetaDataInput,
  TikTokDataInput,
  YoutubeDataInput,
} from 'API';
import { z } from 'zod';
import { BRIEF_NAME_MIN_LENGTH } from './constants';

export const BrandBriefFirstStepSchema = z.object({
  BriefName: z
    .string()
    .min(1)
    .min(BRIEF_NAME_MIN_LENGTH, 'Invalid activation name'),
  objective: z.string().nonempty(),
  country: z.string().nonempty(),
  // TargetAudience: z.string().nonempty("Target audience is required"),
  adText: z
    .string()
    .min(1)
    .max(100, 'Ad caption must be between 1 to 100 characters'),
  brandBriefDetails: z.string().min(1),
  creativeInspirations: z.string().array().nullable().default(['', '', '', '']),
  active: z.boolean(),
  brandBriefFilesUrl: z.string().nullable(),
});

export const UpdateBrandBriefSecondStepSchema = z.object({
  id: z.string(),
  tikTokData: z.object({
    campaignId: z.string().default(''),
    adgroupId: z.string().default(''),
    adIdentityId: z.string().default(''),
    displayName: z.string().default(''),
    adCaption: z.string().default(''),
    callToAction: z.string().default(''),
    landingPageUrl: z.string().default(''),
    tikTokSparkAds: z.boolean().default(false),
  }),
  metaData: z.object({
    campaignId: z.string().default(''),
    adgroupId: z.string().default(''),
    callToAction: z.string().default(''),
    landingPageUrl: z.string().default(''),
    adCaption: z.string().default(''),
  }),
  youtubeData: z.object({
    campaignId: z.string().default(''),
    adgroupId: z.string().default(''),
    callToAction: z.string().default(''),
    landingPageUrl: z.string().default(''),
    adCaption: z.string().default(''),
  }),
  manualData: z.object({
    isManual: z.boolean(),
    adCaption: z.string().nullable().default(''),
  }),
});

export const TiktokDataDefaultValues: TikTokDataInput = {
  adIdentityId: '',
  adgroupId: '',
  campaignId: '',
  adCaption: '',
  displayName: '',
  tikTokSparkAds: false,
  callToAction: '',
  landingPageUrl: '',
};

export const MetaDataDefaultValues: MetaDataInput = {
  adgroupId: '',
  campaignId: '',
  adCaption: '',
  callToAction: '',
  landingPageUrl: '',
};

export const YoutubeDataDefaultValues: YoutubeDataInput = {
  adgroupId: '',
  campaignId: '',
  adCaption: '',
  callToAction: '',
  landingPageUrl: '',
};

export const ManualDataDefaultValues: ManualDataInput = {
  isManual: false,
  adCaption: '',
};
