import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';

interface VideoDialogProps {
  videoSrc: string;
  onVideoEnded?: () => void; // Callback when the video ends
}

const VideoDialog: React.FC<VideoDialogProps> = ({
  videoSrc,
  onVideoEnded,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const smallVideoRef = useRef<HTMLVideoElement>(null);
  const enlargedVideoRef = useRef<HTMLVideoElement>(null);
  const [isVideoComplete, setIsVideoComplete] = useState(false);

  // Check screen size once (on initial render)
  const isSmallScreen = window.innerWidth <= 768;

  // Dimensions for the iPhone-frame container
  // You can tweak these numbers as needed
  const containerWidth = isSmallScreen ? 320 : 320;
  const containerHeight = isSmallScreen ? 570 : 620;

  // Dimensions for the enlarged dialog
  // You can also do "100vw" / "100vh" or something more fluid
  const dialogWidth = isSmallScreen ? '320px' : '460px';
  const dialogHeight = isSmallScreen ? '600px' : '900px';

  const handleEnlargeClick = () => {
    if (smallVideoRef.current) {
      smallVideoRef.current.pause();
    }
    setModalOpen(true);
  };

  const handleVideoEnd = () => {
    if (!isVideoComplete) {
      setIsVideoComplete(true);
      if (onVideoEnded) {
        onVideoEnded(); // Allow next step progression
      }
    }
  };

  return (
    <>
      {/* Small Video in iPhone Frame */}
      <DialogContent
        className="relative flex justify-center items-center p-0"
        style={{ backgroundColor: 'transparent', overflow: 'hidden' }}
      >
        <div
          className="relative rounded-3xl"
          style={{
            borderRadius: '24px',
            height: `${containerHeight}px`,
            width: `${containerWidth}px`,
          }}
        >
          {/* Enlarge Button */}
          <button
            style={{
              position: 'absolute',
              bottom: '10px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 3,
              background: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              borderRadius: '4px',
              padding: '4px 8px',
              cursor: 'pointer',
            }}
            onClick={handleEnlargeClick}
          >
            Enlarge Video
          </button>

          {/* Small Video */}
          <video
            ref={smallVideoRef}
            autoPlay
            controls
            className="absolute top-0 left-0"
            style={{
              width: '90%',
              height: '96%',
              objectFit: 'fill',
              zIndex: 1,
              marginTop: '12px',
              marginLeft: '16px',
              borderRadius: '34px',
            }}
            onEnded={handleVideoEnd}
          >
            <source src={videoSrc} type="video/mp4" />
          </video>

          <img
            src="/images/iPhone-bg.png"
            className="absolute top-0 left-0 w-full h-full pointer-events-none"
            style={{ zIndex: 2 }}
            alt="iPhone frame"
          />
        </div>
      </DialogContent>

      {/* Enlarged Video Dialog */}
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' } }}
        PaperProps={{
          style: {
            width: dialogWidth,
            height: dialogHeight,
            margin: 'auto',
            borderRadius: '16px',
            backgroundColor: 'transparent',
          },
        }}
      >
        <DialogContent
          style={{
            position: 'relative',
            backgroundColor: 'transparent',
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={() => setModalOpen(false)}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 4,
              background: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Enlarged Video */}
          <div style={{ width: '100%', height: '100%' }}>
            <video
              ref={enlargedVideoRef}
              autoPlay
              controls
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              onEnded={handleVideoEnd}
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VideoDialog;
