import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { CircularProgress } from '@mui/material';
import { useVideo } from 'hooks/useVideo';
import { getDriveGoogleUrl } from 'hooks/utils';
import { FC, useEffect, useRef, useState } from 'react';
import {
  FacebookEmbed,
  TikTokEmbed,
  YouTubeEmbed,
} from 'react-social-media-embed';

interface IVideoProps {
  url: string;
  videoUrl: string;
  isMuted?: boolean;
  pauseVideo?: boolean;
  /** Scale factor to adjust the overall size */
  scale?: number;
  /** Container (iPhone overlay) width in pixels */
  containerWidth?: number;
  /** Container (iPhone overlay) height in pixels */
  containerHeight?: number;
}

const Video: FC<IVideoProps> = ({
  url,
  videoUrl,
  isMuted,
  pauseVideo,
  scale = 1,
  containerWidth = 330,
  containerHeight = 644,
}): JSX.Element | null => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const embedRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  const { hasStartedPlaying, togglePause, isPaused } = useVideo(videoRef);

  useEffect(() => {
    if (pauseVideo && videoRef.current) {
      videoRef.current.pause();
    }
  }, [pauseVideo]);

  // 1) For social embeds, simply scale the container.
  if (url.includes('facebook')) {
    return (
      <FacebookEmbed
        ref={embedRef}
        onClick={togglePause}
        url={videoUrl}
        width={containerWidth * scale}
        height={containerHeight * scale}
      />
    );
  } else if (url.includes('youtube')) {
    return (
      <YouTubeEmbed
        url={videoUrl}
        width={containerWidth * scale}
        height={containerHeight * scale}
      />
    );
  } else if (url.includes('tiktok')) {
    return (
      <TikTokEmbed
        onClick={togglePause}
        ref={embedRef}
        url={videoUrl}
        width={containerWidth * scale}
        height={containerHeight * scale}
      />
    );
  } else if (url.includes('drive.google.com') && getDriveGoogleUrl(url)) {
    return (
      <video
        onClick={togglePause}
        ref={videoRef}
        style={{
          width: containerWidth * scale,
          height: containerHeight * scale,
        }}
        controls
      >
        <source src={getDriveGoogleUrl(url)} type="video/mp4" />
      </video>
    );
  }

  // 2) If it's a normal video, position it inside the iPhone PNG.
  if (videoUrl) {
    /**
     * Example offsets to make the video smaller and centered inside the iPhone.
     * Adjust as needed for your exact PNG dimensions:
     *   - videoLeft/videoTop are the offset from top/left
     *   - videoWidth/videoHeight are the final size
     */
    const videoLeft = 15; // px offset from left
    const videoTop = 10;  // px offset from top
    const videoWidth = containerWidth - 30; // slightly smaller than container
    const videoHeight = containerHeight - 20;

    return (
      <div
        style={{
          position: 'relative',
          width: containerWidth * scale,
          height: containerHeight * scale,
        }}
      >
        {/* VIDEO (z-index: 1) */}
        <video
          autoPlay
          ref={videoRef}
          controls
          onClick={(e) => e.preventDefault()}
          style={{
            position: 'absolute',
            zIndex: 1,
            top: videoTop * scale,
            left: videoLeft * scale,
            width: videoWidth * scale,
            height: videoHeight * scale,
            objectFit: 'fill',
            borderRadius: '30px',
          }}
          muted={isMuted}
          onCanPlay={() => setLoading(false)}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>


        {/* PLAY/LOADING OVERLAY (z-index: 2 or 3) */}
        {(loading || isPaused) && !hasStartedPlaying && (
          <div
            onClick={togglePause}
            style={{
              position: 'absolute',
              zIndex: 2,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.3)',
              borderRadius: '55px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                width: 80,
                height: 80,
                borderRadius: '50%',
                backgroundColor: 'white',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: 'black' }} size={25} />
              ) : (
                <PlayArrowRoundedIcon
                  sx={{ color: 'black', cursor: 'pointer' }}
                  fontSize="large"
                />
              )}
            </div>
          </div>
        )}

        {/* IPHONE FRAME (z-index: 3) 
            pointerEvents: 'none' so the user can still click the video behind it. */}
        <img
          src="/images/iPhone-bg.png"
          alt="iPhone frame"
          style={{
            position: 'absolute',
            zIndex: 3,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none', // crucial to allow clicks on the video

          }}
        />
      </div>
    );
  }

  return null;
};

export default Video;
