export enum TileTitles {
  BrandName = 'Brand',
  BriefName = 'Activation name',
  Objective = 'Objective',
  TargetAudience = 'Target Audience',
}

export enum CreatorDetailsTileTitles {
  Creative_ID = 'Creative ID',
  BrandName = 'Brand Name',
  ActivationName = 'Activation Name',
  Status = 'Status',
}
