import { UserProfile } from 'API';
import HintTooltip from 'components/hintTooltip/HintTooltip';
import React, { useEffect, useState } from 'react';
import { FiEdit2, FiUsers, FiVideo } from 'react-icons/fi';
import countryList from 'react-select-country-list'; // ✅ Correct country package
import { IProfileState } from 'state/profileSteps';
import ISOCountryCodes from 'utils/constants/ISOCodeCountry';
import InstagramLogo from '../../../assets/images/instagram-logo.png';
import TikTokLogo from '../../../assets/images/tiktok-log.png';
import EditPaymentDetailsModal from '../EditPaymentDetailsModal/EditPaymentDetailsModal';
import OAuthButton from '../OAuthButton/OAuthButton';
import {
  AboutMe,
  ButtonContainer,
  CancelButton,
  CreatorID,
  Divider,
  EditButton,
  ErrorText,
  FormGroup,
  InputContainer,
  InputField,
  Label,
  MaxTagText,
  PlatformInfo,
  PlatformInputContainer,
  PlatformLogo,
  PlatformName,
  PlatformRow,
  ProfileContainer,
  ProfileHeader,
  ProfileImage,
  ProfileImageContainer,
  SaveButton,
  SectionTitle,
  Tag,
  TagInput,
  TagsContainer,
  TextArea,
  TooltipWrapper,
  UserInfo,
  UserName,
} from './UserProfileStyles'; // Importing extracted styles

const MAX_TAGS = 5;

type Props = {
  profileState: IProfileState;
  avatar?: string | null;
  onChooseAvatar: (file: File | null) => void; // Updated type
  setAvatarPreview: (preview: string | null) => void; // Updated type
  onSave: (updatedProfile: Partial<UserProfile>) => Promise<void>;
};

const UserProfileDetailsCard: React.FC<Props> = ({
  profileState,
  avatar,
  onChooseAvatar,
  setAvatarPreview,
  onSave,
}) => {
  const [countryName, setCountryName] = useState<string>('');
  const [isEditing, setIsEditing] = useState(false);
  const [formState, setFormState] = useState<UserProfile>(
    profileState.data || ({} as UserProfile)
  );
  const [newTag, setNewTag] = useState('');
  const [tagError, setTagError] = useState('');
  const countryOptions = countryList().getData(); // ✅ Fetches country list
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  useEffect(() => {
    if (profileState.data?.country) {
      const country = countryOptions.find(
        (c) => c.value === profileState?.data?.country
      );
      setFormState((prev) => ({
        ...prev,
        country: country ? country.value : profileState?.data?.country,
      }));
    }
  }, [profileState.data?.country]);

  useEffect(() => {
    if (profileState.data?.country) {
      const country = ISOCountryCodes.find(
        (c) => c.code === profileState?.data?.country
      );
      setCountryName(country ? country.name : profileState.data.country);
    }
  }, [profileState.data?.country]);

  const userTags =
    formState.hashtags?.filter((tag) => !tag.startsWith('*')) || [];

  const handleEditClick = () => setIsEditing(true);
  const handleCancelClick = () => {
    setIsEditing(false);
    setFormState(profileState.data || ({} as UserProfile));
    setNewTag('');
  };

  const handleSaveClick = async () => {
    setIsEditing(false);

    const sanitizedFormState = {
      name: formState.name ?? '',
      country: formState.country ?? '',
      hashtags: formState.hashtags ?? [],
      description: formState.description ?? '',
      avatar: formState.avatar ?? '',
      tiktokHandler: formState.tiktokHandler ?? null,
      youtubeHandler: formState.youtubeHandler ?? null,
      instagramHandler: formState.instagramHandler ?? null,
      tiktok_username: formState.tiktok_username ?? null,
    };

    try {
      await onSave(sanitizedFormState); // ✅ Call onSave to update backend
      console.log('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleTagChange = (e) => {
    const value = e.target.value;
    if (value.includes('*')) {
      setTagError('Tags cannot contain "*"');
    } else {
      setTagError('');
    }
    setNewTag(value);
  };

  const handleTagKeyPress = (e) => {
    if (
      e.key === 'Enter' &&
      newTag.trim() !== '' &&
      userTags.length < MAX_TAGS
    ) {
      if (newTag.includes('*')) {
        setTagError('Tags cannot contain "*"');
        return;
      }
      setFormState((prevState) => ({
        ...prevState,
        hashtags: [...userTags, newTag.trim()],
      }));
      setNewTag('');
    }
  };

  const handleTagRemove = (tagToRemove: string) => {
    if (isEditing) {
      setFormState((prevState) => ({
        ...prevState,
        hashtags: prevState.hashtags
          ? prevState.hashtags.filter((tag) => tag !== tagToRemove)
          : [],
      }));
    }
  };

  // const handleCountryChange = (selectedOption) => {
  //    setFormState({ ...formState, country: selectedOption.value });
  //};
  useEffect(() => {
    console.log('formState', formState);
  }, [formState]);

  return (
    <ProfileContainer>
      <ProfileHeader>
        <ProfileImageContainer>
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <ProfileImage
              src={avatar || '/images/default-image.png'}
              alt="Profile"
            />
            {isEditing && (
              <>
                {/* Hidden File Input */}
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="avatar-upload"
                  onChange={(e) => {
                    const file = e.target.files?.[0] ?? null; // Ensure file is not undefined

                    if (file) {
                      onChooseAvatar(file); // Store the file in parent component
                      setAvatarPreview(URL.createObjectURL(file)); // ✅ No error now
                    }
                  }}
                />

                {/* Edit Button that triggers file input */}
                <label
                  htmlFor="avatar-upload"
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    background: 'white',
                    borderRadius: '50%',
                    width: '32px',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                    cursor: 'pointer',
                    border: 'none',
                  }}
                >
                  <FiEdit2 size={16} color="#333" />
                </label>
              </>
            )}
          </div>
          {isEditing ? (
            <FormGroup style={{ marginTop: '20px' }}>
              <Label>Name</Label>
              <InputField
                type="text"
                name="name"
                value={formState.name}
                onChange={handleChange}
                readOnly={!isEditing}
                isEditing={isEditing}
              />
            </FormGroup>
          ) : (
            <UserName>{formState.name}</UserName>
          )}
          {isEditing ? (
            <>
              <HintTooltip
                message="If you wish to change this field, please email us at hello@edcsquared.io"
                tooltipPosition="right"
                triggerContent={<p>{countryName}</p>}
              />
            </>
          ) : (
            // <FormGroup>
            //   <Label left="32px">Country</Label>
            //   <Select
            //     options={countryOptions}
            //     value={countryOptions.find(c => c.value === formState.country)}
            //     onChange={handleCountryChange}
            //     placeholder="Select a country"
            //     styles={{
            //       control: (base, state) => ({
            //         ...base,
            //         height: '50px',
            //         borderRadius: '8px',
            //         border: '1px solid black',
            //         boxShadow: state.isFocused ? 'none' : base.boxShadow, // Remove default focus box shadow
            //         fontSize: '14px',
            //         marginLeft: "20px",
            //         color: '#333',
            //         background: 'white',
            //         width: '100%', // Ensures the select matches input width
            //         textAlign: 'left',
            //         maxWidth: "200px",
            //         minWidth: "200px",
            //         outline: 'none', // Removes outline
            //       }),
            //       menu: (base) => ({
            //         ...base,
            //         textAlign: 'left',
            //         border: '0px',
            //         zIndex: "9999",
            //         marginLeft: "20px",
            //       }),
            //       input: (base) => ({
            //         ...base,
            //         outline: 'none', // Removes focus box
            //         boxShadow: 'none', // Prevents extra box shadow
            //       }),
            //     }}
            //   />
            // </FormGroup>
            <p>{countryName}</p>
          )}
        </ProfileImageContainer>

        <UserInfo>
          <CreatorID>
            <span className="creator-id">CREATOR ID :</span>{' '}
            <strong>{profileState.data?.uniqueId}</strong>
          </CreatorID>
          {/* PHONE NUMBER FIELD WITH TOOLTIP */}
          <FormGroup>
            <Label>Phone Number</Label>
            <TooltipWrapper>
              <InputContainer>
                <InputField
                  type="text"
                  value={formState.phoneNumber ?? 'N/A'}
                  readOnly
                  isEditing={false}
                />
              </InputContainer>
              {isEditing && (
                <HintTooltip
                  message="If you wish to change this field, please email us at hello@edcsquared.io"
                  tooltipPosition="right"
                />
              )}
            </TooltipWrapper>
          </FormGroup>

          {/* EMAIL FIELD WITH TOOLTIP */}
          <FormGroup>
            <Label>Email</Label>
            <TooltipWrapper>
              <InputContainer>
                <InputField
                  type="email"
                  value={formState.email ?? 'N/A'}
                  readOnly
                  isEditing={false}
                />
              </InputContainer>
              {isEditing && (
                <HintTooltip
                  message="If you wish to change this field, please email us at hello@edcsquared.io"
                  tooltipPosition="right"
                />
              )}
            </TooltipWrapper>
          </FormGroup>
        </UserInfo>
      </ProfileHeader>
      <Divider />

      <SectionTitle>ABOUT ME</SectionTitle>
      {isEditing ? (
        <FormGroup>
          <Label>About Me</Label>
          <TextArea
            name="description"
            value={formState.description ?? 'N/A'}
            onChange={handleChange}
          />
        </FormGroup>
      ) : (
        <AboutMe>{formState.description}</AboutMe>
      )}

      <SectionTitle>
        FIVE WORDS TO DESCRIBE ME
        {isEditing && <MaxTagText>(MAX 5)</MaxTagText>}
      </SectionTitle>
      {isEditing && (formState?.hashtags?.length ?? 0) < MAX_TAGS && (
        <FormGroup>
          <Label>Add Tag</Label>
          <TagInput
            type="text"
            placeholder="Type a tag and press Enter"
            value={newTag}
            onChange={handleTagChange}
            onKeyPress={handleTagKeyPress}
          />
          {tagError && <ErrorText>{tagError}</ErrorText>}{' '}
          {/* Display error message */}
        </FormGroup>
      )}

      <TagsContainer>
        {userTags.map((tag, index) => (
          <Tag
            key={index}
            onClick={() => handleTagRemove(tag)}
            isEditing={isEditing}
          >
            {tag} {isEditing && '✖'}
          </Tag>
        ))}
      </TagsContainer>

      <SectionTitle>LINK MY ACCOUNTS</SectionTitle>

      <PlatformRow>
        <PlatformLogo src={TikTokLogo} alt="TikTok" />
        <PlatformInfo>
          <PlatformInputContainer
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20px',
              flexWrap: 'nowrap',
              width: '100%',
            }}
          >
            <div style={{ flex: '0 0 300px' }}>
              <PlatformName>TikTok Handle</PlatformName>
              <InputField
                type="text"
                value={
                  !formState.tiktok_username ||
                  formState.tiktok_username.trim() === ''
                    ? 'N/A'
                    : formState.tiktok_username
                }
                readOnly
                isEditing={false}
                style={{ width: '100%' }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
                flexShrink: 0,
              }}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
              >
                <FiVideo size={20} />
                <span>{formState.tiktok_video_count ?? 'N/A'}</span>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
              >
                <FiUsers size={20} />
                <span>{formState.tiktok_follower_count ?? 'N/A'}</span>
              </div>

              {/* ✅ OAuth Button here when editing */}
              {isEditing && (
                <OAuthButton
                  providerName="tiktok"
                  userId={formState.id}
                  isLinked={formState.tiktok_username}
                  setFormState={setFormState}
                />
              )}
            </div>
          </PlatformInputContainer>
        </PlatformInfo>
      </PlatformRow>

      <PlatformRow>
        <PlatformLogo src={InstagramLogo} alt="Instagram" />
        <PlatformInfo>
          <PlatformInputContainer
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20px',
              flexWrap: 'nowrap',
              width: '100%',
            }}
          >
            <div style={{ flex: '0 0 300px' }}>
              <PlatformName>Instagram Handle</PlatformName>
              <InputField
                type="text"
                value={
                  !formState.instagram_username ||
                  formState.instagram_username.trim() === ''
                    ? 'N/A'
                    : formState.instagram_username
                }
                readOnly
                isEditing={false}
                style={{ width: '100%' }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
                flexShrink: 0,
              }}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
              >
                <FiVideo size={20} />
                <span>
                  {formState.instagram_media_count !== null &&
                  formState.instagram_media_count !== undefined
                    ? formState.instagram_media_count
                    : 'N/A'}
                </span>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
              >
                <FiUsers size={20} />
                <span>
                  {formState.instagram_followers_count !== null &&
                  formState.instagram_followers_count !== undefined
                    ? formState.instagram_followers_count
                    : 'N/A'}
                </span>
              </div>
              {/* ✅ OAuth Button here when editing */}
              {isEditing && (
                <OAuthButton
                  providerName="instagram"
                  userId={formState.id}
                  isLinked={formState.instagram_username}
                  setFormState={setFormState}
                />
              )}
            </div>
          </PlatformInputContainer>
        </PlatformInfo>
      </PlatformRow>

      <ButtonContainer>
        {isEditing ? (
          <>
            <CancelButton onClick={handleCancelClick}>CANCEL</CancelButton>
            <SaveButton onClick={handleSaveClick}>SAVE</SaveButton>
          </>
        ) : (
          <>
            <EditButton onClick={handleEditClick}>
              <FiEdit2 size={16} />
              EDIT PROFILE
            </EditButton>
            <EditButton onClick={() => setIsPaymentModalOpen(true)}>
              Edit Payment Details
            </EditButton>
          </>
        )}
      </ButtonContainer>

      <EditPaymentDetailsModal
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        onSaveClose={() => {
          setIsPaymentModalOpen(false);
        }}
        paymentDetails={formState.userPaymentDetails}
        userId={formState.id}
      />
    </ProfileContainer>
  );
};

export default UserProfileDetailsCard;
