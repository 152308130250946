import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
} from '@mui/material';
import { UserProfile, USER_TYPES } from 'API';
import { IconLoader } from 'components';
import Input from 'components/ui/input';
import Routes from 'components/ui/switch';
import TextArea from 'components/ui/textArea';
import {
  UseGetCreativeEarnings,
  UseGetUserProfile,
  UseUpdateUserProfile,
} from 'hooks';
import useZodForm from 'hooks/useZodForm';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AdminRoutes } from 'utils';
import { defaultValues, schema } from './schema';

interface IGeneralFormProps {
  user: UserProfile;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

const GeneralForm: FC<IGeneralFormProps> = ({ user, setShowModal }) => {
  const [tag, setTag] = useState('');
  const { updateProfile, loading } = UseUpdateUserProfile();
  const { data, getProfile, loading: isLoading } = UseGetUserProfile();
  const { getEarnings } = UseGetCreativeEarnings();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useZodForm({
    schema,
    defaultValues,
    mode: 'all',
  });
  const [selectedVerticals, setSelectedVerticals] = useState<Set<string>>(
    new Set()
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    setSelectedVerticals(
      new Set(data.getUserProfile?.hashtags as string | null)
    );
    reset({
      ...(data.getUserProfile as {
        name: string;
        email: string;
        tiktokHandler: string;
        instagramHandler: string;
        description: string;
        youtubeHandler: string;
        uniqueId?: string;
        id?: string | undefined;
      }),
    });

    if (data.getUserProfile?.userType === USER_TYPES.CREATIVE_USER) {
      getEarnings({ variables: { creatorId: data.getUserProfile.id } });
    }
  }, [data, reset]);

  const onSubmit = handleSubmit(async ({ isBrand, ...data }) => {
    await updateProfile({
      variables: {
        input: {
          ...data,
          hashtags: [...selectedVerticals],
          userType: isBrand ? USER_TYPES.BRAND_USER : USER_TYPES.CREATIVE_USER,
        },
      },
      errorPolicy: 'ignore',
    });
    setShowModal(true);
  });

  const selectVertical = (vertical, action) => {
    if (
      (selectedVerticals.size < 5 && action === 'add') ||
      action === 'delete'
    ) {
      const verticals = new Set(selectedVerticals);
      if (!verticals.has(vertical)) setTag('');
      verticals[action](vertical);
      setSelectedVerticals(verticals);
    }
  };

  useEffect(() => {
    const getUserProfile = async () => {
      console.log('here you are lee---->', user);

      await getProfile({ variables: { id: user.id } });
      console.log();
    };

    getUserProfile();
  }, []);

  return (
    <form
      onSubmit={onSubmit}
      onKeyDown={(e) => {
        if (e.keyCode == 13) e.preventDefault();
      }}
    >
      {isLoading ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '50px',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="grid xl:grid-cols-1 p-6 xl:gap-8">
            <div className="xl:col-span-1 col-span-3">
              <Routes
                name="isBrand"
                label="Mark as Brand"
                className="mb-5"
                control={control}
              />

              <Input
                name="uniqueId"
                label="Creator ID"
                disabled={true}
                register={register}
                className="mb-5"
                errors={errors}
              />

              <Input
                name="country"
                label="Country"
                disabled={true}
                register={register}
                className="mb-5"
                errors={errors}
              />

              <Input
                name="id"
                label="User ID"
                disabled={true}
                register={register}
                className="mb-5"
                errors={errors}
              />
              <Input
                required
                name="name"
                label="Creator Name"
                register={register}
                className="mb-5"
                errors={errors}
              />
              <Input
                required
                name="email"
                type="email"
                label="Creator Email"
                register={register}
                className="mb-5"
                errors={errors}
              />
              <Input
                name="phoneNumber"
                type="phoneNumber"
                label="Creator Phone Number"
                register={register}
                className="mb-5"
                errors={errors}
              />

              <Input
                name="isValid"
                type="isValid"
                label="Creator Validation Status"
                register={register}
                className="mb-5"
                errors={errors}
                disabled
              />

              {/* Instagram Accordion */}
              <Accordion sx={{ mb: 4 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight={600} fontFamily="Oswald, sans-serif">
                    Instagram Details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Input
                    name="instagram_username"
                    label="Instagram Username"
                    register={register}
                    errors={errors}
                    disabled
                    className="mb-5"
                  />
                  <Input
                    name="instagram_media_count"
                    label="Instagram Media Count"
                    register={register}
                    errors={errors}
                    disabled
                    className="mb-5"
                  />
                  <Input
                    name="instagram_followers_count"
                    label="Instagram Followers Count"
                    register={register}
                    errors={errors}
                    disabled
                    className="mb-5"
                  />
                </AccordionDetails>
              </Accordion>

              {/* TikTok Accordion */}
              <Accordion sx={{ mb: 4 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight={600} fontFamily="Oswald, sans-serif">
                    TikTok Details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Input
                    name="tiktok_username"
                    label="TikTok Username"
                    register={register}
                    errors={errors}
                    disabled
                    className="mb-5"
                  />
                  <Input
                    name="tiktok_video_count"
                    label="TikTok Video Count"
                    register={register}
                    errors={errors}
                    disabled
                    className="mb-5"
                  />
                  <Input
                    name="tiktok_follower_count"
                    label="TikTok Follower Count"
                    register={register}
                    errors={errors}
                    disabled
                    className="mb-5"
                  />
                </AccordionDetails>
              </Accordion>

              <div className="brand-dashboard__profile-group lg:grid grid-cols-12 gap-[40px] mb-[20px]">
                <div className="brand-dashboard__profile-group col-span-4 lg:mb-0 mb-3">
                  <div className="profile-label">
                    Add up to 5 tags that best describe you and your content
                  </div>
                  <div className="relative">
                    <input
                      className="profile-input pr-[36px]"
                      onKeyDown={(e) => {
                        if (e.keyCode == 13 && tag.trim()) {
                          e.preventDefault();
                          selectVertical(tag, 'add');
                        }
                      }}
                      maxLength={15}
                      onChange={(e) => setTag(e.target.value)}
                      value={tag}
                      type="text"
                    />
                    <img
                      src="/images/remove.svg"
                      className="absolute top-[11px] right-[12px]"
                    />
                  </div>
                </div>
                <div
                  className={`relative brand-dashboard__profile-group col-span-4 mt-[26px] lg:w-[500px] w-[270px] lg:mb-[20px] lg:mx-0 m-auto test`}
                  style={{ height: 'auto' }}
                >
                  <div
                    className="flex flex-wrap lg:h-auto items-baseline w-[500px]"
                    style={{ height: 'auto' }}
                  >
                    {[...selectedVerticals].map((vertical, index) => {
                      return (
                        <div
                          key={index}
                          className={`border bg-[#202020] text-[#fff] ${
                            vertical?.split(' ') && vertical.length > 9
                              ? 'w-[138px] justify-center'
                              : ''
                          } rounded-[80px] m-1 cursor-pointer w-fit flex px-2 py-1 position-${
                            index + 1
                          }`}
                          onClick={() => selectVertical(vertical, 'delete')}
                        >
                          <span style={{ fontSize: 'small' }}>{vertical}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <TextArea
                rows={9}
                name="description"
                label="Description"
                register={register}
                errors={errors}
              />
            </div>
          </div>
          <div className="grid xl:grid-cols-2 grid-cols-1"></div>
          <div className="xl:hidden w-full px-6 pb-6"></div>
          <div
            className="
  flex sm:flex-row w-full sm:justify-center
  font-sans text-base text-white font-bold flex-col-reverse gap-4 items-center px-6"
          >
            <Link to={AdminRoutes.Creators}>
              <button className="creator-button bg-[#F1EBDF]">CANCEL</button>
            </Link>
            <button
              type="submit"
              className="creator-button disabled:bg-[#a8a8a8]"
              disabled={!isValid}
            >
              {loading ? <IconLoader /> : 'Save'}
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default GeneralForm;
