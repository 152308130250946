import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import {
  ADMIN_STATUS,
  CREATIVE_REQUEST_LAST_UPDATED_BY,
  CREATIVE_STATUS,
} from 'API';
import classNames from 'classnames';
import HintTooltip from 'components/hintTooltip/HintTooltip';
import { IconLoader } from 'components/loader';
import { verticalOptions } from 'hooks/utils';
import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UnknownType } from 'utils';
import ISOCodeCountry from 'utils/constants/ISOCodeCountry';

export type Tdata = {
  id?: string;
  name?: string | null;
  brandBriefs?: string | null;
  creatorName?: string | null;
  creativeTiktokHandle?: string | null;
  tiktokCreativeUrl?: string | null;
  creativeLink?: string | null;
  adminApproval?: string | null;
  brand?: string | null;
  objective?: string | null;
  img?: string | null;
  vertical?: string | null;
  earningsCurrent?: string | null;
  created?: string | null;
  lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
  creatorhandle?: string | null;
  EarningsLifetime?: string | null;
  country?: string | null;
  adminApprovalStatus?: CREATIVE_STATUS | ADMIN_STATUS | null;
  brandApprovalStatus?: string | null;
  activationName?: string | null;
  brandName?: string | null;
  creatorHandle?: string | null;
  creativeId?: string | null;
  briefName?: string | null;
  status?: string | null;
  linkedTikTokCampaign?: string | null;
  details?: string | null;
  dateOfSubmission?: string | null;
  transactions?: string;
  numberOfTransactions?: string;
  totalAmount?: string;
  transactionDate?: string;
  cellWidth?: string;
};

type TDropDown = { id: string | undefined; dropDown: boolean };

type TTableProps = {
  data: Tdata[] | null;
  rows: string[];
  noDataMessage?: string;
  pagination: number;
  onRowClick?: (brief: UnknownType) => void;
  onEditClick?: (brief: UnknownType) => void;
  onTransationClick?: (date: UnknownType) => void;
  changePage?: (type: string, page: number, limit: number) => Promise<void>;
  mainlyData?: Tdata[];
  colHeight?: number;
  loading?: boolean;
  height?: string;
  firstRowName?: string;
  rowWidth?: string;
  dataCy?: string;
  extended?: boolean;
  extendedURL?: string;
  extendedButton?: boolean;
  extendedOnClick?: (brief: UnknownType) => void;
  extendedName?: string;
  borderColor?: string;
  rowClassName?: (rowParam: Tdata) => string;
  creatorUser?: boolean;
  hasSearchBar?: boolean;
  tableCurrentPage?: number;
  header?: {
    title: string;
    icon: string;
    search: boolean;
    tooltip?: string;
    tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
  };
  pageSize?: number;
  tableContentHeight?: string;
  tableHeight?: string;
  textPosition?: string;
  rowHeight?: string;
  onCreatorDetailsClick?: (request) => void;
};

const MAX_PAGE_LENGTH_FOR_SHOWING = 7;

///////////////////////////
// Helper Functions
///////////////////////////

const getStatusColor = (status: string) => {
  switch (status) {
    case CREATIVE_STATUS.Approved:
    case 'Active':
      return 'text-white bg-[#1d1d1d]';
    case CREATIVE_STATUS.Rejected:
    case CREATIVE_STATUS.New:
    case 'In-active':
      return 'text-[#1d1d1d] border border-[#1d1d1d] bg-[transparent]';
    case CREATIVE_STATUS.Submitted:
      return 'text-[#fff] border border-[#1d1d1d] bg-[#1d1d1d]';
    case 'Revision':
      return 'text-white bg-[#1d1c1c80] leading-[20px]';
    default:
      return '';
  }
};

const renderCellContent = (
  row: string,
  item: Tdata,
  extended: boolean,
  textPosition: string,
  onRowClick?: (brief: UnknownType) => void,
  onEditClick?: (brief: UnknownType) => void,
  mainlyData?: Tdata[],
  toggle?: () => void
) => {
  if (row === 'details') {
    return (
      <div
        className={classNames('flex items-center', {
          'justify-end': textPosition,
          'justify-center': !textPosition,
        })}
      >
        <img
          src="/images/item-details.svg"
          alt="details"
          className="w-[30px] h-[30px]" // Increased size
        />
      </div>
    );
  }

  if (row === 'edit') {
    return (
      <div
        className="m-auto flex w-full justify-center items-center"
        data-cy="edit-action"
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          onEditClick &&
            onEditClick(mainlyData?.find((brief) => brief.id === item?.id));
        }}
      >
        <img src="/images/list-edit.svg" alt="edit" />
      </div>
    );
  }

  if (row === 'addEarnings') {
    return (
      <div className="m-auto flex w-full justify-center items-center">
        <img
          src="/images/square-plus.png"
          className="w-[16px] h-[16px]"
          alt="add"
        />
      </div>
    );
  }

  if (row === 'objective') {
    return (
      <div
        className={classNames('flex items-center', {
          'justify-start': extended,
        })}
      >
        <img
          src="/images/list-tip2.svg"
          className="pr-[8px] text-[12px]"
          alt="objective"
        />
        <p>{item[row]}</p>
      </div>
    );
  }

  if (row.toLowerCase().includes('status')) {
    return (
      <div
        className={classNames(
          'flex justify-center rounded-[20px] py-[5px] sm:px-[16px] px-[10px] w-fit font-[400] sm:text-[12px] text-[10px]',
          getStatusColor(item[row] as string)
        )}
      >
        {item[row]}
      </div>
    );
  }

  // Render image cell when no row key is provided
  if (!row && item.img) {
    return (
      <div className="bg-[#fff] sm:border-none border-2 border-[#000] sm:p-[2px] p-[1px] m-auto rounded-full w-[40px] h-[40px]">
        <img
          src={item.img}
          alt="avatar"
          className="w-[36px] h-[36px] rounded-full object-cover"
        />
      </div>
    );
  }

  // Country and vertical conversions
  if (row === 'vertical') {
    return verticalOptions.find((v) => v.value === item[row])?.text;
  }
  if (row === 'country') {
    return ISOCodeCountry.find((c) => c.code === item[row])?.name;
  }
  return item[row];
};

///////////////////////////
// Subcomponents
///////////////////////////

type TableHeaderProps = {
  header?: {
    title: string;
    icon?: string;
    search?: boolean;
    tooltip?: string;
    tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
  };
  firstRowName?: string;
  extended?: boolean;
};

const TableHeader: FC<TableHeaderProps> = ({ header }) => {
  if (!header?.title) return null;

  return (
    <div className="bg-[#F5F1E8] w-full flex justify-center items-center py-[12px] rounded-t-[16px] relative">
      <h6 className="text-[#0E0D0D] font-oswald font-[700] text-[18px] uppercase flex items-center space-x-2">
        {header.title}

        {/* Conditionally render the HintTooltip if a tooltip message is provided */}
        {header.tooltip && (
          <div className="pl-[10px]">
            <HintTooltip
              message={header.tooltip}
              tooltipPosition={header.tooltipPosition || 'top'}
            />
          </div>
        )}
      </h6>
    </div>
  );
};

type TableRowProps = {
  item: Tdata;
  rows: string[];
  extended: boolean;
  textPosition: string;
  rowClassName?: (rowParam: Tdata) => string;
  onRowClick?: (brief: UnknownType) => void;
  onEditClick?: (brief: UnknownType) => void;
  onTransationClick?: (date: UnknownType) => void;
  mainlyData?: Tdata[];
  rowHeight?: string;
};

const TableRow: FC<TableRowProps> = ({
  item,
  rows,
  extended,
  textPosition,
  rowClassName,
  onRowClick,
  onEditClick,
  onTransationClick,
  mainlyData,
  rowHeight,
}) => {
  return (
    <tr
      className={classNames(
        rowHeight || 'h-[50px]',
        'whitespace-nowrap border-transparent',
        { 'cursor-pointer': !!onRowClick || onTransationClick } // Apply pointer only if onRowClick exists
      )}
      data-cy="table-row"
      onClick={() => {
        if (onRowClick) {
          onRowClick(mainlyData?.find((brief) => brief.id === item?.id));
        } else if (onTransationClick) {
          onTransationClick(item?.transactionDate);
        }
      }}
    >
      {rows.map((row, index) => {
        // Define which columns should be center-aligned
        const isCentered = [
          'status',
          'details',
          'adminApprovalStatus',
          'brandApprovalStatus',
          'BRAND',
        ].includes(row);

        return (
          <td
            key={index}
            className={classNames(
              rowClassName?.(item) ?? '',
              'sm:px-[10px] px-[10px] text-[14px] font-inter',
              isCentered ? 'text-center' : 'text-left' // Ensure proper alignment
            )}
          >
            {/* Wrap centered elements in a flex container */}
            <div
              className={isCentered ? 'flex justify-center items-center' : ''}
            >
              {renderCellContent(
                row,
                item,
                extended,
                textPosition,
                onRowClick,
                onEditClick,
                mainlyData
              )}
            </div>
          </td>
        );
      })}
    </tr>
  );
};

type PaginationProps = {
  totalPage: number;
  currentPage: number;
  pageSize: number;
  changePage?: (type: string, page: number, limit: number) => Promise<void>;
};

const Pagination: FC<PaginationProps> = ({
  totalPage,
  currentPage,
  pageSize,
  changePage,
}) => {
  const navigateToPage = (page: number) => {
    if (changePage) {
      changePage('creativeRequest', page, pageSize);
    }
  };

  return (
    <div className="w-full flex justify-center text-secondary my-[10px]">
      {totalPage > 1 && (
        <>
          <button
            className="px-2 border-l-[2px] border-y-2 border-r-[1px] rounded-l-lg cursor-pointer disabled:cursor-not-allowed disabled:text-[#cfcfcf] text-[#3D636B]"
            disabled={currentPage === 1}
            onClick={() => currentPage !== 1 && navigateToPage(currentPage - 1)}
          >
            &lt;
          </button>

          {totalPage > MAX_PAGE_LENGTH_FOR_SHOWING && (
            <div
              className={classNames(
                'px-3 text-[14px] flex justify-center items-center border border-gray-100 cursor-pointer',
                {
                  'bg-[#F9FAFB]': currentPage === 1,
                }
              )}
              onClick={() => navigateToPage(1)}
            >
              1
            </div>
          )}

          {currentPage > 3 && totalPage > MAX_PAGE_LENGTH_FOR_SHOWING && (
            <div>...</div>
          )}

          {Array.from(
            {
              length:
                totalPage > MAX_PAGE_LENGTH_FOR_SHOWING
                  ? totalPage - 2
                  : totalPage,
            },
            (_, i) => (totalPage > MAX_PAGE_LENGTH_FOR_SHOWING ? i + 2 : i + 1)
          ).map((x) => {
            if (
              totalPage > MAX_PAGE_LENGTH_FOR_SHOWING &&
              (x < currentPage - 1 || x > currentPage + 1)
            ) {
              return null;
            }
            return (
              <div
                key={x}
                className={classNames(
                  'px-3 text-[14px] flex justify-center items-center border border-gray-100 cursor-pointer',
                  {
                    'bg-[#F9FAFB]': currentPage === x,
                  }
                )}
                onClick={() => currentPage !== x && navigateToPage(x)}
              >
                {x}
              </div>
            );
          })}

          {totalPage > MAX_PAGE_LENGTH_FOR_SHOWING &&
            currentPage < totalPage - 2 && <div>...</div>}

          {totalPage > MAX_PAGE_LENGTH_FOR_SHOWING && (
            <div
              className={classNames(
                'px-3 text-[14px] flex justify-center items-center border border-gray-100 cursor-pointer',
                {
                  'bg-[#F9FAFB]': currentPage === totalPage,
                }
              )}
              onClick={() => navigateToPage(totalPage)}
            >
              {totalPage}
            </div>
          )}

          <button
            className="px-2 border-r-[2px] border-y-2 border-l-[1px] rounded-r-lg cursor-pointer disabled:cursor-not-allowed disabled:text-[#cfcfcf] text-[#3D636B]"
            disabled={currentPage === totalPage}
            onClick={() =>
              currentPage !== totalPage && navigateToPage(currentPage + 1)
            }
          >
            &gt;
          </button>
        </>
      )}
    </div>
  );
};

///////////////////////////
// Main Table Component
///////////////////////////

const Table: FC<TTableProps> = ({
  data,
  rows,
  onRowClick,
  onTransationClick,
  onEditClick,
  changePage,
  mainlyData,
  noDataMessage = 'No Data Available',
  pagination,
  loading,
  extended = true,
  extendedURL,
  header,
  hasSearchBar = false,
  firstRowName,
  pageSize = 10,
  rowClassName,
  tableCurrentPage = 1,
  tableHeight = 'h-[285px]',
  textPosition = 'text-left',
  rowHeight,
  onCreatorDetailsClick,
}) => {
  const navigate = useNavigate();
  const [totalPage, setTotalPage] = useState(pagination);
  const [currentPage, setCurrentPage] = useState(tableCurrentPage);
  const [showCurrentDetails, setShowCurrentDetails] = useState<TDropDown[]>([]);

  // Initialize dropdown state only once when data changes.
  useEffect(() => {
    if (data) {
      const dropDownData = data.map((item, i) => ({
        id: item.id,
        dropDown: i !== 0,
      }));
      setShowCurrentDetails(dropDownData);
    }
  }, [data]);

  // Update total page when pagination prop changes.
  useEffect(() => {
    if (pagination !== totalPage) setTotalPage(pagination);
  }, [pagination, totalPage]);

  const toggleDropdown = useCallback((id: string | undefined) => {
    setShowCurrentDetails((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, dropDown: !item.dropDown } : item
      )
    );
  }, []);

  return (
    <div className="md:grid grid-cols-1 relative">
      {/* Table Section */}
      <div
        className={classNames(
          extended ? 'lg:flex hidden pb-[14px]' : 'flex',
          'flex-col border border-[#F5F1E8] rounded-[16px] bg-[#ffffff]',
          { 'pt-0': header?.title, 'pt-[22px]': !header?.title }
        )}
      >
        {!loading ? (
          <div className="flex flex-col h-full">
            <TableHeader
              header={header}
              firstRowName={firstRowName}
              extended={extended}
            />

            {/* Scroll container with fixed height */}
            <div
              className={classNames(
                'relative overflow-auto',
                tableHeight || 'h-[500px]'
              )}
            >
              <table className="min-w-full whitespace-nowrap">
                {/* Sticky header implementation */}
                <thead className="sticky top-0 z-20 bg-white">
                  <tr className="shadow-sm">
                    {rows.map((row, index) => {
                      const isCentered = [
                        'status',
                        'details',
                        'adminApprovalStatus',
                        'brandApprovalStatus',
                        '',
                      ].includes(row);
                      return (
                        <th
                          key={index}
                          className={classNames(
                            'pb-[10px] pt-[10px] bg-white pl-[5px] pr-[5px]',
                            isCentered ? 'text-center' : 'text-left'
                          )}
                        >
                          <span className="text-[#0E0D0D] head-text font-[700] text-[14px] uppercase">
                            {row !== ''
                              ? row
                                  .match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g)
                                  ?.join(' ')
                              : firstRowName}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                {data?.length ? (
                  <tbody className="[&>*:nth-child(even)]:bg-[#F5F1E8]">
                    {data.map((item, index) => (
                      <TableRow
                        key={item.id || index}
                        item={item}
                        rows={rows}
                        extended={extended}
                        textPosition={textPosition}
                        rowClassName={rowClassName}
                        onRowClick={onRowClick}
                        onEditClick={onEditClick}
                        onTransationClick={onTransationClick}
                        mainlyData={mainlyData}
                        rowHeight={rowHeight}
                      />
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td
                        className="text-center text-gray-500 h-[250px]"
                        colSpan={rows.length}
                      >
                        {noDataMessage}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>

            {/* Pagination */}
            <Pagination
              totalPage={totalPage}
              currentPage={currentPage}
              pageSize={pageSize}
              changePage={async (type, page, limit) => {
                setCurrentPage(page);
                if (changePage) await changePage(type, page, limit);
              }}
            />

            {/* View All Button at the Bottom */}
            {extendedURL && (
              <div className="flex justify-end w-full mb-[10px] pr-[20px]">
                <div
                  onClick={() => navigate(extendedURL)}
                  className={classNames(
                    'text-[#FF872F] head-text uppercase font-[600] text-[14px] cursor-pointer',
                    'hover:underline'
                  )}
                >
                  View All
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="loader-content h-[250px] flex justify-center items-center">
            <IconLoader />
          </div>
        )}
      </div>

      {/* Mobile View */}
      <div
        className={classNames(
          {
            'lg:hidden lg:bg-[#ffffff] bg-transparent rounded-[16px]': extended,
            hidden: !extended,
          },
          {
            'md:min-h-[calc(100vh-240px)] min-h-[calc(100vh-291px)]':
              hasSearchBar,
            'min-h-[calc(100vh-183px)]': !hasSearchBar,
          },
          'max-h-auto flex flex-col justify-between'
        )}
      >
        {!loading ? (
          <>
            {data?.length ? (
              <div className="overflow-x-auto">
                {data.map((item, index) => (
                  <div
                    key={item.id || index}
                    className={classNames(
                      'border border-black transition-height duration-500 overflow-hidden',
                      {
                        'max-h-[400px]': showCurrentDetails[index]?.dropDown,
                        'max-h-[50px]': !showCurrentDetails[index]?.dropDown,
                        'rounded-[16px]': data.length === 1,
                        'rounded-t-[16px]': index === 0,
                        'rounded-b-[16px]': index === data.length - 1,
                      }
                    )}
                  >
                    {/* Accordion Header */}
                    <div
                      className="flex items-center h-[45px] pl-[5px] pr-[10px] cursor-pointer bg-white"
                      onClick={() =>
                        toggleDropdown(showCurrentDetails[index]?.id)
                      }
                    >
                      {/* Smaller Accordion Icon */}
                      <div className="sm:w-[32px] w-[28px] flex justify-center items-center">
                        <ChevronLeftIcon
                          className={classNames(
                            'text-black w-[18px] transition-transform duration-500',
                            {
                              '-rotate-90':
                                !showCurrentDetails[index]?.dropDown,
                              'rotate-90': showCurrentDetails[index]?.dropDown,
                            }
                          )}
                        />
                      </div>

                      {/* Brand Logo & Name */}
                      <div className="flex items-center">
                        <img
                          src={item.img ?? '/images/default-placeholder.png'} // Default image if null or undefined
                          className="w-[37px] h-[37px] rounded-full object-cover"
                          alt="brand-logo"
                        />
                        <div className="w-[173px] text-[#0E0D0D] font-oswald font-[700] text-[18px] ml-[10px]">
                          {item.creatorName || item.brandName}
                        </div>
                      </div>
                    </div>
                    {/* Accordion Content */}
                    <div
                      className="p-[18px] bg-white overflow-x-auto"
                      onClick={() => {
                        console.log(
                          'Clicked on div - check if event fires with item:',
                          item
                        );
                        if (onCreatorDetailsClick) {
                          onCreatorDetailsClick(
                            mainlyData?.find((brief) => brief.id === item?.id)
                          );
                        } else {
                          console.log(
                            'onCreatorDetailsClick not passed or undefined'
                          );
                        }
                      }}
                    >
                      {' '}
                      {/* Reduced padding */}
                      {rows.map((row, rowIndex) => (
                        <div
                          key={rowIndex}
                          className="grid grid-cols-[140px_auto] gap-2 items-center py-[1px]" // Reduced spacing between rows
                        >
                          {/* Left-Aligned Heading - Oswald, 16px, Bold, Black */}
                          <div className="text-black font-oswald font-[700] text-[16px] uppercase">
                            {row
                              .match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g)
                              ?.join(' ')}
                          </div>

                          {/* Right-Aligned Value - Inter, 14px, Black with Padding-Right */}
                          <div className="text-black font-inter text-[14px] font-normal flex items-center pr-[10px]">
                            {row === 'details' ? (
                              <div
                                className="flex items-center cursor-pointer text-blue-600"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onRowClick &&
                                    onRowClick(
                                      mainlyData?.find(
                                        (brief) => brief.id === item?.id
                                      )
                                    );
                                }}
                              >
                                <img
                                  src="/images/item-details.svg"
                                  alt="view"
                                />
                                <span className="ml-[5px]">View</span>
                              </div>
                            ) : row.toLowerCase().includes('status') ? (
                              <div
                                className={classNames(
                                  'flex justify-center h-[28px] text-[12px] rounded-[20px] py-[4px] px-[14px] font-bold w-fit', // Reduced padding on status
                                  getStatusColor(item[row] as string)
                                )}
                              >
                                {item[row]}
                              </div>
                            ) : (
                              <div className="w-full whitespace-nowrap overflow-hidden overflow-ellipsis">
                                {item[row]}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="border border-[#F5F1E8] h-[350px] rounded-[16px] flex justify-center items-center">
                {noDataMessage}
              </div>
            )}
          </>
        ) : (
          <div className="loader-content h-[250px] flex justify-center items-center">
            <IconLoader />
          </div>
        )}

        {/* Pagination */}
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          pageSize={pageSize}
          changePage={async (type, page, limit) => {
            setCurrentPage(page);
            if (changePage) await changePage(type, page, limit);
          }}
        />
      </div>
    </div>
  );
};

export default Table;
