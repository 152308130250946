import { Download } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { ENTITIES } from 'API';
import { Amplify, API, Auth, Storage } from 'aws-amplify';
import axios from 'axios';
import { downloadFileFromUrl } from 'components';
import { addDays, format } from 'date-fns';
import {
  UseExportEntitiesToCSV,
  UseImportCreativeEarnings,
} from 'hooks/query/useAdminActions';
import React, { useCallback, useEffect, useState } from 'react';
import {
  createStaticRanges,
  DateRangePicker,
  defaultStaticRanges,
} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { toast } from 'react-toastify';
import awsExports from '../../../aws-exports';
import { ACCEPT_CSV_FILES } from '../constants';
import FileUpload from './components/FileUpload';
import OopsModal from './components/OopsModal';
import { ErrorMessages, SuccessMessages } from './constants';
Amplify.configure(awsExports);

const customStaticRanges = createStaticRanges(
  defaultStaticRanges.filter(
    (range) =>
      range.label !== 'days up to today' &&
      range.label !== 'days starting today'
  )
);

const ImportTab: React.FC = () => {
  const [userId, setUserId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [modalMessage, setModalMessage] = useState<string | null>(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection',
    },
  ]);

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const months = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ];

  const resetExportFields = () => {
    setSelectedMonth('');
    setSelectedYear('');
  };

  const closeExportModal = () => {
    resetExportFields();
    setIsExportModalOpen(false);
  };

  const currentYear = new Date().getFullYear();
  const years: string[] = [];
  for (let y = currentYear; y > currentYear - 5; y--) {
    years.push(String(y));
  }

  const { importEarnings, error } = UseImportCreativeEarnings();
  const {
    exportEntities,
    url,
    loading: exportRequestsLoading,
  } = UseExportEntitiesToCSV();

  const togglePicker = () => setIsPickerOpen((prev) => !prev);

  const onSelectFile = useCallback(async (files: File[]) => {
    const file = files?.[0];
    if (!file) return;
    if (file.type !== 'text/csv') {
      toast.error(ErrorMessages.InvalidFormat);
      return;
    }
    setFile(file);
    toast.success(SuccessMessages.FileSelected);
  }, []);

  const onError = useCallback(() => {
    if (!error?.message) return;
    setIsLoading(false);
    setModalMessage(error?.message?.replace('Error: ', ''));
  }, [error]);

  const onSuccess = () => {
    setIsLoading(false);
    toast.success(SuccessMessages.Imported);
  };

  useEffect(() => {
    onError();
  }, [error, onError]);

  useEffect(() => {
    // Fetch the currently logged-in user's info
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // `user.attributes.sub` is typically the unique user ID
        const id = user?.attributes?.sub;
        setUserId(id);
      })
      .catch((err) => {
        console.error('Error fetching user:', err);
      });
  }, []);

  const onSubmit = useCallback(async () => {
    if (!file) {
      toast.error(ErrorMessages.SelectFile);
      return;
    }
    if (!startDate || !endDate) {
      toast.error(ErrorMessages.InvalidDateRange);
      return;
    }

    const adjustedStartDate = new Date(startDate);
    adjustedStartDate.setHours(23, 59, 59, 999);
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);
    setIsLoading(true);
    const key = `imports/creative-payments-${new Date().toISOString()}.csv`;
    await Storage.put(key, file, {
      contentType: 'text/csv',
      level: 'public',
      acl: 'public-read',
    });
    await importEarnings({
      variables: {
        toDate: adjustedEndDate.toISOString(),
        fromDate: adjustedStartDate.toISOString(),
        key: `public/${key}`,
      },
    });
    onSuccess();
  }, [endDate, file, importEarnings, startDate]);

  const onCancel = () => {
    setModalMessage('');
  };

  const handleDateRangeChange = (item: any) => {
    const selectedRange = item.selection;
    setState([selectedRange]);
    setStartDate(selectedRange.startDate);
    setEndDate(selectedRange.endDate);
  };

  const openExportModal = () => {
    setIsExportModalOpen(true);
  };

  const onExportPaymentsSubmit = async () => {
    if (!selectedMonth || !selectedYear) {
      toast.error('Please select both month and year');
      return;
    }

    try {
      setIsLoading(true);
      const response = await API.post(
        'exportPaymentDetails',
        '/exportPaymentDetails',
        {
          body: {
            // { only send this if exporting approved ads
            //   exportApprovedAds: true
            // }
            exportPaymentDetails: true,
            month: `${selectedMonth}/${selectedYear}`,
          },
        }
      );

      const { csvLink } = response;
      if (!csvLink) {
        throw new Error('Invalid CSV link');
      }

      const fileResponse = await fetch(csvLink);
      const blob = await fileResponse.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `Payment_Details_${selectedMonth}_${selectedYear}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      resetExportFields();
      setIsExportModalOpen(false);
    } catch (err) {
      console.error('Error exporting payments:', err);
      toast.error('Error exporting payments');
    } finally {
      setIsLoading(false);
    }
  };

  const onExportWeekly = async () => {
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://eeizhsuc10.execute-api.us-east-1.amazonaws.com/live/sendWeeklyEarnings',
        headers: {
          'x-api-key': 'rpzw4zOOr49XCHxLDuuiA4Yt0bJnedE58A9wiFXP',
          'Content-Type': 'application/json',
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log('errored W');
    }
  };
  const onExportMonthly = async () => {
    try {
      console.log('fired M');
    } catch (e) {
      console.log('errored M');
    }
  };

  const onExportApprovedAdIds = async () => {
    try {
      setIsLoading(true);
      const response = await API.post(
        'exportPaymentDetails',
        '/exportPaymentDetails',
        {
          body: {
            exportApprovedAds: true,
          },
        }
      );

      console.log('response', response);

      const { csvLink } = response;
      if (!csvLink) {
        throw new Error('Invalid CSV link');
      }

      const fileResponse = await fetch(csvLink);
      const blob = await fileResponse.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `Approved_Ad_Ids.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Error exporting payments:', err);
      toast.error('Error exporting payments');
    } finally {
      setIsLoading(false);
    }
  };

  const onExportRequests = useCallback(() => {
    exportEntities({
      variables: { entity: ENTITIES.ApprovedCreativeRequests },
    });
  }, [exportEntities]);

  useEffect(() => {
    if (url) downloadFileFromUrl(url);
  }, [url]);

  const formatDateRange = (startDate: Date | null, endDate: Date | null) => {
    if (!startDate || !endDate) return '';
    return `${format(startDate, 'dd/MM/yyyy')} - ${format(
      endDate,
      'dd/MM/yyyy'
    )}`;
  };

  return (
    <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={8}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Button
              onClick={togglePicker}
              variant="contained"
              color="primary"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 700,
                fontSize: 12,
                py: 1,
                px: 2,
                width: 220,
              }}
            >
              {isPickerOpen ? 'Close Date Picker' : 'Open Date Picker'}
            </Button>
            {startDate && endDate && (
              <Box
                sx={{
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px',
                  p: 1,
                  backgroundColor: '#f9f9f9',
                  mt: 2,
                  display: 'inline-block',
                }}
              >
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ fontFamily: 'Inter, sans-serif', fontWeight: 600 }}
                >
                  Selected Range: {formatDateRange(startDate, endDate)}
                </Typography>
              </Box>
            )}
            <Dialog
              open={isPickerOpen}
              onClose={togglePicker}
              maxWidth="md"
              fullWidth
              sx={{
                '& .MuiDialog-paper': {
                  width: '60vw',
                  maxWidth: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                },
              }}
            >
              <DialogContent>
                <DateRangePicker
                  onChange={handleDateRangeChange}
                  showSelectionPreview
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                  preventSnapRefocus
                  calendarFocus="backwards"
                  staticRanges={customStaticRanges}
                  inputRanges={[]}
                  style={{ margin: '0 auto' }}
                  rangeColors={['#ff8730']}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={togglePicker} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <FileUpload
              emptyMessage={file?.name}
              onFileSelect={onSelectFile}
              accept={ACCEPT_CSV_FILES}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ pl: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={onExportRequests}
              sx={{
                textTransform: 'uppercase',
                fontWeight: 700,
                fontSize: 12,
                py: 1,
                px: 2,
              }}
            >
              {exportRequestsLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>
                  <Download sx={{ mr: 1 }} />
                  Creative Requests
                </>
              )}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={openExportModal}
              sx={{
                textTransform: 'uppercase',
                fontWeight: 700,
                fontSize: 12,
                py: 1,
                px: 2,
              }}
            >
              {exportRequestsLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>
                  <Download sx={{ mr: 1 }} />
                  Creator Payment Export
                </>
              )}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onExportApprovedAdIds}
              sx={{
                textTransform: 'uppercase',
                fontWeight: 700,
                fontSize: 12,
                py: 1,
                px: 2,
              }}
            >
              {exportRequestsLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>
                  <Download sx={{ mr: 1 }} />
                  Approved Ads Export
                </>
              )}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onExportWeekly}
              disabled={false}
              sx={{
                textTransform: 'uppercase',
                fontWeight: 700,
                fontSize: 12,
                py: 1,
                px: 2,
              }}
            >
              {exportRequestsLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>
                  <Download sx={{ mr: 1 }} />
                  Send Weekly Earnings Whatsapp
                </>
              )}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onExportMonthly}
              disabled={userId !== 'da92431c-4c6c-4d16-8cb3-dcfed531bbbb'}
              sx={{
                textTransform: 'uppercase',
                fontWeight: 700,
                fontSize: 12,
                py: 1,
                px: 2,
              }}
            >
              {exportRequestsLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>
                  <Download sx={{ mr: 1 }} />
                  Send Monthly Earnings Whatsapp
                </>
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'flex-start', pt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={isLoading}
          sx={{
            textTransform: 'none',
            fontWeight: 600,
            fontSize: 15,
            px: 3,
            py: 1,
            borderRadius: 2,
            minWidth: 200,
            backgroundColor: isLoading ? 'gray' : 'black',
          }}
        >
          {!isLoading ? (
            'Upload'
          ) : (
            <CircularProgress size={16} color="inherit" />
          )}
        </Button>
      </Box>

      {modalMessage && <OopsModal message={modalMessage} onCancel={onCancel} />}

      <Dialog
        open={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h6" sx={{ fontFamily: 'Inter, sans-serif' }}>
              Select Month and Year
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="select-year-label">Year</InputLabel>
              <Select
                labelId="select-year-label"
                value={selectedYear}
                label="Year"
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="select-month-label">Month</InputLabel>
              <Select
                labelId="select-month-label"
                value={selectedMonth}
                label="Month"
                onChange={(e) => setSelectedMonth(e.target.value)}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: '200px', // Set max height for scrolling
                      overflowY: 'auto', // Enable vertical scrolling
                    },
                  },
                }}
              >
                {months.map((month) => {
                  const monthNumber = parseInt(month.value, 10);
                  const currentMonth = new Date().getMonth() + 1;
                  const disableMonth =
                    selectedYear === String(currentYear) &&
                    monthNumber > currentMonth;
                  return (
                    <MenuItem
                      key={month.value}
                      value={month.value}
                      disabled={disableMonth}
                    >
                      {month.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeExportModal()} color="primary">
            Cancel
          </Button>
          <Button
            onClick={onExportPaymentsSubmit}
            color="primary"
            variant="contained"
          >
            {!isLoading ? (
              'Submit'
            ) : (
              <CircularProgress size={16} color="inherit" />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ImportTab;
