import { GridColDef } from '@mui/x-data-grid';
import {
  ADMIN_STATUS,
  CREATIVE_REQUEST_LAST_UPDATED_BY,
  CREATIVE_STATUS,
  USER_TYPES,
} from 'API';

const getStatusColor = (status: string) => {
  switch (status) {
    case CREATIVE_STATUS.Approved:
      return 'text-white bg-[#1d1d1d] leading-[20px]';
    case CREATIVE_STATUS.Rejected:
      return 'border-2 border-[#1d1d1d] text-[#1d1d1d]';
    case CREATIVE_STATUS.Submitted:
      return 'text-white bg-[#1d1d1d] leading-[20px]';
    default:
      return 'text-white bg-[#1d1c1c80] leading-[20px]';
  }
};

// If admin receives submission by creator OR creator receives submission by admin
// the row for that submission will be highlighted to indicate about new revision update
export const getSubmissionRowColor = ({
  userType,
  adminApproval,
  lastUpdatedBy,
}: {
  userType: USER_TYPES;
  lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
  adminApproval?: CREATIVE_STATUS | ADMIN_STATUS | null;
}) => {
  if (!lastUpdatedBy || adminApproval !== ADMIN_STATUS.Revision) {
    return '';
  }

  const isDifferentPair =
    (lastUpdatedBy === CREATIVE_REQUEST_LAST_UPDATED_BY.ADMIN &&
      userType === USER_TYPES.CREATIVE_USER) ||
    (lastUpdatedBy === CREATIVE_REQUEST_LAST_UPDATED_BY.CREATOR &&
      userType === USER_TYPES.ADMIN_USER);

  return isDifferentPair ? 'bg-green-500 bg-opacity-75' : '';
};

export const TABLE_HEADER: GridColDef[] = [
  {
    filterable: false,
    field: 'name',
    headerName: 'Creator name',
    valueGetter: (_, row) => row.creatorProfile?.name || '',
    renderCell: ({ row }) =>
      row.creatorProfile?.name?.length > 1 ? row.creatorProfile?.name : 'N/A',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Date of submission',
    renderCell: ({ value }) => new Date(value).toLocaleDateString(),
  },
  {
    field: 'uniqueId',
    headerName: 'Creative ID',
    flex: 1,
    renderCell: ({ value }) => value ?? 'N/A',
  },
  {
    flex: 1.5,
    filterable: false,
    field: 'BriefName',
    headerName: 'Brief name',
    renderCell: (params) => params.row.brief?.BriefName,
    valueFormatter: (v) => v || 'N/A',
    valueGetter: (_, row) => row.brief?.BriefName || '',
  },
  {
    filterable: false,
    field: 'creatorEmail',
    headerName: 'Creator email',
    valueGetter: (_, row) => row.creatorProfile?.email || '',
    renderCell: ({ row }) => row.creatorProfile?.email || 'N/A',
    flex: 1,
  },
  {
    field: 'adminApproval',
    headerName: 'Admin Approval status',
    flex: 1,
    renderCell: (params) => (
      <div className="p-2 h-full flex flex-col justify-center align-middle">
        <div
          className={`flex justify-center ${getStatusColor(
            params.value ?? 'Submitted'
          )} text-sm max-h-[50px] rounded-3xl text-center align-middle`}
        >
          <p>{params.value ?? 'Submitted'}</p>
        </div>
      </div>
    ),
  },
  {
    field: 'status',
    headerName: 'Brand approval status',
    flex: 1,
    renderCell: (params) => (
      <div className="p-2 h-full flex flex-col justify-center align-middle">
        <div
          className={`flex justify-center ${getStatusColor(
            params.value ?? 'Submitted'
          )} text-sm rbg-slate-900  max-h-[50px] rounded-3xl text-center align-middle`}
        >
          <p>{params.value ?? 'Submitted'}</p>
        </div>
      </div>
    ),
  },
  {
    field: 'details',
    headerName: 'Details',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
  },
];

export const REQUEST_FETCH_LIMIT = 10;
export enum TabNames {
  Payments = 'Payments',
  General = 'General',
}

export const ACCEPT_CSV_FILES = { 'text/csv': ['.csv'] };
