import styled from 'styled-components';

/* =======================
   📌 MAIN CONTAINER
======================= */

export const ProfileContainer = styled.div`
  background: white;
  padding: 30px;
  border-radius: 16px;
  max-width: 800px;
  margin: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @media (min-width: 1020px) {
    max-width: 100%;
  }
`;

/* =======================
   📌 HEADER & PROFILE IMAGE
======================= */

export const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
  }
`;

export const ProfileImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
`;

/* =======================
   📌 USER INFO & TEXT STYLES
======================= */

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const CreatorID = styled.p`
  font-size: 16px;
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  line-height: 140%;
  color: #1d1c1c;
  margin-bottom: 40px;

  .creator-id {
    color: #666;
    font-size: 16px;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    line-height: 140%;
  }
`;

export const UserName = styled.h2`
  font-size: 22px;
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  line-height: 140%;
  margin-top: 12px;
  text-transform: uppercase;
`;

export const UserCountry = styled.p`
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #666;
  margin-top: 4px;
`;

/* =======================
   📌 FORM INPUTS & LABELS
======================= */

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
`;

export const Label = styled.label<{ left?: string }>`
  position: absolute;
  top: -10px;
  left: ${(props) => props.left || '12px'};
  background: white;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  z-index: 999;
`;

export const InputField = styled.input<{ isEditing: boolean }>`
  padding: 12px;
  border: 1px solid #000;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  color: #333;
  background: white;
  pointer-events: ${(props) => (props.isEditing ? 'auto' : 'none')};
  user-select: ${(props) => (props.isEditing ? 'auto' : 'none')};

  &:focus {
    outline: none;
    border: 1px solid #000;
  }
`;

export const StrongText = styled.strong`
  font-size: 16px;
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  line-height: 140%;
  color: #1d1c1c;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Space between input and tooltip */
  width: 100%; /* Ensure wrapper doesn't shrink input */
`;

export const InputContainer = styled.div`
  flex-grow: 1; /* Ensures input takes full width */
  width: 100%;
`;

/* =======================
   📌 PLATFORM
======================= */

export const PlatformRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    /* Mobile devices */
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align to the left */
  }
`;

export const PlatformLogo = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;

  @media (max-width: 600px) {
    align-self: center; /* Center logo on smaller screens */
  }
`;

export const PlatformInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 250px;
  min-width: 250px;
  width: 100%;
  gap: 8px;

  @media (max-width: 600px) {
    flex-direction: column; /* Stack input and button below the logo */
    align-items: center;
    max-width: 100%; /* Full width on small screens */
  }
`;

export const PlatformInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;

  @media (max-width: 600px) {
    align-items: center; /* Center align on mobile */
    text-align: center;
  }
`;

export const PlatformName = styled.label`
  position: absolute;
  top: -10px;
  left: 12px;
  background: white;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  z-index: 1;
  transition: 0.2s ease-in-out;
`;

export const ActionButton = styled.button`
  background: #000;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: #444;
  }
`;

export const StatusIcon = styled.span`
  font-size: 18px;
  margin-left: 8px;
  color: ${(props) => (props.children === '✅' ? 'green' : 'red')};
`;

/* =======================
   📌 SECTION DIVIDERS
======================= */

export const Divider = styled.hr`
  border: 1px solid #ddd;
  margin-top: 25px;
`;

/* =======================
   📌 TITLES & TEXT CONTENT
======================= */

export const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const AboutMe = styled.p`
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #666;
  margin-bottom: 20px;
  white-space: pre-line; /* PRESERVES NEW LINES */
`;

export const ErrorText = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

export const TextArea = styled.textarea`
  padding: 12px;
  border: 1px solid black !important; /* Ensures border is always present */
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  height: 200px;
  color: #333;
  background: white;
  resize: none;
  transition: border 0.2s ease-in-out;

  &:focus,
  &:active {
    outline: none;
    border: 2px solid black !important; /* Prevents disappearing effect */
    background: white;
  }

  @media (max-width: 768px) {
    height: 250px; /* Increase height on mobile */
  }
`;

/* =======================
   📌 TAGS & HASHTAGS
======================= */

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
`;

export const Tag = styled.span<{ isEditing: boolean }>`
  background: white;
  padding: 6px 12px;
  border: 1px solid #000;
  border-radius: 12px;
  font-size: 14px;
  color: #000;
  cursor: ${(props) => (props.isEditing ? 'pointer' : 'default')};
  transition: all 0.3s ease-in-out;

  &:hover {
    ${(props) => props.isEditing && 'background: #ffefef; color: red;'}
  }
`;

export const TagInput = styled.input`
  padding: 10px;
  border: 1px solid #000;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  color: #333;
  background: white;
  &:focus {
    outline: none;
    border: 1px solid #ff872f; /* Change border color on focus */
  }
`;

export const MaxTagText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #999;
  margin-left: 10px;
`;

/* =======================
   📌 CONTACT SECTIONS
======================= */

export const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

/* =======================
   📌 BUTTONS & ACTIONS
======================= */

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px; /* Adds spacing between buttons */

  @media (max-width: 768px) {
    flex-direction: column-reverse; /* Reverse the order */
    align-items: center;
    width: 100%;
  }
`;

export const EditButton = styled.button`
  background: #ff872f;
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
  width: fit-content; /* Ensure buttons aren't too wide */

  &:hover {
    background: #e67e3c;
  }

  @media (max-width: 768px) {
    width: 100%; /* Full width on mobile */
  }
`;

export const SaveButton = styled(EditButton)`
  background: #ff872f;
`;

export const CancelButton = styled.button`
  background: white;
  border: 1px solid #ff872f;
  color: #ff872f;
  padding: 12px 24px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
`;
