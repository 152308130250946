import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";
import { createPortal } from "react-dom";
import { FiX } from "react-icons/fi";

interface HintTooltipProps {
    message: string;
    tooltipPosition?: "top" | "right" | "bottom" | "left";
    showNavigation?: boolean;
    showCloseButton?: boolean;
    questionBgColor?: string;
    /**
     * If provided, this replaces the default "?"
     * with a custom component (e.g. a <button>).
     */
    triggerContent?: React.ReactNode;
}

const HintTooltip: React.FC<HintTooltipProps> = ({
    message,
    tooltipPosition = "top",
    showNavigation = false,
    showCloseButton = false,
    questionBgColor = "#FF872F",
    triggerContent,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLDivElement>(null);
    const [tooltipStyle, setTooltipStyle] = useState({ top: 0, left: 0 });

    // Check if message is long
    const isLongMessage = message.length > 120;

    // Detect screen size
    useEffect(() => {
        const checkScreenSize = () => setIsMobile(window.innerWidth < 768);
        checkScreenSize();
        window.addEventListener("resize", checkScreenSize);
        return () => window.removeEventListener("resize", checkScreenSize);
    }, []);

    // Adjust tooltip position dynamically
    useEffect(() => {
        if (isVisible && triggerRef.current && tooltipRef.current) {
            const triggerRect = triggerRef.current.getBoundingClientRect();
            const tooltipRect = tooltipRef.current.getBoundingClientRect();

            let top = triggerRect.top;
            let left = triggerRect.left;

            switch (tooltipPosition) {
                case "top":
                    top -= tooltipRect.height + 10;
                    left += triggerRect.width / 2 - tooltipRect.width / 2;
                    break;
                case "right":
                    top += triggerRect.height / 2 - tooltipRect.height / 2;
                    left += triggerRect.width + 10;
                    break;
                case "bottom":
                    top += triggerRect.height + 10;
                    left += triggerRect.width / 2 - tooltipRect.width / 2;
                    break;
                case "left":
                    top += triggerRect.height / 2 - tooltipRect.height / 2;
                    left -= tooltipRect.width + 10;
                    break;
            }

            // Slight shift for mobile to avoid edges
            if (isMobile) {
                left = Math.max(10, left - tooltipRect.width * 0.3);
            }

            // Prevent tooltip from going off-screen
            setTooltipStyle({
                top: Math.max(10, Math.min(window.innerHeight - tooltipRect.height - 10, top)),
                left: Math.max(10, Math.min(window.innerWidth - tooltipRect.width - 10, left)),
            });
        }
    }, [isVisible, tooltipPosition, isMobile]);

    // Close tooltip when clicking outside (only for mobile)
    useEffect(() => {
        if (!isMobile) return;

        const handleClickOutside = (event: MouseEvent) => {
            if (
                tooltipRef.current &&
                !tooltipRef.current.contains(event.target as Node) &&
                triggerRef.current &&
                !triggerRef.current.contains(event.target as Node)
            ) {
                setIsVisible(false);
            }
        };

        if (isVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [isVisible, isMobile]);

    // Animation for tooltip
    const animation = useSpring({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "scale(1)" : "scale(0.95)",
        config: { tension: 200, friction: 20 },
    });

    // Arrow style with the flat side attached to the tooltip.
    const arrowSize = 10;
    const arrowStyle = (() => {
        switch (tooltipPosition) {
            case "top":
                return {
                    width: 0,
                    height: 0,
                    borderLeft: `${arrowSize}px solid transparent`,
                    borderRight: `${arrowSize}px solid transparent`,
                    borderTop: `${arrowSize}px solid ${questionBgColor}`,
                    position: "absolute" as const,
                    bottom: -arrowSize,
                    left: `calc(50% - ${arrowSize}px)`,
                };
            case "bottom":
                return {
                    width: 0,
                    height: 0,
                    borderLeft: `${arrowSize}px solid transparent`,
                    borderRight: `${arrowSize}px solid transparent`,
                    borderBottom: `${arrowSize}px solid ${questionBgColor}`,
                    position: "absolute" as const,
                    top: -arrowSize,
                    left: `calc(50% - ${arrowSize}px)`,
                };
            case "left":
                return {
                    width: 0,
                    height: 0,
                    borderTop: `${arrowSize}px solid transparent`,
                    borderBottom: `${arrowSize}px solid transparent`,
                    borderLeft: `${arrowSize}px solid ${questionBgColor}`,
                    position: "absolute" as const,
                    right: -arrowSize,
                    top: `calc(50% - ${arrowSize}px)`,
                };
            case "right":
                return {
                    width: 0,
                    height: 0,
                    borderTop: `${arrowSize}px solid transparent`,
                    borderBottom: `${arrowSize}px solid transparent`,
                    borderRight: `${arrowSize}px solid ${questionBgColor}`,
                    position: "absolute" as const,
                    left: -arrowSize,
                    top: `calc(50% - ${arrowSize}px)`,
                };
            default:
                return {};
        }
    })();

    // For non-mobile, we show on hover; for mobile, we toggle on click.
    const handleMouseEnter = () => {
        if (!isMobile) setIsVisible(true);
    };
    const handleMouseLeave = () => {
        if (!isMobile) setIsVisible(false);
    };
    const handleClick = () => {
        if (isMobile) setIsVisible((prev) => !prev);
    };

    return (
        <div
            className="relative flex items-center z-[9998]"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            {/* Trigger: Either the default '?' or the user-provided content */}
            <div
                ref={triggerRef}
                className={
                    triggerContent
                        ? "cursor-pointer" // no forced white text if there's custom content
                        : "flex items-center justify-center text-white text-xs font-bold cursor-pointer"
                }
                style={
                    triggerContent
                        ? {}
                        : {
                            backgroundColor: questionBgColor,
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                        }
                }
            >
                {triggerContent ?? "?"}
            </div>


            {/* Tooltip (Rendered in Portal to prevent clipping) */}
            {isVisible &&
                createPortal(
                    <animated.div
                        ref={tooltipRef}
                        style={{
                            ...animation,
                            position: "fixed",
                            top: `${tooltipStyle.top}px`,
                            left: `${tooltipStyle.left}px`,
                            backgroundColor: questionBgColor,
                            width: isLongMessage ? "300px" : "200px",
                            borderRadius: "8px",
                            zIndex: 9999,
                            boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
                            overflow: "visible",
                        }}
                        className="text-white font-oswald font-bold text-[14px]"
                    >
                        {/* Arrow (only on non-mobile to avoid weird overlays) */}
                        {!isMobile && <div style={arrowStyle} />}

                        {/* Tooltip Content */}
                        <div style={{ padding: "10px", position: "relative" }}>
                            {/* Close Button (Optional) */}
                            {showCloseButton && (
                                <button
                                    className="absolute top-2 right-2 text-white bg-white/20 hover:bg-white/30 p-1 rounded-full"
                                    onClick={() => setIsVisible(false)}
                                >
                                    <FiX size={14} />
                                </button>
                            )}

                            {/* Message */}
                            <p className="text-white font-oswald font-bold text-[14px]">{message}</p>

                            {/* Navigation Arrows (Optional) */}
                            {showNavigation && (
                                <div className="flex justify-between items-center mt-3">
                                    <button className="text-white bg-white/20 hover:bg-white/30 p-1 rounded-full">
                                        ‹
                                    </button>
                                    <button className="text-white bg-white/20 hover:bg-white/30 p-1 rounded-full">
                                        ›
                                    </button>
                                </div>
                            )}
                        </div>
                    </animated.div>,
                    document.body // Render tooltip outside any container that might clip it
                )}
        </div>
    );
};

export default HintTooltip;
