import { CheckIcon } from '@heroicons/react/24/solid';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab } from '@mui/material';
import { ADMIN_STATUS, CreativeRequest, CREATIVE_STATUS } from 'API';
import { Storage } from 'aws-amplify';
import SuccessModal from 'components/authentication/modal';
import { IconLoader } from 'components/loader';
import ExportPDF from 'components/pdfSubmission/pdf';
import { GetVideoPreviwUrl } from 'hooks/query/useAdminActions';
import { useWhatsApp } from 'hooks/useWhatsApp';
import { TWO_DAYS_IN_SECONDS } from 'hooks/utils';
import { AdminCreativeRequestsTabs } from 'pages/adminCreativeUsers/constants';
import PdfViewer from 'pages/previewWindow/pdfViewer';
import { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ViewRequestProps, withRequestView } from 'state/requests';
import { BrandRoutes, UnknownType } from 'utils';
import '../campaignSlider/campaignSlider.css';
import EarningsTab from './components/EarningsTab/EarningsTab';
import ReviewSection from './components/ReviewSection/ReviewSection';
import { CreativeDetails } from './CreativeDetails';

interface Props {
  videoUrl?: string;
  reqLoading?: boolean;
  onClose: () => void;
  request: CreativeRequest | null;
  createAdPayload: UnknownType;
  updateCreativeRequestStatus?: (
    newStatus: string,
    comment?: string,
    sendEmail?: boolean,
    previousStatus?: string
  ) => void;
  onSuccess?: () => Promise<void>;
  type?: string;
}

// Define the required style for all h6 elements.
const h6Style: CSSProperties = {
  fontFamily: 'Oswald',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '120%',
  textTransform: 'uppercase',
};

export const AdminApprovalContent: FC<Props & ViewRequestProps> = ({
  videoUrl,
  onClose,
  type,
  updateCreativeRequestStatus,
  request,
  reqLoading,
  getVideoLink,
  approveRequest,
  rejectRequest,
  createAdPayload,
  createAdResponse,
  loading,
  isSuccess,
  errorMsg,
  onSuccess,
}) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [awsURL, setAwsURL] = useState<string>('');
  const [creativeUrl, setCreativeUrl] = useState<string>('');
  const [adName, setAdName] = useState('');
  const [adError, setAdError] = useState('');
  const [rejectComment, setRejectComment] = useState('');
  const [rejectError, setRejectError] = useState(false);
  const [actType, setActType] = useState('');
  const [isCopy, setIsCopy] = useState(false);
  const [previewPdf, setPreviewPdf] = useState('');
  const [showPdf, setShowPdf] = useState(false);
  const sendRejectionEmail = useRef(true);
  const [tab, setTab] = useState<AdminCreativeRequestsTabs>(
    AdminCreativeRequestsTabs.General
  );
  const [selectedVerticals, setSelectedVerticals] = useState<Set<string>>(
    new Set()
  );

  const { sendWhatsAppMessage, loading: loadingWhatsapp } = useWhatsApp();

  const {
    getPreviewUrl,
    data: previewData,
    loading: previewDataLoading,
  } = GetVideoPreviwUrl();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const onOkay = async () => {
    if (!adName) {
      setAdError('Ad name is required');
    } else if (isConfirmationOpen) {
      setAdError('');
      approveRequest(createAdPayload, adName, request);
    }
  };
  const getEmbeddedUrl = (e: string): string => {
    try {
      const { hostname, pathname } = new URL(e);
      if (hostname.includes('tiktok.com')) {
        const videoId = pathname.split('/').at(-1);
        const isValidId = /^\d+$/.test(videoId || '');
        if (videoId?.length && isValidId)
          return `https://www.tiktok.com/embed/v2/${videoId}`;
      }
      return e;
    } catch (err) {
      return e;
    }
  };

  const onReject = () => {
    setActType('reject');
    if (!rejectComment) setRejectError(true);
    if (type === 'Admin' && rejectComment && updateCreativeRequestStatus) {
      updateCreativeRequestStatus(
        ADMIN_STATUS.Rejected,
        rejectComment,
        sendRejectionEmail.current
      );
    }

    if (type !== 'Admin' && !isConfirmationOpen && rejectComment && request) {
      rejectRequest(rejectComment, request, sendRejectionEmail.current);
    }
  };

  const handleSendMessages = async () => {
    if (
      request &&
      request.creatorProfile &&
      request.creatorProfile?.phoneNumber &&
      request?.uniqueId
    ) {
      await sendWhatsAppMessage(
        request.creatorProfile.id,
        request.creatorProfile?.phoneNumber,
        request?.uniqueId,
        rejectComment
      );
    }
  };

  const onRevision = async () => {
    setActType('revision');
    if (!rejectComment) setRejectError(true);
    if (type === 'Admin' && rejectComment && updateCreativeRequestStatus) {
      await handleSendMessages();

      updateCreativeRequestStatus(
        ADMIN_STATUS.Revision,
        rejectComment,
        sendRejectionEmail.current
      );
    }
  };
  useEffect(() => {
    if (selectedVerticals) console.log('selectedVerticals', selectedVerticals);
  }, [selectedVerticals]);

  function addCompressedKey(originalKey: string): string {
    // Check if the key ends with .mov or .mp4 (case-insensitive)
    if (!originalKey.match(/\.(mov|mp4)$/i)) {
      return originalKey;
    }

    // Find the last dot in the key
    const dotIndex = originalKey.lastIndexOf('.');
    if (dotIndex === -1) {
      return originalKey;
    }

    // 🔴 Commenting out "_compressed" addition
    // const newKey =
    //   originalKey.substring(0, dotIndex) +
    //   '_compressed' +
    //   originalKey.substring(dotIndex);

    // 🔄 Returning the original key instead
    return originalKey;
  }

  const onApprove = () => {
    if (!isConfirmationOpen) setIsConfirmationOpen(true);
  };

  const getApproval = () => {
    return Boolean(request?.status && request?.adminApproval);
  };

  function capitalizeFirstLetter(string: string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
  }

  const getPDf = (url: string) => {
    setPreviewPdf(url);
  };

  const getPdfUrlFromBucket = async () => {
    const url = await Storage.get(`SubmissionPdf/${request?.id}`);
    fetch(url).then((res) => {
      if (res.status === 200) setPreviewPdf(url);
      if (res.status === 404) setPreviewPdf('');
    });
  };

  useEffect(() => {
    // 1) If there's a tiktokVideoCode, fetch that link
    if (request?.tiktokVideoCode) {
      getVideoLink(request.tiktokVideoCode);
    }

    // 2) Get original file (for "Download Original")
    const creativeKey =
      request?.tiktokCreativeUrl?.replace('public/', '') || '';
    Storage.get(creativeKey)
      .then((data) => {
        setCreativeUrl(data); // presigned URL for the original file
      })
      .catch((err) => {
        console.error('Failed to load original tiktokCreativeUrl:', err);
      });

    // 3) Get the preview file with "_compressed"
    const key = (
      request?.creativePreviewUrl || request?.tiktokCreativeUrl
    )?.replace('public/', '');

    // Use the nullish coalescing operator ?? to fallback to ""
    const compressedKey = addCompressedKey(key ?? '');
    // e.g. insert "_compressed" before .mov/.mp4

    console.log('compressedKey', compressedKey);

    Storage.get(compressedKey, { expires: TWO_DAYS_IN_SECONDS })
      .then((compressedPresignedUrl) => {
        // This is a valid presigned URL for the _compressed object
        console.log('Preview (compressed) URL:', compressedPresignedUrl);
        setAwsURL(compressedPresignedUrl);
      })
      .catch((err) => {
        console.error(`Failed to load preview for ${compressedKey}:`, err);
      });
  }, [request]);

  useEffect(() => {
    if (!previewData && request) {
      getPreviewUrl({ variables: { videoPath: request.tiktokCreativeUrl } });
    }
  }, [previewData, request]);

  useEffect(() => {
    if (!loading && isSuccess) onClose();
  }, [loading, isSuccess, onClose]);

  useEffect(() => {
    if (createAdResponse && !showSuccessModal) {
      setIsConfirmationOpen(false);
      setShowSuccessModal(true);
    }
  }, [createAdResponse, showSuccessModal]);

  useEffect(() => {
    setSelectedVerticals(new Set(request?.creatorProfile?.hashtags || []));
    getPdfUrlFromBucket();
  }, []);

  const onChangeTab = (
    event: React.SyntheticEvent,
    newTab: AdminCreativeRequestsTabs
  ) => {
    setTab(newTab);
  };

  const [isNotificationEnabled, setIsNotificationEnabled] = useState(
    sendRejectionEmail.current
  );

  const handleToggleNotification = () => {
    const newValue = !isNotificationEnabled;
    setIsNotificationEnabled(newValue);
    sendRejectionEmail.current = newValue;
  };

  const generatePreviewUrl = (
    request: CreativeRequest,
    awsURL: string,
    videoUrl?: string
  ) => {
    if (!request) return '';
    const baseUrl = process.env.REACT_APP_FRONTEND_BASE_URL;
    const { uniqueId, brief, creativePreviewUrl, tiktokCreativeUrl } =
      request || {};
    const briefName = brief?.BriefName;
    const brandImage = brief?.brandProfile?.userProfile?.avatar;
    const splitAndJoin = (url: string) => url.split('/').join('!');
    const previewPath = awsURL
      ? splitAndJoin(creativePreviewUrl || tiktokCreativeUrl || '')
      : videoUrl
      ? splitAndJoin(getEmbeddedUrl(videoUrl))
      : '';
    return `${baseUrl}preview/${previewPath}?id=${uniqueId}&briefName=${briefName}&brandImage=${brandImage}`;
  };

  return (
    <>
      <div className="border border-[#f5f1e8] rounded-[16px] w-full p-[20px]">
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              TabIndicatorProps={{
                className: 'bg-orange-main text-orange-main',
              }}
              onChange={onChangeTab}
            >
              <Tab
                disableRipple={true}
                className="text-[black]"
                value={AdminCreativeRequestsTabs.General}
                label={
                  <span style={h6Style}>
                    {AdminCreativeRequestsTabs.General}
                  </span>
                }
              ></Tab>
              <Tab
                disableRipple={true}
                className="text-[black]"
                value={AdminCreativeRequestsTabs.Details}
                label={
                  <span style={h6Style}>
                    {AdminCreativeRequestsTabs.Details}
                  </span>
                }
              ></Tab>
              <Tab
                disableRipple={true}
                className="text-[black]"
                value={AdminCreativeRequestsTabs.Earnings}
                label={
                  <span style={h6Style}>
                    {AdminCreativeRequestsTabs.Earnings}
                  </span>
                }
              ></Tab>
            </TabList>
          </Box>

          <TabPanel value={AdminCreativeRequestsTabs.General}>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
              <div className="col-span-1 flex items-center justify-center md:items-start md:justify-start">
                <div className="flex-shrink-0 w-[240px] h-[400px] md:w-[320px] md:h-[620px] flex items-center justify-center overflow-hidden">
                  {awsURL ? (
                    <video
                      controls
                      autoPlay
                      muted
                      className="w-full h-full object-fill"
                    >
                      <source src={awsURL} />
                    </video>
                  ) : (
                    <p className="text-gray-500 text-center">No Video Exists</p>
                  )}
                </div>
              </div>

              {/* Right panel */}
              <div className="col-span-3 flex flex-col gap-4">
                <ReviewSection
                  h6Style={h6Style}
                  request={request}
                  rejectComment={rejectComment}
                  rejectError={rejectError}
                  actType={actType}
                  isNotificationEnabled={isNotificationEnabled}
                  reqLoading={reqLoading || false}
                  type={type}
                  setRejectComment={setRejectComment}
                  setRejectError={setRejectError}
                  onReject={onReject}
                  onRevision={onRevision}
                  onApprove={onApprove}
                  updateCreativeRequestStatus={updateCreativeRequestStatus}
                  handleToggleNotification={handleToggleNotification}
                />

                {/* Creator Tags */}
                <div className="mt-5">
                  <h6 style={h6Style} className="mb-3">
                    CREATOR HASHTAGS
                  </h6>
                  <div
                    className={`relative brand-dashboard__profile-group ${
                      Array.from(selectedVerticals || []).length > 0
                        ? 'flex flex-wrap justify-center gap-2'
                        : 'text-left'
                    }`}
                    style={{
                      maxHeight: '300px',
                      overflowY: 'auto',
                      scrollbarWidth: 'none',
                      msOverflowStyle: 'none',
                    }}
                  >
                    {Array.from(selectedVerticals || []).length > 0 ? (
                      Array.from(selectedVerticals).map((vertical, index) => (
                        <div
                          key={index}
                          className={`border bg-[#202020] text-white rounded-[80px] cursor-pointer flex items-center justify-center px-4 py-2`}
                        >
                          <span className="text-sm text-center">
                            {vertical}
                          </span>
                        </div>
                      ))
                    ) : (
                      <p className="text-gray-500 italic text-sm">
                        The user has no tags.
                      </p>
                    )}
                  </div>
                </div>

                {/* For Admin For Creator Have reviewed */}
                {request?.adminApproval && type === 'Admin' && (
                  <div className="mt-4">
                    {capitalizeFirstLetter(request?.adminApproval) ===
                    CREATIVE_STATUS.Approved ? (
                      <span className="text-success flex items-center font-semibold">
                        You have already approved this creative request
                        <CheckIcon className="w-6 ml-2" />
                      </span>
                    ) : (
                      <div>
                        <span
                          className={`flex items-center font-semibold text-[15px] ${
                            capitalizeFirstLetter(request?.adminApproval) ===
                            'Revision'
                              ? 'text-black'
                              : 'text-danger'
                          }`}
                        >
                          {capitalizeFirstLetter(request?.adminApproval) ===
                          'Revision'
                            ? 'You have sent this creative request for revision'
                            : 'You have already rejected this creative request'}
                        </span>
                        <div className="bg-white rounded-lg p-3 h-[116px] overflow-y-auto mt-3">
                          {(request?.adminComment?.length ?? 0) > 0 && (
                            <div>
                              <h6 className="font-bold mb-2">From Admin:</h6>
                              {request?.adminComment?.map((comment, index) => (
                                <div key={index} className="flex pb-1">
                                  -{' '}
                                  <span className="first-letter:capitalize pl-1">
                                    {comment}
                                  </span>
                                </div>
                              ))}
                            </div>
                          )}
                          {capitalizeFirstLetter(request?.adminApproval) ===
                            'Revision' &&
                            (request?.creatorComment?.length ?? 0) > 0 && (
                              <div className="mt-2">
                                <h6 className="font-bold mb-2">
                                  From Creator:
                                </h6>
                                {request?.creatorComment?.map(
                                  (comment, index) => (
                                    <div key={index} className="flex pb-1">
                                      -{' '}
                                      <span className="first-letter:capitalize pl-1">
                                        {comment}
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {/* Activation Description */}
                <div>
                  <h6 style={h6Style} className="mb-2">
                    ACTIVATION DESCRIPTION
                  </h6>
                  <p className="border border-gray-200 p-4 rounded-md">
                    {request?.briefDescription ||
                      'No activation description available.'}
                  </p>
                </div>

                {/* Download Buttons */}
                {!previewDataLoading && previewData !== undefined ? (
                  <div>
                    {type === 'Admin' && previewData ? (
                      <div className="flex flex-col sm:flex-row justify-between items-center mt-5 gap-4">
                        {/* Download Buttons */}
                        <div className="flex gap-2">
                          <a
                            href={awsURL}
                            download
                            className="creator-button flex items-center gap-2 px-4 py-2 text-sm font-medium"
                          >
                            <svg
                              className="fill-current w-4 h-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                            </svg>
                            <span>Download Preview</span>
                          </a>
                          <a
                            href={creativeUrl}
                            download
                            className="creator-button flex items-center gap-2 px-4 py-2 text-sm font-medium"
                          >
                            <svg
                              className="fill-current w-4 h-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                            </svg>
                            <span>Download Original</span>
                          </a>
                        </div>

                        {/* Copy Preview URL Button */}
                        <CopyToClipboard
                          onCopy={() => {
                            setIsCopy(true);
                            setTimeout(() => setIsCopy(false), 1000);
                          }}
                          text={
                            request
                              ? generatePreviewUrl(request, awsURL, videoUrl)
                              : ''
                          }
                        >
                          <button className="creator-button px-4 py-2 text-sm font-medium">
                            {isCopy ? 'Copied' : 'Copy Preview URL'}
                          </button>
                        </CopyToClipboard>

                        {/* PDF Actions */}
                        {previewPdf && (
                          <div className="flex items-center gap-2">
                            <a
                              href={previewPdf}
                              className="border border-gray-400 rounded-md p-1"
                              data-tooltip-id="tooltip-download-pdf"
                            >
                              <img
                                src="images/download-pdf.png"
                                alt="Download PDF"
                                className="w-6 h-6"
                              />
                            </a>
                            <button
                              className="border border-gray-400 rounded-md p-1"
                              onClick={() => setShowPdf(true)}
                              data-tooltip-id="tooltip-see-pdf"
                            >
                              <img
                                src="images/view-pdf.png"
                                alt="View PDF"
                                className="w-6 h-6"
                              />
                            </button>
                            <ReactTooltip
                              id="tooltip-see-pdf"
                              place="top"
                              content="See PDF"
                              className="text-xs"
                            />
                            <ReactTooltip
                              id="tooltip-download-pdf"
                              place="top"
                              content="Download PDF"
                              className="text-xs"
                            />
                          </div>
                        )}

                        {/* PDF Viewer */}
                        {showPdf && (
                          <PdfViewer
                            fileUrl={previewPdf}
                            onClose={() => setShowPdf(false)}
                          />
                        )}

                        <ExportPDF request={request} getPDf={getPDf} />
                      </div>
                    ) : (
                      <div className="text-center mt-5">
                        <h6 style={h6Style}>PREVIEW URL IS ABSENT</h6>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="loader-content h-8 mt-5">
                    <IconLoader />
                  </div>
                )}
              </div>
            </div>
          </TabPanel>

          {/* Details and Earnings Tabs */}
          <TabPanel value={AdminCreativeRequestsTabs.Details}>
            <CreativeDetails onSuccess={onSuccess} creativeRequest={request} />
          </TabPanel>
          <TabPanel value={AdminCreativeRequestsTabs.Earnings}>
            <EarningsTab
              creativeRequestId={request?.id || ''}
              userProfileId={request?.creatorId || ''}
              isApproved={getApproval()}
            />
          </TabPanel>
        </TabContext>
      </div>

      <SuccessModal
        isOpen={showSuccessModal}
        handleClose={() => (window.location.href = BrandRoutes.Creatives)}
        type="brand"
        content="Ad was successfully created"
      />

      <Modal
        title="Please confirm & specify ad name"
        isOpen={isConfirmationOpen}
        handleClose={() => setIsConfirmationOpen(false)}
      >
        <div className="brand-dashboard__profile-group mt-5">
          <div className="brand-dashboard__profile-label">Ad name</div>
          <input
            className="brand-dashboard__profile-input"
            value={adName}
            onChange={(e): void => setAdName(e.target.value)}
          />
        </div>
        <p className="w-100 mb-5 text-center">
          Clicking confirm below will add this creative to your campaign and
          will start spending.
        </p>
        {errorMsg || adError ? (
          <p
            className={`text-center text-danger mb-5 ${
              adError ? 'mt-2' : 'my-8'
            }`}
          >
            {errorMsg || adError}
          </p>
        ) : null}
        <div className="flex justify-center">
          <button
            className="creator-button w-[300px]"
            data-cy={'confirm'}
            onClick={onOkay}
            disabled={loading}
          >
            {loading && !createAdResponse ? <IconLoader /> : 'Confirm'}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default withRequestView(AdminApprovalContent);
