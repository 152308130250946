import { RefObject, useEffect, useMemo, useState } from 'react';

export const useVideo = (ref: RefObject<HTMLVideoElement>) => {
  const [videoState, setVideoState] = useState({
    isPaused: true,
    isMuted: false,
    currentVolume: 100,
    currentTime: 0,
  });

  const video = ref.current;

  const hasStartedPlaying = useMemo(() => {
    if (!video || typeof video.currentTime !== 'number') return false;
    return Number(video.currentTime) > 0;
  }, [videoState.currentTime, video]);

  const play = () => {
    if (!video) return;

    video.play().catch(err => console.log('Play error:', err));

    setVideoState((prev) => ({
      ...prev,
      isPaused: false,
      isMuted: video.muted ?? prev.isMuted,
    }));
  };

  const pause = () => {
    if (!video) return;

    video.pause();
    setVideoState((prev) => ({
      ...prev,
      isPaused: true,
    }));
  };

  const handlePlayPauseControl = (e: Event) => {
    if (!e.target) return;

    setVideoState((prev) => ({
      ...prev,
      isPaused: (e.target as HTMLVideoElement).paused,
    }));
  };

  const togglePause = () => {
    if (!video) return;
    return video.paused ? play() : pause();
  };

  const handleVolume = (delta: number) => {
    if (!video || typeof video.volume !== 'number') return;

    const deltaDecimal = delta / 100;
    let newVolume = video.volume + deltaDecimal;

    newVolume = Math.max(0, Math.min(1, newVolume));

    video.volume = newVolume;
    setVideoState((prev) => ({
      ...prev,
      currentVolume: newVolume * 100,
    }));
  };

  const handleVolumeControl = (e: Event) => {
    if (!e.target || !video) return;

    const target = e.target as HTMLVideoElement;
    if (typeof target.volume !== 'number') return;

    const newVolume = target.volume * 100;

    if (newVolume === videoState.currentVolume) {
      handleMute(video.muted);
      return;
    }

    setVideoState((prev) => ({
      ...prev,
      currentVolume: target.volume * 100,
    }));
  };

  const handleMute = (mute: boolean) => {
    if (!video) return;

    video.muted = mute;
    setVideoState((prev) => ({
      ...prev,
      isMuted: mute,
    }));
  };

  const handleTime = (delta = 5) => {
    if (!video || typeof video.currentTime !== 'number' ||
      typeof video.duration !== 'number' || isNaN(video.duration)) return;

    let newTime = video.currentTime + delta;
    newTime = Math.max(0, Math.min(video.duration, newTime));

    video.currentTime = newTime;
    setVideoState((prev) => ({
      ...prev,
      currentTime: newTime,
    }));
  };

  const handleTimeControl = (e: Event) => {
    if (!e.target) return;
    const target = e.target as HTMLVideoElement;

    if (typeof target.currentTime !== 'number') return;

    setVideoState((prev) => ({
      ...prev,
      currentTime: target.currentTime,
    }));
  };

  const toggleFullscreen = () => {
    if (!video) return;

    const isFullScreen = document.fullscreenElement;
    if (!isFullScreen) {
      try {
        video.requestFullscreen();
        return;
      } catch (error) {
        console.error(error);
      }
    }

    document.exitFullscreen();
  };

  useEffect(() => {
    if (video) {
      setVideoState({
        isPaused: video.paused,
        isMuted: video.muted,
        currentVolume: typeof video.volume === 'number' ? video.volume * 100 : 100,
        currentTime: typeof video.currentTime === 'number' ? video.currentTime : 0,
      });
    }
  }, [video]);

  useEffect(() => {
    return () => {
      pause();
    };
  }, []);

  useEffect(() => {
    if (!video) return;

    video.addEventListener('volumechange', handleVolumeControl);
    video.addEventListener('play', handlePlayPauseControl);
    video.addEventListener('pause', handlePlayPauseControl);
    video.addEventListener('timeupdate', handleTimeControl);

    return () => {
      video.removeEventListener('volumechange', handleVolumeControl);
      video.removeEventListener('play', handlePlayPauseControl);
      video.removeEventListener('pause', handlePlayPauseControl);
      video.removeEventListener('timeupdate', handleTimeControl);
    };
  }, [video]);

  return {
    ...videoState,
    play,
    hasStartedPlaying,
    pause,
    togglePause,
    increaseVolume: (increase = 5) => handleVolume(increase),
    decreaseVolume: (decrease = 5) => handleVolume(decrease * -1),
    mute: () => handleMute(true),
    unmute: () => handleMute(false),
    toggleMute: () => video && handleMute(!video.muted),
    forward: (increase = 5) => handleTime(increase),
    back: (decrease = 5) => handleTime(decrease * -1),
    toggleFullscreen,
  };
};