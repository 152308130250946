import { FC } from 'react';
import './styles/login.scss';
import styled from 'styled-components';
import { Center } from '../common';
import Header from 'components/header';
import Footer from 'components/footer';
import CognitoAuth from 'components/cognitoAuth';


export const Login: FC = () => {

  return (
    <Wrapper>
      <Header />
      <ContentWrapper>
        <CognitoAuth />
      </ContentWrapper>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled(Center)`
  width: 100%;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  z-index: 10;
  background-color: white;
  min-height: 100vh;
`;

export default Login;
