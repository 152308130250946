import { zodResolver } from '@hookform/resolvers/zod';
import { UpdateBrandBriefInput } from 'API';
import Modal from 'components/authentication/modal';
import Input from 'components/ui/input';
import Label from 'components/ui/label';
import Select from 'components/ui/select';
import TextArea from 'components/ui/textArea';
import { editCampaignBrief, useGetBrandBrief } from 'hooks';
import {
  CALL_TO_ACTION_OPTIONS,
  ManualDataDefaultValues,
  MetaDataDefaultValues,
  META_CALL_TO_ACTION_LIST,
  TiktokDataDefaultValues,
  UpdateBrandBriefSecondStepSchema,
  YoutubeDataDefaultValues,
} from 'hooks/utils';
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import { SaveBriefProps } from 'state/brandBrief';
import withSaveBrief from 'state/brandBrief/withSaveBriefHoc';
import { AuthRoutes, BrandRoutes } from 'utils';
import { ProfileContext } from '../../state/profileSteps';

const getMetaData = (brief, isMeta) =>
  isMeta
    ? {
        adgroupId: brief.metaData?.adgroupId,
        campaignId: brief.metaData?.campaignId,
        adCaption: brief.metaData?.adCaption,
        callToAction: brief.metaData?.callToAction,
        landingPageUrl: brief.metaData?.landingPageUrl,
      }
    : MetaDataDefaultValues;

const getTiktokData = (brief, isTiktok, identities) => {
  const displayName =
    identities.find((i) => i.id === brief?.tikTokData?.adIdentityId)?.value ||
    '';

  return isTiktok
    ? {
        displayName,
        adIdentityId: brief?.tikTokData?.adIdentityId,
        adgroupId: brief?.tikTokData?.adgroupId,
        campaignId: brief?.tikTokData?.campaignId,
        adCaption: brief?.tikTokData?.adCaption,
        callToAction: brief?.tikTokData?.callToAction,
        landingPageUrl: brief?.tikTokData?.landingPageUrl,
        tikTokSparkAds: brief?.tikTokData?.tikTokSparkAds ?? false,
      }
    : TiktokDataDefaultValues;
};

const getYoutubeData = (brief, isYoutube) =>
  isYoutube
    ? {
        adgroupId: brief.youtubeData?.adgroupId,
        campaignId: brief.youtubeData?.campaignId,
        adCaption: brief.youtubeData?.adCaption,
        callToAction: brief.youtubeData?.callToAction,
        landingPageUrl: brief.youtubeData?.landingPageUrl,
      }
    : YoutubeDataDefaultValues;

const getManualData = (brief, isManual) =>
  isManual
    ? { isManual, adCaption: brief.manualData?.adCaption }
    : ManualDataDefaultValues;

function BriefFormStep2({
  getAdGroups,
  getMetaAdGroups,
  listAdGroups,
  listMetaAdGroups,
  dataLoading,
  metaDataLoading,
  listCampaigns,
  listMetaCampaigns,
  listIdentities,
}: SaveBriefProps) {
  const { editBrief, data: editData } = editCampaignBrief();
  const { getBrandBriefData, briefData: brief } = useGetBrandBrief();
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const {
    state: { brief: briefState },
  } = useLocation();
  const [isYoutube, setIsYoutube] = useState(false);
  const [isTiktok, setIsTiktok] = useState(false);
  const [isMeta, setIsMeta] = useState(false);
  const [isManual, setIsManual] = useState(false);

  const {
    register,
    watch,
    control,
    handleSubmit,
    setError,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<UpdateBrandBriefInput>({
    resolver: zodResolver(UpdateBrandBriefSecondStepSchema),
    defaultValues: {
      id: brief?.id || '',
      tikTokData: {
        adIdentityId: brief?.tikTokData?.adIdentityId || '',
        adgroupId: brief?.tikTokData?.adgroupId || '',
        campaignId: brief?.tikTokData?.campaignId || '',
        adCaption: brief?.tikTokData?.adCaption || '',
        displayName: brief?.tikTokData?.adCaption || '',
        callToAction: brief?.tikTokData?.callToAction || '',
        landingPageUrl: brief?.tikTokData?.landingPageUrl || '',
        tikTokSparkAds: brief?.tikTokData?.tikTokSparkAds ?? false,
      },
      metaData: {
        adgroupId: brief?.metaData?.adgroupId || '',
        campaignId: brief?.metaData?.campaignId || '',
        adCaption: brief?.metaData?.adCaption || '',
        callToAction: brief?.metaData?.callToAction || '',
        landingPageUrl: brief?.metaData?.landingPageUrl || '',
      },
      youtubeData: {
        adgroupId: brief?.youtubeData?.adgroupId || '',
        campaignId: brief?.youtubeData?.campaignId || '',
        adCaption: brief?.youtubeData?.adCaption || '',
        callToAction: brief?.youtubeData?.callToAction || '',
        landingPageUrl: brief?.youtubeData?.landingPageUrl || '',
      },
      manualData: {
        isManual: !!brief?.manualData.isManual,
        adCaption: brief?.manualData?.adCaption || '',
      },
    },
    mode: 'all',
  });

  const initiateBriefFormValues = async (id?: string) => {
    if (!id) {
      return;
    }

    const payload = await getBrandBriefData({ variables: { id } });
    const brandBrief = payload.data?.getBrandBrief;
    if (brandBrief == null) {
      return;
    }

    Object.entries(brandBrief).forEach(([k, v]) => {
      // @ts-expect-error
      setValue(k, v);
    });
  };

  useLayoutEffect(() => {
    initiateBriefFormValues(briefState.id);
  }, [briefState]);

  useEffect(() => {
    console.log('Errors :: ', errors);
  }, [errors]);

  useEffect(() => {
    if (isInitialDataLoaded || !brief) {
      return;
    }

    setIsTiktok(!!brief?.tikTokData.adgroupId);
    setIsMeta(!!brief?.metaData.adgroupId);
    setIsYoutube(!!brief?.youtubeData.adgroupId);
    setIsManual(!!brief?.manualData.isManual);
    setIsInitialDataLoaded(true);
  }, [brief]);

  const [compType, setCompType] = useState('');
  const { profileState } = useContext(ProfileContext);
  const [formError] = useState(false);
  const navigate = useNavigate();
  const tiktokCampaignOptions = useMemo(
    () =>
      (listCampaigns || []).map((item) => ({
        text: item.value,
        value: item.id,
      })),
    [listCampaigns]
  );

  const tiktokAdGroupOptions = useMemo(
    () =>
      listAdGroups.map((item) => ({
        text: item.value,
        value: item.id,
      })),
    [listAdGroups]
  );

  const metaAdGroupOptions = useMemo(
    () =>
      listMetaAdGroups.map((item) => ({
        text: item.value,
        value: item.id,
      })),
    [listMetaAdGroups]
  );

  const metaCampaignOptions = useMemo(
    () =>
      listMetaCampaigns.map((item) => ({
        text: item.value,
        value: item.id,
      })),
    [listMetaCampaigns]
  );

  const listIdentitiesOptions = useMemo(
    () =>
      (listIdentities || [])?.map((item) => ({
        text: item.value,
        value: item.id,
        ...(item.image && { image: item.image }),
      })),
    [listIdentities]
  );

  const tiktokSelectedCampaign = watch('tikTokData.campaignId');
  const metaSelectedCampaign = watch('metaData.campaignId');
  const tikTokSparkAds = watch('tikTokData.tikTokSparkAds');

  useEffect(() => {
    if (tiktokSelectedCampaign) {
      getAdGroups(tiktokSelectedCampaign);
    }
  }, [tiktokSelectedCampaign]);

  useEffect(() => {
    if (tiktokSelectedCampaign && listCampaigns.length) {
      const type = listCampaigns.find((campaign) => {
        return campaign.id == tiktokSelectedCampaign;
      })?.campaign_type;

      if (type === 'SHORT_VIDEO_LIVE') {
        setValue('tikTokData.callToAction', 'WATCH_LIVE');
        setCompType(() => type || '');
      }
    }
  }, [tiktokSelectedCampaign, listCampaigns]);

  useEffect(() => {
    if (metaSelectedCampaign) {
      getMetaAdGroups(metaSelectedCampaign);
    }
  }, [metaSelectedCampaign]);

  useEffect(() => {
    if (tiktokSelectedCampaign && !listAdGroups.length && !dataLoading) {
      setError('tikTokData.adgroupId', {
        message:
          'No Ad Groups found in the campaign. Please configure it in TikTok',
      });
    } else {
      resetField('tikTokData.adgroupId');
    }
  }, [listAdGroups, resetField, setError, dataLoading]);

  const onSubmit = handleSubmit(async (data) => {
    const displayName =
      listIdentities.find((item) => item.id === data?.tikTokData?.adIdentityId)
        ?.value || '';

    const formData = {
      ...data,
      tikTokDAta: { ...data.tikTokData, displayName: displayName },
    };

    const input = {
      id: formData.id,
      tikTokData: getTiktokData(formData, isTiktok, listIdentities),
      metaData: getMetaData(formData, isMeta),
      youtubeData: getYoutubeData(formData, isYoutube),
      manualData: getManualData(formData, isManual),
    };

    editBrief({ variables: { input }, errorPolicy: 'ignore' });
  });

  return (
    <section
      data-cy="brief-action-step2"
      className="bg-white py-[20px] rounded-[16px]"
    >
      <h1 className="text-[#0E0D0D] uppercase ml-6 head-text text-[16px] font-[700]">
        {brief ? 'Edit' : 'Create'} Activation
      </h1>
      <form onSubmit={onSubmit}>
        <div className="grid xl:grid-cols-2 p-6 xl:gap-8">
          <div className="xl:col-span-1 col-span-3">
            <div className="flex">
              <div>
                <Label name={'TikTok'} />
                <Switch
                  onChange={() => setIsTiktok((prev) => !prev)}
                  checked={isTiktok}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={22}
                  width={45}
                  disabled={!profileState?.data?.tiktokAccountAccess}
                />
              </div>
              <div className="ml-[50px]"></div>
            </div>
            {isTiktok ? (
              <>
                <Select
                  required
                  name="tikTokData.campaignId"
                  label="Select TikTok campaign to link to *"
                  placeholder="Select an option"
                  options={tiktokCampaignOptions}
                  disabled={!tiktokCampaignOptions?.length}
                  isLoading={dataLoading && !tiktokSelectedCampaign}
                  control={control}
                  errors={errors}
                  dataCy="tikTokCampaign"
                />
                <Select
                  required
                  name="tikTokData.adgroupId"
                  label="Ad group *"
                  placeholder={
                    tiktokSelectedCampaign
                      ? 'Select an option'
                      : 'Select campaign first'
                  }
                  disabled={dataLoading || !tiktokSelectedCampaign}
                  isLoading={dataLoading && !!tiktokSelectedCampaign?.length}
                  options={tiktokAdGroupOptions}
                  control={control}
                  errors={errors}
                  dataCy="tikTokAdGroup"
                />
                <Select
                  name="tikTokData.adIdentityId"
                  label="Set Custom Identity *"
                  placeholder="Display name"
                  options={listIdentitiesOptions}
                  control={control}
                  errors={errors}
                  dataCy="identity"
                />
                <Select
                  name="tikTokData.callToAction"
                  label="Call To Action *"
                  placeholder="Select an option"
                  options={CALL_TO_ACTION_OPTIONS}
                  control={control}
                  required
                  errors={errors}
                  disabled={compType === 'SHORT_VIDEO_LIVE'}
                />
                <Input
                  name="tikTokData.landingPageUrl"
                  label="Destination URL"
                  register={register}
                  errors={errors}
                />
                <Label name={'TikTok spark ads'} />
                <Switch
                  onChange={() =>
                    setValue('tikTokData.tikTokSparkAds', !tikTokSparkAds)
                  }
                  checked={tikTokSparkAds || false}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={22}
                  width={45}
                />
                <TextArea
                  required
                  rows={9}
                  name="tikTokData.adCaption"
                  label="Ad caption"
                  register={register}
                  errors={errors}
                  dataCy="adCaption"
                />
              </>
            ) : null}
          </div>

          <div className="xl:col-span-1 col-span-3 rounded-lg mb-4">
            <Label name={'Meta'} />
            <Switch
              onChange={() => setIsMeta((prev) => !prev)}
              checked={isMeta}
              uncheckedIcon={false}
              checkedIcon={false}
              height={22}
              width={45}
              disabled={!profileState?.data?.facebookAccountAccess}
            />
            <div className="xl:col-span-1 col-span-3">
              <div className="flex"></div>
              {isMeta ? (
                <>
                  <Select
                    required
                    name="metaData.campaignId"
                    label="Select META campaign to link to"
                    placeholder="Select an option"
                    options={metaCampaignOptions}
                    isLoading={!metaCampaignOptions?.length}
                    disabled={!metaCampaignOptions?.length}
                    control={control}
                    errors={errors}
                  />
                  <Select
                    required
                    name="metaData.adgroupId"
                    label="Ad group * "
                    placeholder={
                      metaSelectedCampaign
                        ? 'Select an option'
                        : 'Select campaign first'
                    }
                    disabled={metaDataLoading || !metaSelectedCampaign?.length}
                    isLoading={
                      metaDataLoading && !!metaSelectedCampaign?.length
                    }
                    options={metaAdGroupOptions}
                    control={control}
                    errors={errors}
                  />
                  <Select
                    name="metaData.callToAction"
                    label="Call To Action"
                    placeholder="Select an option"
                    options={META_CALL_TO_ACTION_LIST}
                    control={control}
                    errors={errors}
                  />
                  <Input
                    name="metaData.landingPageUrl"
                    label="Destination URL"
                    register={register}
                    errors={errors}
                  />
                  <TextArea
                    required
                    className=""
                    rows={9}
                    name="metaData.adCaption"
                    label="Ad caption"
                    register={register}
                    errors={errors}
                  />
                </>
              ) : null}
            </div>
          </div>
          <div className="xl:col-span-1 col-span-3">
            <div>
              <Label name={'Youtube'} />
              <Switch
                onChange={setIsYoutube}
                checked={isYoutube}
                uncheckedIcon={false}
                checkedIcon={false}
                height={22}
                width={45}
                disabled={!profileState?.data?.youtubeAccountAccess}
              />
            </div>
            <div className="flex">
              <div className="ml-[50px]"></div>
            </div>
            {isYoutube ? (
              <>
                <Select
                  required
                  name="youtubeData.campaignId"
                  label="Select YouTube campaign to link to"
                  placeholder="Select an option"
                  options={tiktokCampaignOptions}
                  control={control}
                  errors={errors}
                />
                <Select
                  required
                  name="youtubeData.adgroupId"
                  label="Ad group"
                  placeholder={
                    tiktokSelectedCampaign
                      ? 'Select an option'
                      : 'Select campaign first'
                  }
                  disabled={!tiktokSelectedCampaign?.length}
                  isLoading={dataLoading && !!tiktokSelectedCampaign?.length}
                  options={tiktokAdGroupOptions}
                  control={control}
                  errors={errors}
                />
                <Select
                  name="youtubeData.callToAction"
                  label="Call To Action"
                  placeholder="Select an option"
                  options={CALL_TO_ACTION_OPTIONS}
                  control={control}
                  errors={errors}
                />
                <Input
                  name="youtubeData.landingPageUrl"
                  label="Destination URL"
                  register={register}
                  errors={errors}
                />
                <TextArea
                  required
                  rows={9}
                  name="youtubeData.adCaption"
                  label="Ad caption"
                  register={register}
                  errors={errors}
                />
              </>
            ) : null}
          </div>

          <div className="xl:col-span-1 col-span-3 rounded-lg mb-4">
            <Label name={'Manual'} />

            <Switch
              onChange={setIsManual}
              checked={isManual}
              uncheckedIcon={false}
              checkedIcon={false}
              height={22}
              width={45}
            />
          </div>
        </div>
        <div
          className="
            flex sm:flex-row w-full sm:justify-center
            font-sans text-base text-white font-bold flex-col-reverse gap-4 items-center px-6"
        >
          <button
            type="button"
            className="creator-button"
            onClick={() =>
              navigate(BrandRoutes.EditBrief, {
                state: {
                  brief: brief,
                },
              })
            }
          >
            Back
          </button>

          <button
            type="submit"
            className="creator-button disabled:bg-[#a8a8a8]"
            data-cy="finish-brief-action"
          >
            Finish
          </button>
        </div>
        <div className="text-red-500 text-[14px] font-[600] text-center mt-[20px]">
          {formError ? 'Please fill valid details' : ''}
        </div>
      </form>

      <Modal
        content="Great, your activation has been saved!"
        isOpen={!!editData}
        type="brand"
        handleClose={() => navigate(AuthRoutes.BrandBrief)}
        actionLabel="Back To Brand Activations"
        actionHandler={() => navigate(AuthRoutes.BrandBrief)}
        dataCy="go-briefs-page"
      />
    </section>
  );
}

export default withSaveBrief(BriefFormStep2);
