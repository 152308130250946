import { CreativeRequest, UserProfile } from 'API';
import { Storage } from 'aws-amplify';
import {
  UseGetBrandBriefById,
  UseGetCreativeEarningsByCreativeId,
} from 'hooks';
import { useEffect, useState } from 'react';
import { ViewRequestProps, withRequestView } from 'state/requests';
import CreativeDetailsComponent from './CreativeDetailsComponent';

const TWO_DAYS_IN_SECONDS = 2 * 24 * 60 * 60;

function addCompressedKey(originalKey: string): string {
  if (!originalKey.match(/\.(mov|mp4)$/i)) {
    return originalKey;
  }
  const dotIndex = originalKey.lastIndexOf('.');
  return originalKey; // Keeping this original since compression is commented out
}

const CreatorCreativeDetailsPage = ({
  request,
  getVideoLink,
  setShowDetails,
  profileData,
}: {
  request: CreativeRequest;
  setShowDetails: any;
  profileData: UserProfile;
} & ViewRequestProps) => {
  const [awsURL, setAwsURL] = useState('');
  const [totalEarnings, setTotalEarnings] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    data: earningsData,
    getEarningsByCreative,
    loading,
  } = UseGetCreativeEarningsByCreativeId();
  const {
    getBrief,
    data: BrandBrief,
    loading: loadingBrief,
  } = UseGetBrandBriefById();

  useEffect(() => {
    const briefId = request?.brandBriefId;

    if (!briefId) {
      return;
    }
    getBrief({ variables: { id: briefId } });
  }, []);

  useEffect(() => {
    if (request?.tiktokVideoCode) {
      getVideoLink(request.tiktokVideoCode);
    }
    const compressedKey = addCompressedKey(
      (request?.creativePreviewUrl || request?.tiktokCreativeUrl)?.replace(
        'public/',
        ''
      ) || ''
    );
    Storage.get(compressedKey, { expires: TWO_DAYS_IN_SECONDS })
      .then(setAwsURL)
      .catch(console.error);

    // Fetch earnings
    if (request?.id) {
      getEarningsByCreative({
        variables: { creativeRequestId: request.id },
        errorPolicy: 'ignore',
      });
    }
  }, [request]);

  useEffect(() => {
    if (
      earningsData?.getCreativeEarningsByCreative &&
      earningsData.getCreativeEarningsByCreative.items
    ) {
      const amounts = earningsData.getCreativeEarningsByCreative.items.map(
        (entry) => entry?.amount || 0
      );
      const total = amounts.reduce((sum, val) => sum + val, 0);
      setTotalEarnings(total);
    }
  }, [earningsData]);

  useEffect(() => {
    if (loading || loadingBrief) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loading, loadingBrief]);

  return (
    <CreativeDetailsComponent
      creativeUniqueId={request.uniqueId || ''}
      brandName={request.brandName || ''}
      activationName={request.briefName || ''}
      status={request.adminApproval || 'Submitted'}
      videoUrl={awsURL || request.tiktokCreativeUrl}
      earnings={
        request.adminApproval === 'Approved' && request.status === 'Approved'
          ? `Your earnings on this creative is $${totalEarnings}`
          : 'Your earnings will appear here once your creative has been approved. Check back soon!'
      }
      activationOverview={
        request.briefDescription || 'No description available.'
      }
      messaging={BrandBrief?.messaging || 'No messaging provided.'}
      setShowDetails={setShowDetails}
      adminStatus={request.adminApproval || ''}
      request={request}
      isLoading={isLoading}
    />
  );
};

export default withRequestView(CreatorCreativeDetailsPage);
