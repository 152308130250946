import { UserPaymentDetails } from 'API';
import { Storage } from 'aws-amplify';
import { useCallback, useContext, useLayoutEffect, useState } from 'react';
import { createUserPayment, UpdateUserPayment } from '../../hooks';
import {
  validatePaymentForm,
  validatePaymentFormAccountNumber,
  validatePaymentFormAddress,
  validatePaymentFormBranchCode,
  validatePaymentFormDocumentId,
  validatePaymentFormFullName,
  validatePaymentFormSwiftCode,
} from '../../state/auth';
import { IPayment, IPaymentError } from '../../state/payment';
import { IProfileImageUpload, ProfileContext } from '../../state/profileSteps';
import {
  defaultPaymentError,
  defaultPaymentState,
  UnknownType,
} from '../../utils';
import CountryList from '../../utils/constants/ISOCodeCountry';
import * as S from '../authentication/styles/auth.styled';
import { IconLoader } from '../loader';
import ShouldRender from '../shouldRender';
import ImageInput from './components/ImageInput';
import { IMAGE_FILE_SIZE_LIMIT } from './constants';

export interface PaymentDetailsProps {
  paymentDetails?: UserPaymentDetails | null;
  userId: string | null;
  onClose?: () => void;
  isOpen: boolean;
}

const PaymentDetailsModal = ({
  onClose,
  isOpen,
  userId,
  paymentDetails,
}: PaymentDetailsProps) => {
  const [formState, setFormState] = useState<IPayment>(defaultPaymentState);
  const [formError, setFormError] =
    useState<IPaymentError>(defaultPaymentError);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentImg, setDocumentImg] = useState<IProfileImageUpload>({});
  const [proofOfAddress, setProofOfAddress] = useState<IProfileImageUpload>({});
  const [confirmationLetter, setConfirmationLetter] =
    useState<IProfileImageUpload>({});
  const [type, setType] = useState<boolean>(false);
  const { setProfileState, setSuccModal } = useContext(ProfileContext);

  const { createPayment } = createUserPayment();
  const { updatePayment } = UpdateUserPayment();
  const updateState = (key: string, value: string): void => {
    setFormError((prev) => ({ ...prev, [key]: null }));
    setFormState((prev) => ({ ...prev, [key]: value }));
  };

  const getDocumentImg = async ({
    confirmationLetter,
    proofOfAddress,
  }: UserPaymentDetails) => {
    if (proofOfAddress) {
      const proofOfAddressURL = await Storage.get(proofOfAddress);
      setProofOfAddress((prev) => ({ ...prev, image: proofOfAddressURL }));
    }

    if (confirmationLetter) {
      const confirmationLetterURL = await Storage.get(confirmationLetter);
      setConfirmationLetter((prev) => ({
        ...prev,
        image: confirmationLetterURL,
      }));
    }

    const url = await Storage.get(`Document/${userId}`);
    setDocumentImg((prev) => ({ ...prev, image: url }));
  };

  const validateProfileForm = useCallback((): boolean => {
    const fullName = validatePaymentFormFullName(
      formState.fullName,
      'Full Name'
    );
    const firstAddress = validatePaymentFormAddress(
      formState.firstAddress,
      'First Address'
    );
    const secondAddress = validatePaymentFormAddress(
      formState.secondAddress,
      'Second Address'
    );
    const swiftCode = validatePaymentFormSwiftCode(formState.swiftCode);
    const branchCode = validatePaymentFormBranchCode(formState.branchCode);
    const country = validatePaymentForm(formState.country, 'Country');
    const documentID = type
      ? validatePaymentFormDocumentId(formState.documentID, 'Document ID')
      : '';
    const accountNumber = validatePaymentFormAccountNumber(
      formState.accountNumber
    );

    if (
      fullName ||
      firstAddress ||
      secondAddress ||
      swiftCode ||
      branchCode ||
      country ||
      accountNumber ||
      documentID
    ) {
      setFormError({
        fullName,
        firstAddress,
        secondAddress,
        swiftCode,
        country,
        accountNumber,
        documentID,
        branchCode,
      });
      return false;
    }
    return true;
  }, [formState, type]);

  const handleSubmit = useCallback(async () => {
    if (!userId) {
      return;
    }

    const isFormValid = validateProfileForm();
    if (!isFormValid) {
      return;
    }

    if (!documentImg.file && !documentImg.image) {
      setDocumentImg({ error: 'Document image is absent' });
      return;
    }
    setIsLoading(true);

    try {
      if (paymentDetails) {
        const passportImageKey = documentImg.file ? `Document/${userId}` : '';
        const proofOfAddressKey = proofOfAddress.file
          ? `Document/${userId}/proof-of-address`
          : '';

        const confirmationLetterKey = confirmationLetter.file
          ? `Document/${userId}/confirmation-letter`
          : '';

        await Promise.all([
          ...(passportImageKey
            ? [Storage.put(passportImageKey, documentImg.file)]
            : []),
          ...(proofOfAddressKey
            ? [Storage.put(proofOfAddressKey, proofOfAddress.file)]
            : []),
          ...(confirmationLetterKey
            ? [Storage.put(confirmationLetterKey, confirmationLetter.file)]
            : []),
        ]);

        await updatePayment({
          variables: {
            input: {
              ...formState,
              id: userId,
              confirmationLetter:
                confirmationLetterKey || formState.confirmationLetter,
              proofOfAddress: proofOfAddressKey || formState.proofOfAddress,
            },
          },
        });

        setProfileState((prev) => {
          if (!prev.data) return { isLoading: false };

          return {
            ...prev,
            data: {
              ...prev.data,
            },
          };
        });
      } else {
        const { data: updateRes } = await createPayment({
          variables: {
            input: {
              ...formState,
              id: userId,
            },
          },
          errorPolicy: 'ignore',
        });

        setProfileState((prev) => {
          if (!prev.data) return { isLoading: false };

          return {
            ...prev,
            data: {
              ...prev.data,
            },
          };
        });
      }

      setSuccModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      onClose && onClose();
    }
  }, [
    confirmationLetter.file,
    createPayment,
    documentImg.file,
    documentImg.image,
    formState,
    onClose,
    paymentDetails,
    proofOfAddress.file,
    setProfileState,
    setSuccModal,
    updatePayment,
    userId,
    validateProfileForm,
  ]);

  const handleChange = (
    e: UnknownType,
    type: 'passport' | 'letter' | 'proofOfAddress'
  ) => {
    const setter =
      type === 'passport'
        ? setDocumentImg
        : type === 'letter'
          ? setConfirmationLetter
          : setProofOfAddress;

    const file = e.target.files[0];
    if (!file || file.size > IMAGE_FILE_SIZE_LIMIT) {
      setter(() => ({
        error: 'Support for a single or bulk upload. Maximum file size 2MB',
      }));
      return;
    }

    setter(() => ({
      file,
      image: URL.createObjectURL(file),
    }));
  };

  useLayoutEffect(() => {
    if (!paymentDetails) {
      return;
    }

    getDocumentImg(paymentDetails);
    setType(!!paymentDetails.documentID);

    setFormState({
      fullName: paymentDetails.fullName || '',
      firstAddress: paymentDetails.firstAddress || '',
      secondAddress: paymentDetails.secondAddress || '',
      swiftCode: paymentDetails.swiftCode || '',
      country: paymentDetails.country || '',
      documentID: paymentDetails.documentID || '',
      accountNumber: paymentDetails.accountNumber || '',
      postCode: paymentDetails.postCode || '',
      branchCode: paymentDetails.branchCode || '',
      confirmationLetter: paymentDetails.confirmationLetter || '',
      proofOfAddress: paymentDetails.proofOfAddress || '',
    });
  }, []);

  return (
    <>
      {isOpen && (
        <S.ModalOverlay className="z-[99999] overflow-y-auto items-start py-[20px]">
          <S.ModalContent
            className="flex flex-col p-2 gap-[20px]"
            $type="type"
            $withCheckBox={false}
          >
            <S.ModalTopBar className="mt-3 px-3 mr-0">
              <div className="w-full flex justify-between ">
                <h4 className="text-slate-800 text-base">Payment details</h4>
                <img src="/images/cross.svg" alt="close" onClick={onClose} />
              </div>
            </S.ModalTopBar>

            <div className="flex-column w-full p-4">
              <div className="brand-dashboard__profile-group">
                <div className="brand-dashboard__profile-label text-black text-left font-[400] text-[14px]">
                  Full Name
                </div>
                <input
                  className="brand-dashboard__profile-input h-[40px] py-0 pl-[20px] border-black rounded-[8px] text-black"
                  type="text"
                  maxLength={35}
                  value={formState.fullName}
                  onChange={(e): void =>
                    updateState('fullName', e.target.value)
                  }
                />
                <ShouldRender if={formError.fullName}>
                  <p className="error-text mb-0 text-right">
                    {formError.fullName}
                  </p>
                </ShouldRender>
              </div>

              <div className="brand-dashboard__profile-group">
                <div className="brand-dashboard__profile-label text-black text-left font-[400] text-[14px]">
                  First line of address
                </div>
                <input
                  className="brand-dashboard__profile-input h-[40px] py-0 pl-[20px] border-black rounded-[8px] text-black"
                  type="text"
                  value={formState.firstAddress}
                  maxLength={35}
                  onChange={(e): void =>
                    updateState('firstAddress', e.target.value)
                  }
                />
                <ShouldRender if={formError.firstAddress}>
                  <p className="error-text mb-0 text-right">
                    {formError.firstAddress}
                  </p>
                </ShouldRender>
              </div>
              <div className="brand-dashboard__profile-group">
                <div className="brand-dashboard__profile-label text-black text-left font-[400] text-[14px]">
                  Second line of address
                </div>

                <input
                  className="brand-dashboard__profile-input h-[40px] py-0 pl-[20px] border-black rounded-[8px] text-black"
                  type="text"
                  value={formState.secondAddress}
                  maxLength={35}
                  onChange={(e): void =>
                    updateState('secondAddress', e.target.value)
                  }
                />
                <ShouldRender if={formError.secondAddress}>
                  <p className="error-text mb-0 text-right">
                    {formError.secondAddress}
                  </p>
                </ShouldRender>
              </div>

              <div className="brand-dashboard__profile-group">
                <div className="brand-dashboard__profile-label text-black text-left font-[400] text-[14px]">
                  Post Code
                </div>

                <input
                  className="brand-dashboard__profile-input h-[40px] py-0 pl-[20px] border-black rounded-[8px] text-black"
                  type="text"
                  placeholder="Post Code"
                  value={formState.postCode}
                  maxLength={35}
                  onChange={(e): void =>
                    updateState('postCode', e.target.value)
                  }
                />
              </div>
              <div className="brand-dashboard__profile-group">
                <div className="brand-dashboard__profile-label text-black text-left font-[400] text-[14px]">
                  Country
                </div>
                <select
                  className=" bg-[#f9fbfd] w-full h-[40px] px-[20px] font-[400] text-[#000] border-black rounded-[8px]"
                  value={formState.country}
                  onChange={(e): void => updateState('country', e.target.value)}
                >
                  <option hidden></option>
                  {CountryList.map((item, index) => {
                    return (
                      <option value={item.code} key={index}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <ShouldRender if={formError.country}>
                  <p className="error-text mb-0 text-right">
                    {formError.country}
                  </p>
                </ShouldRender>
              </div>

              <div className="flex flex-col gap-y-2 py-4">
                <ImageInput
                  type="passport"
                  label="Passport / ID"
                  id="something1"
                  imageURL={documentImg.image}
                  handleChange={handleChange}
                />

                <ImageInput
                  type="letter"
                  label="Confirmation letter"
                  id="something2"
                  imageURL={confirmationLetter.image}
                  handleChange={handleChange}
                />

                <ImageInput
                  type="proofOfAddress"
                  label="Proof of address"
                  id="something3"
                  imageURL={proofOfAddress.image}
                  handleChange={handleChange}
                />
              </div>

              <div className="brand-dashboard__profile-group">
                <div className="brand-dashboard__profile-label text-black text-left font-[400] text-[14px]">
                  Account number
                </div>
                <input
                  className="brand-dashboard__profile-input h-[40px] py-0 pl-[20px] border-black rounded-[8px]"
                  type="text"
                  value={formState.accountNumber}
                  maxLength={22}
                  onChange={(e): void =>
                    updateState('accountNumber', e.target.value)
                  }
                />
                <ShouldRender if={formError.accountNumber}>
                  <p className="error-text mb-0 text-right">
                    {formError.accountNumber}
                  </p>
                </ShouldRender>
              </div>
              <div className="brand-dashboard__profile-group">
                <div className="brand-dashboard__profile-label text-black text-left font-[400] text-[14px]">
                  Swift / BIC code
                </div>
                <input
                  className="brand-dashboard__profile-input h-[40px] py-0 pl-[20px] border-black rounded-[8px]"
                  type="text"
                  value={formState.swiftCode}
                  maxLength={11}
                  onChange={(e) => updateState('swiftCode', e.target.value)}
                />
                <ShouldRender if={formError.swiftCode}>
                  <p className="error-text mb-0 text-left">
                    {formError.swiftCode}
                  </p>
                </ShouldRender>
              </div>

              <div className="brand-dashboard__profile-group">
                <div className="brand-dashboard__profile-label text-black text-left font-[400] text-[14px]">
                  Branch Code
                </div>

                <input
                  className="brand-dashboard__profile-input h-[40px] py-0 pl-[20px] border-black rounded-[8px]"
                  type="text"
                  value={formState.branchCode}
                  onChange={(e) => updateState('branchCode', e.target.value)}
                />

                <ShouldRender if={formError.branchCode}>
                  <p className="error-text mb-0 text-left">
                    {formError.branchCode}
                  </p>
                </ShouldRender>
              </div>

              {type && (
                <>
                  <div className="brand-dashboard__profile-group">
                    <div className="brand-dashboard__profile-label text-black text-left font-[400] text-[14px]">
                      If business beneficiary invoice / document ID
                    </div>
                    <input
                      className="brand-dashboard__profile-input h-[40px] py-0 pl-[20px] border-black rounded-[8px]"
                      type="text"
                      maxLength={35}
                      value={formState.documentID}
                      onChange={(e) =>
                        updateState('documentID', e.target.value)
                      }
                    />
                  </div>
                  <ShouldRender if={formError.documentID}>
                    <p className="error-text mb-0 text-right">
                      {formError.documentID}
                    </p>
                  </ShouldRender>
                </>
              )}

              <div className="flex justify-start">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="individual"
                    name="fav_language"
                    checked={!type}
                    onChange={() => setType(!type)}
                  />
                  <label
                    htmlFor="individual"
                    className="px-1 pt-[1px] mr-5 text-[15px] text-black font-[500]"
                  >
                    Individual
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    checked={type}
                    type="radio"
                    id="buissnes"
                    name="fav_language"
                    onChange={() => setType(!type)}
                  />
                  <label
                    htmlFor="business"
                    className="px-1 pt-[1px] text-[15px] text-black font-[500]"
                  >
                    Business
                  </label>
                </div>
              </div>

              <button
                className="creator-button w-[100px] h-[40px] text-[16px] bg-black m-auto my-[10px]"
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <IconLoader className="w-5 h-5" color="#005f73" />
                ) : (
                  <span>Save</span>
                )}
              </button>
            </div>
          </S.ModalContent>
        </S.ModalOverlay>
      )}
    </>
  );
};

export default PaymentDetailsModal;
