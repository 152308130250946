import { UseGetUserProfile } from 'hooks';
import styled from 'styled-components';

const ActionButton = styled.button`
  background: #000;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: #444;
  }
`;

const OAuthButton = ({ providerName, userId, isLinked, setFormState }) => {
  const { getProfile } = UseGetUserProfile();
  const API_BASE_URL =
    'https://eeizhsuc10.execute-api.us-east-1.amazonaws.com/live';

  const initiateOAuth = () => {
    const oauthUrl = `${API_BASE_URL}/auth/${providerName}-${userId}/init`;
    const newPopup = window.open(
      oauthUrl,
      'oauthPopup',
      'width=600,height=600'
    );

    if (newPopup) {
      // ✅ Detect when the popup closes
      const checkClosed = setInterval(() => {
        if (newPopup.closed) {
          clearInterval(checkClosed);

          // ✅ Fetch updated profile after OAuth popup closes
          getProfile({ variables: { id: userId } })
            .then(({ data }) => {
              if (data?.getUserProfile?.tiktok_username) {
                setFormState((prevState) => ({
                  ...prevState,
                  tiktok_username: data.getUserProfile?.tiktok_username,
                }));
              }
            })
            .catch((error) =>
              console.error('Error fetching updated profile:', error)
            );
        }
      }, 500); // Check every 500ms, but only when the popup exists
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <ActionButton onClick={initiateOAuth}>
        {isLinked ? 'Linked' : `Link`}
      </ActionButton>
    </div>
  );
};

export default OAuthButton;
