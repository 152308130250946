import { Button, Dialog, DialogContent, DialogTitle, Typography, Divider, IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Check, Close } from '@mui/icons-material';
import { IIcon } from 'assets/icons/icons';
import { ReactNode, useMemo, useState } from 'react';
import { BrandBrief } from 'API';
import { useNavigate } from 'react-router-dom';
import { AuthRoutes } from 'utils/constants/routes';

interface IBeforeYouStartFilmingProps {
    isOpen: boolean;
    onClose: () => void;
    brandBrief: BrandBrief;
}

const BeforeYouStartFilming: React.FC<IBeforeYouStartFilmingProps> = ({ isOpen, onClose, brandBrief }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    // Media query hooks
    const isSuperSmallScreen = useMediaQuery('(max-width: 320px)');
    const isSmallScreen = useMediaQuery('(max-width: 425px)');
    const isTabletScreen = useMediaQuery('(max-width: 1280px)');

    const navigate = useNavigate();

    const handleNextImage = () => {
        setCurrentImageIndex((prev) =>
            prev < carouselImages.length - 1 ? prev + 1 : 0
        );
    };

    const handlePreviousImage = () => {
        setCurrentImageIndex((prev) =>
            prev > 0 ? prev - 1 : carouselImages.length - 1
        );
    };

    const getPractices = (practices: string[], icon: ReactNode) => (
        <>
            {practices.map((p) => (
                <div key={p} className="flex items-center gap-x-2 break-all">
                    {icon}
                    <p>{p}</p>
                </div>
            ))}
        </>
    );

    const bestPractices = useMemo(
        () =>
            getPractices(brandBrief?.goodPractices || [], <Check fontSize="small" />),
        [brandBrief?.goodPractices]
    );

    const badPractices = useMemo(
        () =>
            getPractices(
                [
                    ...(brandBrief?.badPractices || []),
                    ...(brandBrief?.commonRejectionReasons || []),
                ],
                <Close fontSize="small" />
            ),
        [brandBrief?.badPractices, brandBrief?.commonRejectionReasons]
    );

    const maybes = useMemo(
        () => getPractices(brandBrief?.maybes || [], <IIcon fontSize="small" />),
        [brandBrief?.maybes]
    );

    const carouselImages = [
        "/images/TikTokSafeZone.png",
        "/images/ReelsSafeZone.png",
    ];

    // Styles
    const dialogStyles = {
        '& .MuiDialog-paper': {
            width: '80%',
            height: isSmallScreen ? '75vh' : isTabletScreen ? '80vh' : '90vh',
            borderRadius: '16px',
        },
    };

    const carouselContainerStyles = {
        position: "relative",
        textAlign: "center",
        padding: "20px 50px",
        maxWidth: isSmallScreen ? '100%' : '500px',
        margin: isSmallScreen ? '0 auto' : 'inherit',
    };
    const imageContainerStyles = {
        height: isSuperSmallScreen ? '190px' : isSmallScreen ? '300px' : '500px',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        borderRadius: "12px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    };

    const steps = [
        {
            title: "REMEMBER THE SAFE ZONE",
            description:
                "The social media safe zone ensures important content, like text or logos, stays visible across platforms, avoiding cropping or cuts.",
            carousel: true,
            // buttonText: "Download Template",
            // buttonAction: () => {
            //     const link = document.createElement('a');
            //     link.href = "/images/tiktok-safe-zone.png";
            //     link.download = "TikTokSafeZone.png";
            //     document.body.appendChild(link);
            //     link.click();
            //     document.body.removeChild(link);
            // },
        },
        {
            title: "TIPS AND TRICKS TO GET AN APPROVED VIDEO",
            description: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'left',
                        padding: '0 10px',
                    }}
                >
                    <ul
                        style={{
                            listStyleType: 'disc',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '16px',
                            lineHeight: '2',
                            color: '#1D1C1C',
                            maxWidth: '1200px',
                        }}
                    >
                        <li style={{ marginBottom: '16px' }}>
                            <b>Read the brief:</b> Make sure you follow the brand's instructions.
                        </li>
                        <li style={{ marginBottom: '16px' }}>
                            <b>Plan your content:</b> Outline your key message and structure to stay on point.
                        </li>
                        <li style={{ marginBottom: '16px' }}>
                            <b>Check your lighting:</b> Natural light or soft artificial light works best.
                        </li>
                        <li style={{ marginBottom: '16px' }}>
                            <b>Choose a clean background:</b> Avoid distractions and clutter.
                        </li>
                        <li style={{ marginBottom: '16px' }}>
                            <b>Ensure clear audio:</b> Use a mic or film in a quiet location for better sound.
                        </li>
                        <li style={{ marginBottom: '16px' }}>
                            <b>Frame properly:</b> Use the rule of thirds to balance your shot.
                        </li>
                        <li style={{ marginBottom: '16px' }}>
                            <b>Keep it short:</b> Attention spans are short, so aim for concise, engaging content.
                        </li>
                        <li>
                            <b>Test your angles:</b> Experiment with different angles for variety.
                        </li>
                    </ul>
                </div>
            ),
        },
        {
            title: "DOS, DON’TS AND MAYBES FOR THIS ACTIVATION",
            description: (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        padding: useMediaQuery('(max-width: 425px)') ? '20px 10px' : '20px 80px',
                        width: useMediaQuery('(max-width: 425px)') ? '100%' : 'auto',
                    }}
                >
                    <div>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 700,
                                fontFamily: 'Oswald, sans-serif',
                                color: '#1D1C1C',
                                marginBottom: '12px',
                            }}
                        >
                            Must include:
                        </Typography>
                        {bestPractices}
                    </div>

                    <div
                        style={{
                            borderBottom: '1px solid #E0E0E0',
                            margin: '24px 0',
                        }}
                    ></div>

                    <div>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 700,
                                fontFamily: 'Oswald, sans-serif',
                                color: '#1D1C1C',
                                marginBottom: '12px',
                            }}
                        >
                            Must exclude:
                        </Typography>
                        {badPractices}
                    </div>

                    <div
                        style={{
                            borderBottom: '1px solid #E0E0E0',
                            margin: '15px 0',
                        }}
                    ></div>

                    <div>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 700,
                                fontFamily: 'Oswald, sans-serif',
                                color: '#1D1C1C',
                                marginBottom: '12px',
                            }}
                        >
                            Maybes:
                        </Typography>
                        {maybes}
                    </div>
                </div>
            ),
        },

    ];

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep((prev) => prev + 1);
        } else {
            navigate(AuthRoutes.BrandBrief);
        }
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth={true}
            maxWidth="md"
            onClose={onClose}
            sx={dialogStyles}
        >
            <DialogTitle
                sx={{
                    padding: '24px',
                    textAlign: 'center',
                    fontWeight: 600,
                    fontSize: '20px',
                    textTransform: 'uppercase',
                    fontFamily: 'Oswald, sans-serif',
                    position: 'relative',
                }}
            >
                Before You Start Filming
                <Divider
                    sx={{
                        backgroundColor: '#FF872F',
                        opacity: 1,
                        height: '2px',
                        width: '100%',
                        marginTop: '8px',
                    }}
                />
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: useMediaQuery('(max-width: 375px)') ? '0px' : '16px',
                        right: useMediaQuery('(max-width: 375px)') ? '0px' : '16px',
                        color: '#000',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ padding: '24px' }}>
                <div
                    style={{
                        display: 'flex',
                        gap: '0px',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                    }}
                >

                    {steps[currentStep]?.carousel && (
                        <div style={carouselContainerStyles}>
                            <div style={imageContainerStyles}>
                                <img
                                    src={carouselImages[currentImageIndex]}
                                    alt={`Carousel ${currentImageIndex + 1}`}
                                    style={{
                                        width: "100%",
                                        maxHeight: "100%",
                                        objectFit: "fill",
                                    }}
                                />
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", gap: "16px", marginTop: "16px" }}>
                                <Button
                                    onClick={handlePreviousImage}
                                    sx={{
                                        backgroundColor: "#FF872F",
                                        color: "#FFF",
                                        borderRadius: "50%",
                                        minWidth: "50px",
                                        minHeight: "50px",
                                        fontSize: "20px",
                                        "&:hover": {
                                            backgroundColor: "#FF7600",
                                        },
                                    }}
                                >
                                    {"<"}
                                </Button>
                                <Button
                                    onClick={handleNextImage}
                                    sx={{
                                        backgroundColor: "#FF872F",
                                        color: "#FFF",
                                        borderRadius: "50%",
                                        minWidth: "50px",
                                        minHeight: "50px",
                                        fontSize: "20px",
                                        "&:hover": {
                                            backgroundColor: "#FF7600",
                                        },
                                    }}
                                >
                                    {">"}
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "8px",
                                    marginTop: "16px",
                                }}
                            >
                                {carouselImages.map((_, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            width: "12px",
                                            height: "12px",
                                            borderRadius: "50%",
                                            backgroundColor: index === currentImageIndex ? "#FF872F" : "#E0E0E0",
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    )}



                    <div style={{ flex: 1, textAlign: 'left' }}>
                        <Typography
                            sx={{
                                fontFamily: 'Oswald, sans-serif',
                                fontSize: '18px',
                                fontWeight: 600,
                                color: '#FF872F',
                                marginBottom: '8px',
                                padding: currentStep === 1 || currentStep === 2 ? '16px 50px' : '0px',
                            }}
                        >
                            {steps[currentStep]?.title}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                fontWeight: 400,
                                color: '#1D1C1C',
                                marginBottom: '24px',
                            }}
                        >
                            {steps[currentStep]?.description}
                        </Typography>
                        {/* {steps[currentStep]?.buttonText && (
                            <Button
                                variant="contained"
                                onClick={steps[currentStep].buttonAction}
                                sx={{
                                    backgroundColor: '#1D1C1C',
                                    color: '#FFF',
                                    padding: '10px 16px',
                                    borderRadius: '6px',
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                            >
                                {steps[currentStep].buttonText}
                            </Button>
                        )} */}
                    </div>
                </div>
            </DialogContent>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '16px',
                    padding: '0 16px', // Added padding for spacing
                    position: 'relative',
                }}
            >
                {/* Ellipses centered */}
                <div
                    style={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        display: 'flex',
                        gap: '8px',
                    }}
                >
                    {steps.map((_, index) => (
                        <div
                            key={index}
                            style={{
                                width: '8px',
                                height: '8px',
                                borderRadius: '50%',
                                backgroundColor: index === currentStep ? '#FF872F' : '#E0E0E0',
                            }}
                        ></div>
                    ))}
                </div>

                {/* Button aligned to the right */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        paddingTop: "10px"
                    }}
                >
                    <Button
                        onClick={handleNext}
                        sx={{
                            backgroundColor: currentStep < steps.length - 1 ? '#FF872F' : '#00C8C8',
                            color: '#FFF',
                            textTransform: 'uppercase',
                            fontSize: '14px',
                            fontWeight: 600,
                            width: '100px',
                            height: '40px',
                            borderRadius: '20px',
                            '&:hover': {
                                backgroundColor: currentStep < steps.length - 1 ? '#FF7600' : '#00B8B8',
                            },
                        }}
                    >
                        {currentStep < steps.length - 1 ? 'Next' : 'Done'}
                    </Button>
                </div>
            </div>


        </Dialog>
    );
};

export default BeforeYouStartFilming;
