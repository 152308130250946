import { useLazyQuery, useMutation } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import {
  CreativeRequestsByDateQuery,
  CreativeRequestsByDateQueryVariables,
  DeleteCreativeRequestInput,
  DeleteCreativeRequestMutationVariables,
  ExportsMutation,
  ExportsMutationVariables,
  ExportUsersMutation,
  ExportUsersMutationVariables,
  GetUserSignUpsCountQuery,
  GetUserSignUpsCountQueryVariables,
  ImportCreativeEarningsMutation,
  ImportCreativeEarningsMutationVariables,
  ListApprovedAdsQuery,
  ListApprovedAdsQueryVariables,
  ListUserProfilesQuery,
  ListUserProfilesQueryVariables,
  SearchCreativeUsersQuery,
  SearchCreativeUsersQueryVariables,
  UserProfile,
} from 'API';
import axios from 'axios';
import {
  brandBriefByDate,
  creativeRequestsByDate,
  getUserSignUpsCount,
  listApprovedAds,
  listUserPaymentDetails,
  listUserProfiles,
  searchCreativeUsers,
  userProfilesByUserType,
} from 'graphql/queries';
import { getQuery } from 'hooks/utils/helpers';
import { getAllCreatorIds } from 'state/admin/queries';
import {
  BrandBriefByDateQuery,
  BrandBriefByDateQueryVariables,
  ListUserPaymentDetailsQuery,
  ListUserPaymentDetailsQueryVariables,
  UpdateBrandBriefMutation,
  UpdateBrandBriefMutationVariables,
  UpdateUserTransactionsMutation,
  UpdateUserTransactionsMutationVariables,
  UserPaymentDetails,
  UserProfilesByUserTypeQuery,
  UserProfilesByUserTypeQueryVariables,
  VideoPreviewUrlMutation,
  VideoPreviewUrlMutationVariables,
} from './../../API';
import {
  deleteCreativeRequest,
  exports as exportEntities,
  exportUsers,
  importCreativeEarnings,
  updateBrandBrief,
  updateUserTransactions,
  videoPreviewUrl,
} from './../../graphql/mutations';
export const GetListApprovedAds = () => {
  const [getListApprovedAdsData, { data, loading, error }] = useLazyQuery<
    ListApprovedAdsQuery,
    ListApprovedAdsQueryVariables
  >(getQuery(listApprovedAds));
  const adsData = data?.listApprovedAds || null;
  return {
    getListApprovedAdsData,
    loading,
    adsData,
    error,
  };
};

export const GetListCreativeRequests = () => {
  const [getListCreativeRequests, { data, loading, error }] = useLazyQuery<
    CreativeRequestsByDateQuery,
    CreativeRequestsByDateQueryVariables
  >(getQuery(creativeRequestsByDate));
  const creativeRequestsData = data?.creativeRequestsByDate?.items || null;
  return {
    getListCreativeRequests,
    loading,
    creativeRequestsData,
    error,
  };
};

export const GetUsersList = () => {
  const [getListUsersData, { data, loading, error }] = useLazyQuery<
    ListUserProfilesQuery,
    ListUserProfilesQueryVariables
  >(getQuery(listUserProfiles));
  const users = data?.listUserProfiles || null;
  return {
    getListUsersData,
    loading,
    users,
    error,
  };
};
export const DeleteCreativeRequest = () => {
  const [deleteRequest, { loading, error }] = useMutation<
    DeleteCreativeRequestInput,
    DeleteCreativeRequestMutationVariables
  >(getQuery(deleteCreativeRequest));
  return { loading, deleteRequest, error };
};

export const GetUsersPaymentDetails = () => {
  const [getUserPaymentDetails, { data, loading, error }] = useLazyQuery<
    ListUserPaymentDetailsQuery,
    ListUserPaymentDetailsQueryVariables
  >(getQuery(listUserPaymentDetails));
  const paymentDetails =
    (data?.listUserPaymentDetails?.items?.filter(
      (item) => item
    ) as UserPaymentDetails[]) || [];

  return {
    getUserPaymentDetails,
    loading,
    paymentDetails,
    error,
  };
};

export const UpdateUserTransactions = () => {
  const [updateTransactions, { loading, error }] = useMutation<
    UpdateUserTransactionsMutation,
    UpdateUserTransactionsMutationVariables
  >(getQuery(updateUserTransactions));
  return { loading, updateTransactions, error };
};

// Remove duplicated code
export const GetAdminBrandBriefs = () => {
  const [getListBrandBriefs, { data, loading, error }] = useLazyQuery<
    BrandBriefByDateQuery,
    BrandBriefByDateQueryVariables
  >(getQuery(brandBriefByDate));
  const brandBriefs = data?.brandBriefByDate;
  return {
    getListBrandBriefs,
    loading,
    brandBriefs,
    error,
  };
};

export const UpdateBrandBrief = () => {
  const [updateBrief, { loading, error }] = useMutation<
    UpdateBrandBriefMutation,
    UpdateBrandBriefMutationVariables
  >(getQuery(updateBrandBrief));
  return { loading, updateBrief, error };
};

export const GetVideoPreviwUrl = () => {
  const [getPreviewUrl, { loading, error, data }] = useMutation<
    VideoPreviewUrlMutation,
    VideoPreviewUrlMutationVariables
  >(getQuery(videoPreviewUrl));
  return { loading, getPreviewUrl, error, data: data?.videoPreviewUrl };
};

export const GetCreatorUsersList = () => {
  const [getCreatorUsers, { loading, error, data }] = useMutation<
    VideoPreviewUrlMutation,
    VideoPreviewUrlMutationVariables
  >(getQuery(videoPreviewUrl));
  return { loading, getCreatorUsers, error, data: data?.videoPreviewUrl };
};

export const GetCreativeUsers = () => {
  const [getCreativeUsers, { data, loading, error }] = useLazyQuery<
    UserProfilesByUserTypeQuery,
    UserProfilesByUserTypeQueryVariables
  >(getQuery(userProfilesByUserType));
  return {
    getCreativeUsers,
    loading,
    creativeUsers: (data?.userProfilesByUserType?.items || []) as UserProfile[],
    nextToken: data?.userProfilesByUserType?.nextToken,
    error,
  };
};

export const UseGetCreativeUsersCount = () => {
  const [getCreatorIds, { data }] = useLazyQuery<
    UserProfilesByUserTypeQuery,
    UserProfilesByUserTypeQueryVariables
  >(getQuery(getAllCreatorIds));

  return {
    getCreatorIds,
    count: data?.userProfilesByUserType?.items.length,
  };
};

export const UseExportCreativeUsers = () => {
  const [exportCreativeUsers, { data, error, loading }] = useMutation<
    ExportUsersMutation,
    ExportUsersMutationVariables
  >(getQuery(exportUsers));

  return { exportCreativeUsers, data, error, loading };
};

export const UseExportEntitiesToCSV = () => {
  const [exportEntitiesToCSV, { data, error, loading }] = useMutation<
    ExportsMutation,
    ExportsMutationVariables
  >(getQuery(exportEntities));

  return {
    exportEntities: exportEntitiesToCSV,
    url: data?.exports?.url,
    error,
    loading,
  };
};
async function getCurrentUserInfo() {
  try {
    // Get the current authenticated user
    const user = await Auth.currentAuthenticatedUser();

    // Or, to get detailed info about the current session
    const session = await Auth.currentSession();

    // For example, grab the ID token
    const idToken = session.getIdToken().getJwtToken();

    // or get the Access Token instead
    const accessToken = session.getAccessToken().getJwtToken();

    // Return whatever you need
    return { user, idToken, accessToken };
  } catch (error) {
    console.log('Error getting user info', error);
    return null;
  }
}
export const UseExportPaymentsToCSV = async (month) => {
  try {
    // 1. Get the current session from Amplify
    const session = await Auth.currentSession();

    // 2. Extract the ID token (JWT)
    const idToken = session.getIdToken().getJwtToken();

    // 3. Build your request data
    const monthData = JSON.stringify({
      month: month,
    });
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'http://node-fargate-nlb-55940046cadb2a7f.elb.us-east-1.amazonaws.com:3000/payment-sheet',
      headers: {
        Authorization: idToken, // <-- Use the ID token here
        'Content-Type': 'application/json',
      },
      data: monthData,
    };
    const response = await axios.request(config);
    console.log('Response:', JSON.stringify(response.data));
    return {
      downloadUrl: response.data.csvLink,
    };
  } catch (error) {
    console.error('Error exporting payments to CSV:', error);
    throw error;
  }
};

export const UseSearchCreativeUsers = () => {
  const [searchUsers, { data, error, loading }] = useLazyQuery<
    SearchCreativeUsersQuery,
    SearchCreativeUsersQueryVariables
  >(getQuery(searchCreativeUsers));

  const creativeUsers = data?.searchCreativeUsers?.items || [];
  const totalItems = data?.searchCreativeUsers?.totalItems;

  return {
    searchCreativeUsers: searchUsers,
    creativeUsers,
    totalItems,
    error,
    loading,
  };
};

export const UseImportCreativeEarnings = () => {
  const [importEarnings, { data, error, loading }] = useMutation<
    ImportCreativeEarningsMutation,
    ImportCreativeEarningsMutationVariables
  >(getQuery(importCreativeEarnings));

  return { importEarnings, data, error, loading };
};

export const UseGetUsersSignUps = () => {
  const [getSignups, { data, error, loading }] = useLazyQuery<
    GetUserSignUpsCountQuery,
    GetUserSignUpsCountQueryVariables
  >(getQuery(getUserSignUpsCount));

  return {
    getSignups,
    signUps: data?.getUserSignUpsCount?.count ?? 0,
    loading,
    error,
  };
};
