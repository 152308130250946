import { useLazyQuery } from '@apollo/client';
import {
  CreativeRequestsByBrandBriefIdQuery,
  CreativeRequestsByBrandBriefIdQueryVariables,
  CreativeRequestsByDateQuery,
  CreativeRequestsByDateQueryVariables,
  ListCreativeRequestsQuery,
  ListCreativeRequestsQueryVariables,
} from 'API';
import { getQuery } from 'hooks/utils';
import { useState } from 'react';
import {
  getCreativeRequests,
  getCreativeRequestsByBrandBriefId,
  getCreativeRequestsByDate,
} from './queries';

export const UseGetAdminCreativeRequests = () => {
  const [getRequests, { data, loading, error }] = useLazyQuery<
    ListCreativeRequestsQuery,
    ListCreativeRequestsQueryVariables
  >(getQuery(getCreativeRequests));

  return {
    getRequests,
    data: data?.listCreativeRequests?.items || [],
    loading,
    error,
  };
};

export const UseAdminGetCreativeRequestsByDate = () => {
  const [getRequests, { loading, error }] = useLazyQuery<
    CreativeRequestsByDateQuery,
    CreativeRequestsByDateQueryVariables
  >(getQuery(getCreativeRequestsByDate));

  const [allRequests, setAllRequests] = useState<any[]>([]);

  const fetchAllRequests = async (
    variables: CreativeRequestsByDateQueryVariables
  ) => {
    let allItems: any[] = [];

    const fetchPage = async (nextToken?: string | null) => {
      const response = await getRequests({
        variables: {
          ...variables,
          nextToken: nextToken ?? undefined,
        },
        fetchPolicy: 'network-only',
      });

      const items = response?.data?.creativeRequestsByDate?.items || [];
      const newNextToken = response?.data?.creativeRequestsByDate?.nextToken;

      allItems = [...allItems, ...items];

      // ✅ Only continue if items were returned and nextToken exists
      if (newNextToken && items.length > 0) {
        await fetchPage(newNextToken);
      }
    };

    await fetchPage();
    setAllRequests(allItems);
  };

  return {
    getAllRequests: fetchAllRequests,
    data: allRequests,
    loading,
    error,
  };
};

export const UseGetCreativeRequestsByBrandBriefId = () => {
  const [getRequests, { data, error, loading }] = useLazyQuery<
    CreativeRequestsByBrandBriefIdQuery,
    CreativeRequestsByBrandBriefIdQueryVariables
  >(getQuery(getCreativeRequestsByBrandBriefId));

  return {
    getRequests,
    data: data?.creativeRequestsByBrandBriefId?.items || [],
    error,
    loading,
  };
};
