import './walletCard.css';

interface Props {
  dueDateCheck: boolean;
  walletTitle: string;
  cost: string;
}

export default function WalletCard({ dueDateCheck, walletTitle, cost }: Props) {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Get current month and determine the next processing month
  const currentDate = new Date();
  const nextMonthIndex = (currentDate.getMonth() + 1) % 12;
  const nextMonthName = months[nextMonthIndex] || 'January';
  const nextProcessingDay = 1;

  return (
    <div className="lg:w-full md:w-[260px] w-[200px]">
      {/* Wallet Title */}
      <div className="creator-dashboard__item-block-key shrink-mobile mb-[10px] head-text text-black opacity-50 font-[700] uppercase">
        {walletTitle}
      </div>

      {/* Cost Value */}
      <div className="creator-dashboard__item-block-value my-0 font-[500] text-[33px] text-black">
        {cost}
      </div>

      {/* Next Payment Processing Date - Always Below the Cost */}
      {dueDateCheck && (
        <div className="wallet-due-date text-[#000]">
          Next payment processing date is {nextMonthName} {nextProcessingDay}.
        </div>
      )}
    </div>
  );
}
