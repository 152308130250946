import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Typography } from '@mui/material';
import { BrandBrief } from 'API';
import { Storage } from 'aws-amplify';
import classNames from 'classnames';
import { UseUpdateBrandBrief } from 'hooks';
import FileUpload from 'pages/adminCreativeApproval/components/components/FileUpload';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { b2Kb, validateVideoSizeRatio } from 'utils/utils';
import { v4 } from 'uuid';
import { getHeadObjectFromS3, getUrlFilename } from '../../../utils/aws.utils';
import { SecondStageSchema } from '../schema';
import CreativeInspirations from './components/CreativeInspirations/CreativeInspirations';
import FileProgress from './components/FileProgress/FileProgress';
import {
  ACCEPT_VIDEO_FILES,
  ACCEPT_ZIP_FILES,
  ErrorMessages,
  FILE_INPUT_EMPTY_MESSAGE,
  INSPIRATION_VIDEOS_COUNT_LIMIT,
  INSPIRATION_VIDEOS_SIZE_LIMIT_IN_KB,
  VIDEO_FILE_NAME_VALIDATION_REGEX,
} from './constants';
import { FileUploadStatus, IFilesUploadState, IFileUploadState } from './types';
import {compressVideo} from "../../../utils/videoUtils";

export interface ISecondPageProps {
  stageNext: () => void;
  stageBack: () => void;
  brandBrief: BrandBrief;
}

const SecondPage: FC<ISecondPageProps> = ({
  stageBack,
  stageNext,
  brandBrief,
}) => {
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      creativeInspirations: brandBrief.creativeInspirations || [],
      brandBriefFilesUrl: brandBrief.brandBriefFilesUrl || '',
    },
    resolver: zodResolver(SecondStageSchema),
  });

  const { updateBrief, loading: updateBriefLoading } = UseUpdateBrandBrief();

  const brandBriefFilesUrl = brandBrief?.brandBriefFilesUrl || '';
  const overviewUrlFilename = getUrlFilename(brandBriefFilesUrl);
  const [selectedBrandBriefFileUrl, setSelectedBrandBriefFileUrl] = useState({
    preview: overviewUrlFilename || FILE_INPUT_EMPTY_MESSAGE,
    key: brandBriefFilesUrl,
  });

  const [selectedFiles, setSelectedFiles] = useState<string[]>(
    brandBrief?.creativeInspirations || []
  );
  const [filesUploadStatus, setFilesUploadStatus] = useState<IFilesUploadState>(
    {}
  );
  const ongoingUploads = useMemo(
    () =>
      Object.values(filesUploadStatus).some(
        ({ status }) => status === FileUploadStatus.Loading
      ),
    [filesUploadStatus]
  );

  const videoUrls = useMemo(
    () =>
      [...new Set(selectedFiles)].filter((k) =>
        VIDEO_FILE_NAME_VALIDATION_REGEX.test(k)
      ),
    [brandBrief.creativeInspirations, selectedFiles]
  );

  const formattedUrlsWithDetails = async () => {
    const initialStatus = videoUrls.reduce((aggr, val) => {
      return {
        ...aggr,
        [val]: {},
      };
    }, {});

    setFilesUploadStatus(initialStatus);

    const videoDetailedUrls = await Promise.all(
      videoUrls.map(async (videoUrl) => {
        let contentFinalLength;
        try {
          const { ContentLength } = await getHeadObjectFromS3({
            Key: `public/${videoUrl}`,
          });

          contentFinalLength = ContentLength;
        } catch (error) {
          try {
            const { ContentLength } = await getHeadObjectFromS3({
              Key: videoUrl,
            });

            contentFinalLength = ContentLength;
          } catch (error) {
            console.error('Failed to load head object');
          }
        }

        return {
          contentLength: contentFinalLength ?? 0,
          key: videoUrl,
        };
      })
    );

    const formattedUrls = videoDetailedUrls.reduce((aggr, val) => {
      return {
        ...aggr,
        [val.key]: {
          ...aggr[val.key],
          status: FileUploadStatus.Complete,
          progress: 100,
          fileSize: val.contentLength,
          fileKey: val,
          onDelete: () =>
            onDelete({ key: val.key, fieldName: 'creativeInspirations' }),
        },
      };
    }, {});

    setFilesUploadStatus(formattedUrls);
    setSelectedFiles(videoUrls);
  };

  useEffect(() => {
    formattedUrlsWithDetails();
  }, []);

  const onDelete = ({
    promise,
    key,
    fieldName,
  }: {
    promise?: Promise<any>;
    key: string;
    fieldName: string;
  }) => {
    if (promise) Storage.cancel(promise);
    setSelectedFiles((prev) => prev.filter((p) => p !== key));
    setFilesUploadStatus((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        status: FileUploadStatus.Failed,
      } as IFileUploadState,
    }));

    if (fieldName === 'brandBriefFilesUrl') {
      setValue(fieldName, '');
      setSelectedBrandBriefFileUrl({ key: '', preview: '' });
    }
  };

  const onSelectFile = async ({
    file,
    fieldName,
  }: {
    file: File;
    fieldName: 'creativeInspirations' | 'brandBriefFilesUrl';
  }) => {
    if (file == null || file?.size === undefined) {
      return;
    }

    const isVideo = fieldName === 'creativeInspirations';
    if (isVideo) {
      const isValidAspectRatio = await validateVideoSizeRatio(file);
      if (!isValidAspectRatio) {
        toast.error(ErrorMessages.BadAspectRatio);
        return;
      }

      if (videoUrls.length >= INSPIRATION_VIDEOS_COUNT_LIMIT) {
        toast.error(ErrorMessages.LimitReached);
        return;
      }
    }

    const fileSize = b2Kb(file.size);
    if (fileSize >= INSPIRATION_VIDEOS_SIZE_LIMIT_IN_KB) {
      toast.error(ErrorMessages.FileTooBig);
      return;
    }

    const updatedFileName = `${v4()}_${file?.name}`;
    const key = `brief/${brandBrief?.id || ''}/${updatedFileName}`;
    setSelectedFiles((prev) => [...new Set([...prev, key])]);
    const compressedFile = await compressVideo(file);
    const promise = Storage.put(key, compressedFile, {
      contentType: file.type,
      level: 'public',
      acl: 'public-read',
      progressCallback: (progress: { loaded: number; total: number }) => {
        const progressInPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );

        const status =
          progressInPercentage < 100
            ? FileUploadStatus.Loading
            : FileUploadStatus.Complete;

        setFilesUploadStatus((prev) => ({
          ...prev,
          [key]: {
            ...prev[key],
            progress: progressInPercentage,
            status,
          } as IFileUploadState,
        }));
      },
    });
    setFilesUploadStatus((prev) => ({
      ...prev,
      [key]: {
        progress: 0,
        fileName: file.name,
        fileSize: file.size,
        fileKey: key,
        status: FileUploadStatus.Loading,
        onDelete: () => onDelete({ promise, key, fieldName }),
      },
    }));

    await promise;
    if (fieldName === 'brandBriefFilesUrl') {
      setValue(fieldName, key);
      setSelectedBrandBriefFileUrl({ preview: getUrlFilename(key), key });
    } else {
      setValue(fieldName, [...new Set([...selectedFiles, key])]);
    }

    trigger(fieldName);

    toast.success('Uploaded');
  };

  const isFormError = !!Object.values(errors).length;
  const onSubmit = useCallback(
    async (formData) => {
      if (!brandBrief?.id) {
        return;
      }

      const creativeInspirations = (selectedFiles || []).filter((k) =>
        VIDEO_FILE_NAME_VALIDATION_REGEX.test(k)
      );

      await updateBrief({
        variables: {
          input: {
            id: brandBrief.id,
            creativeInspirations: creativeInspirations,
            brandBriefFilesUrl: formData.brandBriefFilesUrl,
          },
        },
      });

      stageNext();
    },
    [brandBrief.id, selectedFiles, updateBrief, stageNext]
  );

  const progressBars = ({ selectedFiles }: { selectedFiles: string[] }) =>
    selectedFiles.map((fileName, idx) => {
      const fileUploadStatus = filesUploadStatus[fileName];

      return (
        <FileProgress
          key={idx}
          fileSize={String(b2Kb(fileUploadStatus?.fileSize))}
          fileName={fileUploadStatus?.fileName}
          uploadStatus={fileUploadStatus?.status || FileUploadStatus.Loading}
          progress={fileUploadStatus?.progress || 100}
          onAbort={fileUploadStatus?.onDelete}
        />
      );
    });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-y-8">
          <Typography className="uppercase font-oswald font-[600] text-[24px]">
            Create brand activation - 2 of 3
          </Typography>

          <div className="grid grid-cols-2 gap-x-8">
            <div>
              <div className="flex flex-col gap-y-4">
                <FileUpload
                  emptyMessage={FILE_INPUT_EMPTY_MESSAGE}
                  accept={ACCEPT_VIDEO_FILES}
                  label="Click to upload creative inspiration"
                  onFileSelect={(files) =>
                    onSelectFile({
                      file: files?.[0],
                      fieldName: 'creativeInspirations',
                    })
                  }
                />

                <ErrorMessage
                  errors={errors}
                  name="creativeInspirations"
                  as="p"
                  className="text-red-500 text-[12px]"
                />

                <div className="max-h-[200px] overflow-y-auto">
                  {progressBars({
                    selectedFiles: selectedFiles.filter((f) =>
                      VIDEO_FILE_NAME_VALIDATION_REGEX.test(f)
                    ),
                  })}
                </div>
              </div>

              <div className="flex flex-col gap-y-4">
                <div>
                  <FileUpload
                    accept={ACCEPT_ZIP_FILES}
                    emptyMessage={selectedBrandBriefFileUrl.preview}
                    onFileSelect={(files) =>
                      onSelectFile({
                        file: files?.[0],
                        fieldName: 'brandBriefFilesUrl',
                      })
                    }
                  />
                </div>

                <ErrorMessage
                  errors={errors}
                  name="brandBriefFilesUrl"
                  as="p"
                  className="text-red-500 text-[12px]"
                />

                {filesUploadStatus[selectedBrandBriefFileUrl.key] && (
                  <div className="max-h-[200px] overflow-y-auto">
                    {progressBars({
                      selectedFiles: [selectedBrandBriefFileUrl.key],
                    })}
                  </div>
                )}
              </div>
            </div>
            <CreativeInspirations
              videos={videoUrls}
              hasOngoingUpload={ongoingUploads}
            />
          </div>

          <div className="flex justify-center gap-x-4">
            <Button
              disableRipple={true}
              className="bg-gray-300 text-black px-[22px] py-[8px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap"
              onClick={stageBack}
            >
              <div className="flex justify-evenly uppercase font-semibold items-center gap-x-1">
                <div>Back</div>
              </div>
            </Button>

            <Button
              type="submit"
              disableRipple={true}
              disabled={ongoingUploads || updateBriefLoading || isFormError}
              className={classNames(
                'bg-main-black text-white px-[22px] py-[8px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap self-center',
                { 'bg-gray-600': isFormError }
              )}
            >
              <p className="flex justify-evenly uppercase font-semibold items-center gap-x-1">
                Next
              </p>
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default SecondPage;
