import { CircularProgress, CircularProgressProps } from '@mui/material';
import React from 'react';

interface AppLoaderProps extends CircularProgressProps {
  size?: number;
  color?:
    | 'primary'
    | 'secondary'
    | 'inherit'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  wrapperSize?: number; // optional: allows controlling the container div if needed
}

const AppLoader: React.FC<AppLoaderProps> = ({
  size = 30,
  color = 'primary',
  wrapperSize,
  ...props
}) => {
  return (
    <div
      style={{
        width: wrapperSize ? `${wrapperSize}px` : '100%',
        height: wrapperSize ? `${wrapperSize}px` : '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={size} color={color} {...props} />
    </div>
  );
};

export default AppLoader;
