import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

interface MobileNumberInputProps {
    onValidNumberChange?: (phone: string) => void;
    getCountry: (country: string) => void;
    error: string;
    setError: (error: string) => void;
    isLoading?: boolean
}

const MobileNumberInput: React.FC<MobileNumberInputProps> = ({ onValidNumberChange, getCountry, error, setError, isLoading }) => {
    const [value, setValue] = useState<string | undefined>(undefined);

    const handleBlur = () => {
        if (!value) {
            setError("Please enter your mobile number.");
            return;
        }

        if (!isValidPhoneNumber(value)) {
            setError("Invalid mobile number.");
            return;
        }

        setError("");
    };

    const handleSubmit = () => {
        setError("");
        if (!value || !isValidPhoneNumber(value)) {
            setError("Please enter a valid phone number.");
            return;
        }

        // Extract country from the phone number
        if (value) {
            try {
                const parsedPhone = parsePhoneNumber(value);
                if (parsedPhone && parsedPhone.country) {
                    getCountry(parsedPhone.country); // Pass country code to parent
                }
            } catch (error) {
                console.error("Error parsing phone number:", error);
            }
        }

        if (onValidNumberChange) {
            onValidNumberChange(value);
        }
    };

    return (
        <div className="inset-0 flex items-center justify-center">
            <div className="w-full max-w-md p-6 bg-white text-center">
                <h2 className="text-[18px] font-bold text-gray-800 mb-2" style={{ fontFamily: "Oswald, sans-serif" }}>
                    Step 1: Mobile And Country Validation
                </h2>
                <p className="text-[15px] text-gray-700 mb-4" style={{ fontFamily: "Inter, sans-serif" }}>
                    Please select your country and input your phone number:
                </p>

                {/* Phone Input Field */}
                <div className="w-full flex justify-center">
                    <PhoneInput
                        className="w-full border border-gray-300 px-4 py-3 rounded-lg text-lg focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
                        placeholder="Enter phone number"
                        defaultCountry="ZA"
                        value={value}
                        onChange={setValue}
                        onBlur={handleBlur}
                    />
                </div>

                {/* Error Message */}
                {error && <p className="text-red-500 text-sm mt-2">{error}</p>}

                {/* Submit Button */}
                <button
                    onClick={handleSubmit}
                    disabled={isLoading} // Disable button when loading
                    className={`mt-4 px-6 py-3 text-lg font-semibold rounded-lg transition w-full ${isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-black hover:bg-gray-800 text-white"
                        }`}
                >
                    {isLoading ? (
                        <div className="flex items-center justify-center">
                            <CircularProgress size={24} className="text-white mr-2" />
                            Processing...
                        </div>
                    ) : (
                        "Submit"
                    )}
                </button>

            </div>
        </div>
    );
};

export default MobileNumberInput;
