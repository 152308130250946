import { z } from 'zod';
import init from 'zod-empty';

export const schema = z.object({
  description: z.string().nullable(),
  email: z.string().nonempty(),
  id: z.string().optional(),
  phoneNumber: z.string().optional(),
  isValid: z.string().optional(),
  instagramHandler: z.string().nullable(),
  isBrand: z.boolean().optional(),
  name: z.string().nonempty(),
  tiktokHandler: z.string().nullable(),
  userType: z.string(),
  vertical: z.string().nullable(),
  youtubeHandler: z.string().nullable(),
  uniqueId: z.string().optional(),
  country: z.string().optional(),
  tiktok_username: z.string().optional(),
  tiktok_follower_count: z.number().optional(),
  tiktok_video_count: z.number().optional(),
  instagram_username: z.string().optional(),
  instagram_media_count: z.number().optional(),
  instagram_followers_count: z.number().optional(),
});

export const defaultValues = {
  ...init(schema),
  active: true,
  isBrand: false,
};
