import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";

interface StepData {
    step: number;
    subHeading: string;
    videoSrc: string;
    thumbnail: string;
    keyPoints?: string[];
}

interface PillarData {
    pillar: number;
    mainHeading: string;
    steps: StepData[];
}

interface PillarOverviewProps {
    pillars: PillarData[];
    currentPillarIndex: number;
    currentStepIndex: number;
    onSelectStep: (pillarIndex: number, stepIndex: number) => void;
}

const PillarOverview: React.FC<PillarOverviewProps> = ({ pillars, currentPillarIndex, currentStepIndex, onSelectStep }) => {
    const [thumbnailCache, setThumbnailCache] = useState<Record<string, string>>({});
    const [loadingThumbnails, setLoadingThumbnails] = useState<Record<string, boolean>>({});

    // Function to load a thumbnail from S3 with a forced 2s delay
    async function loadThumbnail(thumbnailSrc: string): Promise<void> {
        if (!thumbnailSrc) return;
        if (thumbnailCache[thumbnailSrc]) return; // Already cached

        setLoadingThumbnails((prev) => ({ ...prev, [thumbnailSrc]: true })); // Start loading state

        try {
            const url = await Storage.get(`creatorHubThumbnails/${thumbnailSrc}`); // Fetch from S3

            // Ensure the thumbnail stays loading for at least 2s
            setTimeout(() => {
                setThumbnailCache((prev) => ({ ...prev, [thumbnailSrc]: url })); // Cache result
                setLoadingThumbnails((prev) => ({ ...prev, [thumbnailSrc]: false })); // Hide loader
            }, 2000);
        } catch (err) {
            console.error("Failed to load thumbnail:", err);
            setTimeout(() => {
                setLoadingThumbnails((prev) => ({ ...prev, [thumbnailSrc]: false })); // Hide loader even if error
            }, 2000);
        }
    }

    // Preload thumbnails when the component mounts
    useEffect(() => {
        pillars.forEach((pillar) => {
            pillar.steps.forEach((step) => {
                if (step.thumbnail && !thumbnailCache[step.thumbnail]) {
                    loadThumbnail(step.thumbnail);
                }
            });
        });
    }, [pillars]);

    return (
        <div className="w-full max-w-4xl mx-auto mt-6">
            <h2 className="text-[26px] font-bold text-center mb-4 font-oswald">Your Progress</h2>

            {pillars.map((pillar, pillarIndex) => (
                <div key={pillar.pillar} className="mb-6 border-b pb-4">
                    <h3 className="text-[18px] font-bold font-oswald mb-2">{`Pillar ${pillar.pillar}: ${pillar.mainHeading}`}</h3>

                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        {pillar.steps.map((step, stepIndex) => {
                            const isUnlocked =
                                pillarIndex < currentPillarIndex || (pillarIndex === currentPillarIndex && stepIndex <= currentStepIndex);

                            return (
                                <button
                                    key={step.step}
                                    onClick={() => isUnlocked && onSelectStep(pillarIndex, stepIndex)}
                                    className={`p-2 border rounded-lg transition-all ${isUnlocked ? "border-green-500 hover:bg-green-100" : "border-gray-300 opacity-50 cursor-not-allowed"}`}
                                >
                                    {/* Video Thumbnail with Forced 2s Loader */}
                                    <div className="w-full h-24 bg-gray-200 flex items-center justify-center rounded-md mb-2 relative">
                                        {isUnlocked ? (
                                            loadingThumbnails[step.thumbnail] ? (
                                                <div className="w-10 h-10 border-4 border-gray-300 border-t-gray-500 rounded-full animate-spin"></div>
                                            ) : (
                                                <img
                                                    src={thumbnailCache[step.thumbnail] || "/default-thumbnail.png"}
                                                    alt={step.subHeading}
                                                    className="w-full h-full object-fill rounded-md"
                                                />
                                            )
                                        ) : (
                                            <span className="text-gray-500">🔒 Locked</span>
                                        )}
                                    </div>

                                    <p className="text-[16px] font-semibold font-inter text-center">{step.subHeading}</p>
                                </button>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PillarOverview;
