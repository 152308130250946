import { ADMIN_STATUS, CreativeRequest } from 'API';
import AppLoader from 'components/AppLoader/AppLoader';
import ChatPanel from 'components/creatorCreativeDetailsComponents/ChatPannel/ChatPannel';
import { FC } from 'react';

interface ReviewSectionProps {
  h6Style: React.CSSProperties;
  request: CreativeRequest | null;
  rejectComment: string;
  rejectError: boolean;
  actType: string;
  isNotificationEnabled: boolean;
  reqLoading: boolean;
  type?: string;
  setRejectComment: (comment: string) => void;
  setRejectError: (val: boolean) => void;
  onReject: () => void;
  onRevision: () => void;
  onApprove: () => void;
  updateCreativeRequestStatus?: (
    newStatus: string,
    comment?: string,
    sendEmail?: boolean,
    previousStatus?: string
  ) => void;
  handleToggleNotification: () => void;
}

const ReviewSection: FC<ReviewSectionProps> = ({
  h6Style,
  request,
  rejectComment,
  rejectError,
  actType,
  isNotificationEnabled,
  reqLoading,
  type,
  setRejectComment,
  setRejectError,
  onReject,
  onRevision,
  onApprove,
  updateCreativeRequestStatus,
  handleToggleNotification,
}) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {/* LEFT PANEL */}
      <div className="flex flex-col gap-4">
        {/* Brand View Comments */}
        <div>
          <h6 style={h6Style} className="mb-4">
            BRAND VIEW COMMENTS
          </h6>
          <ul
            className="w-full border border-gray-300 rounded-md p-3 text-sm"
            style={{
              listStyleType: 'disc',
              paddingLeft: '20px',
              height: '200px',
              overflowY: 'auto',
            }}
          >
            {Array.isArray(request?.brandComment) &&
            request.brandComment.length > 0 ? (
              request.brandComment.map((comment, index) => (
                <li
                  key={index}
                  style={{ marginBottom: '10px', marginLeft: '15px' }}
                >
                  <p>{comment}</p>
                </li>
              ))
            ) : (
              <p className="text-gray-500 italic">No comments available.</p>
            )}
          </ul>
        </div>

        {/* Approve / Reject */}
        <div>
          <h6 style={h6Style} className="mb-4">
            APPROVE / REJECT
          </h6>

          {/* Comment Input */}
          <div
            contentEditable
            className={`text-sm mb-4 border rounded-md p-3 w-full ${
              rejectError ? 'border-red-500' : 'border-gray-300'
            }`}
            style={{
              height: '200px',
              backgroundColor: '#FFFFFF',
              fontFamily: 'Inter, sans-serif',
              fontSize: '16px',
              lineHeight: '20px',
              color: '#333333',
              overflowY: 'auto',
            }}
            onInput={(e) => {
              setRejectComment(e.currentTarget.textContent || '');
              if (rejectError) setRejectError(false);
            }}
          />

          {rejectError && (
            <p className="text-red-500 text-sm mt-1">Please enter a comment.</p>
          )}

          {/* Action Buttons */}
          <div className="flex flex-col lg:flex-row justify-between mt-4 w-full gap-4">
            <button
              onClick={() => {
                if (!rejectComment.trim()) {
                  setRejectError(true);
                  return;
                }
                onReject();
              }}
              disabled={
                reqLoading || request?.adminApproval === ADMIN_STATUS.Rejected
              }
              className={`px-8 py-3 rounded-md shadow-lg transition-transform hover:scale-105 ${
                reqLoading || request?.adminApproval === ADMIN_STATUS.Rejected
                  ? 'bg-gray-300 text-white cursor-not-allowed'
                  : 'bg-[#E11D48] text-white'
              }`}
              style={{
                fontFamily: 'Oswald, sans-serif',
                fontSize: '16px',
                fontWeight: 700,
                textTransform: 'uppercase',
                minWidth: '150px',
              }}
            >
              {reqLoading && actType === 'reject' ? (
                <AppLoader size={20} />
              ) : (
                'Reject'
              )}
            </button>

            <button
              onClick={() => {
                if (!rejectComment.trim()) {
                  setRejectError(true);
                  return;
                }
                onRevision();
              }}
              disabled={reqLoading}
              className={`px-8 py-3 rounded-md shadow-lg transition-transform hover:scale-105 ${
                reqLoading
                  ? 'bg-gray-300 text-white cursor-not-allowed'
                  : 'bg-[#F97316] text-white'
              }`}
              style={{
                fontFamily: 'Oswald, sans-serif',
                fontSize: '16px',
                fontWeight: 700,
                textTransform: 'uppercase',
                minWidth: '150px',
              }}
            >
              {reqLoading && actType === 'revision' ? (
                <AppLoader size={20} />
              ) : (
                'Revision'
              )}
            </button>

            <button
              onClick={() => {
                if (type === 'Admin' && updateCreativeRequestStatus) {
                  updateCreativeRequestStatus(
                    ADMIN_STATUS.Approved,
                    '',
                    true,
                    ADMIN_STATUS.Revision
                  );
                } else {
                  onApprove();
                }
              }}
              disabled={
                reqLoading || request?.adminApproval === ADMIN_STATUS.Approved
              }
              className={`px-8 py-3 rounded-md shadow-lg transition-transform hover:scale-105 ${
                reqLoading || request?.adminApproval === ADMIN_STATUS.Approved
                  ? 'bg-gray-300 text-white cursor-not-allowed'
                  : 'bg-[#000000] text-white'
              }`}
              style={{
                fontFamily: 'Oswald, sans-serif',
                fontSize: '16px',
                fontWeight: 700,
                textTransform: 'uppercase',
                minWidth: '150px',
              }}
            >
              {reqLoading && actType !== 'revision' && actType !== 'reject' ? (
                <AppLoader size={20} />
              ) : (
                'Approve'
              )}
            </button>
          </div>

          {/* Notification Toggle */}
          <div className="flex items-center gap-2 mt-4">
            <label
              htmlFor="sendNotification"
              className="text-sm"
              style={{
                fontFamily: 'Inter, sans-serif',
                fontSize: '14px',
                color: '#6B7280',
                cursor: 'pointer',
              }}
            >
              Send notification
            </label>
            <input
              type="checkbox"
              id="sendNotification"
              className="cursor-pointer"
              checked={isNotificationEnabled}
              onChange={handleToggleNotification}
            />
          </div>
        </div>
      </div>

      {/* RIGHT PANEL - Chat */}
      <div>
        <ChatPanel
          isAdminView
          msgFrom="admin"
          context={request?.uniqueId ?? ''}
          creatorId={request?.creatorProfile?.id ?? ''}
        />
      </div>
    </div>
  );
};

export default ReviewSection;
