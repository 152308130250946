import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://117ded6ef80584242b649eff52a0f567@o4508612872372224.ingest.de.sentry.io/4508612875845712",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    beforeSend(event) {
        const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

        if (isLocalhost) {
            return null;
        }

        return event;
    },
});

const container = document.getElementById('root');
const root = createRoot(container || document.createElement('div'));
root.render(<App />);
