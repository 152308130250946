import { useState, useCallback, useContext, useLayoutEffect, useEffect } from "react";
import { Storage } from "aws-amplify";
import { createUserPayment, UpdateUserPayment } from "../hooks";
import {
  validatePaymentForm,
  validatePaymentFormAccountNumber,
  validatePaymentFormAddress,
  validatePaymentFormBranchCode,
  validatePaymentFormFullName,
  validatePaymentFormSwiftCode,
  validatePostCode,
} from "../state/auth";
import { IPayment, IPaymentError } from "../state/payment";
import { IProfileImageUpload, ProfileContext } from "../state/profileSteps";
import { defaultPaymentError, defaultPaymentState } from "../utils";
import { UserPaymentDetails } from "API";
import { IMAGE_FILE_SIZE_LIMIT } from "../components/paymentCard/constants";
import { toast } from "react-toastify";
import { CREATOR_PROFILE_SUCCESS_MESSAGES, TOAST_CONFIG } from "./utils";

// Unified validateFile function
const validateFile = (file: File | Blob): boolean => {
  if (!file || !("type" in file)) return false;
  const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
  return allowedTypes.includes(file.type);
};

export const usePaymentDetails = (userId: string | null, paymentDetails?: UserPaymentDetails | null) => {
  const [formState, setFormState] = useState<IPayment>(defaultPaymentState);
  const [formError, setFormError] = useState<IPaymentError>(defaultPaymentError);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // The file state might be stored directly as a File or as an object with a file and/or image property.
  const [documentImg, setDocumentImg] = useState<IProfileImageUpload | File>({});
  const [proofOfAddress, setProofOfAddress] = useState<IProfileImageUpload | File>({});
  const [confirmationLetter, setConfirmationLetter] = useState<IProfileImageUpload | File>({});
  const { setProfileState, setSuccModal } = useContext(ProfileContext);

  const { createPayment } = createUserPayment();
  const { updatePayment } = UpdateUserPayment();

  const updateState = (key: string, value: string): void => {
    setFormError((prev) => ({ ...prev, [key]: null }));
    setFormState((prev) => ({ ...prev, [key]: value }));
  };

  /**
   * Fetches document images from AWS S3 and sets them in state.
   */
  const getDocumentImg = async ({ documentID, confirmationLetter, proofOfAddress }: UserPaymentDetails) => {
    try {
      if (documentID) {
        const documentIDURL = await Storage.get(documentID);
        setDocumentImg({ image: documentIDURL });
      }
      if (proofOfAddress) {
        const proofOfAddressURL = await Storage.get(proofOfAddress);
        setProofOfAddress({ image: proofOfAddressURL });
      }
      if (confirmationLetter) {
        const confirmationLetterURL = await Storage.get(confirmationLetter);
        setConfirmationLetter({ image: confirmationLetterURL });
      }
    } catch (error) {
      console.error("Error fetching document images:", error);
    }
  };

  /**
   * Checks file validity.
   * It first checks if fileObj is a File; if not, it looks for a file property.
   * If the file property is an HTMLInputElement, it extracts the first file.
   * Finally, it falls back to checking if there's an image URL.
   */
  const checkFileValidity = (fileObj: IProfileImageUpload | File, key: string): boolean => {
    let file: File | undefined;

    if (fileObj instanceof File) {
      file = fileObj;
    } else if ((fileObj as IProfileImageUpload).file) {
      const candidate = (fileObj as IProfileImageUpload).file;
      if (candidate instanceof File) {
        file = candidate;
      } else if (candidate instanceof HTMLInputElement) {
        file = candidate.files?.[0] ?? undefined;
      }
    }

    if (file) {
      return validateFile(file);
    }
    return !!(fileObj as IProfileImageUpload).image;
  };

  /**
   * Validates the entire payment form, including document uploads.
   */
  const validateProfileForm = useCallback((): boolean => {
    // ✅ Validate form fields
    const fullName = validatePaymentFormFullName(formState.fullName, "Full Name");
    const firstAddress = validatePaymentFormAddress(formState.firstAddress, "First Address");
    const secondAddress = validatePaymentFormAddress(formState.secondAddress, "Second Address");
    const swiftCode = validatePaymentFormSwiftCode(formState.swiftCode);
    const branchCode = validatePaymentFormBranchCode(formState.branchCode);
    const country = validatePaymentForm(formState.country, "Country");
    const accountNumber = validatePaymentFormAccountNumber(formState.accountNumber);
    const postCode = validatePostCode(formState.postCode);

    // ✅ Collect errors for fields
    const errors: IPaymentError = {
      fullName,
      firstAddress,
      secondAddress,
      swiftCode,
      country,
      accountNumber,
      branchCode,
      postCode
    };

    // ✅ Validate required documents
    const documentErrors: Partial<IPaymentError> = {};

    const isPassportValid = checkFileValidity(documentImg, "Passport/ID");
    const isProofOfAddressValid = checkFileValidity(proofOfAddress, "Proof of Address");
    const isConfirmationLetterValid = checkFileValidity(confirmationLetter, "Confirmation Letter");

    if (!isPassportValid) documentErrors.documentID = "Valid Passport/ID is required (.png, .jpg, .jpeg, .pdf)";
    if (!isProofOfAddressValid) documentErrors.proofOfAddress = "Valid Proof of Address is required (.png, .jpg, .jpeg, .pdf)";
    if (!isConfirmationLetterValid) documentErrors.confirmationLetter = "Valid Confirmation Letter is required (.png, .jpg, .jpeg, .pdf)";

    // ✅ Merge all errors
    const allErrors = { ...errors, ...documentErrors };

    // ✅ If there are any errors, store them and return false
    if (Object.values(allErrors).some(error => error !== null)) {
      setFormError(allErrors);
      return false;
    }

    return true;
}, [formState, documentImg, proofOfAddress, confirmationLetter]);
  /**
   * Handles form submission, uploads files, and updates the database.
   */
  const handleSubmit = useCallback(async (onSaveClose) => {
    setIsLoading(true);
    if (!userId){  setIsLoading(false); return} 
    if (!validateProfileForm()){ setIsLoading(false); return;}

    try {
      const uploadFileToS3 = async (file: File, path: string) => Storage.put(path, file);

      const passportImageKey = documentImg instanceof File 
      ? `Document/${userId}/passport` 
      : formState.documentID || `Document/${userId}/default-passport`;
    
    const proofOfAddressKey = proofOfAddress instanceof File 
      ? `Document/${userId}/proof-of-address` 
      : formState.proofOfAddress || `Document/${userId}/default-proof-of-address`;
    
    const confirmationLetterKey = confirmationLetter instanceof File 
      ? `Document/${userId}/confirmation-letter` 
      : formState.confirmationLetter || `Document/${userId}/default-confirmation-letter`;
    
    await Promise.all([
      ...(documentImg instanceof File ? [uploadFileToS3(documentImg, passportImageKey)] : []),
      ...(proofOfAddress instanceof File ? [uploadFileToS3(proofOfAddress, proofOfAddressKey)] : []),
      ...(confirmationLetter instanceof File ? [uploadFileToS3(confirmationLetter, confirmationLetterKey)] : []),
    ]);

      const paymentData = {
        ...formState,
        id: userId,
        documentID: passportImageKey,
        confirmationLetter: confirmationLetterKey,
        proofOfAddress: proofOfAddressKey,
      };

      if (paymentDetails) {
        await updatePayment({ variables: { input: paymentData } });
      } else {
        await createPayment({ variables: { input: paymentData }, errorPolicy: "ignore" });
      }

     //@ts-ignore
      setProfileState(prev => ({ ...prev, data: { ...prev.data } }));
      setSuccModal(true);
    } catch (error) {
      console.error("Error submitting payment details:", error);
      toast.error('Failed to update payment details. Please try again.', TOAST_CONFIG);
      setIsLoading(false);
    } finally {
    toast.success(CREATOR_PROFILE_SUCCESS_MESSAGES.ProfileUpdated, TOAST_CONFIG);
      setIsLoading(false);
      onSaveClose()
    }
  }, [formState, documentImg, proofOfAddress, confirmationLetter, userId, paymentDetails]);

  useEffect(() => {
    // Optionally handle state changes if needed.
  }, [documentImg, proofOfAddress, confirmationLetter]);

  useLayoutEffect(() => {
    if (!paymentDetails || !userId) return;
    getDocumentImg(paymentDetails);
    setFormState((prev) => ({
      ...prev,
      fullName: paymentDetails.fullName || prev.fullName || "",
      firstAddress: paymentDetails.firstAddress || prev.firstAddress || "",
      secondAddress: paymentDetails.secondAddress || prev.secondAddress || "",
      swiftCode: paymentDetails.swiftCode || prev.swiftCode || "",
      country: paymentDetails.country || prev.country || "",
      accountNumber: paymentDetails.accountNumber || prev.accountNumber || "",
      postCode: paymentDetails.postCode || prev.postCode || "",
      branchCode: paymentDetails.branchCode || prev.branchCode || "",
      confirmationLetter: paymentDetails.confirmationLetter || prev.confirmationLetter || "",
      proofOfAddress: paymentDetails.proofOfAddress || prev.proofOfAddress || "",
      documentID: paymentDetails.documentID || prev.documentID || "",
    }));
  }, [userId, paymentDetails]);

  return {
    formState,
    formError,
    isLoading,
    updateState,
    handleSubmit,
    documentImg,
    setDocumentImg,
    proofOfAddress,
    setProofOfAddress,
    confirmationLetter,
    setConfirmationLetter,
  };
};
