import { Edit, Search } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import {
  ADMIN_STATUS,
  CreativeRequest,
  CREATIVE_STATUS,
  ModelSortDirection,
} from 'API';
import { StyledDataGrid } from 'components/DataGrid';
import { StyledTextField } from 'components/TextField';
import { UseGetWhitelistedBriefs } from 'hooks';
import { DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS } from 'hooks/utils';
import { debounce } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseAdminGetCreativeRequestsByDate } from 'state/admin/useCreativeRequests';
import { AuthContext } from 'state/auth';
import { ProfileContext, withProfile } from 'state/profileSteps';
import { MOCK_TABLE_DATA, TABLE_COLUMNS } from './constants';
import EmptyRow from './EmptyRow';
import { getCreativePreviewUrl } from './utils';

export interface IEverydayContentProps {
  brandBriefId: string;
}

const getFormattedCreativeRequests = (creativeRequests: CreativeRequest[]) =>
  creativeRequests.map((cr) => ({
    ...cr,
    briefName: cr?.brief?.BriefName || '',
  }));

const EverydayContent = () => {
  const navigate = useNavigate();
  const { profileState } = useContext(ProfileContext);
  const { authState } = useContext(AuthContext);
  const [tableData, setTableData] = useState<CreativeRequest[] | null>(null);
  const { getAllRequests, data: creativeRequests } =
    UseAdminGetCreativeRequestsByDate();

  const { getBriefs, data: availableBriefs } = UseGetWhitelistedBriefs();
  const tableHead = useMemo(
    () =>
      TABLE_COLUMNS.map((tc) => {
        switch (tc.field) {
          case 'details':
            return {
              ...tc,
              renderCell: ({ row }) => (
                <IconButton
                  onClick={() => {
                    onDetails(row);
                  }}
                >
                  <Edit />
                </IconButton>
              ),
            };
          default:
            return tc;
        }
      }),
    []
  );

  const getAvailableCreativeRequests = async () => {
    if (!availableBriefs?.length) return;

    await getAllRequests({
      type: 'CreativeRequest',
      updatedAt: { ge: '1970-01-01T00:00:00.000Z' },
      sortDirection: ModelSortDirection.DESC,
      limit: 1000,
      filter: {
        and: [
          {
            or: [
              { adminApproval: { eq: ADMIN_STATUS.Approved } },
              {
                and: [
                  { adminApproval: { eq: ADMIN_STATUS.Revision } },
                  { status: { eq: CREATIVE_STATUS.Rejected } },
                ],
              },
            ],
          },
          {
            or: availableBriefs.map((id) => ({ brandBriefId: { eq: id } })),
          },
        ],
      },
    });
  };

  useEffect(() => {
    if (!availableBriefs.length) {
      getBriefs({
        variables: { userId: profileState?.data?.id || authState.userId || '' },
      });
    }
  }, []);

  useEffect(() => {
    getAvailableCreativeRequests();
  }, [JSON.stringify(availableBriefs)]);

  useEffect(() => {
    const filteredRequests =
      (creativeRequests.filter((cr) => cr) as CreativeRequest[]) || [];

    const getOrder = (req: CreativeRequest) => {
      // Define a custom order: lower number means higher priority in display.
      if (
        req.adminApproval === ADMIN_STATUS.Approved &&
        req.status === CREATIVE_STATUS.Submitted
      )
        return 0;
      if (
        req.adminApproval === ADMIN_STATUS.Approved &&
        req.status === CREATIVE_STATUS.Approved
      )
        return 1;
      if (
        (req.adminApproval === ADMIN_STATUS.Revision &&
          req.status === CREATIVE_STATUS.Rejected) ||
        (req.adminApproval === ADMIN_STATUS.Rejected &&
          req.status === CREATIVE_STATUS.Rejected)
      )
        return 2;

      return 99;
    };

    const sortedRequests = filteredRequests.sort(
      (a, b) => getOrder(a) - getOrder(b)
    );

    setTableData(getFormattedCreativeRequests(sortedRequests));
  }, [JSON.stringify(creativeRequests)]);

  const onDetails = (creativeRequest: CreativeRequest) => {
    navigate(getCreativePreviewUrl(creativeRequest));
  };

  const onSearch = debounce((value: string) => {
    const trimmed = value.trim().toLowerCase();
    const filteredRequests = creativeRequests.filter(
      (cr) =>
        cr?.brief?.BriefName?.toLowerCase().includes(trimmed) ||
        cr?.uniqueId?.toLowerCase()?.includes(trimmed) ||
        cr?.status.toLowerCase() === trimmed
    ) as CreativeRequest[];

    setTableData(getFormattedCreativeRequests(filteredRequests));
  }, DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS);

  return (
    <div className="w-full flex flex-col gap-y-3">
      <div className="px-1">
        <StyledTextField
          variant="standard"
          placeholder="Search..."
          onChange={(event) => {
            onSearch(event?.target.value);
          }}
          sx={{ maxWidth: 250 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <StyledDataGrid
        columns={tableHead}
        className="[&>.MuiDataGrid-main>.MuiDataGrid-virtualScroller>.MuiDataGrid-overlayWrapper]:h-[400px]"
        density="comfortable"
        paginationMode="client"
        rows={tableData ? tableData : MOCK_TABLE_DATA}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          sorting: {
            sortModel: [{ field: 'status', sort: 'desc' }],
          },
        }}
        rowHeight={120}
        onRowClick={({ row }) => onDetails(row)}
        pageSizeOptions={[5]}
        disableColumnResize={true}
        disableRowSelectionOnClick={true}
        disableAutosize={true}
        disableColumnMenu={true}
        autosizeOnMount={true}
        disableColumnSelector={true}
        loading={!tableData}
        slots={{
          noRowsOverlay: EmptyRow,
        }}
      />
    </div>
  );
};

export default withProfile(EverydayContent);
