import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

interface IFileUploadProps {
  accept?: Accept;
  onFileSelect: (file) => Promise<void>;
  emptyMessage?: string;
  label?: string;
}

const FileUpload = ({
  accept,
  onFileSelect,
  emptyMessage,
  label = 'Click to upload files',
}: IFileUploadProps) => {
  const onDrop = useCallback(onFileSelect, [onFileSelect]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: '2px dashed grey',
        borderRadius: '10px',
        padding: '20px',
        cursor: 'pointer',
        backgroundColor: isDragActive ? '#f0f0f0' : '#ffffff',
        maxWidth: '100%',
        height: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <input {...getInputProps()} onChange={onFileSelect} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2, // You can adjust the gap as needed
        }}
      >
        <UploadFileIcon sx={{ color: '#60a5fa', fontSize: 40 }} />
        <Typography variant="h6" gutterBottom>
          {isDragActive ? (
            <span style={{ fontSize: '16px' }}>Drop the files here ...</span>
          ) : (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5rem',
              }}
            >
              <span
                style={{
                  color: '#60a5fa',
                  textDecoration: 'underline',
                  fontSize: '16px',
                }}
              >
                {label}
              </span>
              <span style={{ fontSize: '14px' }}>or drag and drop</span>
            </span>
          )}
        </Typography>
        <Typography fontWeight={400} fontSize={14}>
          {emptyMessage || 'SVG, PNG, JPG or GIF (max. 3MB)'}
        </Typography>
      </Box>
    </Box>
  );
};

export default FileUpload;
