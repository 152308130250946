import { ExpandMore, Send } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  InputBase,
  Typography,
} from '@mui/material';
import { OpenFolderIcon } from 'assets/icons/icons';
import AppLoader from 'components/AppLoader/AppLoader';
import { useMessages } from 'hooks/useMessages';
import { FC, useEffect, useRef, useState } from 'react';

interface ChatPanelProps {
  creatorId: string;
  msgFrom: 'user' | 'admin';
  context: string;
  isAdminView?: boolean;
}

const ChatPanel: FC<ChatPanelProps> = ({
  creatorId,
  msgFrom,
  context,
  isAdminView = false,
}) => {
  const [message, setMessage] = useState('');
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const { getMessages, sendMessage, loading } = useMessages();
  const [messages, setMessages] = useState<any[]>([]);
  const [loadingMessages, setLoadingMessages] = useState(false);

  const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);
    return `${date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })} ${date.toLocaleDateString()}`;
  };

  const fetchMessages = async () => {
    if (!creatorId) return;
    setLoadingMessages(true);
    const { response } = await getMessages(creatorId);
    if (response?.data) {
      const filtered = response.data
        .filter((msg: any) => msg.context === context)
        .sort(
          (a: any, b: any) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      setMessages(filtered);
    }
    setLoadingMessages(false);
  };

  // ✅ Scroll to bottom when messages change
  useEffect(() => {
    messagesContainerRef.current?.scrollTo({
      top: messagesContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [messages]);

  // ✅ Fetch and filter messages by context
  useEffect(() => {
    fetchMessages();
  }, [creatorId, context]);

  const handleSend = async () => {
    if (!message.trim() || !creatorId || !msgFrom || !context) return;

    const optimisticMessage = {
      message,
      from: msgFrom,
      context,
      createdAt: new Date().toISOString(),
    };

    setMessages((prev) =>
      Array.isArray(prev) ? [...prev, optimisticMessage] : [optimisticMessage]
    );
    setMessage('');

    await sendMessage({
      context,
      from: msgFrom,
      creatorId,
      message,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSend();
  };

  const RefreshButton = () => (
    <button
      onClick={fetchMessages}
      disabled={loadingMessages}
      className="text-sm px-3 py-1 border border-gray-300 rounded hover:bg-gray-100 transition"
    >
      {loadingMessages ? 'Refreshing...' : 'Refresh'}
    </button>
  );

  const renderMessages = (
    <div
      ref={messagesContainerRef}
      className="flex flex-col gap-4 max-h-[400px] overflow-y-auto"
      style={{ flexGrow: 1 }}
    >
      {loadingMessages ? (
        <div className="flex justify-center py-4">
          <AppLoader size={40} />
        </div>
      ) : messages.length > 0 ? (
        messages.map((msg, index) => (
          <div
            key={index}
            className={`text-gray-900 p-3 rounded-lg w-fit max-w-[80%] ${
              isAdminView
                ? msg.from === 'admin'
                  ? 'bg-[#D1FAE5] self-end'
                  : 'bg-[#F0F0F0] self-start'
                : msg.from === 'admin'
                ? 'bg-[#F0F0F0] self-start'
                : 'bg-[#D1FAE5] self-end'
            }`}
          >
            <Typography fontSize="14px" sx={{ whiteSpace: 'pre-line' }}>
              {msg.message || msg.body}
            </Typography>
            <Typography
              fontSize="10px"
              color="text.secondary"
              textAlign="right"
              marginTop="4px"
            >
              {formatTimestamp(
                msg.createdAt || msg.dateSent || msg.dateCreated || ''
              )}
            </Typography>
          </div>
        ))
      ) : (
        <Typography>No messages found for this creative.</Typography>
      )}
    </div>
  );

  const renderInputField = (
    <div className="flex items-center border rounded-md mt-4 px-2 py-1">
      <InputBase
        placeholder="Type a message"
        fullWidth
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        sx={{ ml: 1, flex: 1 }}
        disabled={loading}
      />
      <IconButton onClick={handleSend} disabled={!message.trim() || loading}>
        <Send sx={{ color: '#FF872F' }} />
      </IconButton>
    </div>
  );

  if (isAdminView) {
    return (
      <>
        <Typography
          sx={{
            fontSize: '18px',
            fontFamily: 'Oswald, sans-serif',
            fontWeight: 700,
            marginBottom: '10px',
            textTransform: 'uppercase',
          }}
        >
          Chat With Creator <RefreshButton />
        </Typography>

        <Box
          sx={{
            border: '1px solid #E0E0E0',
            borderRadius: '16px',
            padding: '20px',
            backgroundColor: '#fff',
            height: '515px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {renderMessages}
          {renderInputField}
        </Box>
      </>
    );
  }

  return (
    <Accordion
      expanded
      sx={{
        border: 'none',
        boxShadow: 'none',
        borderRadius: '16px',
        mb: 2,
        '&.Mui-expanded': {
          border: '1px solid #E0E0E0',
          borderRadius: '16px',
        },
        '&:before': { display: 'none' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          backgroundColor: '#F5F1E8',
          borderRadius: '16px',
          minHeight: '64px',
          '&.Mui-expanded': { borderRadius: '16px 16px 0 0' },
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '18px',
            fontFamily: 'Oswald, sans-serif',
            fontWeight: 600,
            textTransform: 'uppercase',
          },
        }}
      >
        <OpenFolderIcon fontSize="small" /> Chat
        <RefreshButton />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          background: '#fff',
          padding: '20px',
          borderRadius: '0 0 16px 16px',
        }}
      >
        {renderMessages}
        {renderInputField}
      </AccordionDetails>
    </Accordion>
  );
};

export default ChatPanel;
