import { useState } from 'react';

const BASE_API_URL =
  'https://eeizhsuc10.execute-api.us-east-1.amazonaws.com/live/message';
const API_KEY = 'rpzw4zOOr49XCHxLDuuiA4Yt0bJnedE58A9wiFXP';

interface APIResponse {
  success: boolean;
  message?: string;
  data?: any;
}

export const useMessages = () => {
  const [loading, setLoading] = useState(false);

  const getFriendlyErrorMessage = (status?: number, message?: string) => {
    if (!status) return 'An unexpected error occurred.';
    switch (status) {
      case 400:
        return 'Invalid request.';
      case 401:
        return 'Unauthorized.';
      case 403:
        return 'Access denied.';
      case 404:
        return 'Not found.';
      case 500:
        return 'Server error.';
      default:
        return message || 'An error occurred.';
    }
  };

  const getMessages = async (creatorId: string) => {
    setLoading(true);
    try {
      const res = await fetch(`${BASE_API_URL}/${creatorId}`, {
        headers: {
          'x-api-key': API_KEY,
        },
      });

      const data = await res.json();
      setLoading(false);

      if (!res.ok) {
        return {
          response: null,
          error: getFriendlyErrorMessage(res.status, data.message),
        };
      }

      return { response: data, error: null };
    } catch (err) {
      setLoading(false);
      return { response: null, error: 'Network error.' };
    }
  };

  const sendMessage = async ({
    context,
    from = 'user',
    creatorId,
    message,
  }: {
    context: string;
    from?: 'user' | 'admin';
    creatorId: string;
    message: string;
  }) => {
    setLoading(true);
    try {
      const res = await fetch(`${BASE_API_URL}/${creatorId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify({
          context,
          from,
          creatorId,
          message,
        }),
      });

      const data = await res.json();
      setLoading(false);

      if (!res.ok) {
        return {
          response: null,
          error: getFriendlyErrorMessage(res.status, data.message),
        };
      }

      return { response: data, error: null };
    } catch (err) {
      setLoading(false);
      return { response: null, error: 'Failed to send message.' };
    }
  };

  return {
    getMessages,
    sendMessage,
    loading,
  };
};
