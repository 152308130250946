import React, { FC, useState, useEffect } from "react";
import { Upload, FileText, Pencil } from "lucide-react";
import ShouldRender from "components/shouldRender";

const ACCEPTED_FILE_TYPES = ".png, .jpg, .jpeg, .pdf";

export interface IImageInputProps {
  label: string;
  id: string;
  error?: string | null;
  imageURL?: string | null;
  accept?: string | null;
  type: "passport" | "letter" | "proofOfAddress";
  handleChange: (file: File | null, type: "passport" | "letter" | "proofOfAddress") => void;
}

const ImageInput: FC<IImageInputProps> = ({ error, type, label, id, handleChange, imageURL, accept = ACCEPTED_FILE_TYPES }) => {
  const [displayFile, setDisplayFile] = useState<string | null | undefined>(imageURL);
  const [isPDF, setIsPDF] = useState<boolean>(false);
  const [imageError, setImageError] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  // ✅ Ensure preview updates when a new imageURL is provided
  useEffect(() => {
    setDisplayFile(imageURL);
    setIsPDF(imageURL?.endsWith(".pdf") || false);
    setImageError(false);
  }, [imageURL]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const fileURL = URL.createObjectURL(file);
    setDisplayFile(fileURL);
    setIsPDF(file.type === "application/pdf");
    setImageError(false);

    handleChange(file, type);
    setIsEditing(false); // Exit edit mode after file selection
  };

  return (
    <div className="relative w-full pb-[10px]">
      {/* ✅ Floating Label */}
      <label htmlFor={id} className="absolute z-[999] top-[-10px] left-2 bg-white px-2 text-gray-500 text-[14px] font-medium pointer-events-none">
        {label}
      </label>

      {/* ✅ Upload Box */}
      <div
        className={`border rounded-lg p-4 w-full flex flex-col items-center justify-center relative hover:border-black transition-all ${error ? "border-red-500" : "border-gray-400"
          }`}
      >
        {displayFile && !isEditing ? (
          isPDF || imageError ? (
            // ✅ If it's a PDF or image fails to load, show PDF download option
            <div className="flex flex-col items-center">
              <FileText size={40} className="text-blue-500 mb-2" />
              <a href={displayFile || "#"} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline text-sm">
                View PDF
              </a>
              <button
                onClick={() => setIsEditing(true)}
                className="mt-2 text-white bg-blue-500 hover:bg-blue-600 px-3 py-1 text-sm rounded flex items-center"
              >
                <Pencil size={14} className="mr-1" /> Edit
              </button>
            </div>
          ) : (
            // ✅ If it's an image, show preview with Edit button
            <div className="relative w-full">
              <img
                className="w-full h-[200px] rounded-lg object-cover"
                src={displayFile}
                alt="Uploaded Preview"
                onError={() => {
                  setImageError(true);
                  setIsPDF(true);
                }}
              />
              <button
                onClick={() => setIsEditing(true)}
                className="absolute bottom-2 right-2 bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 text-sm rounded flex items-center"
              >
                <Pencil size={14} className="mr-1" /> Edit
              </button>
            </div>
          )
        ) : (
          // ✅ Show upload prompt when no file exists or in edit mode
          <div className="flex flex-col items-center">
            <Upload size={25} strokeWidth={2} className="text-blue-500" />
            <p className="text-gray-900 font-medium text-[14px]">Click or drag to upload</p>
            <p className="text-gray-500 text-[12px] text-center mt-1">Supports images & PDFs</p>
            <p className="text-gray-500 text-[12px] text-center">Max file size 2MB</p>
            <input
              type="file"
              accept={accept || ""}
              id={id}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              onChange={handleFileChange}
            />
          </div>
        )}
      </div>

      {/* ✅ Error Message */}
      {error && <span className="text-[12px] text-red-600 font-medium mt-1 block">{error}</span>}
    </div>
  );
};

export default ImageInput;
