import { motion } from "framer-motion";
import { ArrowLeft, ArrowRight } from "lucide-react";

interface PillarProgressProps {
    currentStep: number;
    totalSteps: number;
    onNext: () => void;
    onPrev: () => void;
    pillarHeading: string;
    isLastStep: boolean; // New prop to check if user is at last step
}

const PillarProgress: React.FC<PillarProgressProps> = ({
    currentStep,
    totalSteps,
    onNext,
    onPrev,
    pillarHeading,
    isLastStep,
}) => {
    return (
        <div className="flex flex-col items-center w-full mt-2">
            {/* Animated Pillar Heading */}
            <motion.h2
                key={pillarHeading}
                initial={{ opacity: 0, y: -30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 30 }}
                transition={{ duration: 0.4, ease: "easeOut" }}
                className="text-4xl font-bold text-black mb-3 font-oswald"
            >
                {pillarHeading}
            </motion.h2>

            {/* Navigation and Progress */}
            <div className="flex items-center justify-center w-full">
                {/* Step Progress */}
                <div className="flex justify-center items-center gap-3 mx-4">
                    {Array.from({ length: totalSteps }).map((_, index) => {
                        const isActive = index < currentStep;
                        return (
                            <motion.div
                                key={index}
                                className="w-6 h-4 rounded-md"
                                style={{
                                    backgroundColor: isActive ? "#FF872F" : "#FFE0C2",
                                    border: "1px solid #D46C1C",
                                }}
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{
                                    opacity: isActive ? 1 : 0.3,
                                    scale: isActive ? 1 : 0.8,
                                }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default PillarProgress;
