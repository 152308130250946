// videoUtils.ts
import { FFmpeg } from '@ffmpeg/ffmpeg';

export async function compressVideo(file: File): Promise<File> {
    try {
        // 1. Create an FFmpeg instance
        const ffmpeg = new FFmpeg();

        // 2. Load the FFmpeg WASM (once per FFmpeg instance)
        await ffmpeg.load();

        // 3. Convert the File into a Uint8Array
        const fileData = new Uint8Array(await file.arrayBuffer());

        // 4. Write the input file into FFmpeg's in-memory filesystem
        await ffmpeg.writeFile('input.mp4', fileData);

        // 5. Execute the FFmpeg command (use `exec` instead of `run`)
        // Example: compress the video by re-encoding with H.264 at CRF 28
        await ffmpeg.exec([
            '-i',
            'input.mp4',
            '-vcodec',
            'libx264',
            '-crf',
            '28',
            '-preset',
            'veryfast',
            'output.mp4',
        ]);

        // 6. Read the compressed output from the in-memory FS
        const outputData = await ffmpeg.readFile('output.mp4');

        // 7. Convert that Uint8Array into a Blob/File
        const compressedBlob = new Blob([outputData], { type: 'video/mp4' });
        const compressedFile = new File([compressedBlob], 'compressed.mp4', {
            type: 'video/mp4',
            lastModified: Date.now(),
        });

        return compressedFile;
    } catch (error) {
        console.error('Error compressing video:', error);
        // Fallback to the original file if compression fails
        return file;
    }
}
