import { Accordion, AccordionDetails, AccordionSummary, Typography, Button, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExpandMore } from '@mui/icons-material';
import { Check, Close, Visibility } from '@mui/icons-material';
import { BrandBrief } from 'API';
import { IIcon, OpenFolderIcon, EditWithPencilIcon } from 'assets/icons/icons';
import { FC, ReactNode, useMemo, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import CreativeInspirations from '../CreativeInspirations/CreativeInspirations';

export interface IInformationDisplayProps {
  brandBrief?: BrandBrief;
  setExpandedTabs: (tabs: string[]) => void;
  onFilesDownload: () => Promise<void>;
  setPauseVideo: (value: boolean) => void;
}

const getPractices = (practices: string[], icon: ReactNode) => (
  <>
    {practices.map((p) => (
      <div key={p} className="flex items-center gap-x-2 break-all">
        {icon}
        <p>{p}</p>
      </div>
    ))}
  </>
);

const getBriefTags = (tags: string[]) =>
  tags?.map((t) => (
    <div
      className="border border-[#B0B0B0] text-[#6F6F6F] text-[14px] rounded-[16px] py-[4px] px-[12px] font-inter"
      key={t}
    >
      {t}
    </div>
  ));

const InformationDisplay: FC<IInformationDisplayProps> = ({ brandBrief, setExpandedTabs, onFilesDownload, setPauseVideo }) => {

  const [expandedPanels, setExpandedPanels] = useState<string[]>(['activation-overview']);

  const handleAccordionChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpandedPanels((prev) => {
      const newPanels = isExpanded
        ? [...prev, panel]
        : prev.filter((p) => p !== panel);

      // @ts-ignore
      setExpandedTabs((prevOpened) => {
        if (isExpanded && !prevOpened.includes(panel)) {
          return [...prevOpened, panel];
        }
        return prevOpened;
      });


      return newPanels;
    });
  };
  const bestPractices = useMemo(
    () =>
      getPractices(brandBrief?.goodPractices || [], <Check fontSize="small" />),
    [brandBrief?.goodPractices]
  );

  const badPractices = useMemo(
    () =>
      getPractices(
        [
          ...(brandBrief?.badPractices || []),
          ...(brandBrief?.commonRejectionReasons || []),
        ],
        <Close fontSize="small" />
      ),
    [brandBrief?.badPractices, brandBrief?.commonRejectionReasons]
  );

  const maybes = useMemo(
    () => getPractices(brandBrief?.maybes || [], <IIcon fontSize="small" />),
    [brandBrief?.maybes]
  );

  const tags = useMemo(
    () => getBriefTags(brandBrief?.tags || []),
    [brandBrief?.tags]
  );


  const isDownloadDisabled = useMemo(
    () => !brandBrief?.brandBriefFilesUrl,
    [brandBrief?.brandBriefFilesUrl]
  );

  //@ts-ignore
  const CustomTooltip = styled(({ className, ...props }) => (
    //@ts-ignore
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: '#FF872F',
      color: '#FFF',
      fontSize: '16px',
      fontFamily: 'Oswald, sans-serif',
      padding: '10px 20px',
      borderRadius: '8px',
    },
    [`& .MuiTooltip-arrow`]: {
      color: '#FF872F',
    },
  }));


  return (
    <div className="flex flex-col gap-y-4">
      <Typography className="uppercase font-oswald text-[#1D1C1C] font-[600] text-[20px] pt-[15px]">
        The important stuff
      </Typography>

      <Accordion
        sx={{
          border: 'none',
          boxShadow: 'none',
          borderRadius: '16px',
          marginBottom: '10px',
          '&.Mui-expanded': {
            border: '1px solid #E0E0E0',
            borderRadius: '16px',
          },
          '&:before': {
            display: 'none',
          },
        }}
        expanded={expandedPanels.includes('activation-overview')}
        onChange={handleAccordionChange('activation-overview')}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            backgroundColor: '#F5F1E8',
            borderRadius: '16px',
            minHeight: '64px',
            '&.Mui-expanded': {
              borderRadius: '16px 16px 0 0',
            },
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '18px',
              fontFamily: 'Oswald, sans-serif',
            },
          }}
        >
          <div className="flex items-center gap-2">
            <OpenFolderIcon fontSize="small" />
            <Typography className="uppercase font-oswald font-[600] text-[16px]">
              The Activation Overview
            </Typography>
            {/* Tooltip Section */}
            <img
              className="w-4 h-4"
              data-tooltip-id="tooltip-icon-activation-overview"
              src="/images/info-icon.svg"
              alt="Info"
            />
            <ReactTooltip
              id="tooltip-icon-activation-overview"
              place="top"
              content="This section provides an overview of the activation process and key components of the campaign"
              className="custom-tooltip lg:text-[14px] text-[12px]"
            />
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '16px',
            fontFamily: 'Inter, sans-serif',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              color: '#1D1C1C',
              marginBottom: '20px',
            }}
          >
            {brandBrief?.overview}
          </Typography>

          {/* Line Separator */}
          <div
            style={{
              borderBottom: '1px solid #E0E0E0',
              margin: '16px 0',
            }}
          ></div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600, // SemiBold
                  fontFamily: 'Oswald, sans-serif',
                  textTransform: 'uppercase',
                  color: '#1D1C1C',
                }}
              >
                Tags
              </Typography>
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  flexWrap: 'wrap',
                }}
              >
                {tags?.length > 0 ? (
                  tags
                ) : (
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      fontFamily: 'Inter, sans-serif',
                      color: '#A0A0A0',
                      fontStyle: 'italic',
                    }}
                  >
                    No tags
                  </Typography>
                )}
              </div>

            </div>

            {/* Line Separator */}
            <div
              style={{
                borderBottom: '1px solid #E0E0E0',
                margin: '16px 0',
              }}
            ></div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600, // SemiBold
                  fontFamily: 'Oswald, sans-serif',
                  textTransform: 'uppercase',
                  color: '#1D1C1C',
                }}
              >
                Objective
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  color: '#1D1C1C',
                }}
              >
                {brandBrief?.objective}
              </Typography>
            </div>

            {/* Line Separator */}
            <div
              style={{
                borderBottom: '1px solid #E0E0E0',
                margin: '16px 0',
              }}
            ></div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600, // SemiBold
                  fontFamily: 'Oswald, sans-serif',
                  textTransform: 'uppercase',
                  color: '#1D1C1C',
                }}
              >
                Files
              </Typography>

              <Typography
                sx={{
                  fontSize: '16px',
                  color: '#1D1C1C',
                }}
              >
                Necessary files for this activation like logo’s & fonts can be downloaded here.
              </Typography>

              {/* Tooltip and Button */}
              <div
                style={{
                  padding: '10px',
                  borderRadius: '8px',
                  display: 'inline-block',
                }}
              >
                {/* @ts-ignore */}
                <CustomTooltip
                  title={
                    isDownloadDisabled
                      ? 'No files available for download in this activation.'
                      : ''
                  }
                  placement="right"
                  arrow
                >
                  <span>
                    <Button
                      disabled={isDownloadDisabled}
                      disableRipple
                      onClick={onFilesDownload}
                      className="capitalize bg-[#1D1C1C] font-inter font-[500] text-white text-sm px-[16px] py-[15px] rounded-[6px] disabled:bg-gray-400 max-w-[200px] text-center truncate"
                    >
                      Download Files
                    </Button>
                  </span>
                </CustomTooltip>
              </div>
            </div>


          </div>
        </AccordionDetails>
      </Accordion>



      <Accordion
        sx={{
          border: 'none',
          boxShadow: 'none',
          borderRadius: '16px',
          marginBottom: '10px',
          '&.Mui-expanded': {
            border: '1px solid #E0E0E0', // Light border when expanded
            borderRadius: '16px',
          },
          '&:before': {
            display: 'none',
          },
        }}
        expanded={expandedPanels.includes('messaging')}
        onChange={handleAccordionChange('messaging')}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            backgroundColor: '#F5F1E8',
            borderRadius: '16px',
            minHeight: '64px', // Adjusted height
            '&.Mui-expanded': {
              borderRadius: '16px 16px 0 0',
            },
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '18px', // Adjusted font size
              fontFamily: 'Oswald, sans-serif', // Font consistency
            },
          }}
        >
          <EditWithPencilIcon fontSize="small" />
          <Typography className="uppercase font-oswald font-[600]">
            Messaging
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Messaging Content */}
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              color: '#1D1C1C',
              marginBottom: '16px',
            }}
            className="break-all whitespace-pre-line"
          >
            {brandBrief?.messaging}
          </Typography>

          {/* Line Separator */}
          <div
            style={{
              borderBottom: '1px solid #E0E0E0',
              margin: '16px 0',
            }}
          ></div>

          {/* Tone Heading */}
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600, // Semi-bold
              fontFamily: 'Oswald, sans-serif',
              textTransform: 'uppercase',
              color: '#1D1C1C',
              marginBottom: '8px',
            }}
          >
            Tone
          </Typography>

          {/* Tone Content */}
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              color: '#1D1C1C',
            }}
            className="break-all whitespace-pre-line"
          >
            {brandBrief?.tone}
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion
        sx={{
          border: 'none',
          boxShadow: 'none',
          borderRadius: '16px',
          marginBottom: '10px',
          '&.Mui-expanded': {
            border: '1px solid #E0E0E0', // Light border when expanded
            borderRadius: '16px',
          },
          '&:before': {
            display: 'none',
          },
        }}
        expanded={expandedPanels.includes('dos-donts-maybes')}
        onChange={handleAccordionChange('dos-donts-maybes')}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            backgroundColor: '#F5F1E8',
            borderRadius: '16px',
            minHeight: '64px', // Adjusted height
            '&.Mui-expanded': {
              borderRadius: '16px 16px 0 0',
            },
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '18px', // Adjusted font size
              fontFamily: 'Oswald, sans-serif', // Font consistency
            },
          }}
        >
          <Visibility fontSize="small" />
          <Typography className="uppercase font-oswald font-[600]">
            Dos, Don'ts & Maybes
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex flex-col space-y-4">
            {/* Must include */}
            <div>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  fontFamily: 'Oswald, sans-serif',
                  color: '#1D1C1C',
                  marginBottom: '8px',
                }}
              >
                Must include:
              </Typography>
              {bestPractices}
            </div>

            {/* Line Separator */}
            <div
              style={{
                borderBottom: '1px solid #E0E0E0',
                margin: '16px 0',
              }}
            ></div>

            {/* Must exclude */}
            <div>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  fontFamily: 'Oswald, sans-serif',
                  color: '#1D1C1C',
                  marginBottom: '8px',
                }}
              >
                Must exclude:
              </Typography>
              {badPractices}
            </div>

            {/* Line Separator */}
            <div
              style={{
                borderBottom: '1px solid #E0E0E0',
                margin: '16px 0',
              }}
            ></div>

            {/* Maybes */}
            <div>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  fontFamily: 'Oswald, sans-serif',
                  color: '#1D1C1C',
                  marginBottom: '8px',
                }}
              >
                Maybes:
              </Typography>
              {maybes}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <CreativeInspirations
        urls={
          brandBrief?.creativeInspirations?.filter(
            (inspiration) => inspiration
          ) || []
        }
        setPauseVideo={setPauseVideo}
        expanded={expandedPanels.includes('creative-inspiration')}
        onChange={handleAccordionChange('creative-inspiration')}
      />

    </div>
  );
};

export default InformationDisplay;
