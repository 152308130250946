import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { Storage } from "aws-amplify";
import PillarProgress from "./PillarProgress";
import VideoDialog from "./VideoDialog";
import PillarOverview from "./PillarOverview";
import { UseGetUserProfile, UseUpdateUserProfile } from "hooks";
import { ProfileContext } from "state/profileSteps";

interface StepData {
    step: number;
    subHeading: string;
    videoSrc: string;
    thumbnail: string;
    keyPoints: string[];
}

interface PillarData {
    pillar: number;
    mainHeading: string;
    steps: StepData[];
}

interface PillarConstructionProps {
    pillars: PillarData[];
}

const LOCAL_STORAGE_KEY = "userPillarProgress";

const PillarConstruction: React.FC<PillarConstructionProps> = ({ pillars }) => {
    const [currentPillarIndex, setCurrentPillarIndex] = useState(0);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [highestPillarIndex, setHighestPillarIndex] = useState(0);
    const [highestStepIndex, setHighestStepIndex] = useState(0);

    const lastUpdatedProgress = useRef(JSON.stringify({ pillarIndex: 0, stepIndex: 0 }));
    const [videoCache, setVideoCache] = useState<Record<string, string>>({});
    const [currentVideoUrl, setCurrentVideoUrl] = useState("");
    const [isVideoLoading, setIsVideoLoading] = useState(false);
    const [isVideoComplete, setIsVideoComplete] = useState(false);
    const [isOverviewVisible, setIsOverviewVisible] = useState(true);
    const [progressFetched, setProgressFetched] = useState(false);

    const { updateProfile, loading: updateProfileLoading } = UseUpdateUserProfile();
    const { profileState } = useContext(ProfileContext);
    const { getProfile } = UseGetUserProfile();


    const parseHubProgress = (hubProgress: string): { pillarIndex: number; stepIndex: number } => {

        const jsonString = hubProgress
            .replace(/=/g, ":")
            .replace(/(\w+):/g, '"$1":')
            .replace(/'/g, '"');

        try {
            const parsed = JSON.parse(jsonString);
            return {
                pillarIndex: parsed.pillarIndex ?? 0,
                stepIndex: parsed.stepIndex ?? 0,
            };
        } catch (err) {
            console.error("Failed to parse hubProgress:", err);
            return { pillarIndex: 0, stepIndex: 0 };
        }
    };


    useEffect(() => {
        const fetchAndSetProgress = async () => {
            try {

                if (!profileState?.data?.id) {

                    loadFromLocalStorage();
                    setProgressFetched(true);
                    return;
                }


                const { data } = await getProfile({ variables: { id: profileState?.data?.id } });
                const hubProgress = data?.getUserProfile?.hubProgress;

                if (hubProgress) {

                    const { pillarIndex, stepIndex } = parseHubProgress(hubProgress);

                    setCurrentPillarIndex(pillarIndex);
                    setCurrentStepIndex(stepIndex);
                    setHighestPillarIndex(pillarIndex);
                    setHighestStepIndex(stepIndex);
                } else {

                    loadFromLocalStorage();
                }
            } catch (err) {
                console.error("Error fetching user progress from backend:", err);

                loadFromLocalStorage();
            } finally {
                setProgressFetched(true);
            }
        };

        fetchAndSetProgress();
    }, [getProfile, profileState]);

    const loadFromLocalStorage = () => {
        const savedProgress = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (savedProgress) {
            try {
                const {
                    savedPillarIndex,
                    savedStepIndex,
                    savedHighestPillar,
                    savedHighestStep,
                } = JSON.parse(savedProgress);

                setCurrentPillarIndex(savedPillarIndex || 0);
                setCurrentStepIndex(savedStepIndex || 0);
                setHighestPillarIndex(savedHighestPillar || 0);
                setHighestStepIndex(savedHighestStep || 0);
            } catch (err) {
                console.error("Failed to parse localStorage progress:", err);
            }
        }
    };


    useEffect(() => {

        if (!progressFetched) return;

        localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify({
                savedPillarIndex: currentPillarIndex,
                savedStepIndex: currentStepIndex,
                savedHighestPillar: highestPillarIndex,
                savedHighestStep: highestStepIndex,
            })
        );
    }, [currentPillarIndex, currentStepIndex, highestPillarIndex, highestStepIndex, progressFetched]);

    const currentPillar = pillars[currentPillarIndex] ?? {
        pillar: 1,
        mainHeading: "Loading...",
        steps: [{ step: 1, subHeading: "Loading...", videoSrc: "", keyPoints: [] }],
    };

    const currentStep = currentPillar.steps[currentStepIndex] ?? {
        step: 1,
        subHeading: "Loading...",
        videoSrc: "",
        keyPoints: [],
    };

    async function loadVideo(videoSrc: string): Promise<string> {
        if (!videoSrc) return "";
        if (videoCache[videoSrc]) {
            return videoCache[videoSrc];
        }
        try {
            const url = await Storage.get(`creatorHubVideos/${videoSrc}`);
            setVideoCache((prev) => ({ ...prev, [videoSrc]: url }));
            return url;
        } catch (err) {
            console.error("Failed to load video:", err);
            return "";
        }
    }

    useEffect(() => {
        (async () => {
            setIsVideoLoading(true);
            setIsVideoComplete(false);

            const url = await loadVideo(currentStep.videoSrc);
            setCurrentVideoUrl(url);
            setIsVideoLoading(false);


            const next1 = currentPillar.steps[currentStepIndex + 1];
            if (next1) loadVideo(next1.videoSrc);

            const next2 = currentPillar.steps[currentStepIndex + 2];
            if (next2) loadVideo(next2.videoSrc);
        })();
    }, [currentPillarIndex, currentStepIndex]);

    useEffect(() => {
        if (
            currentPillarIndex > highestPillarIndex ||
            (currentPillarIndex === highestPillarIndex && currentStepIndex > highestStepIndex)
        ) {
            setHighestPillarIndex(currentPillarIndex);
            setHighestStepIndex(currentStepIndex);
        }
    }, [currentPillarIndex, currentStepIndex]);


    const updateHubProgress = useCallback(async () => {
        if (!profileState?.data?.id) return;

        try {
            await updateProfile({
                variables: {
                    input: {
                        id: profileState?.data?.id,
                        hubProgress: {
                            pillarIndex: highestPillarIndex,
                            stepIndex: highestStepIndex,
                        },
                    },
                },
                errorPolicy: "ignore",
            });
            console.log("Hub progress updated:", {
                pillarIndex: highestPillarIndex,
                stepIndex: highestStepIndex,
            });
        } catch (error) {
            console.error("Error updating hub progress:", error);
        }
    }, [highestPillarIndex, highestStepIndex, updateProfile, profileState]);

    useEffect(() => {
        if (progressFetched && !updateProfileLoading) {

            const current = JSON.stringify({ pillarIndex: highestPillarIndex, stepIndex: highestStepIndex });


            if (current !== lastUpdatedProgress.current) {
                updateHubProgress();
                lastUpdatedProgress.current = current;
            }
        }
    }, [progressFetched, highestPillarIndex, highestStepIndex, updateHubProgress, updateProfileLoading]);


    const nextStep = () => {
        if (currentStepIndex < currentPillar.steps.length - 1) {
            setCurrentStepIndex((prev) => prev + 1);
        }
    };

    const nextPillar = () => {
        if (
            currentPillarIndex < pillars.length - 1 &&
            currentStepIndex === currentPillar.steps.length - 1
        ) {
            setCurrentPillarIndex((prev) => prev + 1);
            setCurrentStepIndex(0);
        }
    };

    const prevStep = () => {
        if (currentStepIndex > 0) {
            setCurrentStepIndex((prev) => prev - 1);
        }
    };

    const prevPillar = () => {
        if (currentPillarIndex > 0) {
            setCurrentPillarIndex((prev) => prev - 1);
            const previousPillar = pillars[currentPillarIndex - 1];
            setCurrentStepIndex(previousPillar ? previousPillar.steps.length - 1 : 0);
        }
    };


    const handleSelectStep = (pillarIndex: number, stepIndex: number) => {
        setCurrentPillarIndex(pillarIndex);
        setCurrentStepIndex(stepIndex);
        setIsOverviewVisible(false);
    };

    return (
        <div className="flex flex-col items-center w-full">
            {isOverviewVisible ? (
                <PillarOverview
                    pillars={pillars}
                    currentPillarIndex={highestPillarIndex}
                    currentStepIndex={highestStepIndex}
                    onSelectStep={handleSelectStep}
                />
            ) : (
                <>
                    <PillarProgress
                        currentStep={currentPillarIndex + 1}
                        totalSteps={pillars.length}
                        onNext={nextPillar}
                        onPrev={prevPillar}
                        pillarHeading={`Pillar ${currentPillar.pillar}: ${currentPillar.mainHeading}`}
                        isLastStep={currentStepIndex === currentPillar.steps.length - 1}
                    />

                    <h2 className="text-3xl font-bold text-center mt-4 mb-6 font-oswald">
                        {currentStep.subHeading} (Step {currentStep.step}/
                        {currentPillar.steps.length})
                    </h2>

                    {isVideoLoading ? (
                        <div className="flex justify-center items-center w-full h-[200px]">
                            <p className="text-gray-500 text-lg font-oswald animate-pulse">
                                Loading video...
                            </p>
                        </div>
                    ) : (
                        <VideoDialog
                            key={currentVideoUrl}
                            videoSrc={currentVideoUrl}
                            onVideoEnded={() => setIsVideoComplete(true)}
                        />
                    )}

                    {/* Step Navigation */}
                    <div className="flex justify-between w-full max-w-[400px] mt-3">
                        <button
                            className={`p-3 rounded-full ${currentStepIndex === 0 && currentPillarIndex === 0
                                ? "opacity-50 cursor-not-allowed"
                                : "bg-gray-200"
                                }`}
                            onClick={prevStep}
                            disabled={currentStepIndex === 0 && currentPillarIndex === 0}
                        >
                            ◀
                        </button>

                        {currentStepIndex < currentPillar.steps.length - 1 ? (
                            <button
                                className={`p-3 rounded-full ${isVideoComplete
                                    ? "bg-green-600 hover:bg-green-500"
                                    : "bg-gray-300 cursor-not-allowed"
                                    }`}
                                onClick={nextStep}
                                disabled={!isVideoComplete}
                            >
                                ▶
                            </button>
                        ) : (
                            <button
                                className={`p-3 rounded-full transition-all ${currentPillarIndex === pillars.length - 1
                                    ? "opacity-50 cursor-not-allowed"
                                    : isVideoComplete
                                        ? "bg-green-600 hover:bg-green-500"
                                        : "bg-gray-300 cursor-not-allowed"
                                    }`}
                                onClick={nextPillar}
                                disabled={!isVideoComplete || currentPillarIndex === pillars.length - 1}
                            >
                                Next Pillar ▶
                            </button>
                        )}
                    </div>

                    {/* Toggle Overview */}
                    <button
                        className="p-2 text-white bg-[#FF872F] rounded-md mb-4 font-oswald text-[18px] font-bold"
                        onClick={() => setIsOverviewVisible(!isOverviewVisible)}
                    >
                        {isOverviewVisible ? "View Video" : "View All Videos"}
                    </button>
                </>
            )}
        </div>
    );
};

export default PillarConstruction;
