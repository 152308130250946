import CreatorTile from 'pages/CreatorBriefDetails/components/CreatorTile/CreatorTile';
import { CreatorDetailsTileTitles } from 'pages/CreatorBriefDetails/constants';
import { FC } from 'react';

interface ICreatorDetailsHeaderProps {
  creativeUniqueId?: string;
  brandName?: string;
  activationName?: string;
  status?: string;
}
const CreatorDetailsHeader: FC<ICreatorDetailsHeaderProps> = ({
  creativeUniqueId,
  brandName,
  activationName,
  status,
}) => (
  <>
    <CreatorTile
      title={CreatorDetailsTileTitles.Creative_ID}
      subtitle={creativeUniqueId}
    />

    <CreatorTile
      title={CreatorDetailsTileTitles.BrandName}
      subtitle={brandName || 'N/A'}
    />

    <CreatorTile
      title={CreatorDetailsTileTitles.ActivationName}
      subtitle={activationName || 'N/A'}
    />

    <CreatorTile title={CreatorDetailsTileTitles.Status} subtitle={status} />
  </>
);

export default CreatorDetailsHeader;
