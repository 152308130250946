/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type LIST_CAMPAIGNS_INFO_RESPONSE = {
  __typename: 'LIST_CAMPAIGNS_INFO_RESPONSE';
  campaigns?: Array<CAMPAIGNS_RESPONSE | null> | null;
  identities?: Array<IDENTITIES_RESPONSE | null> | null;
};

export type CAMPAIGNS_RESPONSE = {
  __typename: 'CAMPAIGNS_RESPONSE';
  advertiser_id?: string | null;
  campaign_id?: string | null;
  campaign_system_origin?: string | null;
  create_time?: string | null;
  modify_time?: string | null;
  objective_type?: string | null;
  app_promotion_type?: string | null;
  is_search_campaign?: boolean | null;
  is_smart_performance_campaign?: boolean | null;
  campaign_type?: string | null;
  app_id?: string | null;
  is_advanced_dedicated_campaign?: string | null;
  campaign_app_profile_page_state?: string | null;
  rf_campaign_type?: string | null;
  campaign_product_source?: string | null;
  campaign_name?: string | null;
  special_industries?: Array<string | null> | null;
  budget_optimize_on?: boolean | null;
  bid_type?: string | null;
  depp_bid_type?: string | null;
  roas_bid?: number | null;
  optimization_goal?: string | null;
  budget_mode?: string | null;
  budget?: number | null;
  operation_status?: string | null;
  secondary_status?: string | null;
  postback_window_mode?: string | null;
  is_new_structure?: boolean | null;
  objective?: string | null;
};

export type IDENTITIES_RESPONSE = {
  __typename: 'IDENTITIES_RESPONSE';
  identity_id?: string | null;
  identity_type?: string | null;
  identity_authorized_bc_id?: string | null;
  can_push_video?: boolean | null;
  can_use_live_ads?: boolean | null;
  can_pull_video?: boolean | null;
  display_name?: string | null;
  profile_image?: string | null;
};

export type CreativeRequestEarnings = {
  __typename: 'CreativeRequestEarnings';
  creativeRequestEarningId: string;
  creativeRequestId: string;
  creatorId: string;
  earningType: EARNING_TYPE;
  amount: number;
  creativeUniqueId: string;
  currentEarnings?: number | null;
  month: string;
  toDate: string;
  fromDate: string;
  createdAt: string;
  updatedAt?: string | null;
  importedAt?: string | null;
};

export enum EARNING_TYPE {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
}

export type EXPORT_USERS_RESPONSE = {
  __typename: 'EXPORT_USERS_RESPONSE';
  url: string;
};

export type MESSAGE_RESPONSE = {
  __typename: 'MESSAGE_RESPONSE';
  message: string;
};

export enum ENTITIES {
  UserProfile = 'UserProfile',
  UserTransactions = 'UserTransactions',
  ApprovedAds = 'ApprovedAds',
  BrandBrief = 'BrandBrief',
  CreativeRequest = 'CreativeRequest',
  CreativeUsers = 'CreativeUsers',
  ApprovedCreativeRequests = 'ApprovedCreativeRequests',
  InterestedUsers = 'InterestedUsers',
  UninterestedUsers = 'UninterestedUsers',
}

export type GENERIC_URL_RESPONSE = {
  __typename: 'GENERIC_URL_RESPONSE';
  url: string;
};

export type LINK_AD_DATA = {
  adId: string;
  advertiserId: string;
  approvedAdType: ApprovedAdType;
};

export enum ApprovedAdType {
  TIKTOK = 'TIKTOK',
  META = 'META',
  YOUTUBE = 'YOUTUBE',
  MANUAL = 'MANUAL',
  YT_SHORTS = 'YT_SHORTS',
  DV360 = 'DV360',
}

export type WhitelistEntriesInput = {
  email: string;
  isApprover: boolean;
};

export type SendDetailsMessageResponse = {
  __typename: 'SendDetailsMessageResponse';
  message?: string | null;
};

export type DeleteBestPracticesInput = {
  id: string;
};

export type ModelBestPracticesConditionInput = {
  headLine?: ModelStringInput | null;
  description?: ModelStringInput | null;
  urlPath?: ModelStringInput | null;
  active?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelBestPracticesConditionInput | null> | null;
  or?: Array<ModelBestPracticesConditionInput | null> | null;
  not?: ModelBestPracticesConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  userProfileBestPracticesId?: ModelIDInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null',
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type BestPractices = {
  __typename: 'BestPractices';
  id: string;
  headLine: string;
  description: string;
  urlPath: string;
  active: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  userProfileBestPracticesId?: string | null;
};

export type DeleteBrandProfileInput = {
  id: string;
};

export type ModelBrandProfileConditionInput = {
  name?: ModelStringInput | null;
  toneVoice?: ModelStringInput | null;
  pillars?: ModelStringInput | null;
  description?: ModelStringInput | null;
  internalMission?: ModelStringInput | null;
  strapLine?: ModelStringInput | null;
  userEmail?: ModelStringInput | null;
  tiktokHandle?: ModelStringInput | null;
  vertical?: ModelStringInput | null;
  metaData?: ModelStringInput | null;
  hashtags?: ModelStringInput | null;
  personalDescription?: ModelStringInput | null;
  and?: Array<ModelBrandProfileConditionInput | null> | null;
  or?: Array<ModelBrandProfileConditionInput | null> | null;
  not?: ModelBrandProfileConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  userProfileBrandId?: ModelIDInput | null;
  owner?: ModelStringInput | null;
};

export type BrandProfile = {
  __typename: 'BrandProfile';
  id: string;
  name?: string | null;
  toneVoice?: Array<string> | null;
  pillars?: Array<string> | null;
  description?: string | null;
  internalMission?: string | null;
  strapLine?: string | null;
  userEmail?: string | null;
  tiktokHandle?: string | null;
  vertical?: string | null;
  metaData?: string | null;
  userProfile?: UserProfile | null;
  briefs?: ModelBrandBriefConnection | null;
  hashtags?: Array<string> | null;
  personalDescription?: string | null;
  createdAt: string;
  updatedAt: string;
  userProfileBrandId?: string | null;
  owner?: string | null;
};

export type UserProfile = {
  __typename: 'UserProfile';
  id: string;
  uniqueId?: string | null;
  name: string;
  description?: string | null;
  brand?: ModelBrandProfileConnection | null;
  owner?: string | null;
  userType?: USER_TYPES | null;
  tiktokHandler?: string | null;
  instagramHandler?: string | null;
  youtubeHandler?: string | null;
  instagram_username?: string | null;
  tiktok_username?: string | null;
  instagram_followers_count?: number | null;
  instagram_media_count?: number | null;
  tiktok_follower_count?: number | null;
  tiktok_video_count?: number | null;
  bestPractices?: ModelBestPracticesConnection | null;
  tiktokAccountAccess?: TiktokAccountAccess | null;
  facebookAccountAccess?: FacebookAccountAccess | null;
  youtubeAccountAccess?: YoutubeAccountAccess | null;
  userPaymentDetails?: UserPaymentDetails | null;
  userWallet?: UserWallet | null;
  lastLoginDate?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  country?: string | null;
  hashtags?: Array<string> | null;
  profileContent?: Array<string> | null;
  avatar?: string | null;
  vertical?: string | null;
  termsAndConditions?: boolean | null;
  isNotified?: boolean | null;
  isValid?: boolean | null;
  hubProgress?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelBrandProfileConnection = {
  __typename: 'ModelBrandProfileConnection';
  items: Array<BrandProfile | null>;
  nextToken?: string | null;
};

export enum USER_TYPES {
  CREATIVE_USER = 'CREATIVE_USER',
  BRAND_USER = 'BRAND_USER',
  ADMIN_USER = 'ADMIN_USER',
  BRAND_VIEW = 'BRAND_VIEW',
}

export type ModelBestPracticesConnection = {
  __typename: 'ModelBestPracticesConnection';
  items: Array<BestPractices | null>;
  nextToken?: string | null;
};

export type TiktokAccountAccess = {
  __typename: 'TiktokAccountAccess';
  access_token?: string | null;
  advertiser_id?: string | null;
  advertisers_list?: Array<AdvertiserData> | null;
};

export type AdvertiserData = {
  __typename: 'AdvertiserData';
  advertiser_id?: string | null;
  advertiser_name?: string | null;
};

export type FacebookAccountAccess = {
  __typename: 'FacebookAccountAccess';
  access_token?: string | null;
  advertiser_id?: string | null;
  advertisers_list?: Array<AdvertiserData> | null;
};

export type YoutubeAccountAccess = {
  __typename: 'YoutubeAccountAccess';
  access_token?: string | null;
  advertiser_id?: string | null;
  advertisers_list?: Array<AdvertiserData> | null;
};

export type UserPaymentDetails = {
  __typename: 'UserPaymentDetails';
  id: string;
  fullName: string;
  firstAddress: string;
  secondAddress?: string | null;
  country: string;
  accountNumber: string;
  postCode?: string | null;
  swiftCode: string;
  documentID?: string | null;
  owner?: string | null;
  userTransactions?: ModelUserTransactionsConnection | null;
  confirmationLetter?: string | null;
  proofOfAddress?: string | null;
  branchCode?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelUserTransactionsConnection = {
  __typename: 'ModelUserTransactionsConnection';
  items: Array<UserTransactions | null>;
  nextToken?: string | null;
};

export type UserTransactions = {
  __typename: 'UserTransactions';
  id: string;
  paymentStatus?: PAYMENT_STATUS | null;
  paymentAmount?: number | null;
  paymentAmountZar?: number | null;
  userProfileId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  userPaymentDetailsUserTransactionsId?: string | null;
};

export enum PAYMENT_STATUS {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export type UserWallet = {
  __typename: 'UserWallet';
  id: string;
  currentBalance?: number | null;
  currentBalanceZar?: number | null;
  totalEarned?: number | null;
  totalEarnedZar?: number | null;
  extraBalance?: number | null;
  extraBalanceZar?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  owner?: string | null;
};

export type ModelBrandBriefConnection = {
  __typename: 'ModelBrandBriefConnection';
  items: Array<BrandBrief | null>;
  nextToken?: string | null;
};

export type BrandBrief = {
  __typename: 'BrandBrief';
  id: string;
  BriefName: string;
  TargetAudience?: string | null;
  vertical: string;
  objective: string;
  brandBriefDetails: string;
  brandBriefFilesUrl?: string | null;
  creativeInspirations?: Array<string> | null;
  active: boolean;
  brandName?: string | null;
  brandImageUrl?: string | null;
  tiktokAdvertiserId?: string | null;
  creativeRequests?: ModelCreativeRequestConnection | null;
  brandId: string;
  brandProfile?: BrandProfile | null;
  adText: string;
  country: string;
  type?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  tikTokData: tikTokData;
  metaData: metaData;
  youtubeData: youtubeData;
  manualData: manualData;
  whitelist?: Array<WhitelistEntry> | null;
  creativeRequestsCount?: number | null;
  isCreationNotificationSent?: boolean | null;
  messaging?: string | null;
  goodPractices?: Array<string> | null;
  badPractices?: Array<string> | null;
  tags?: Array<string> | null;
  overview?: string | null;
  overviewVideoUrl?: string | null;
  tone?: string | null;
  isCompleted?: boolean | null;
  interestedUsers?: Array<InterestedUser> | null;
  commonRejectionReasons?: Array<string> | null;
  maybes?: Array<string> | null;
  owner?: string | null;
};

export type ModelCreativeRequestConnection = {
  __typename: 'ModelCreativeRequestConnection';
  items: Array<CreativeRequest | null>;
  nextToken?: string | null;
};

export type CreativeRequest = {
  __typename: 'CreativeRequest';
  id: string;
  brandBriefId: string;
  creatorId: string;
  creatorProfile?: UserProfile | null;
  brief?: BrandBrief | null;
  lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
  brandProfileImageUrl?: string | null;
  briefDescription?: string | null;
  ad_id?: string | null;
  status: CREATIVE_STATUS;
  tiktokCreativeUrl: string;
  creativePreviewUrl?: string | null;
  tiktokVideoCode: string;
  creativeTiktokHandle?: string | null;
  creativeYoutubeHandle?: string | null;
  creativeInstagramHandle?: string | null;
  approvedAds?: ModelApprovedAdsConnection | null;
  brandComment?: Array<string> | null;
  adminComment?: Array<string> | null;
  creatorComment?: Array<string> | null;
  adminApproval?: ADMIN_STATUS | null;
  creatorVisibility?: CREATOR_VISIBILITY | null;
  BriefName?: string | null;
  type?: string | null;
  email?: string | null;
  uniqueId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  brandName?: string | null;
  approvals?: ModelCreativeRequestApprovalConnection | null;
  owner?: string | null;
  briefName?:string | null
};

export enum CREATIVE_REQUEST_LAST_UPDATED_BY {
  ADMIN = 'ADMIN',
  CREATOR = 'CREATOR',
}

export enum CREATIVE_STATUS {
  New = 'New',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export type ModelApprovedAdsConnection = {
  __typename: 'ModelApprovedAdsConnection';
  items: Array<ApprovedAds | null>;
  nextToken?: string | null;
};

export type ApprovedAds = {
  __typename: 'ApprovedAds';
  id: string;
  creativeRequestId: string;
  creativeRequest?: CreativeRequest | null;
  identity_id?: string | null;
  item_id?: string | null;
  ad_id?: string | null;
  ad_group_id?: string | null;
  campaing_id?: string | null;
  advertiser_id?: string | null;
  user_profile_id?: string | null;
  accessToken?: string | null;
  ad_report?: string | null;
  ad_comment?: string | null;
  status?: string | null;
  adName?: string | null;
  approvedAdType?: ApprovedAdType | null;
  owner?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isManuallyLinked?: boolean | null;
};

export enum ADMIN_STATUS {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Revision = 'Revision',
}

export enum CREATOR_VISIBILITY {
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export type ModelCreativeRequestApprovalConnection = {
  __typename: 'ModelCreativeRequestApprovalConnection';
  items: Array<CreativeRequestApproval | null>;
  nextToken?: string | null;
};

export type CreativeRequestApproval = {
  __typename: 'CreativeRequestApproval';
  id: string;
  creativeRequestId: string;
  approverEmail: string;
  approvalStatus?: ApprovalStatus | null;
  reason?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
};

export enum ApprovalStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Awaiting = 'Awaiting',
}

export type tikTokData = {
  __typename: 'tikTokData';
  adIdentityId?: string | null;
  adgroupId?: string | null;
  campaignId?: string | null;
  adCaption?: string | null;
  displayName?: string | null;
  tikTokSparkAds?: boolean | null;
  callToAction?: string | null;
  landingPageUrl?: string | null;
};

export type metaData = {
  __typename: 'metaData';
  adgroupId?: string | null;
  campaignId?: string | null;
  adCaption?: string | null;
  callToAction?: string | null;
  landingPageUrl?: string | null;
};

export type youtubeData = {
  __typename: 'youtubeData';
  adgroupId?: string | null;
  campaignId?: string | null;
  adCaption?: string | null;
  callToAction?: string | null;
  landingPageUrl?: string | null;
};

export type manualData = {
  __typename: 'manualData';
  isManual: boolean;
  adCaption?: string | null;
};

export type WhitelistEntry = {
  __typename: 'WhitelistEntry';
  email: string;
  isApprover: boolean;
};

export type InterestedUser = {
  __typename: 'InterestedUser';
  id: string;
  isInterested: boolean;
  hasBeenShown: boolean;
};

export type CreateCreativeRequestApprovalInput = {
  id?: string | null;
  creativeRequestId: string;
  approverEmail: string;
  approvalStatus?: ApprovalStatus | null;
  reason?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
};

export type ModelCreativeRequestApprovalConditionInput = {
  creativeRequestId?: ModelIDInput | null;
  approverEmail?: ModelStringInput | null;
  approvalStatus?: ModelApprovalStatusInput | null;
  reason?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCreativeRequestApprovalConditionInput | null> | null;
  or?: Array<ModelCreativeRequestApprovalConditionInput | null> | null;
  not?: ModelCreativeRequestApprovalConditionInput | null;
};

export type ModelApprovalStatusInput = {
  eq?: ApprovalStatus | null;
  ne?: ApprovalStatus | null;
};

export type UpdateCreativeRequestApprovalInput = {
  id: string;
  creativeRequestId?: string | null;
  approverEmail?: string | null;
  approvalStatus?: ApprovalStatus | null;
  reason?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
};

export type DeleteCreativeRequestApprovalInput = {
  id: string;
};

export type CreateUserProfileInput = {
  id?: string | null;
  uniqueId?: string | null;
  name: string;
  description?: string | null;
  owner?: string | null;
  userType?: USER_TYPES | null;
  tiktokHandler?: string | null;
  instagramHandler?: string | null;
  youtubeHandler?: string | null;
  instagram_username?: string | null;
  tiktok_username?: string | null;
  instagram_followers_count?: number | null;
  instagram_media_count?: number | null;
  tiktok_follower_count?: number | null;
  tiktok_video_count?: number | null;
  tiktokAccountAccess?: TiktokAccountAccessInput | null;
  facebookAccountAccess?: FacebookAccountAccessInput | null;
  youtubeAccountAccess?: YoutubeAccountAccessInput | null;
  lastLoginDate?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  country?: string | null;
  hashtags?: Array<string> | null;
  profileContent?: Array<string> | null;
  avatar?: string | null;
  vertical?: string | null;
  termsAndConditions?: boolean | null;
  isNotified?: boolean | null;
  isValid?: boolean | null;
  hubProgress?: string | null;
};

export type TiktokAccountAccessInput = {
  access_token?: string | null;
  advertiser_id?: string | null;
  advertisers_list?: Array<AdvertiserDataInput> | null;
};

export type AdvertiserDataInput = {
  advertiser_id?: string | null;
  advertiser_name?: string | null;
};

export type FacebookAccountAccessInput = {
  access_token?: string | null;
  advertiser_id?: string | null;
  advertisers_list?: Array<AdvertiserDataInput> | null;
};

export type YoutubeAccountAccessInput = {
  access_token?: string | null;
  advertiser_id?: string | null;
  advertisers_list?: Array<AdvertiserDataInput> | null;
};

export type ModelUserProfileConditionInput = {
  uniqueId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  userType?: ModelUSER_TYPESInput | null;
  tiktokHandler?: ModelStringInput | null;
  instagramHandler?: ModelStringInput | null;
  youtubeHandler?: ModelStringInput | null;
  instagram_username?: ModelStringInput | null;
  tiktok_username?: ModelStringInput | null;
  instagram_followers_count?: ModelIntInput | null;
  instagram_media_count?: ModelIntInput | null;
  tiktok_follower_count?: ModelIntInput | null;
  tiktok_video_count?: ModelIntInput | null;
  lastLoginDate?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phoneNumber?: ModelStringInput | null;
  country?: ModelStringInput | null;
  hashtags?: ModelStringInput | null;
  profileContent?: ModelStringInput | null;
  avatar?: ModelStringInput | null;
  vertical?: ModelStringInput | null;
  termsAndConditions?: ModelBooleanInput | null;
  isNotified?: ModelBooleanInput | null;
  isValid?: ModelBooleanInput | null;
  hubProgress?: ModelStringInput | null;
  and?: Array<ModelUserProfileConditionInput | null> | null;
  or?: Array<ModelUserProfileConditionInput | null> | null;
  not?: ModelUserProfileConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type ModelUSER_TYPESInput = {
  eq?: USER_TYPES | null;
  ne?: USER_TYPES | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateUserProfileInput = {
  id: string;
  uniqueId?: string | null;
  name?: string | null;
  description?: string | null;
  owner?: string | null;
  userType?: USER_TYPES | null;
  tiktokHandler?: string | null;
  instagramHandler?: string | null;
  youtubeHandler?: string | null;
  instagram_username?: string | null;
  tiktok_username?: string | null;
  instagram_followers_count?: number | null;
  instagram_media_count?: number | null;
  tiktok_follower_count?: number | null;
  tiktok_video_count?: number | null;
  tiktokAccountAccess?: TiktokAccountAccessInput | null;
  facebookAccountAccess?: FacebookAccountAccessInput | null;
  youtubeAccountAccess?: YoutubeAccountAccessInput | null;
  lastLoginDate?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  country?: string | null;
  hashtags?: Array<string> | null;
  profileContent?: Array<string> | null;
  avatar?: string | null;
  vertical?: string | null;
  termsAndConditions?: boolean | null;
  isNotified?: boolean | null;
  isValid?: boolean | null;
  hubProgress?: string | null;
};

export type DeleteUserProfileInput = {
  id: string;
};

export type CreateUserPaymentDetailsInput = {
  id?: string | null;
  fullName: string;
  firstAddress: string;
  secondAddress?: string | null;
  country: string;
  accountNumber: string;
  postCode?: string | null;
  swiftCode: string;
  documentID?: string | null;
  owner?: string | null;
  confirmationLetter?: string | null;
  proofOfAddress?: string | null;
  branchCode?: string | null;
};

export type ModelUserPaymentDetailsConditionInput = {
  fullName?: ModelStringInput | null;
  firstAddress?: ModelStringInput | null;
  secondAddress?: ModelStringInput | null;
  country?: ModelStringInput | null;
  accountNumber?: ModelStringInput | null;
  postCode?: ModelStringInput | null;
  swiftCode?: ModelStringInput | null;
  documentID?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  confirmationLetter?: ModelStringInput | null;
  proofOfAddress?: ModelStringInput | null;
  branchCode?: ModelStringInput | null;
  and?: Array<ModelUserPaymentDetailsConditionInput | null> | null;
  or?: Array<ModelUserPaymentDetailsConditionInput | null> | null;
  not?: ModelUserPaymentDetailsConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type UpdateUserPaymentDetailsInput = {
  id: string;
  fullName?: string | null;
  firstAddress?: string | null;
  secondAddress?: string | null;
  country?: string | null;
  accountNumber?: string | null;
  postCode?: string | null;
  swiftCode?: string | null;
  documentID?: string | null;
  owner?: string | null;
  confirmationLetter?: string | null;
  proofOfAddress?: string | null;
  branchCode?: string | null;
};

export type DeleteUserPaymentDetailsInput = {
  id: string;
};

export type CreateUserWalletInput = {
  id?: string | null;
  currentBalance?: number | null;
  currentBalanceZar?: number | null;
  totalEarned?: number | null;
  totalEarnedZar?: number | null;
  extraBalance?: number | null;
  extraBalanceZar?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  owner?: string | null;
};

export type ModelUserWalletConditionInput = {
  currentBalance?: ModelFloatInput | null;
  currentBalanceZar?: ModelFloatInput | null;
  totalEarned?: ModelFloatInput | null;
  totalEarnedZar?: ModelFloatInput | null;
  extraBalance?: ModelFloatInput | null;
  extraBalanceZar?: ModelFloatInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelUserWalletConditionInput | null> | null;
  or?: Array<ModelUserWalletConditionInput | null> | null;
  not?: ModelUserWalletConditionInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateUserWalletInput = {
  id: string;
  currentBalance?: number | null;
  currentBalanceZar?: number | null;
  totalEarned?: number | null;
  totalEarnedZar?: number | null;
  extraBalance?: number | null;
  extraBalanceZar?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  owner?: string | null;
};

export type DeleteUserWalletInput = {
  id: string;
};

export type CreateUserTransactionsInput = {
  id?: string | null;
  paymentStatus?: PAYMENT_STATUS | null;
  paymentAmount?: number | null;
  paymentAmountZar?: number | null;
  userProfileId?: string | null;
  owner?: string | null;
  userPaymentDetailsUserTransactionsId?: string | null;
};

export type ModelUserTransactionsConditionInput = {
  paymentStatus?: ModelPAYMENT_STATUSInput | null;
  paymentAmount?: ModelFloatInput | null;
  paymentAmountZar?: ModelFloatInput | null;
  userProfileId?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelUserTransactionsConditionInput | null> | null;
  or?: Array<ModelUserTransactionsConditionInput | null> | null;
  not?: ModelUserTransactionsConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  userPaymentDetailsUserTransactionsId?: ModelIDInput | null;
};

export type ModelPAYMENT_STATUSInput = {
  eq?: PAYMENT_STATUS | null;
  ne?: PAYMENT_STATUS | null;
};

export type UpdateUserTransactionsInput = {
  id: string;
  paymentStatus?: PAYMENT_STATUS | null;
  paymentAmount?: number | null;
  paymentAmountZar?: number | null;
  userProfileId?: string | null;
  owner?: string | null;
  userPaymentDetailsUserTransactionsId?: string | null;
};

export type DeleteUserTransactionsInput = {
  id: string;
};

export type CreateApprovedAdsInput = {
  id?: string | null;
  creativeRequestId: string;
  identity_id?: string | null;
  item_id?: string | null;
  ad_id?: string | null;
  ad_group_id?: string | null;
  campaing_id?: string | null;
  advertiser_id?: string | null;
  user_profile_id?: string | null;
  accessToken?: string | null;
  ad_report?: string | null;
  ad_comment?: string | null;
  status?: string | null;
  adName?: string | null;
  approvedAdType?: ApprovedAdType | null;
  owner?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isManuallyLinked?: boolean | null;
};

export type ModelApprovedAdsConditionInput = {
  creativeRequestId?: ModelIDInput | null;
  identity_id?: ModelStringInput | null;
  item_id?: ModelStringInput | null;
  ad_id?: ModelStringInput | null;
  ad_group_id?: ModelStringInput | null;
  campaing_id?: ModelStringInput | null;
  advertiser_id?: ModelStringInput | null;
  user_profile_id?: ModelStringInput | null;
  accessToken?: ModelStringInput | null;
  ad_report?: ModelStringInput | null;
  ad_comment?: ModelStringInput | null;
  status?: ModelStringInput | null;
  adName?: ModelStringInput | null;
  approvedAdType?: ModelApprovedAdTypeInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  isManuallyLinked?: ModelBooleanInput | null;
  and?: Array<ModelApprovedAdsConditionInput | null> | null;
  or?: Array<ModelApprovedAdsConditionInput | null> | null;
  not?: ModelApprovedAdsConditionInput | null;
};

export type ModelApprovedAdTypeInput = {
  eq?: ApprovedAdType | null;
  ne?: ApprovedAdType | null;
};

export type UpdateApprovedAdsInput = {
  id: string;
  creativeRequestId?: string | null;
  identity_id?: string | null;
  item_id?: string | null;
  ad_id?: string | null;
  ad_group_id?: string | null;
  campaing_id?: string | null;
  advertiser_id?: string | null;
  user_profile_id?: string | null;
  accessToken?: string | null;
  ad_report?: string | null;
  ad_comment?: string | null;
  status?: string | null;
  adName?: string | null;
  approvedAdType?: ApprovedAdType | null;
  owner?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isManuallyLinked?: boolean | null;
};

export type DeleteApprovedAdsInput = {
  id: string;
};

export type CreateBestPracticesInput = {
  id?: string | null;
  headLine: string;
  description: string;
  urlPath: string;
  active: string;
  owner?: string | null;
  userProfileBestPracticesId?: string | null;
};

export type UpdateBestPracticesInput = {
  id: string;
  headLine?: string | null;
  description?: string | null;
  urlPath?: string | null;
  active?: string | null;
  owner?: string | null;
  userProfileBestPracticesId?: string | null;
};

export type CreateBrandProfileInput = {
  id?: string | null;
  name?: string | null;
  toneVoice?: Array<string> | null;
  pillars?: Array<string> | null;
  description?: string | null;
  internalMission?: string | null;
  strapLine?: string | null;
  userEmail?: string | null;
  tiktokHandle?: string | null;
  vertical?: string | null;
  metaData?: string | null;
  hashtags?: Array<string> | null;
  personalDescription?: string | null;
  userProfileBrandId?: string | null;
};

export type UpdateBrandProfileInput = {
  id: string;
  name?: string | null;
  toneVoice?: Array<string> | null;
  pillars?: Array<string> | null;
  description?: string | null;
  internalMission?: string | null;
  strapLine?: string | null;
  userEmail?: string | null;
  tiktokHandle?: string | null;
  vertical?: string | null;
  metaData?: string | null;
  hashtags?: Array<string> | null;
  personalDescription?: string | null;
  userProfileBrandId?: string | null;
};

export type CreateBrandBriefInput = {
  id?: string | null;
  BriefName: string;
  TargetAudience?: string | null;
  vertical: string;
  objective: string;
  brandBriefDetails: string;
  brandBriefFilesUrl?: string | null;
  creativeInspirations?: Array<string> | null;
  active: boolean;
  brandName?: string | null;
  brandImageUrl?: string | null;
  tiktokAdvertiserId?: string | null;
  brandId: string;
  adText: string;
  country: string;
  type?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  tikTokData: TikTokDataInput;
  metaData: MetaDataInput;
  youtubeData: YoutubeDataInput;
  manualData: ManualDataInput;
  whitelist?: Array<WhitelistEntryInput> | null;
  creativeRequestsCount?: number | null;
  isCreationNotificationSent?: boolean | null;
  messaging?: string | null;
  goodPractices?: Array<string> | null;
  badPractices?: Array<string> | null;
  tags?: Array<string> | null;
  overview?: string | null;
  overviewVideoUrl?: string | null;
  tone?: string | null;
  isCompleted?: boolean | null;
  interestedUsers?: Array<InterestedUserInput> | null;
  commonRejectionReasons?: Array<string> | null;
  maybes?: Array<string> | null;
};

export type TikTokDataInput = {
  adIdentityId?: string | null;
  adgroupId?: string | null;
  campaignId?: string | null;
  adCaption?: string | null;
  displayName?: string | null;
  tikTokSparkAds?: boolean | null;
  callToAction?: string | null;
  landingPageUrl?: string | null;
};

export type MetaDataInput = {
  adgroupId?: string | null;
  campaignId?: string | null;
  adCaption?: string | null;
  callToAction?: string | null;
  landingPageUrl?: string | null;
};

export type YoutubeDataInput = {
  adgroupId?: string | null;
  campaignId?: string | null;
  adCaption?: string | null;
  callToAction?: string | null;
  landingPageUrl?: string | null;
};

export type ManualDataInput = {
  isManual: boolean;
  adCaption?: string | null;
};

export type WhitelistEntryInput = {
  email: string;
  isApprover: boolean;
};

export type InterestedUserInput = {
  id: string;
  isInterested: boolean;
  hasBeenShown: boolean;
};

export type ModelBrandBriefConditionInput = {
  BriefName?: ModelStringInput | null;
  TargetAudience?: ModelStringInput | null;
  vertical?: ModelStringInput | null;
  objective?: ModelStringInput | null;
  brandBriefDetails?: ModelStringInput | null;
  brandBriefFilesUrl?: ModelStringInput | null;
  creativeInspirations?: ModelStringInput | null;
  active?: ModelBooleanInput | null;
  brandName?: ModelStringInput | null;
  brandImageUrl?: ModelStringInput | null;
  tiktokAdvertiserId?: ModelStringInput | null;
  brandId?: ModelIDInput | null;
  adText?: ModelStringInput | null;
  country?: ModelStringInput | null;
  type?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  creativeRequestsCount?: ModelIntInput | null;
  isCreationNotificationSent?: ModelBooleanInput | null;
  messaging?: ModelStringInput | null;
  goodPractices?: ModelStringInput | null;
  badPractices?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  overview?: ModelStringInput | null;
  overviewVideoUrl?: ModelStringInput | null;
  tone?: ModelStringInput | null;
  isCompleted?: ModelBooleanInput | null;
  commonRejectionReasons?: ModelStringInput | null;
  maybes?: ModelStringInput | null;
  and?: Array<ModelBrandBriefConditionInput | null> | null;
  or?: Array<ModelBrandBriefConditionInput | null> | null;
  not?: ModelBrandBriefConditionInput | null;
  owner?: ModelStringInput | null;
};

export type UpdateBrandBriefInput = {
  id: string;
  BriefName?: string | null;
  TargetAudience?: string | null;
  vertical?: string | null;
  objective?: string | null;
  brandBriefDetails?: string | null;
  brandBriefFilesUrl?: string | null;
  creativeInspirations?: Array<string> | null;
  active?: boolean | null;
  brandName?: string | null;
  brandImageUrl?: string | null;
  tiktokAdvertiserId?: string | null;
  brandId?: string | null;
  adText?: string | null;
  country?: string | null;
  type?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  tikTokData?: TikTokDataInput | null;
  metaData?: MetaDataInput | null;
  youtubeData?: YoutubeDataInput | null;
  manualData?: ManualDataInput | null;
  whitelist?: Array<WhitelistEntryInput> | null;
  creativeRequestsCount?: number | null;
  isCreationNotificationSent?: boolean | null;
  messaging?: string | null;
  goodPractices?: Array<string> | null;
  badPractices?: Array<string> | null;
  tags?: Array<string> | null;
  overview?: string | null;
  overviewVideoUrl?: string | null;
  tone?: string | null;
  isCompleted?: boolean | null;
  interestedUsers?: Array<InterestedUserInput> | null;
  commonRejectionReasons?: Array<string> | null;
  maybes?: Array<string> | null;
};

export type DeleteBrandBriefInput = {
  id: string;
};

export type CreateCreativeRequestInput = {
  id?: string | null;
  brandBriefId: string;
  creatorId: string;
  lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
  brandProfileImageUrl?: string | null;
  briefDescription?: string | null;
  ad_id?: string | null;
  status: CREATIVE_STATUS;
  tiktokCreativeUrl: string;
  creativePreviewUrl?: string | null;
  tiktokVideoCode: string;
  creativeTiktokHandle?: string | null;
  creativeYoutubeHandle?: string | null;
  creativeInstagramHandle?: string | null;
  brandComment?: Array<string> | null;
  adminComment?: Array<string> | null;
  creatorComment?: Array<string> | null;
  adminApproval?: ADMIN_STATUS | null;
  creatorVisibility?: CREATOR_VISIBILITY | null;
  BriefName?: string | null;
  type?: string | null;
  email?: string | null;
  uniqueId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  brandName?: string | null;
};

export type ModelCreativeRequestConditionInput = {
  brandBriefId?: ModelIDInput | null;
  creatorId?: ModelIDInput | null;
  lastUpdatedBy?: ModelCREATIVE_REQUEST_LAST_UPDATED_BYInput | null;
  brandProfileImageUrl?: ModelStringInput | null;
  briefDescription?: ModelStringInput | null;
  ad_id?: ModelStringInput | null;
  status?: ModelCREATIVE_STATUSInput | null;
  tiktokCreativeUrl?: ModelStringInput | null;
  creativePreviewUrl?: ModelStringInput | null;
  tiktokVideoCode?: ModelStringInput | null;
  creativeTiktokHandle?: ModelStringInput | null;
  creativeYoutubeHandle?: ModelStringInput | null;
  creativeInstagramHandle?: ModelStringInput | null;
  brandComment?: ModelStringInput | null;
  adminComment?: ModelStringInput | null;
  creatorComment?: ModelStringInput | null;
  adminApproval?: ModelADMIN_STATUSInput | null;
  creatorVisibility?: ModelCREATOR_VISIBILITYInput | null;
  BriefName?: ModelStringInput | null;
  type?: ModelStringInput | null;
  email?: ModelStringInput | null;
  uniqueId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  brandName?: ModelStringInput | null;
  and?: Array<ModelCreativeRequestConditionInput | null> | null;
  or?: Array<ModelCreativeRequestConditionInput | null> | null;
  not?: ModelCreativeRequestConditionInput | null;
  owner?: ModelStringInput | null;
};

export type ModelCREATIVE_REQUEST_LAST_UPDATED_BYInput = {
  eq?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
  ne?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
};

export type ModelCREATIVE_STATUSInput = {
  eq?: CREATIVE_STATUS | null;
  ne?: CREATIVE_STATUS | null;
};

export type ModelADMIN_STATUSInput = {
  eq?: ADMIN_STATUS | null;
  ne?: ADMIN_STATUS | null;
};

export type ModelCREATOR_VISIBILITYInput = {
  eq?: CREATOR_VISIBILITY | null;
  ne?: CREATOR_VISIBILITY | null;
};

export type UpdateCreativeRequestInput = {
  id: string;
  brandBriefId?: string | null;
  creatorId?: string | null;
  lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
  brandProfileImageUrl?: string | null;
  briefDescription?: string | null;
  ad_id?: string | null;
  status?: CREATIVE_STATUS | null;
  tiktokCreativeUrl?: string | null;
  creativePreviewUrl?: string | null;
  tiktokVideoCode?: string | null;
  creativeTiktokHandle?: string | null;
  creativeYoutubeHandle?: string | null;
  creativeInstagramHandle?: string | null;
  brandComment?: Array<string> | null;
  adminComment?: Array<string> | null;
  creatorComment?: Array<string> | null;
  adminApproval?: ADMIN_STATUS | null;
  creatorVisibility?: CREATOR_VISIBILITY | null;
  BriefName?: string | null;
  type?: string | null;
  email?: string | null;
  uniqueId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  brandName?: string | null;
};

export type DeleteCreativeRequestInput = {
  id: string;
};

export type CreateCreativeRequestEarningsInput = {
  creativeRequestEarningId: string;
  creativeRequestId: string;
  creatorId: string;
  earningType: EARNING_TYPE;
  amount: number;
  creativeUniqueId: string;
  currentEarnings?: number | null;
  month: string;
  toDate: string;
  fromDate: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  importedAt?: string | null;
};

export type ModelCreativeRequestEarningsConditionInput = {
  creativeRequestId?: ModelIDInput | null;
  earningType?: ModelEARNING_TYPEInput | null;
  amount?: ModelFloatInput | null;
  creativeUniqueId?: ModelStringInput | null;
  currentEarnings?: ModelFloatInput | null;
  month?: ModelStringInput | null;
  toDate?: ModelStringInput | null;
  fromDate?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  importedAt?: ModelStringInput | null;
  and?: Array<ModelCreativeRequestEarningsConditionInput | null> | null;
  or?: Array<ModelCreativeRequestEarningsConditionInput | null> | null;
  not?: ModelCreativeRequestEarningsConditionInput | null;
};

export type ModelEARNING_TYPEInput = {
  eq?: EARNING_TYPE | null;
  ne?: EARNING_TYPE | null;
};

export type UpdateCreativeRequestEarningsInput = {
  creativeRequestEarningId: string;
  creativeRequestId?: string | null;
  creatorId: string;
  earningType?: EARNING_TYPE | null;
  amount?: number | null;
  creativeUniqueId?: string | null;
  currentEarnings?: number | null;
  month?: string | null;
  toDate?: string | null;
  fromDate?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  importedAt?: string | null;
};

export type DeleteCreativeRequestEarningsInput = {
  creatorId: string;
  creativeRequestEarningId: string;
};

export type GET_CREATOR_BRAND_BRIEFS_RESPONSE = {
  __typename: 'GET_CREATOR_BRAND_BRIEFS_RESPONSE';
  items?: Array<BRAND_BREIFS_WITH_BRAND_PROFILE> | null;
  totalPages: number;
  currentPage: number;
};

export type BRAND_BREIFS_WITH_BRAND_PROFILE = {
  __typename: 'BRAND_BREIFS_WITH_BRAND_PROFILE';
  id: string;
  BriefName: string;
  vertical: string;
  objective: string;
  brandBriefDetails: string;
  brandBriefFilesUrl?: string | null;
  creativeInspirations?: Array<string> | null;
  active: boolean;
  brandImageUrl?: string | null;
  tiktokAdvertiserId?: string | null;
  brandId: string;
  adText: string;
  country: string;
  type?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  tikTokData?: tikTokData | null;
  metaData?: metaData | null;
  youtubeData?: youtubeData | null;
  manualData?: manualData | null;
  creativeRequestsCount?: number | null;
  brandInfo?: BrandProfile | null;
  interestedUsers?: Array<InterestedUser | null> | null;
};

export enum EMAIL_TYPE {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REVISION = 'REVISION',
  ADMIN_APPROVED = 'ADMIN_APPROVED',
}

export type GET_CREATIVE_REQUESTS_RESPONSE = {
  __typename: 'GET_CREATIVE_REQUESTS_RESPONSE';
  items?: Array<CREATIVE_REQUEST_RESPONSE> | null;
  totalPages: number;
  totalItems: number;
  currentPage: number;
};

export type CREATIVE_REQUEST_RESPONSE = {
  __typename: 'CREATIVE_REQUEST_RESPONSE';
  id: string;
  brandBriefId: string;
  creatorId: string;
  briefDescription?: string | null;
  creatorProfileImage?: string | null;
  ad_id?: string | null;
  status: CREATIVE_STATUS;
  tiktokCreativeUrl: string;
  creativePreviewUrl?: string | null;
  tiktokVideoCode: string;
  creativeTiktokHandle?: string | null;
  creativeYoutubeHandle?: string | null;
  creativeInstagramHandle?: string | null;
  creatorDescription?: string | null;
  creatorName?: string | null;
  creatorImage?: string | null;
  approvedAds?: Array<ApprovedAds | null> | null;
  brandComment?: Array<string> | null;
  adminComment?: Array<string> | null;
  creatorComment?: Array<string> | null;
  adminApproval?: ADMIN_STATUS | null;
  creatorVisibility?: CREATOR_VISIBILITY | null;
  BriefName?: string | null;
  type?: string | null;
  email?: string | null;
  uniqueId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  brandName?: string | null;
  brief: BrandBrief;
};

export type GET_BRAND_BRIEFS_RESPONSE = {
  __typename: 'GET_BRAND_BRIEFS_RESPONSE';
  items?: Array<BrandBrief> | null;
  totalPages: number;
  totalItems: number;
  currentPage: number;
};

export type GET_REQUESTS_BY_CREATOR_RESPONSE = {
  __typename: 'GET_REQUESTS_BY_CREATOR_RESPONSE';
  items?: Array<CREATIVE_REQUESTS_WITH_BRAND_USER_PROFILE> | null;
  totalPages: number;
  currentPage: number;
};

export type CREATIVE_REQUESTS_WITH_BRAND_USER_PROFILE = {
  __typename: 'CREATIVE_REQUESTS_WITH_BRAND_USER_PROFILE';
  id: string;
  brandBriefId: string;
  creatorId: string;
  briefDescription?: string | null;
  creatorProfileImage?: string | null;
  ad_id?: string | null;
  status: CREATIVE_STATUS;
  tiktokCreativeUrl: string;
  creativePreviewUrl?: string | null;
  tiktokVideoCode: string;
  creativeTiktokHandle?: string | null;
  creativeYoutubeHandle?: string | null;
  creativeInstagramHandle?: string | null;
  creatorDescription?: string | null;
  lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
  creatorName?: string | null;
  creatorImage?: string | null;
  approvedAds?: Array<ApprovedAds | null> | null;
  brandComment?: Array<string> | null;
  adminComment?: Array<string> | null;
  creatorComment?: Array<string> | null;
  adminApproval?: ADMIN_STATUS | null;
  creatorVisibility?: CREATOR_VISIBILITY | null;
  briefName?: string | null;
  type?: string | null;
  email?: string | null;
  uniqueId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  brandName?: string | null;
  brandProfileImage?: string | null;
};

export type GPT_INPUT = {
  context?: string | null;
  prompType: GPT_PROMPT;
  businessDescription?: string | null;
  toneOfVoice?: string | null;
  brandName?: string | null;
  brandPillars?: Array<string | null> | null;
  brandMissionStatement?: string | null;
  tagLine?: string | null;
};

export enum GPT_PROMPT {
  BRAND_NAME = 'BRAND_NAME',
  BRAND_NAME_REFRESH = 'BRAND_NAME_REFRESH',
  BRAND_PILLARS = 'BRAND_PILLARS',
  BRAND_VALUES = 'BRAND_VALUES',
  BRAND_MISSION_STATEMENT = 'BRAND_MISSION_STATEMENT',
  BRAND_MISSION_STATEMENT_REFRESH = 'BRAND_MISSION_STATEMENT_REFRESH',
  BRAND_TAGLINE_STATEMENT = 'BRAND_TAGLINE_STATEMENT',
  BRAND_TAGLINE_STATEMENT_REFRESH = 'BRAND_TAGLINE_STATEMENT_REFRESH',
  BRAND_PILLARS_REFRESH = 'BRAND_PILLARS_REFRESH',
}

export type GPT_RESPONSE = {
  __typename: 'GPT_RESPONSE';
  responseType?: GPT_RESPONSE_TYPE | null;
  BRAND_NAME?: string | null;
  BRAND_NAME_REFRESH?: string | null;
  BRAND_PILLARS?: string | null;
  BRAND_PILLARS_REFRESH?: string | null;
  BRAND_VALUES?: string | null;
  BRAND_MISSION_STATEMENT?: string | null;
  BRAND_TAGLINE_STATEMENT?: string | null;
  BRAND_TAGLINE_STATEMENT_REFRESH?: string | null;
  BRAND_MISSION_STATEMENT_REFRESH?: string | null;
  error?: boolean | null;
  message?: string | null;
};

export enum GPT_RESPONSE_TYPE {
  BRAND_NAME = 'BRAND_NAME',
  BRAND_NAME_REFRESH = 'BRAND_NAME_REFRESH',
  BRAND_PILLARS = 'BRAND_PILLARS',
  BRAND_VALUES = 'BRAND_VALUES',
  BRAND_MISSION_STATEMENT = 'BRAND_MISSION_STATEMENT',
  BRAND_TAGLINE_STATEMENT = 'BRAND_TAGLINE_STATEMENT',
  BRAND_TAGLINE_STATEMENT_REFRESH = 'BRAND_TAGLINE_STATEMENT_REFRESH',
  BRAND_MISSION_STATEMENT_REFRESH = 'BRAND_MISSION_STATEMENT_REFRESH',
  BRAND_PILLARS_REFRESH = 'BRAND_PILLARS_REFRESH',
}

export type CREATIVE_REQUEST_EARNINGS_BY_CREATOR_RESPONSE = {
  __typename: 'CREATIVE_REQUEST_EARNINGS_BY_CREATOR_RESPONSE';
  items?: Array<CREATIVE_REQUEST_EARNINGS_BY_CREATOR | null> | null;
};

export type CREATIVE_REQUEST_EARNINGS_BY_CREATOR = {
  __typename: 'CREATIVE_REQUEST_EARNINGS_BY_CREATOR';
  creativeRequestId: string;
  creativeUniqueId: string;
  creatorId: string;
  creativeRequestEarningId: string;
  previousEarnings: number;
  currentEarnings: number;
  lifetimeEarnings: number;
};

export type CREATIVE_REQUEST_EARNINGS_BY_CREATIVE_RESPONSE = {
  __typename: 'CREATIVE_REQUEST_EARNINGS_BY_CREATIVE_RESPONSE';
  items?: Array<CREATIVE_REQUEST_EARNINGS_RESPONSE | null> | null;
};

export type CREATIVE_REQUEST_EARNINGS_RESPONSE = {
  __typename: 'CREATIVE_REQUEST_EARNINGS_RESPONSE';
  fromDate: string;
  updatedAt: string;
  month: string;
  createdAt: string;
  earningType: EARNING_TYPE;
  amount: number;
  creativeRequestId: string;
  creativeUniqueId: string;
  toDate: string;
  creatorId: string;
  creativeRequestEarningId: string;
};

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export type SEARCH_CREATIVE_USERS_RESPONSE = {
  __typename: 'SEARCH_CREATIVE_USERS_RESPONSE';
  totalItems: number;
  totalPages: number;
  items?: Array<SEARCH_USERS_RESPONSE> | null;
  currentPage: number;
};

export type SEARCH_USERS_RESPONSE = {
  __typename: 'SEARCH_USERS_RESPONSE';
  id: string;
  uniqueId?: string | null;
  name?: string | null;
  description?: string | null;
  owner?: string | null;
  userType?: USER_TYPES | null;
  tiktokHandler?: string | null;
  instagramHandler?: string | null;
  youtubeHandler?: string | null;
  tiktokAccountAccess?: string | null;
  facebookAccountAccess?: string | null;
  youtubeAccountAccess?: string | null;
  tiktok_follower_count?: number | null;
  tiktok_video_count?: number | null;
  instagram_followers_count?: number | null;
  instagram_media_count?: number | null;
  tiktok_username?: string | null;
  lastLoginDate?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  country?: string | null;
  hashtags?: Array<string> | null;
  isValid?: boolean | null;
  profileContent?: Array<string> | null;
  avatar?: string | null;
  vertical?: string | null;
  termsAndConditions?: boolean | null;
  userPaymentDetails?: USER_PAYMENT_DETAILS_RESPONSE | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type USER_PAYMENT_DETAILS_RESPONSE = {
  __typename: 'USER_PAYMENT_DETAILS_RESPONSE';
  id?: string | null;
  accountNumber?: string | null;
  country?: string | null;
  documentId?: string | null;
  firstAddress?: string | null;
  secondAddress?: string | null;
  fullName?: string | null;
  postCode?: string | null;
  swiftCode?: string | null;
  branchCode?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type GET_WHITELISTED_BRAND_BRIEFS_RESPONSE = {
  __typename: 'GET_WHITELISTED_BRAND_BRIEFS_RESPONSE';
  brandBriefIds?: Array<string> | null;
};

export type SEARCH_CREATIVE_REQUESTS_PAGINATED_RESPONSE = {
  __typename: 'SEARCH_CREATIVE_REQUESTS_PAGINATED_RESPONSE';
  page: number;
  pageSize: number;
  totalItems: number;
  items?: Array<SEARCH_CREATIVE_REQUESTS_RESPONSE | null> | null;
};

export type SEARCH_CREATIVE_REQUESTS_RESPONSE = {
  __typename: 'SEARCH_CREATIVE_REQUESTS_RESPONSE';
  id?: string | null;
  brandBriefId?: string | null;
  uniqueId?: string | null;
  tiktokCreativeUrl?: string | null;
  brief?: BrandBrief | null;
  approvedAds?: Array<ApprovedAds | null> | null;
  creatorId?: string | null;
  lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
  brandProfileImageUrl?: string | null;
  briefDescription?: string | null;
  ad_id?: string | null;
  status?: CREATIVE_STATUS | null;
  creativePreviewUrl?: string | null;
  tiktokVideoCode?: string | null;
  creativeTiktokHandle?: string | null;
  creativeYoutubeHandle?: string | null;
  creativeInstagramHandle?: string | null;
  brandComment?: Array<string | null> | null;
  adminComment?: Array<string | null> | null;
  creatorComment?: Array<string | null> | null;
  adminApproval?: ADMIN_STATUS | null;
  creatorVisibility?: CREATOR_VISIBILITY | null;
  BriefName?: string | null;
  type?: string | null;
  email?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  brandName?: string | null;
  creatorProfile?: SEARCH_USERS_RESPONSE | null;
};

export type USERS_SIGNUPS_COUNT_RESPONSE = {
  __typename: 'USERS_SIGNUPS_COUNT_RESPONSE';
  count: number;
};

export type ModelUserProfileFilterInput = {
  id?: ModelIDInput | null;
  uniqueId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  userType?: ModelUSER_TYPESInput | null;
  tiktokHandler?: ModelStringInput | null;
  instagramHandler?: ModelStringInput | null;
  youtubeHandler?: ModelStringInput | null;
  instagram_username?: ModelStringInput | null;
  tiktok_username?: ModelStringInput | null;
  instagram_followers_count?: ModelIntInput | null;
  instagram_media_count?: ModelIntInput | null;
  tiktok_follower_count?: ModelIntInput | null;
  tiktok_video_count?: ModelIntInput | null;
  lastLoginDate?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phoneNumber?: ModelStringInput | null;
  country?: ModelStringInput | null;
  hashtags?: ModelStringInput | null;
  profileContent?: ModelStringInput | null;
  avatar?: ModelStringInput | null;
  vertical?: ModelStringInput | null;
  termsAndConditions?: ModelBooleanInput | null;
  isNotified?: ModelBooleanInput | null;
  isValid?: ModelBooleanInput | null;
  hubProgress?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelUserProfileFilterInput | null> | null;
  or?: Array<ModelUserProfileFilterInput | null> | null;
  not?: ModelUserProfileFilterInput | null;
};

export type ModelUserProfileConnection = {
  __typename: 'ModelUserProfileConnection';
  items: Array<UserProfile | null>;
  nextToken?: string | null;
};

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ModelUserPaymentDetailsFilterInput = {
  id?: ModelIDInput | null;
  fullName?: ModelStringInput | null;
  firstAddress?: ModelStringInput | null;
  secondAddress?: ModelStringInput | null;
  country?: ModelStringInput | null;
  accountNumber?: ModelStringInput | null;
  postCode?: ModelStringInput | null;
  swiftCode?: ModelStringInput | null;
  documentID?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  confirmationLetter?: ModelStringInput | null;
  proofOfAddress?: ModelStringInput | null;
  branchCode?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelUserPaymentDetailsFilterInput | null> | null;
  or?: Array<ModelUserPaymentDetailsFilterInput | null> | null;
  not?: ModelUserPaymentDetailsFilterInput | null;
};

export type ModelUserPaymentDetailsConnection = {
  __typename: 'ModelUserPaymentDetailsConnection';
  items: Array<UserPaymentDetails | null>;
  nextToken?: string | null;
};

export type ModelUserWalletFilterInput = {
  id?: ModelIDInput | null;
  currentBalance?: ModelFloatInput | null;
  currentBalanceZar?: ModelFloatInput | null;
  totalEarned?: ModelFloatInput | null;
  totalEarnedZar?: ModelFloatInput | null;
  extraBalance?: ModelFloatInput | null;
  extraBalanceZar?: ModelFloatInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelUserWalletFilterInput | null> | null;
  or?: Array<ModelUserWalletFilterInput | null> | null;
  not?: ModelUserWalletFilterInput | null;
};

export type ModelUserWalletConnection = {
  __typename: 'ModelUserWalletConnection';
  items: Array<UserWallet | null>;
  nextToken?: string | null;
};

export type ModelUserTransactionsFilterInput = {
  id?: ModelIDInput | null;
  paymentStatus?: ModelPAYMENT_STATUSInput | null;
  paymentAmount?: ModelFloatInput | null;
  paymentAmountZar?: ModelFloatInput | null;
  userProfileId?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelUserTransactionsFilterInput | null> | null;
  or?: Array<ModelUserTransactionsFilterInput | null> | null;
  not?: ModelUserTransactionsFilterInput | null;
  userPaymentDetailsUserTransactionsId?: ModelIDInput | null;
};

export type ModelApprovedAdsFilterInput = {
  id?: ModelIDInput | null;
  creativeRequestId?: ModelIDInput | null;
  identity_id?: ModelStringInput | null;
  item_id?: ModelStringInput | null;
  ad_id?: ModelStringInput | null;
  ad_group_id?: ModelStringInput | null;
  campaing_id?: ModelStringInput | null;
  advertiser_id?: ModelStringInput | null;
  user_profile_id?: ModelStringInput | null;
  accessToken?: ModelStringInput | null;
  ad_report?: ModelStringInput | null;
  ad_comment?: ModelStringInput | null;
  status?: ModelStringInput | null;
  adName?: ModelStringInput | null;
  approvedAdType?: ModelApprovedAdTypeInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  isManuallyLinked?: ModelBooleanInput | null;
  and?: Array<ModelApprovedAdsFilterInput | null> | null;
  or?: Array<ModelApprovedAdsFilterInput | null> | null;
  not?: ModelApprovedAdsFilterInput | null;
};

export type ModelBestPracticesFilterInput = {
  id?: ModelIDInput | null;
  headLine?: ModelStringInput | null;
  description?: ModelStringInput | null;
  urlPath?: ModelStringInput | null;
  active?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelBestPracticesFilterInput | null> | null;
  or?: Array<ModelBestPracticesFilterInput | null> | null;
  not?: ModelBestPracticesFilterInput | null;
  userProfileBestPracticesId?: ModelIDInput | null;
};

export type ModelBrandProfileFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  toneVoice?: ModelStringInput | null;
  pillars?: ModelStringInput | null;
  description?: ModelStringInput | null;
  internalMission?: ModelStringInput | null;
  strapLine?: ModelStringInput | null;
  userEmail?: ModelStringInput | null;
  tiktokHandle?: ModelStringInput | null;
  vertical?: ModelStringInput | null;
  metaData?: ModelStringInput | null;
  hashtags?: ModelStringInput | null;
  personalDescription?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelBrandProfileFilterInput | null> | null;
  or?: Array<ModelBrandProfileFilterInput | null> | null;
  not?: ModelBrandProfileFilterInput | null;
  userProfileBrandId?: ModelIDInput | null;
  owner?: ModelStringInput | null;
};

export type ModelBrandBriefFilterInput = {
  id?: ModelIDInput | null;
  BriefName?: ModelStringInput | null;
  TargetAudience?: ModelStringInput | null;
  vertical?: ModelStringInput | null;
  objective?: ModelStringInput | null;
  brandBriefDetails?: ModelStringInput | null;
  brandBriefFilesUrl?: ModelStringInput | null;
  creativeInspirations?: ModelStringInput | null;
  active?: ModelBooleanInput | null;
  brandName?: ModelStringInput | null;
  brandImageUrl?: ModelStringInput | null;
  tiktokAdvertiserId?: ModelStringInput | null;
  brandId?: ModelIDInput | null;
  adText?: ModelStringInput | null;
  country?: ModelStringInput | null;
  type?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  creativeRequestsCount?: ModelIntInput | null;
  isCreationNotificationSent?: ModelBooleanInput | null;
  messaging?: ModelStringInput | null;
  goodPractices?: ModelStringInput | null;
  badPractices?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  overview?: ModelStringInput | null;
  overviewVideoUrl?: ModelStringInput | null;
  tone?: ModelStringInput | null;
  isCompleted?: ModelBooleanInput | null;
  commonRejectionReasons?: ModelStringInput | null;
  maybes?: ModelStringInput | null;
  and?: Array<ModelBrandBriefFilterInput | null> | null;
  or?: Array<ModelBrandBriefFilterInput | null> | null;
  not?: ModelBrandBriefFilterInput | null;
  owner?: ModelStringInput | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelCreativeRequestApprovalFilterInput = {
  id?: ModelIDInput | null;
  creativeRequestId?: ModelIDInput | null;
  approverEmail?: ModelStringInput | null;
  approvalStatus?: ModelApprovalStatusInput | null;
  reason?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCreativeRequestApprovalFilterInput | null> | null;
  or?: Array<ModelCreativeRequestApprovalFilterInput | null> | null;
  not?: ModelCreativeRequestApprovalFilterInput | null;
};

export type ModelCreativeRequestFilterInput = {
  id?: ModelIDInput | null;
  brandBriefId?: ModelIDInput | null;
  creatorId?: ModelIDInput | null;
  lastUpdatedBy?: ModelCREATIVE_REQUEST_LAST_UPDATED_BYInput | null;
  brandProfileImageUrl?: ModelStringInput | null;
  briefDescription?: ModelStringInput | null;
  ad_id?: ModelStringInput | null;
  status?: ModelCREATIVE_STATUSInput | null;
  tiktokCreativeUrl?: ModelStringInput | null;
  creativePreviewUrl?: ModelStringInput | null;
  tiktokVideoCode?: ModelStringInput | null;
  creativeTiktokHandle?: ModelStringInput | null;
  creativeYoutubeHandle?: ModelStringInput | null;
  creativeInstagramHandle?: ModelStringInput | null;
  brandComment?: ModelStringInput | null;
  adminComment?: ModelStringInput | null;
  creatorComment?: ModelStringInput | null;
  adminApproval?: ModelADMIN_STATUSInput | null;
  creatorVisibility?: ModelCREATOR_VISIBILITYInput | null;
  BriefName?: ModelStringInput | null;
  type?: ModelStringInput | null;
  email?: ModelStringInput | null;
  uniqueId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  brandName?: ModelStringInput | null;
  and?: Array<ModelCreativeRequestFilterInput | null> | null;
  or?: Array<ModelCreativeRequestFilterInput | null> | null;
  not?: ModelCreativeRequestFilterInput | null;
  owner?: ModelStringInput | null;
};

export type ModelIDKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelCreativeRequestEarningsFilterInput = {
  creativeRequestEarningId?: ModelIDInput | null;
  creativeRequestId?: ModelIDInput | null;
  creatorId?: ModelIDInput | null;
  earningType?: ModelEARNING_TYPEInput | null;
  amount?: ModelFloatInput | null;
  creativeUniqueId?: ModelStringInput | null;
  currentEarnings?: ModelFloatInput | null;
  month?: ModelStringInput | null;
  toDate?: ModelStringInput | null;
  fromDate?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  importedAt?: ModelStringInput | null;
  id?: ModelIDInput | null;
  and?: Array<ModelCreativeRequestEarningsFilterInput | null> | null;
  or?: Array<ModelCreativeRequestEarningsFilterInput | null> | null;
  not?: ModelCreativeRequestEarningsFilterInput | null;
};

export type ModelCreativeRequestEarningsConnection = {
  __typename: 'ModelCreativeRequestEarningsConnection';
  items: Array<CreativeRequestEarnings | null>;
  nextToken?: string | null;
};

export type ModelSubscriptionUserProfileFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  uniqueId?: ModelSubscriptionStringInput | null;
  name?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  userType?: ModelSubscriptionStringInput | null;
  tiktokHandler?: ModelSubscriptionStringInput | null;
  instagramHandler?: ModelSubscriptionStringInput | null;
  youtubeHandler?: ModelSubscriptionStringInput | null;
  instagram_username?: ModelSubscriptionStringInput | null;
  tiktok_username?: ModelSubscriptionStringInput | null;
  instagram_followers_count?: ModelSubscriptionIntInput | null;
  instagram_media_count?: ModelSubscriptionIntInput | null;
  tiktok_follower_count?: ModelSubscriptionIntInput | null;
  tiktok_video_count?: ModelSubscriptionIntInput | null;
  lastLoginDate?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  phoneNumber?: ModelSubscriptionStringInput | null;
  country?: ModelSubscriptionStringInput | null;
  hashtags?: ModelSubscriptionStringInput | null;
  profileContent?: ModelSubscriptionStringInput | null;
  avatar?: ModelSubscriptionStringInput | null;
  vertical?: ModelSubscriptionStringInput | null;
  termsAndConditions?: ModelSubscriptionBooleanInput | null;
  isNotified?: ModelSubscriptionBooleanInput | null;
  isValid?: ModelSubscriptionBooleanInput | null;
  hubProgress?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionUserProfileFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserProfileFilterInput | null> | null;
  userProfileBrandId?: ModelSubscriptionIDInput | null;
  userProfileBestPracticesId?: ModelSubscriptionIDInput | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelSubscriptionUserPaymentDetailsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  fullName?: ModelSubscriptionStringInput | null;
  firstAddress?: ModelSubscriptionStringInput | null;
  secondAddress?: ModelSubscriptionStringInput | null;
  country?: ModelSubscriptionStringInput | null;
  accountNumber?: ModelSubscriptionStringInput | null;
  postCode?: ModelSubscriptionStringInput | null;
  swiftCode?: ModelSubscriptionStringInput | null;
  documentID?: ModelSubscriptionStringInput | null;
  confirmationLetter?: ModelSubscriptionStringInput | null;
  proofOfAddress?: ModelSubscriptionStringInput | null;
  branchCode?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionUserPaymentDetailsFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserPaymentDetailsFilterInput | null> | null;
  userPaymentDetailsUserTransactionsId?: ModelSubscriptionIDInput | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionUserWalletFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  currentBalance?: ModelSubscriptionFloatInput | null;
  currentBalanceZar?: ModelSubscriptionFloatInput | null;
  totalEarned?: ModelSubscriptionFloatInput | null;
  totalEarnedZar?: ModelSubscriptionFloatInput | null;
  extraBalance?: ModelSubscriptionFloatInput | null;
  extraBalanceZar?: ModelSubscriptionFloatInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionUserWalletFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserWalletFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionUserTransactionsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  paymentStatus?: ModelSubscriptionStringInput | null;
  paymentAmount?: ModelSubscriptionFloatInput | null;
  paymentAmountZar?: ModelSubscriptionFloatInput | null;
  userProfileId?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionUserTransactionsFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserTransactionsFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionApprovedAdsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  creativeRequestId?: ModelSubscriptionIDInput | null;
  identity_id?: ModelSubscriptionStringInput | null;
  item_id?: ModelSubscriptionStringInput | null;
  ad_id?: ModelSubscriptionStringInput | null;
  ad_group_id?: ModelSubscriptionStringInput | null;
  campaing_id?: ModelSubscriptionStringInput | null;
  advertiser_id?: ModelSubscriptionStringInput | null;
  user_profile_id?: ModelSubscriptionStringInput | null;
  accessToken?: ModelSubscriptionStringInput | null;
  ad_report?: ModelSubscriptionStringInput | null;
  ad_comment?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  adName?: ModelSubscriptionStringInput | null;
  approvedAdType?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  isManuallyLinked?: ModelSubscriptionBooleanInput | null;
  and?: Array<ModelSubscriptionApprovedAdsFilterInput | null> | null;
  or?: Array<ModelSubscriptionApprovedAdsFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionBestPracticesFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  headLine?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  urlPath?: ModelSubscriptionStringInput | null;
  active?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionBestPracticesFilterInput | null> | null;
  or?: Array<ModelSubscriptionBestPracticesFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionBrandProfileFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  toneVoice?: ModelSubscriptionStringInput | null;
  pillars?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  internalMission?: ModelSubscriptionStringInput | null;
  strapLine?: ModelSubscriptionStringInput | null;
  userEmail?: ModelSubscriptionStringInput | null;
  tiktokHandle?: ModelSubscriptionStringInput | null;
  vertical?: ModelSubscriptionStringInput | null;
  metaData?: ModelSubscriptionStringInput | null;
  hashtags?: ModelSubscriptionStringInput | null;
  personalDescription?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionBrandProfileFilterInput | null> | null;
  or?: Array<ModelSubscriptionBrandProfileFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionBrandBriefFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  BriefName?: ModelSubscriptionStringInput | null;
  TargetAudience?: ModelSubscriptionStringInput | null;
  vertical?: ModelSubscriptionStringInput | null;
  objective?: ModelSubscriptionStringInput | null;
  brandBriefDetails?: ModelSubscriptionStringInput | null;
  brandBriefFilesUrl?: ModelSubscriptionStringInput | null;
  creativeInspirations?: ModelSubscriptionStringInput | null;
  active?: ModelSubscriptionBooleanInput | null;
  brandName?: ModelSubscriptionStringInput | null;
  brandImageUrl?: ModelSubscriptionStringInput | null;
  tiktokAdvertiserId?: ModelSubscriptionStringInput | null;
  brandId?: ModelSubscriptionIDInput | null;
  adText?: ModelSubscriptionStringInput | null;
  country?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  creativeRequestsCount?: ModelSubscriptionIntInput | null;
  isCreationNotificationSent?: ModelSubscriptionBooleanInput | null;
  messaging?: ModelSubscriptionStringInput | null;
  goodPractices?: ModelSubscriptionStringInput | null;
  badPractices?: ModelSubscriptionStringInput | null;
  tags?: ModelSubscriptionStringInput | null;
  overview?: ModelSubscriptionStringInput | null;
  overviewVideoUrl?: ModelSubscriptionStringInput | null;
  tone?: ModelSubscriptionStringInput | null;
  isCompleted?: ModelSubscriptionBooleanInput | null;
  commonRejectionReasons?: ModelSubscriptionStringInput | null;
  maybes?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionBrandBriefFilterInput | null> | null;
  or?: Array<ModelSubscriptionBrandBriefFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionCreativeRequestApprovalFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  creativeRequestId?: ModelSubscriptionIDInput | null;
  approverEmail?: ModelSubscriptionStringInput | null;
  approvalStatus?: ModelSubscriptionStringInput | null;
  reason?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCreativeRequestApprovalFilterInput | null> | null;
  or?: Array<ModelSubscriptionCreativeRequestApprovalFilterInput | null> | null;
};

export type ModelSubscriptionCreativeRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  brandBriefId?: ModelSubscriptionIDInput | null;
  creatorId?: ModelSubscriptionIDInput | null;
  lastUpdatedBy?: ModelSubscriptionStringInput | null;
  brandProfileImageUrl?: ModelSubscriptionStringInput | null;
  briefDescription?: ModelSubscriptionStringInput | null;
  ad_id?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  tiktokCreativeUrl?: ModelSubscriptionStringInput | null;
  creativePreviewUrl?: ModelSubscriptionStringInput | null;
  tiktokVideoCode?: ModelSubscriptionStringInput | null;
  creativeTiktokHandle?: ModelSubscriptionStringInput | null;
  creativeYoutubeHandle?: ModelSubscriptionStringInput | null;
  creativeInstagramHandle?: ModelSubscriptionStringInput | null;
  brandComment?: ModelSubscriptionStringInput | null;
  adminComment?: ModelSubscriptionStringInput | null;
  creatorComment?: ModelSubscriptionStringInput | null;
  adminApproval?: ModelSubscriptionStringInput | null;
  creatorVisibility?: ModelSubscriptionStringInput | null;
  BriefName?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  uniqueId?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  brandName?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCreativeRequestFilterInput | null> | null;
  or?: Array<ModelSubscriptionCreativeRequestFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionCreativeRequestEarningsFilterInput = {
  creativeRequestEarningId?: ModelSubscriptionIDInput | null;
  creativeRequestId?: ModelSubscriptionIDInput | null;
  creatorId?: ModelSubscriptionIDInput | null;
  earningType?: ModelSubscriptionStringInput | null;
  amount?: ModelSubscriptionFloatInput | null;
  creativeUniqueId?: ModelSubscriptionStringInput | null;
  currentEarnings?: ModelSubscriptionFloatInput | null;
  month?: ModelSubscriptionStringInput | null;
  toDate?: ModelSubscriptionStringInput | null;
  fromDate?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  importedAt?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionCreativeRequestEarningsFilterInput | null> | null;
  or?: Array<ModelSubscriptionCreativeRequestEarningsFilterInput | null> | null;
};

export type CreativeRequestUniqueIdMutationVariables = {
  brandBriefId?: string | null;
  creativeRequestId?: string | null;
};

export type CreativeRequestUniqueIdMutation = {
  creativeRequestUniqueId?: string | null;
};

export type VideoPreviewUrlMutationVariables = {
  videoPath?: string | null;
};

export type VideoPreviewUrlMutation = {
  videoPreviewUrl?: string | null;
};

export type CreativeRequestAuthorizationMutationVariables = {
  creativeRequestId?: string | null;
  brandBriefId?: string | null;
  advertiser_id?: string | null;
  tiktokVideoCode?: string | null;
};

export type CreativeRequestAuthorizationMutation = {
  creativeRequestAuthorization?: string | null;
};

export type LinkTiktokAccountMutationVariables = {
  authCode?: string | null;
  userProfileId?: string | null;
};

export type LinkTiktokAccountMutation = {
  linkTiktokAccount?: string | null;
};

export type LinkCreatorTikTokAccountMutationVariables = {
  authCode?: string | null;
  userProfileId?: string | null;
};

export type LinkCreatorTikTokAccountMutation = {
  linkCreatorTikTokAccount?: string | null;
};

export type LinkCreatorInstagramAccountMutationVariables = {
  authCode?: string | null;
  userProfileId?: string | null;
};

export type LinkCreatorInstagramAccountMutation = {
  linkCreatorInstagramAccount?: string | null;
};

export type LinkFacebookAccountMutationVariables = {
  authCode?: string | null;
  userProfileId?: string | null;
};

export type LinkFacebookAccountMutation = {
  linkFacebookAccount?: string | null;
};

export type LinkYoutubeAccountMutationVariables = {
  authCode?: string | null;
  userProfileId?: string | null;
};

export type LinkYoutubeAccountMutation = {
  linkYoutubeAccount?: string | null;
};

export type LinkCreatorYoutubeAccountMutationVariables = {
  authCode?: string | null;
  userProfileId?: string | null;
};

export type LinkCreatorYoutubeAccountMutation = {
  linkCreatorYoutubeAccount?: string | null;
};

export type ValidateTiktokAccessMutationVariables = {
  accessToken?: string | null;
};

export type ValidateTiktokAccessMutation = {
  validateTiktokAccess?: string | null;
};

export type LinkUserTypeMutationVariables = {
  userType?: string | null;
  profileId?: string | null;
};

export type LinkUserTypeMutation = {
  linkUserType?: boolean | null;
};

export type CreateAdsMutationVariables = {
  token?: string | null;
  authCode?: string | null;
  advId?: string | null;
  adgroupId?: string | null;
  landingPageUrl?: string | null;
  identityId?: string | null;
  displayName?: string | null;
  videoUrl?: string | null;
  callToAction?: string | null;
  creativeRequestId?: string | null;
  adName?: string | null;
  creatorId?: string | null;
  adCaption?: string | null;
};

export type CreateAdsMutation = {
  createAds?: boolean | null;
};

export type CreateMetaAdMutationVariables = {
  accessToken: string;
  accountId: string;
  adName: string;
  adSetId: string;
  campaignId: string;
  videoUrl: string;
  pageId?: string | null;
  creativeRequestId: string;
};

export type CreateMetaAdMutation = {
  createMetaAd?: string | null;
};

export type CreateManualAdMutationVariables = {
  creativeRequestId: string;
};

export type CreateManualAdMutation = {
  createManualAd?: string | null;
};

export type ListAdGroupsMutationVariables = {
  token?: string | null;
  advertiser_id?: string | null;
  campaignId?: string | null;
};

export type ListAdGroupsMutation = {
  listAdGroups?: string | null;
};

export type ListCampaignsMutationVariables = {
  token?: string | null;
  advertiser_id?: string | null;
};

export type ListCampaignsMutation = {
  listCampaigns?: {
    __typename: 'LIST_CAMPAIGNS_INFO_RESPONSE';
    campaigns?: Array<{
      __typename: 'CAMPAIGNS_RESPONSE';
      advertiser_id?: string | null;
      campaign_id?: string | null;
      campaign_system_origin?: string | null;
      create_time?: string | null;
      modify_time?: string | null;
      objective_type?: string | null;
      app_promotion_type?: string | null;
      is_search_campaign?: boolean | null;
      is_smart_performance_campaign?: boolean | null;
      campaign_type?: string | null;
      app_id?: string | null;
      is_advanced_dedicated_campaign?: string | null;
      campaign_app_profile_page_state?: string | null;
      rf_campaign_type?: string | null;
      campaign_product_source?: string | null;
      campaign_name?: string | null;
      special_industries?: Array<string | null> | null;
      budget_optimize_on?: boolean | null;
      bid_type?: string | null;
      depp_bid_type?: string | null;
      roas_bid?: number | null;
      optimization_goal?: string | null;
      budget_mode?: string | null;
      budget?: number | null;
      operation_status?: string | null;
      secondary_status?: string | null;
      postback_window_mode?: string | null;
      is_new_structure?: boolean | null;
      objective?: string | null;
    } | null> | null;
    identities?: Array<{
      __typename: 'IDENTITIES_RESPONSE';
      identity_id?: string | null;
      identity_type?: string | null;
      identity_authorized_bc_id?: string | null;
      can_push_video?: boolean | null;
      can_use_live_ads?: boolean | null;
      can_pull_video?: boolean | null;
      display_name?: string | null;
      profile_image?: string | null;
    } | null> | null;
  } | null;
};

export type GetFacebookCampaignMutationVariables = {
  access_token?: string | null;
  advertiser_id?: string | null;
};

export type GetFacebookCampaignMutation = {
  getFacebookCampaign?: string | null;
};

export type GetFacebookAdSetsMutationVariables = {
  access_token?: string | null;
  campaign_id?: string | null;
};

export type GetFacebookAdSetsMutation = {
  getFacebookAdSets?: string | null;
};

export type GetVideoFromAuthCodeMutationVariables = {
  token?: string | null;
  advertiser_id?: string | null;
  authCode?: string | null;
};

export type GetVideoFromAuthCodeMutation = {
  getVideoFromAuthCode?: string | null;
};

export type AddCreativeEarningMutationVariables = {
  userProfileId: string;
  creativeRequestId: string;
  amount: number;
  month: string;
  toDate: string;
  fromDate: string;
};

export type AddCreativeEarningMutation = {
  addCreativeEarning?: {
    __typename: 'CreativeRequestEarnings';
    creativeRequestEarningId: string;
    creativeRequestId: string;
    creatorId: string;
    earningType: EARNING_TYPE;
    amount: number;
    creativeUniqueId: string;
    currentEarnings?: number | null;
    month: string;
    toDate: string;
    fromDate: string;
    createdAt: string;
    updatedAt?: string | null;
    importedAt?: string | null;
  } | null;
};

export type ExportUsersMutationVariables = {
  arg?: string | null;
};

export type ExportUsersMutation = {
  exportUsers?: {
    __typename: 'EXPORT_USERS_RESPONSE';
    url: string;
  } | null;
};

export type ReassignCreativeRequestMutationVariables = {
  creativeRequestId: string;
  assignee: string;
};

export type ReassignCreativeRequestMutation = {
  reassignCreativeRequest?: {
    __typename: 'MESSAGE_RESPONSE';
    message: string;
  } | null;
};

export type ExportsMutationVariables = {
  entity: ENTITIES;
  brandBriefId?: string | null;
};

export type ExportsMutation = {
  exports?: {
    __typename: 'GENERIC_URL_RESPONSE';
    url: string;
  } | null;
};

export type LinkApprovedAdsMutationVariables = {
  creativeId: string;
  approvedAdsData?: Array<LINK_AD_DATA | null> | null;
};

export type LinkApprovedAdsMutation = {
  linkApprovedAds?: string | null;
};

export type HandleBrandBriefWhitelistMutationVariables = {
  brandBriefId: string;
  entries?: Array<WhitelistEntriesInput> | null;
};

export type HandleBrandBriefWhitelistMutation = {
  handleBrandBriefWhitelist?: string | null;
};

export type SendAlertMessageMutationVariables = {
  email: string;
};

export type SendAlertMessageMutation = {
  sendAlertMessage?: string | null;
};

export type SendContentStatusAlertMutationVariables = {
  moderatorEmail: string;
  creativeRequestId: string;
};

export type SendContentStatusAlertMutation = {
  sendContentStatusAlert?: string | null;
};

export type ImportCreativeEarningsMutationVariables = {
  key: string;
  fromDate: string;
  toDate: string;
};

export type ImportCreativeEarningsMutation = {
  importCreativeEarnings?: string | null;
};

export type SendBrandBriefCreationNotificationMutationVariables = {
  brandBriefId: string;
};

export type SendBrandBriefCreationNotificationMutation = {
  sendBrandBriefCreationNotification?: string | null;
};

export type SendDetailsMessageMutationVariables = {
  email: string;
};

export type SendDetailsMessageMutation = {
  sendDetailsMessage?: {
    __typename: 'SendDetailsMessageResponse';
    message?: string | null;
  } | null;
};

export type DeleteBestPracticesMutationVariables = {
  input: DeleteBestPracticesInput;
  condition?: ModelBestPracticesConditionInput | null;
};

export type DeleteBestPracticesMutation = {
  deleteBestPractices?: {
    __typename: 'BestPractices';
    id: string;
    headLine: string;
    description: string;
    urlPath: string;
    active: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBestPracticesId?: string | null;
  } | null;
};

export type DeleteBrandProfileMutationVariables = {
  input: DeleteBrandProfileInput;
  condition?: ModelBrandProfileConditionInput | null;
};

export type DeleteBrandProfileMutation = {
  deleteBrandProfile?: {
    __typename: 'BrandProfile';
    id: string;
    name?: string | null;
    toneVoice?: Array<string> | null;
    pillars?: Array<string> | null;
    description?: string | null;
    internalMission?: string | null;
    strapLine?: string | null;
    userEmail?: string | null;
    tiktokHandle?: string | null;
    vertical?: string | null;
    metaData?: string | null;
    userProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    briefs?: {
      __typename: 'ModelBrandBriefConnection';
      items: Array<{
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    hashtags?: Array<string> | null;
    personalDescription?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBrandId?: string | null;
    owner?: string | null;
  } | null;
};

export type CreateCreativeRequestApprovalMutationVariables = {
  input: CreateCreativeRequestApprovalInput;
  condition?: ModelCreativeRequestApprovalConditionInput | null;
};

export type CreateCreativeRequestApprovalMutation = {
  createCreativeRequestApproval?: {
    __typename: 'CreativeRequestApproval';
    id: string;
    creativeRequestId: string;
    approverEmail: string;
    approvalStatus?: ApprovalStatus | null;
    reason?: string | null;
    updatedAt?: string | null;
    createdAt?: string | null;
  } | null;
};

export type UpdateCreativeRequestApprovalMutationVariables = {
  input: UpdateCreativeRequestApprovalInput;
  condition?: ModelCreativeRequestApprovalConditionInput | null;
};

export type UpdateCreativeRequestApprovalMutation = {
  updateCreativeRequestApproval?: {
    __typename: 'CreativeRequestApproval';
    id: string;
    creativeRequestId: string;
    approverEmail: string;
    approvalStatus?: ApprovalStatus | null;
    reason?: string | null;
    updatedAt?: string | null;
    createdAt?: string | null;
  } | null;
};

export type DeleteCreativeRequestApprovalMutationVariables = {
  input: DeleteCreativeRequestApprovalInput;
  condition?: ModelCreativeRequestApprovalConditionInput | null;
};

export type DeleteCreativeRequestApprovalMutation = {
  deleteCreativeRequestApproval?: {
    __typename: 'CreativeRequestApproval';
    id: string;
    creativeRequestId: string;
    approverEmail: string;
    approvalStatus?: ApprovalStatus | null;
    reason?: string | null;
    updatedAt?: string | null;
    createdAt?: string | null;
  } | null;
};

export type CreateUserProfileMutationVariables = {
  input: CreateUserProfileInput;
  condition?: ModelUserProfileConditionInput | null;
};

export type CreateUserProfileMutation = {
  createUserProfile?: {
    __typename: 'UserProfile';
    id: string;
    uniqueId?: string | null;
    name: string;
    description?: string | null;
    brand?: {
      __typename: 'ModelBrandProfileConnection';
      items: Array<{
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    userType?: USER_TYPES | null;
    tiktokHandler?: string | null;
    instagramHandler?: string | null;
    youtubeHandler?: string | null;
    instagram_username?: string | null;
    tiktok_username?: string | null;
    instagram_followers_count?: number | null;
    instagram_media_count?: number | null;
    tiktok_follower_count?: number | null;
    tiktok_video_count?: number | null;
    bestPractices?: {
      __typename: 'ModelBestPracticesConnection';
      items: Array<{
        __typename: 'BestPractices';
        id: string;
        headLine: string;
        description: string;
        urlPath: string;
        active: string;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBestPracticesId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    tiktokAccountAccess?: {
      __typename: 'TiktokAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    facebookAccountAccess?: {
      __typename: 'FacebookAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    youtubeAccountAccess?: {
      __typename: 'YoutubeAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    userPaymentDetails?: {
      __typename: 'UserPaymentDetails';
      id: string;
      fullName: string;
      firstAddress: string;
      secondAddress?: string | null;
      country: string;
      accountNumber: string;
      postCode?: string | null;
      swiftCode: string;
      documentID?: string | null;
      owner?: string | null;
      userTransactions?: {
        __typename: 'ModelUserTransactionsConnection';
        items: Array<{
          __typename: 'UserTransactions';
          id: string;
          paymentStatus?: PAYMENT_STATUS | null;
          paymentAmount?: number | null;
          paymentAmountZar?: number | null;
          userProfileId?: string | null;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userPaymentDetailsUserTransactionsId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      confirmationLetter?: string | null;
      proofOfAddress?: string | null;
      branchCode?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    userWallet?: {
      __typename: 'UserWallet';
      id: string;
      currentBalance?: number | null;
      currentBalanceZar?: number | null;
      totalEarned?: number | null;
      totalEarnedZar?: number | null;
      extraBalance?: number | null;
      extraBalanceZar?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner?: string | null;
    } | null;
    lastLoginDate?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    country?: string | null;
    hashtags?: Array<string> | null;
    profileContent?: Array<string> | null;
    avatar?: string | null;
    vertical?: string | null;
    termsAndConditions?: boolean | null;
    isNotified?: boolean | null;
    isValid?: boolean | null;
    hubProgress?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateUserProfileMutationVariables = {
  input: UpdateUserProfileInput;
  condition?: ModelUserProfileConditionInput | null;
};

export type UpdateUserProfileMutation = {
  updateUserProfile?: {
    __typename: 'UserProfile';
    id: string;
    uniqueId?: string | null;
    name: string;
    description?: string | null;
    brand?: {
      __typename: 'ModelBrandProfileConnection';
      items: Array<{
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    userType?: USER_TYPES | null;
    tiktokHandler?: string | null;
    instagramHandler?: string | null;
    youtubeHandler?: string | null;
    instagram_username?: string | null;
    tiktok_username?: string | null;
    instagram_followers_count?: number | null;
    instagram_media_count?: number | null;
    tiktok_follower_count?: number | null;
    tiktok_video_count?: number | null;
    bestPractices?: {
      __typename: 'ModelBestPracticesConnection';
      items: Array<{
        __typename: 'BestPractices';
        id: string;
        headLine: string;
        description: string;
        urlPath: string;
        active: string;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBestPracticesId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    tiktokAccountAccess?: {
      __typename: 'TiktokAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    facebookAccountAccess?: {
      __typename: 'FacebookAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    youtubeAccountAccess?: {
      __typename: 'YoutubeAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    userPaymentDetails?: {
      __typename: 'UserPaymentDetails';
      id: string;
      fullName: string;
      firstAddress: string;
      secondAddress?: string | null;
      country: string;
      accountNumber: string;
      postCode?: string | null;
      swiftCode: string;
      documentID?: string | null;
      owner?: string | null;
      userTransactions?: {
        __typename: 'ModelUserTransactionsConnection';
        items: Array<{
          __typename: 'UserTransactions';
          id: string;
          paymentStatus?: PAYMENT_STATUS | null;
          paymentAmount?: number | null;
          paymentAmountZar?: number | null;
          userProfileId?: string | null;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userPaymentDetailsUserTransactionsId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      confirmationLetter?: string | null;
      proofOfAddress?: string | null;
      branchCode?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    userWallet?: {
      __typename: 'UserWallet';
      id: string;
      currentBalance?: number | null;
      currentBalanceZar?: number | null;
      totalEarned?: number | null;
      totalEarnedZar?: number | null;
      extraBalance?: number | null;
      extraBalanceZar?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner?: string | null;
    } | null;
    lastLoginDate?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    country?: string | null;
    hashtags?: Array<string> | null;
    profileContent?: Array<string> | null;
    avatar?: string | null;
    vertical?: string | null;
    termsAndConditions?: boolean | null;
    isNotified?: boolean | null;
    isValid?: boolean | null;
    hubProgress?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteUserProfileMutationVariables = {
  input: DeleteUserProfileInput;
  condition?: ModelUserProfileConditionInput | null;
};

export type DeleteUserProfileMutation = {
  deleteUserProfile?: {
    __typename: 'UserProfile';
    id: string;
    uniqueId?: string | null;
    name: string;
    description?: string | null;
    brand?: {
      __typename: 'ModelBrandProfileConnection';
      items: Array<{
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    userType?: USER_TYPES | null;
    tiktokHandler?: string | null;
    instagramHandler?: string | null;
    youtubeHandler?: string | null;
    instagram_username?: string | null;
    tiktok_username?: string | null;
    instagram_followers_count?: number | null;
    instagram_media_count?: number | null;
    tiktok_follower_count?: number | null;
    tiktok_video_count?: number | null;
    bestPractices?: {
      __typename: 'ModelBestPracticesConnection';
      items: Array<{
        __typename: 'BestPractices';
        id: string;
        headLine: string;
        description: string;
        urlPath: string;
        active: string;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBestPracticesId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    tiktokAccountAccess?: {
      __typename: 'TiktokAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    facebookAccountAccess?: {
      __typename: 'FacebookAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    youtubeAccountAccess?: {
      __typename: 'YoutubeAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    userPaymentDetails?: {
      __typename: 'UserPaymentDetails';
      id: string;
      fullName: string;
      firstAddress: string;
      secondAddress?: string | null;
      country: string;
      accountNumber: string;
      postCode?: string | null;
      swiftCode: string;
      documentID?: string | null;
      owner?: string | null;
      userTransactions?: {
        __typename: 'ModelUserTransactionsConnection';
        items: Array<{
          __typename: 'UserTransactions';
          id: string;
          paymentStatus?: PAYMENT_STATUS | null;
          paymentAmount?: number | null;
          paymentAmountZar?: number | null;
          userProfileId?: string | null;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userPaymentDetailsUserTransactionsId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      confirmationLetter?: string | null;
      proofOfAddress?: string | null;
      branchCode?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    userWallet?: {
      __typename: 'UserWallet';
      id: string;
      currentBalance?: number | null;
      currentBalanceZar?: number | null;
      totalEarned?: number | null;
      totalEarnedZar?: number | null;
      extraBalance?: number | null;
      extraBalanceZar?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner?: string | null;
    } | null;
    lastLoginDate?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    country?: string | null;
    hashtags?: Array<string> | null;
    profileContent?: Array<string> | null;
    avatar?: string | null;
    vertical?: string | null;
    termsAndConditions?: boolean | null;
    isNotified?: boolean | null;
    isValid?: boolean | null;
    hubProgress?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateUserPaymentDetailsMutationVariables = {
  input: CreateUserPaymentDetailsInput;
  condition?: ModelUserPaymentDetailsConditionInput | null;
};

export type CreateUserPaymentDetailsMutation = {
  createUserPaymentDetails?: {
    __typename: 'UserPaymentDetails';
    id: string;
    fullName: string;
    firstAddress: string;
    secondAddress?: string | null;
    country: string;
    accountNumber: string;
    postCode?: string | null;
    swiftCode: string;
    documentID?: string | null;
    owner?: string | null;
    userTransactions?: {
      __typename: 'ModelUserTransactionsConnection';
      items: Array<{
        __typename: 'UserTransactions';
        id: string;
        paymentStatus?: PAYMENT_STATUS | null;
        paymentAmount?: number | null;
        paymentAmountZar?: number | null;
        userProfileId?: string | null;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userPaymentDetailsUserTransactionsId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    confirmationLetter?: string | null;
    proofOfAddress?: string | null;
    branchCode?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateUserPaymentDetailsMutationVariables = {
  input: UpdateUserPaymentDetailsInput;
  condition?: ModelUserPaymentDetailsConditionInput | null;
};

export type UpdateUserPaymentDetailsMutation = {
  updateUserPaymentDetails?: {
    __typename: 'UserPaymentDetails';
    id: string;
    fullName: string;
    firstAddress: string;
    secondAddress?: string | null;
    country: string;
    accountNumber: string;
    postCode?: string | null;
    swiftCode: string;
    documentID?: string | null;
    owner?: string | null;
    userTransactions?: {
      __typename: 'ModelUserTransactionsConnection';
      items: Array<{
        __typename: 'UserTransactions';
        id: string;
        paymentStatus?: PAYMENT_STATUS | null;
        paymentAmount?: number | null;
        paymentAmountZar?: number | null;
        userProfileId?: string | null;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userPaymentDetailsUserTransactionsId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    confirmationLetter?: string | null;
    proofOfAddress?: string | null;
    branchCode?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteUserPaymentDetailsMutationVariables = {
  input: DeleteUserPaymentDetailsInput;
  condition?: ModelUserPaymentDetailsConditionInput | null;
};

export type DeleteUserPaymentDetailsMutation = {
  deleteUserPaymentDetails?: {
    __typename: 'UserPaymentDetails';
    id: string;
    fullName: string;
    firstAddress: string;
    secondAddress?: string | null;
    country: string;
    accountNumber: string;
    postCode?: string | null;
    swiftCode: string;
    documentID?: string | null;
    owner?: string | null;
    userTransactions?: {
      __typename: 'ModelUserTransactionsConnection';
      items: Array<{
        __typename: 'UserTransactions';
        id: string;
        paymentStatus?: PAYMENT_STATUS | null;
        paymentAmount?: number | null;
        paymentAmountZar?: number | null;
        userProfileId?: string | null;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userPaymentDetailsUserTransactionsId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    confirmationLetter?: string | null;
    proofOfAddress?: string | null;
    branchCode?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateUserWalletMutationVariables = {
  input: CreateUserWalletInput;
  condition?: ModelUserWalletConditionInput | null;
};

export type CreateUserWalletMutation = {
  createUserWallet?: {
    __typename: 'UserWallet';
    id: string;
    currentBalance?: number | null;
    currentBalanceZar?: number | null;
    totalEarned?: number | null;
    totalEarnedZar?: number | null;
    extraBalance?: number | null;
    extraBalanceZar?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner?: string | null;
  } | null;
};

export type UpdateUserWalletMutationVariables = {
  input: UpdateUserWalletInput;
  condition?: ModelUserWalletConditionInput | null;
};

export type UpdateUserWalletMutation = {
  updateUserWallet?: {
    __typename: 'UserWallet';
    id: string;
    currentBalance?: number | null;
    currentBalanceZar?: number | null;
    totalEarned?: number | null;
    totalEarnedZar?: number | null;
    extraBalance?: number | null;
    extraBalanceZar?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner?: string | null;
  } | null;
};

export type DeleteUserWalletMutationVariables = {
  input: DeleteUserWalletInput;
  condition?: ModelUserWalletConditionInput | null;
};

export type DeleteUserWalletMutation = {
  deleteUserWallet?: {
    __typename: 'UserWallet';
    id: string;
    currentBalance?: number | null;
    currentBalanceZar?: number | null;
    totalEarned?: number | null;
    totalEarnedZar?: number | null;
    extraBalance?: number | null;
    extraBalanceZar?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner?: string | null;
  } | null;
};

export type CreateUserTransactionsMutationVariables = {
  input: CreateUserTransactionsInput;
  condition?: ModelUserTransactionsConditionInput | null;
};

export type CreateUserTransactionsMutation = {
  createUserTransactions?: {
    __typename: 'UserTransactions';
    id: string;
    paymentStatus?: PAYMENT_STATUS | null;
    paymentAmount?: number | null;
    paymentAmountZar?: number | null;
    userProfileId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userPaymentDetailsUserTransactionsId?: string | null;
  } | null;
};

export type UpdateUserTransactionsMutationVariables = {
  input: UpdateUserTransactionsInput;
  condition?: ModelUserTransactionsConditionInput | null;
};

export type UpdateUserTransactionsMutation = {
  updateUserTransactions?: {
    __typename: 'UserTransactions';
    id: string;
    paymentStatus?: PAYMENT_STATUS | null;
    paymentAmount?: number | null;
    paymentAmountZar?: number | null;
    userProfileId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userPaymentDetailsUserTransactionsId?: string | null;
  } | null;
};

export type DeleteUserTransactionsMutationVariables = {
  input: DeleteUserTransactionsInput;
  condition?: ModelUserTransactionsConditionInput | null;
};

export type DeleteUserTransactionsMutation = {
  deleteUserTransactions?: {
    __typename: 'UserTransactions';
    id: string;
    paymentStatus?: PAYMENT_STATUS | null;
    paymentAmount?: number | null;
    paymentAmountZar?: number | null;
    userProfileId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userPaymentDetailsUserTransactionsId?: string | null;
  } | null;
};

export type CreateApprovedAdsMutationVariables = {
  input: CreateApprovedAdsInput;
  condition?: ModelApprovedAdsConditionInput | null;
};

export type CreateApprovedAdsMutation = {
  createApprovedAds?: {
    __typename: 'ApprovedAds';
    id: string;
    creativeRequestId: string;
    creativeRequest?: {
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null;
    identity_id?: string | null;
    item_id?: string | null;
    ad_id?: string | null;
    ad_group_id?: string | null;
    campaing_id?: string | null;
    advertiser_id?: string | null;
    user_profile_id?: string | null;
    accessToken?: string | null;
    ad_report?: string | null;
    ad_comment?: string | null;
    status?: string | null;
    adName?: string | null;
    approvedAdType?: ApprovedAdType | null;
    owner?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    isManuallyLinked?: boolean | null;
  } | null;
};

export type UpdateApprovedAdsMutationVariables = {
  input: UpdateApprovedAdsInput;
  condition?: ModelApprovedAdsConditionInput | null;
};

export type UpdateApprovedAdsMutation = {
  updateApprovedAds?: {
    __typename: 'ApprovedAds';
    id: string;
    creativeRequestId: string;
    creativeRequest?: {
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null;
    identity_id?: string | null;
    item_id?: string | null;
    ad_id?: string | null;
    ad_group_id?: string | null;
    campaing_id?: string | null;
    advertiser_id?: string | null;
    user_profile_id?: string | null;
    accessToken?: string | null;
    ad_report?: string | null;
    ad_comment?: string | null;
    status?: string | null;
    adName?: string | null;
    approvedAdType?: ApprovedAdType | null;
    owner?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    isManuallyLinked?: boolean | null;
  } | null;
};

export type DeleteApprovedAdsMutationVariables = {
  input: DeleteApprovedAdsInput;
  condition?: ModelApprovedAdsConditionInput | null;
};

export type DeleteApprovedAdsMutation = {
  deleteApprovedAds?: {
    __typename: 'ApprovedAds';
    id: string;
    creativeRequestId: string;
    creativeRequest?: {
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null;
    identity_id?: string | null;
    item_id?: string | null;
    ad_id?: string | null;
    ad_group_id?: string | null;
    campaing_id?: string | null;
    advertiser_id?: string | null;
    user_profile_id?: string | null;
    accessToken?: string | null;
    ad_report?: string | null;
    ad_comment?: string | null;
    status?: string | null;
    adName?: string | null;
    approvedAdType?: ApprovedAdType | null;
    owner?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    isManuallyLinked?: boolean | null;
  } | null;
};

export type CreateBestPracticesMutationVariables = {
  input: CreateBestPracticesInput;
  condition?: ModelBestPracticesConditionInput | null;
};

export type CreateBestPracticesMutation = {
  createBestPractices?: {
    __typename: 'BestPractices';
    id: string;
    headLine: string;
    description: string;
    urlPath: string;
    active: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBestPracticesId?: string | null;
  } | null;
};

export type UpdateBestPracticesMutationVariables = {
  input: UpdateBestPracticesInput;
  condition?: ModelBestPracticesConditionInput | null;
};

export type UpdateBestPracticesMutation = {
  updateBestPractices?: {
    __typename: 'BestPractices';
    id: string;
    headLine: string;
    description: string;
    urlPath: string;
    active: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBestPracticesId?: string | null;
  } | null;
};

export type CreateBrandProfileMutationVariables = {
  input: CreateBrandProfileInput;
  condition?: ModelBrandProfileConditionInput | null;
};

export type CreateBrandProfileMutation = {
  createBrandProfile?: {
    __typename: 'BrandProfile';
    id: string;
    name?: string | null;
    toneVoice?: Array<string> | null;
    pillars?: Array<string> | null;
    description?: string | null;
    internalMission?: string | null;
    strapLine?: string | null;
    userEmail?: string | null;
    tiktokHandle?: string | null;
    vertical?: string | null;
    metaData?: string | null;
    userProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    briefs?: {
      __typename: 'ModelBrandBriefConnection';
      items: Array<{
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    hashtags?: Array<string> | null;
    personalDescription?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBrandId?: string | null;
    owner?: string | null;
  } | null;
};

export type UpdateBrandProfileMutationVariables = {
  input: UpdateBrandProfileInput;
  condition?: ModelBrandProfileConditionInput | null;
};

export type UpdateBrandProfileMutation = {
  updateBrandProfile?: {
    __typename: 'BrandProfile';
    id: string;
    name?: string | null;
    toneVoice?: Array<string> | null;
    pillars?: Array<string> | null;
    description?: string | null;
    internalMission?: string | null;
    strapLine?: string | null;
    userEmail?: string | null;
    tiktokHandle?: string | null;
    vertical?: string | null;
    metaData?: string | null;
    userProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    briefs?: {
      __typename: 'ModelBrandBriefConnection';
      items: Array<{
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    hashtags?: Array<string> | null;
    personalDescription?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBrandId?: string | null;
    owner?: string | null;
  } | null;
};

export type CreateBrandBriefMutationVariables = {
  input: CreateBrandBriefInput;
  condition?: ModelBrandBriefConditionInput | null;
};

export type CreateBrandBriefMutation = {
  createBrandBrief?: {
    __typename: 'BrandBrief';
    id: string;
    BriefName: string;
    TargetAudience?: string | null;
    vertical: string;
    objective: string;
    brandBriefDetails: string;
    brandBriefFilesUrl?: string | null;
    creativeInspirations?: Array<string> | null;
    active: boolean;
    brandName?: string | null;
    brandImageUrl?: string | null;
    tiktokAdvertiserId?: string | null;
    creativeRequests?: {
      __typename: 'ModelCreativeRequestConnection';
      items: Array<{
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandId: string;
    brandProfile?: {
      __typename: 'BrandProfile';
      id: string;
      name?: string | null;
      toneVoice?: Array<string> | null;
      pillars?: Array<string> | null;
      description?: string | null;
      internalMission?: string | null;
      strapLine?: string | null;
      userEmail?: string | null;
      tiktokHandle?: string | null;
      vertical?: string | null;
      metaData?: string | null;
      userProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      briefs?: {
        __typename: 'ModelBrandBriefConnection';
        items: Array<{
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      hashtags?: Array<string> | null;
      personalDescription?: string | null;
      createdAt: string;
      updatedAt: string;
      userProfileBrandId?: string | null;
      owner?: string | null;
    } | null;
    adText: string;
    country: string;
    type?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tikTokData: {
      __typename: 'tikTokData';
      adIdentityId?: string | null;
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      displayName?: string | null;
      tikTokSparkAds?: boolean | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    metaData: {
      __typename: 'metaData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    youtubeData: {
      __typename: 'youtubeData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    manualData: {
      __typename: 'manualData';
      isManual: boolean;
      adCaption?: string | null;
    };
    whitelist?: Array<{
      __typename: 'WhitelistEntry';
      email: string;
      isApprover: boolean;
    }> | null;
    creativeRequestsCount?: number | null;
    isCreationNotificationSent?: boolean | null;
    messaging?: string | null;
    goodPractices?: Array<string> | null;
    badPractices?: Array<string> | null;
    tags?: Array<string> | null;
    overview?: string | null;
    overviewVideoUrl?: string | null;
    tone?: string | null;
    isCompleted?: boolean | null;
    interestedUsers?: Array<{
      __typename: 'InterestedUser';
      id: string;
      isInterested: boolean;
      hasBeenShown: boolean;
    }> | null;
    commonRejectionReasons?: Array<string> | null;
    maybes?: Array<string> | null;
    owner?: string | null;
  } | null;
};

export type UpdateBrandBriefMutationVariables = {
  input: UpdateBrandBriefInput;
  condition?: ModelBrandBriefConditionInput | null;
};

export type UpdateBrandBriefMutation = {
  updateBrandBrief?: {
    __typename: 'BrandBrief';
    id: string;
    BriefName: string;
    TargetAudience?: string | null;
    vertical: string;
    objective: string;
    brandBriefDetails: string;
    brandBriefFilesUrl?: string | null;
    creativeInspirations?: Array<string> | null;
    active: boolean;
    brandName?: string | null;
    brandImageUrl?: string | null;
    tiktokAdvertiserId?: string | null;
    creativeRequests?: {
      __typename: 'ModelCreativeRequestConnection';
      items: Array<{
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandId: string;
    brandProfile?: {
      __typename: 'BrandProfile';
      id: string;
      name?: string | null;
      toneVoice?: Array<string> | null;
      pillars?: Array<string> | null;
      description?: string | null;
      internalMission?: string | null;
      strapLine?: string | null;
      userEmail?: string | null;
      tiktokHandle?: string | null;
      vertical?: string | null;
      metaData?: string | null;
      userProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      briefs?: {
        __typename: 'ModelBrandBriefConnection';
        items: Array<{
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      hashtags?: Array<string> | null;
      personalDescription?: string | null;
      createdAt: string;
      updatedAt: string;
      userProfileBrandId?: string | null;
      owner?: string | null;
    } | null;
    adText: string;
    country: string;
    type?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tikTokData: {
      __typename: 'tikTokData';
      adIdentityId?: string | null;
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      displayName?: string | null;
      tikTokSparkAds?: boolean | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    metaData: {
      __typename: 'metaData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    youtubeData: {
      __typename: 'youtubeData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    manualData: {
      __typename: 'manualData';
      isManual: boolean;
      adCaption?: string | null;
    };
    whitelist?: Array<{
      __typename: 'WhitelistEntry';
      email: string;
      isApprover: boolean;
    }> | null;
    creativeRequestsCount?: number | null;
    isCreationNotificationSent?: boolean | null;
    messaging?: string | null;
    goodPractices?: Array<string> | null;
    badPractices?: Array<string> | null;
    tags?: Array<string> | null;
    overview?: string | null;
    overviewVideoUrl?: string | null;
    tone?: string | null;
    isCompleted?: boolean | null;
    interestedUsers?: Array<{
      __typename: 'InterestedUser';
      id: string;
      isInterested: boolean;
      hasBeenShown: boolean;
    }> | null;
    commonRejectionReasons?: Array<string> | null;
    maybes?: Array<string> | null;
    owner?: string | null;
  } | null;
};

export type DeleteBrandBriefMutationVariables = {
  input: DeleteBrandBriefInput;
  condition?: ModelBrandBriefConditionInput | null;
};

export type DeleteBrandBriefMutation = {
  deleteBrandBrief?: {
    __typename: 'BrandBrief';
    id: string;
    BriefName: string;
    TargetAudience?: string | null;
    vertical: string;
    objective: string;
    brandBriefDetails: string;
    brandBriefFilesUrl?: string | null;
    creativeInspirations?: Array<string> | null;
    active: boolean;
    brandName?: string | null;
    brandImageUrl?: string | null;
    tiktokAdvertiserId?: string | null;
    creativeRequests?: {
      __typename: 'ModelCreativeRequestConnection';
      items: Array<{
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandId: string;
    brandProfile?: {
      __typename: 'BrandProfile';
      id: string;
      name?: string | null;
      toneVoice?: Array<string> | null;
      pillars?: Array<string> | null;
      description?: string | null;
      internalMission?: string | null;
      strapLine?: string | null;
      userEmail?: string | null;
      tiktokHandle?: string | null;
      vertical?: string | null;
      metaData?: string | null;
      userProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      briefs?: {
        __typename: 'ModelBrandBriefConnection';
        items: Array<{
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      hashtags?: Array<string> | null;
      personalDescription?: string | null;
      createdAt: string;
      updatedAt: string;
      userProfileBrandId?: string | null;
      owner?: string | null;
    } | null;
    adText: string;
    country: string;
    type?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tikTokData: {
      __typename: 'tikTokData';
      adIdentityId?: string | null;
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      displayName?: string | null;
      tikTokSparkAds?: boolean | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    metaData: {
      __typename: 'metaData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    youtubeData: {
      __typename: 'youtubeData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    manualData: {
      __typename: 'manualData';
      isManual: boolean;
      adCaption?: string | null;
    };
    whitelist?: Array<{
      __typename: 'WhitelistEntry';
      email: string;
      isApprover: boolean;
    }> | null;
    creativeRequestsCount?: number | null;
    isCreationNotificationSent?: boolean | null;
    messaging?: string | null;
    goodPractices?: Array<string> | null;
    badPractices?: Array<string> | null;
    tags?: Array<string> | null;
    overview?: string | null;
    overviewVideoUrl?: string | null;
    tone?: string | null;
    isCompleted?: boolean | null;
    interestedUsers?: Array<{
      __typename: 'InterestedUser';
      id: string;
      isInterested: boolean;
      hasBeenShown: boolean;
    }> | null;
    commonRejectionReasons?: Array<string> | null;
    maybes?: Array<string> | null;
    owner?: string | null;
  } | null;
};

export type CreateCreativeRequestMutationVariables = {
  input: CreateCreativeRequestInput;
  condition?: ModelCreativeRequestConditionInput | null;
};

export type CreateCreativeRequestMutation = {
  createCreativeRequest?: {
    __typename: 'CreativeRequest';
    id: string;
    brandBriefId: string;
    creatorId: string;
    creatorProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    brief?: {
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null;
    lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
    brandProfileImageUrl?: string | null;
    briefDescription?: string | null;
    ad_id?: string | null;
    status: CREATIVE_STATUS;
    tiktokCreativeUrl: string;
    creativePreviewUrl?: string | null;
    tiktokVideoCode: string;
    creativeTiktokHandle?: string | null;
    creativeYoutubeHandle?: string | null;
    creativeInstagramHandle?: string | null;
    approvedAds?: {
      __typename: 'ModelApprovedAdsConnection';
      items: Array<{
        __typename: 'ApprovedAds';
        id: string;
        creativeRequestId: string;
        creativeRequest?: {
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null;
        identity_id?: string | null;
        item_id?: string | null;
        ad_id?: string | null;
        ad_group_id?: string | null;
        campaing_id?: string | null;
        advertiser_id?: string | null;
        user_profile_id?: string | null;
        accessToken?: string | null;
        ad_report?: string | null;
        ad_comment?: string | null;
        status?: string | null;
        adName?: string | null;
        approvedAdType?: ApprovedAdType | null;
        owner?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        isManuallyLinked?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandComment?: Array<string> | null;
    adminComment?: Array<string> | null;
    creatorComment?: Array<string> | null;
    adminApproval?: ADMIN_STATUS | null;
    creatorVisibility?: CREATOR_VISIBILITY | null;
    BriefName?: string | null;
    type?: string | null;
    email?: string | null;
    uniqueId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    brandName?: string | null;
    approvals?: {
      __typename: 'ModelCreativeRequestApprovalConnection';
      items: Array<{
        __typename: 'CreativeRequestApproval';
        id: string;
        creativeRequestId: string;
        approverEmail: string;
        approvalStatus?: ApprovalStatus | null;
        reason?: string | null;
        updatedAt?: string | null;
        createdAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type UpdateCreativeRequestMutationVariables = {
  input: UpdateCreativeRequestInput;
  condition?: ModelCreativeRequestConditionInput | null;
};

export type UpdateCreativeRequestMutation = {
  updateCreativeRequest?: {
    __typename: 'CreativeRequest';
    id: string;
    brandBriefId: string;
    creatorId: string;
    creatorProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    brief?: {
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null;
    lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
    brandProfileImageUrl?: string | null;
    briefDescription?: string | null;
    ad_id?: string | null;
    status: CREATIVE_STATUS;
    tiktokCreativeUrl: string;
    creativePreviewUrl?: string | null;
    tiktokVideoCode: string;
    creativeTiktokHandle?: string | null;
    creativeYoutubeHandle?: string | null;
    creativeInstagramHandle?: string | null;
    approvedAds?: {
      __typename: 'ModelApprovedAdsConnection';
      items: Array<{
        __typename: 'ApprovedAds';
        id: string;
        creativeRequestId: string;
        creativeRequest?: {
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null;
        identity_id?: string | null;
        item_id?: string | null;
        ad_id?: string | null;
        ad_group_id?: string | null;
        campaing_id?: string | null;
        advertiser_id?: string | null;
        user_profile_id?: string | null;
        accessToken?: string | null;
        ad_report?: string | null;
        ad_comment?: string | null;
        status?: string | null;
        adName?: string | null;
        approvedAdType?: ApprovedAdType | null;
        owner?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        isManuallyLinked?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandComment?: Array<string> | null;
    adminComment?: Array<string> | null;
    creatorComment?: Array<string> | null;
    adminApproval?: ADMIN_STATUS | null;
    creatorVisibility?: CREATOR_VISIBILITY | null;
    BriefName?: string | null;
    type?: string | null;
    email?: string | null;
    uniqueId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    brandName?: string | null;
    approvals?: {
      __typename: 'ModelCreativeRequestApprovalConnection';
      items: Array<{
        __typename: 'CreativeRequestApproval';
        id: string;
        creativeRequestId: string;
        approverEmail: string;
        approvalStatus?: ApprovalStatus | null;
        reason?: string | null;
        updatedAt?: string | null;
        createdAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type DeleteCreativeRequestMutationVariables = {
  input: DeleteCreativeRequestInput;
  condition?: ModelCreativeRequestConditionInput | null;
};

export type DeleteCreativeRequestMutation = {
  deleteCreativeRequest?: {
    __typename: 'CreativeRequest';
    id: string;
    brandBriefId: string;
    creatorId: string;
    creatorProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    brief?: {
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null;
    lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
    brandProfileImageUrl?: string | null;
    briefDescription?: string | null;
    ad_id?: string | null;
    status: CREATIVE_STATUS;
    tiktokCreativeUrl: string;
    creativePreviewUrl?: string | null;
    tiktokVideoCode: string;
    creativeTiktokHandle?: string | null;
    creativeYoutubeHandle?: string | null;
    creativeInstagramHandle?: string | null;
    approvedAds?: {
      __typename: 'ModelApprovedAdsConnection';
      items: Array<{
        __typename: 'ApprovedAds';
        id: string;
        creativeRequestId: string;
        creativeRequest?: {
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null;
        identity_id?: string | null;
        item_id?: string | null;
        ad_id?: string | null;
        ad_group_id?: string | null;
        campaing_id?: string | null;
        advertiser_id?: string | null;
        user_profile_id?: string | null;
        accessToken?: string | null;
        ad_report?: string | null;
        ad_comment?: string | null;
        status?: string | null;
        adName?: string | null;
        approvedAdType?: ApprovedAdType | null;
        owner?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        isManuallyLinked?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandComment?: Array<string> | null;
    adminComment?: Array<string> | null;
    creatorComment?: Array<string> | null;
    adminApproval?: ADMIN_STATUS | null;
    creatorVisibility?: CREATOR_VISIBILITY | null;
    BriefName?: string | null;
    type?: string | null;
    email?: string | null;
    uniqueId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    brandName?: string | null;
    approvals?: {
      __typename: 'ModelCreativeRequestApprovalConnection';
      items: Array<{
        __typename: 'CreativeRequestApproval';
        id: string;
        creativeRequestId: string;
        approverEmail: string;
        approvalStatus?: ApprovalStatus | null;
        reason?: string | null;
        updatedAt?: string | null;
        createdAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type CreateCreativeRequestEarningsMutationVariables = {
  input: CreateCreativeRequestEarningsInput;
  condition?: ModelCreativeRequestEarningsConditionInput | null;
};

export type CreateCreativeRequestEarningsMutation = {
  createCreativeRequestEarnings?: {
    __typename: 'CreativeRequestEarnings';
    creativeRequestEarningId: string;
    creativeRequestId: string;
    creatorId: string;
    earningType: EARNING_TYPE;
    amount: number;
    creativeUniqueId: string;
    currentEarnings?: number | null;
    month: string;
    toDate: string;
    fromDate: string;
    createdAt: string;
    updatedAt?: string | null;
    importedAt?: string | null;
  } | null;
};

export type UpdateCreativeRequestEarningsMutationVariables = {
  input: UpdateCreativeRequestEarningsInput;
  condition?: ModelCreativeRequestEarningsConditionInput | null;
};

export type UpdateCreativeRequestEarningsMutation = {
  updateCreativeRequestEarnings?: {
    __typename: 'CreativeRequestEarnings';
    creativeRequestEarningId: string;
    creativeRequestId: string;
    creatorId: string;
    earningType: EARNING_TYPE;
    amount: number;
    creativeUniqueId: string;
    currentEarnings?: number | null;
    month: string;
    toDate: string;
    fromDate: string;
    createdAt: string;
    updatedAt?: string | null;
    importedAt?: string | null;
  } | null;
};

export type DeleteCreativeRequestEarningsMutationVariables = {
  input: DeleteCreativeRequestEarningsInput;
  condition?: ModelCreativeRequestEarningsConditionInput | null;
};

export type DeleteCreativeRequestEarningsMutation = {
  deleteCreativeRequestEarnings?: {
    __typename: 'CreativeRequestEarnings';
    creativeRequestEarningId: string;
    creativeRequestId: string;
    creatorId: string;
    earningType: EARNING_TYPE;
    amount: number;
    creativeUniqueId: string;
    currentEarnings?: number | null;
    month: string;
    toDate: string;
    fromDate: string;
    createdAt: string;
    updatedAt?: string | null;
    importedAt?: string | null;
  } | null;
};

export type GetCreatorBrandBriefsQueryVariables = {
  page?: number | null;
  pageSize?: number | null;
  country?: string | null;
};

export type GetCreatorBrandBriefsQuery = {
  getCreatorBrandBriefs?: {
    __typename: 'GET_CREATOR_BRAND_BRIEFS_RESPONSE';
    items?: Array<{
      __typename: 'BRAND_BREIFS_WITH_BRAND_PROFILE';
      id: string;
      BriefName: string;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      brandId: string;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData?: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      } | null;
      metaData?: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      } | null;
      youtubeData?: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      } | null;
      manualData?: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      } | null;
      creativeRequestsCount?: number | null;
      brandInfo?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      } | null> | null;
    }> | null;
    totalPages: number;
    currentPage: number;
  } | null;
};

export type CreativeRequestStatusEmailQueryVariables = {
  emailType: EMAIL_TYPE;
  name?: string | null;
  email?: string | null;
  brandBriefId: string;
  brandBriefName?: string | null;
  feedback?: string | null;
  creativeUniqueId?: string | null;
  creativeRequestUrl?: string | null;
};

export type CreativeRequestStatusEmailQuery = {
  creativeRequestStatusEmail?: string | null;
};

export type GetCreativeRequestsQueryVariables = {
  page?: number | null;
  pageSize?: number | null;
  brandId: string;
  search?: string | null;
};

export type GetCreativeRequestsQuery = {
  getCreativeRequests?: {
    __typename: 'GET_CREATIVE_REQUESTS_RESPONSE';
    items?: Array<{
      __typename: 'CREATIVE_REQUEST_RESPONSE';
      id: string;
      brandBriefId: string;
      creatorId: string;
      briefDescription?: string | null;
      creatorProfileImage?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      creatorDescription?: string | null;
      creatorName?: string | null;
      creatorImage?: string | null;
      approvedAds?: Array<{
        __typename: 'ApprovedAds';
        id: string;
        creativeRequestId: string;
        creativeRequest?: {
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null;
        identity_id?: string | null;
        item_id?: string | null;
        ad_id?: string | null;
        ad_group_id?: string | null;
        campaing_id?: string | null;
        advertiser_id?: string | null;
        user_profile_id?: string | null;
        accessToken?: string | null;
        ad_report?: string | null;
        ad_comment?: string | null;
        status?: string | null;
        adName?: string | null;
        approvedAdType?: ApprovedAdType | null;
        owner?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        isManuallyLinked?: boolean | null;
      } | null> | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      brief: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      };
    }> | null;
    totalPages: number;
    totalItems: number;
    currentPage: number;
  } | null;
};

export type GetCreativeRequestsCountQueryVariables = {
  brandId?: string | null;
};

export type GetCreativeRequestsCountQuery = {
  getCreativeRequestsCount?: string | null;
};

export type GetCampaignSpentQueryVariables = {
  userId?: string | null;
  campaignId?: string | null;
};

export type GetCampaignSpentQuery = {
  getCampaignSpent?: string | null;
};

export type GetBrandBriefsQueryVariables = {
  page?: number | null;
  pageSize?: number | null;
  brandId?: string | null;
  search?: string | null;
};

export type GetBrandBriefsQuery = {
  getBrandBriefs?: {
    __typename: 'GET_BRAND_BRIEFS_RESPONSE';
    items?: Array<{
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    }> | null;
    totalPages: number;
    totalItems: number;
    currentPage: number;
  } | null;
};

export type GetBrandAvatarQueryVariables = {};

export type GetBrandAvatarQuery = {
  getBrandAvatar?: string | null;
};

export type CreativeRequestsByCreatorQueryVariables = {
  page?: number | null;
  pageSize?: number | null;
  creatorId?: string | null;
};

export type CreativeRequestsByCreatorQuery = {
  creativeRequestsByCreator?: {
    __typename: 'GET_REQUESTS_BY_CREATOR_RESPONSE';
    items?: Array<{
      __typename: 'CREATIVE_REQUESTS_WITH_BRAND_USER_PROFILE';
      id: string;
      brandBriefId: string;
      creatorId: string;
      briefDescription?: string | null;
      creatorProfileImage?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      creatorDescription?: string | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      creatorName?: string | null;
      creatorImage?: string | null;
      approvedAds?: Array<{
        __typename: 'ApprovedAds';
        id: string;
        creativeRequestId: string;
        creativeRequest?: {
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null;
        identity_id?: string | null;
        item_id?: string | null;
        ad_id?: string | null;
        ad_group_id?: string | null;
        campaing_id?: string | null;
        advertiser_id?: string | null;
        user_profile_id?: string | null;
        accessToken?: string | null;
        ad_report?: string | null;
        ad_comment?: string | null;
        status?: string | null;
        adName?: string | null;
        approvedAdType?: ApprovedAdType | null;
        owner?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        isManuallyLinked?: boolean | null;
      } | null> | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      briefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      brandProfileImage?: string | null;
    }> | null;
    totalPages: number;
    currentPage: number;
  } | null;
};

export type GetGPTresponseQueryVariables = {
  data?: GPT_INPUT | null;
};

export type GetGPTresponseQuery = {
  getGPTresponse?: {
    __typename: 'GPT_RESPONSE';
    responseType?: GPT_RESPONSE_TYPE | null;
    BRAND_NAME?: string | null;
    BRAND_NAME_REFRESH?: string | null;
    BRAND_PILLARS?: string | null;
    BRAND_PILLARS_REFRESH?: string | null;
    BRAND_VALUES?: string | null;
    BRAND_MISSION_STATEMENT?: string | null;
    BRAND_TAGLINE_STATEMENT?: string | null;
    BRAND_TAGLINE_STATEMENT_REFRESH?: string | null;
    BRAND_MISSION_STATEMENT_REFRESH?: string | null;
    error?: boolean | null;
    message?: string | null;
  } | null;
};

export type SendContentSubmissionEmailQueryVariables = {
  email?: string | null;
  name?: string | null;
  brandBriefName?: string | null;
};

export type SendContentSubmissionEmailQuery = {
  sendContentSubmissionEmail?: string | null;
};

export type GetCreativeRequestCountBetweenDatesQueryVariables = {
  startDate: string;
  endDate: string;
};

export type GetCreativeRequestCountBetweenDatesQuery = {
  getCreativeRequestCountBetweenDates?: string | null;
};

export type GetCreativeEarningsQueryVariables = {
  creatorId: string;
};

export type GetCreativeEarningsQuery = {
  getCreativeEarnings?: {
    __typename: 'CREATIVE_REQUEST_EARNINGS_BY_CREATOR_RESPONSE';
    items?: Array<{
      __typename: 'CREATIVE_REQUEST_EARNINGS_BY_CREATOR';
      creativeRequestId: string;
      creativeUniqueId: string;
      creatorId: string;
      creativeRequestEarningId: string;
      previousEarnings: number;
      currentEarnings: number;
      lifetimeEarnings: number;
    } | null> | null;
  } | null;
};

export type GetCreativeEarningsByCreativeQueryVariables = {
  creativeRequestId: string;
};

export type GetCreativeEarningsByCreativeQuery = {
  getCreativeEarningsByCreative?: {
    __typename: 'CREATIVE_REQUEST_EARNINGS_BY_CREATIVE_RESPONSE';
    items?: Array<{
      __typename: 'CREATIVE_REQUEST_EARNINGS_RESPONSE';
      fromDate: string;
      updatedAt: string;
      month: string;
      createdAt: string;
      earningType: EARNING_TYPE;
      amount: number;
      creativeRequestId: string;
      creativeUniqueId: string;
      toDate: string;
      creatorId: string;
      creativeRequestEarningId: string;
    } | null> | null;
  } | null;
};

export type GetApprovedAdsCountWithinRangeQueryVariables = {
  startDate: string;
  endDate: string;
  status: string;
};

export type GetApprovedAdsCountWithinRangeQuery = {
  getApprovedAdsCountWithinRange?: string | null;
};

export type SearchCreativeUsersQueryVariables = {
  page: number;
  pageSize: number;
  search?: string | null;
  sortKey?: string | null;
  sortOrder?: SortOrder | null;
};

export type SearchCreativeUsersQuery = {
  searchCreativeUsers?: {
    __typename: 'SEARCH_CREATIVE_USERS_RESPONSE';
    totalItems: number;
    totalPages: number;
    items?: Array<{
      __typename: 'SEARCH_USERS_RESPONSE';
      id: string;
      uniqueId?: string | null;
      name?: string | null;
      description?: string | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      tiktokAccountAccess?: string | null;
      facebookAccountAccess?: string | null;
      youtubeAccountAccess?: string | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      isValid?: boolean | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      userPaymentDetails?: {
        __typename: 'USER_PAYMENT_DETAILS_RESPONSE';
        id?: string | null;
        accountNumber?: string | null;
        country?: string | null;
        documentId?: string | null;
        firstAddress?: string | null;
        secondAddress?: string | null;
        fullName?: string | null;
        postCode?: string | null;
        swiftCode?: string | null;
        branchCode?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    }> | null;
    currentPage: number;
  } | null;
};

export type GetWhitelistedBrandBriefsQueryVariables = {
  userId: string;
};

export type GetWhitelistedBrandBriefsQuery = {
  getWhitelistedBrandBriefs?: {
    __typename: 'GET_WHITELISTED_BRAND_BRIEFS_RESPONSE';
    brandBriefIds?: Array<string> | null;
  } | null;
};

export type SearchCreativeRequestsQueryVariables = {
  page: number;
  pageSize: number;
  search?: string | null;
  sortKey?: string | null;
  sortOrder?: SortOrder | null;
};

export type SearchCreativeRequestsQuery = {
  searchCreativeRequests?: {
    __typename: 'SEARCH_CREATIVE_REQUESTS_PAGINATED_RESPONSE';
    page: number;
    pageSize: number;
    totalItems: number;
    items?: Array<{
      __typename: 'SEARCH_CREATIVE_REQUESTS_RESPONSE';
      id?: string | null;
      brandBriefId?: string | null;
      uniqueId?: string | null;
      tiktokCreativeUrl?: string | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      approvedAds?: Array<{
        __typename: 'ApprovedAds';
        id: string;
        creativeRequestId: string;
        creativeRequest?: {
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null;
        identity_id?: string | null;
        item_id?: string | null;
        ad_id?: string | null;
        ad_group_id?: string | null;
        campaing_id?: string | null;
        advertiser_id?: string | null;
        user_profile_id?: string | null;
        accessToken?: string | null;
        ad_report?: string | null;
        ad_comment?: string | null;
        status?: string | null;
        adName?: string | null;
        approvedAdType?: ApprovedAdType | null;
        owner?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        isManuallyLinked?: boolean | null;
      } | null> | null;
      creatorId?: string | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status?: CREATIVE_STATUS | null;
      creativePreviewUrl?: string | null;
      tiktokVideoCode?: string | null;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      brandComment?: Array<string | null> | null;
      adminComment?: Array<string | null> | null;
      creatorComment?: Array<string | null> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      creatorProfile?: {
        __typename: 'SEARCH_USERS_RESPONSE';
        id: string;
        uniqueId?: string | null;
        name?: string | null;
        description?: string | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        tiktokAccountAccess?: string | null;
        facebookAccountAccess?: string | null;
        youtubeAccountAccess?: string | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        userPaymentDetails?: {
          __typename: 'USER_PAYMENT_DETAILS_RESPONSE';
          id?: string | null;
          accountNumber?: string | null;
          country?: string | null;
          documentId?: string | null;
          firstAddress?: string | null;
          secondAddress?: string | null;
          fullName?: string | null;
          postCode?: string | null;
          swiftCode?: string | null;
          branchCode?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        } | null;
        isValid?: boolean | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetUserSignUpsCountQueryVariables = {
  userType: USER_TYPES;
  fromDate?: string | null;
  toDate?: string | null;
};

export type GetUserSignUpsCountQuery = {
  getUserSignUpsCount?: {
    __typename: 'USERS_SIGNUPS_COUNT_RESPONSE';
    count: number;
  } | null;
};

export type GetUserProfileQueryVariables = {
  id: string;
};

export type GetUserProfileQuery = {
  getUserProfile?: {
    __typename: 'UserProfile';
    id: string;
    uniqueId?: string | null;
    name: string;
    description?: string | null;
    brand?: {
      __typename: 'ModelBrandProfileConnection';
      items: Array<{
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    userType?: USER_TYPES | null;
    tiktokHandler?: string | null;
    instagramHandler?: string | null;
    youtubeHandler?: string | null;
    instagram_username?: string | null;
    tiktok_username?: string | null;
    instagram_followers_count?: number | null;
    instagram_media_count?: number | null;
    tiktok_follower_count?: number | null;
    tiktok_video_count?: number | null;
    bestPractices?: {
      __typename: 'ModelBestPracticesConnection';
      items: Array<{
        __typename: 'BestPractices';
        id: string;
        headLine: string;
        description: string;
        urlPath: string;
        active: string;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBestPracticesId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    tiktokAccountAccess?: {
      __typename: 'TiktokAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    facebookAccountAccess?: {
      __typename: 'FacebookAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    youtubeAccountAccess?: {
      __typename: 'YoutubeAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    userPaymentDetails?: {
      __typename: 'UserPaymentDetails';
      id: string;
      fullName: string;
      firstAddress: string;
      secondAddress?: string | null;
      country: string;
      accountNumber: string;
      postCode?: string | null;
      swiftCode: string;
      documentID?: string | null;
      owner?: string | null;
      userTransactions?: {
        __typename: 'ModelUserTransactionsConnection';
        items: Array<{
          __typename: 'UserTransactions';
          id: string;
          paymentStatus?: PAYMENT_STATUS | null;
          paymentAmount?: number | null;
          paymentAmountZar?: number | null;
          userProfileId?: string | null;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userPaymentDetailsUserTransactionsId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      confirmationLetter?: string | null;
      proofOfAddress?: string | null;
      branchCode?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    userWallet?: {
      __typename: 'UserWallet';
      id: string;
      currentBalance?: number | null;
      currentBalanceZar?: number | null;
      totalEarned?: number | null;
      totalEarnedZar?: number | null;
      extraBalance?: number | null;
      extraBalanceZar?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner?: string | null;
    } | null;
    lastLoginDate?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    country?: string | null;
    hashtags?: Array<string> | null;
    profileContent?: Array<string> | null;
    avatar?: string | null;
    vertical?: string | null;
    termsAndConditions?: boolean | null;
    isNotified?: boolean | null;
    isValid?: boolean | null;
    hubProgress?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListUserProfilesQueryVariables = {
  filter?: ModelUserProfileFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListUserProfilesQuery = {
  listUserProfiles?: {
    __typename: 'ModelUserProfileConnection';
    items: Array<{
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UserProfilesByUserTypeQueryVariables = {
  userType: USER_TYPES;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelUserProfileFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type UserProfilesByUserTypeQuery = {
  userProfilesByUserType?: {
    __typename: 'ModelUserProfileConnection';
    items: Array<{
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UserProfilesByEmailQueryVariables = {
  email: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelUserProfileFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type UserProfilesByEmailQuery = {
  userProfilesByEmail?: {
    __typename: 'ModelUserProfileConnection';
    items: Array<{
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetUserPaymentDetailsQueryVariables = {
  id: string;
};

export type GetUserPaymentDetailsQuery = {
  getUserPaymentDetails?: {
    __typename: 'UserPaymentDetails';
    id: string;
    fullName: string;
    firstAddress: string;
    secondAddress?: string | null;
    country: string;
    accountNumber: string;
    postCode?: string | null;
    swiftCode: string;
    documentID?: string | null;
    owner?: string | null;
    userTransactions?: {
      __typename: 'ModelUserTransactionsConnection';
      items: Array<{
        __typename: 'UserTransactions';
        id: string;
        paymentStatus?: PAYMENT_STATUS | null;
        paymentAmount?: number | null;
        paymentAmountZar?: number | null;
        userProfileId?: string | null;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userPaymentDetailsUserTransactionsId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    confirmationLetter?: string | null;
    proofOfAddress?: string | null;
    branchCode?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListUserPaymentDetailsQueryVariables = {
  filter?: ModelUserPaymentDetailsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListUserPaymentDetailsQuery = {
  listUserPaymentDetails?: {
    __typename: 'ModelUserPaymentDetailsConnection';
    items: Array<{
      __typename: 'UserPaymentDetails';
      id: string;
      fullName: string;
      firstAddress: string;
      secondAddress?: string | null;
      country: string;
      accountNumber: string;
      postCode?: string | null;
      swiftCode: string;
      documentID?: string | null;
      owner?: string | null;
      userTransactions?: {
        __typename: 'ModelUserTransactionsConnection';
        items: Array<{
          __typename: 'UserTransactions';
          id: string;
          paymentStatus?: PAYMENT_STATUS | null;
          paymentAmount?: number | null;
          paymentAmountZar?: number | null;
          userProfileId?: string | null;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userPaymentDetailsUserTransactionsId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      confirmationLetter?: string | null;
      proofOfAddress?: string | null;
      branchCode?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetUserWalletQueryVariables = {
  id: string;
};

export type GetUserWalletQuery = {
  getUserWallet?: {
    __typename: 'UserWallet';
    id: string;
    currentBalance?: number | null;
    currentBalanceZar?: number | null;
    totalEarned?: number | null;
    totalEarnedZar?: number | null;
    extraBalance?: number | null;
    extraBalanceZar?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner?: string | null;
  } | null;
};

export type ListUserWalletsQueryVariables = {
  filter?: ModelUserWalletFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListUserWalletsQuery = {
  listUserWallets?: {
    __typename: 'ModelUserWalletConnection';
    items: Array<{
      __typename: 'UserWallet';
      id: string;
      currentBalance?: number | null;
      currentBalanceZar?: number | null;
      totalEarned?: number | null;
      totalEarnedZar?: number | null;
      extraBalance?: number | null;
      extraBalanceZar?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UserWalletsByOwnerQueryVariables = {
  owner: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelUserWalletFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type UserWalletsByOwnerQuery = {
  userWalletsByOwner?: {
    __typename: 'ModelUserWalletConnection';
    items: Array<{
      __typename: 'UserWallet';
      id: string;
      currentBalance?: number | null;
      currentBalanceZar?: number | null;
      totalEarned?: number | null;
      totalEarnedZar?: number | null;
      extraBalance?: number | null;
      extraBalanceZar?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetUserTransactionsQueryVariables = {
  id: string;
};

export type GetUserTransactionsQuery = {
  getUserTransactions?: {
    __typename: 'UserTransactions';
    id: string;
    paymentStatus?: PAYMENT_STATUS | null;
    paymentAmount?: number | null;
    paymentAmountZar?: number | null;
    userProfileId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userPaymentDetailsUserTransactionsId?: string | null;
  } | null;
};

export type ListUserTransactionsQueryVariables = {
  filter?: ModelUserTransactionsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListUserTransactionsQuery = {
  listUserTransactions?: {
    __typename: 'ModelUserTransactionsConnection';
    items: Array<{
      __typename: 'UserTransactions';
      id: string;
      paymentStatus?: PAYMENT_STATUS | null;
      paymentAmount?: number | null;
      paymentAmountZar?: number | null;
      userProfileId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      userPaymentDetailsUserTransactionsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetApprovedAdsQueryVariables = {
  id: string;
};

export type GetApprovedAdsQuery = {
  getApprovedAds?: {
    __typename: 'ApprovedAds';
    id: string;
    creativeRequestId: string;
    creativeRequest?: {
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null;
    identity_id?: string | null;
    item_id?: string | null;
    ad_id?: string | null;
    ad_group_id?: string | null;
    campaing_id?: string | null;
    advertiser_id?: string | null;
    user_profile_id?: string | null;
    accessToken?: string | null;
    ad_report?: string | null;
    ad_comment?: string | null;
    status?: string | null;
    adName?: string | null;
    approvedAdType?: ApprovedAdType | null;
    owner?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    isManuallyLinked?: boolean | null;
  } | null;
};

export type ListApprovedAdsQueryVariables = {
  filter?: ModelApprovedAdsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListApprovedAdsQuery = {
  listApprovedAds?: {
    __typename: 'ModelApprovedAdsConnection';
    items: Array<{
      __typename: 'ApprovedAds';
      id: string;
      creativeRequestId: string;
      creativeRequest?: {
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null;
      identity_id?: string | null;
      item_id?: string | null;
      ad_id?: string | null;
      ad_group_id?: string | null;
      campaing_id?: string | null;
      advertiser_id?: string | null;
      user_profile_id?: string | null;
      accessToken?: string | null;
      ad_report?: string | null;
      ad_comment?: string | null;
      status?: string | null;
      adName?: string | null;
      approvedAdType?: ApprovedAdType | null;
      owner?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      isManuallyLinked?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ApprovedAdsByIdQueryVariables = {
  id: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelApprovedAdsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ApprovedAdsByIdQuery = {
  approvedAdsById?: {
    __typename: 'ModelApprovedAdsConnection';
    items: Array<{
      __typename: 'ApprovedAds';
      id: string;
      creativeRequestId: string;
      creativeRequest?: {
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null;
      identity_id?: string | null;
      item_id?: string | null;
      ad_id?: string | null;
      ad_group_id?: string | null;
      campaing_id?: string | null;
      advertiser_id?: string | null;
      user_profile_id?: string | null;
      accessToken?: string | null;
      ad_report?: string | null;
      ad_comment?: string | null;
      status?: string | null;
      adName?: string | null;
      approvedAdType?: ApprovedAdType | null;
      owner?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      isManuallyLinked?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ApprovedAdsByCreativeRequestIdQueryVariables = {
  creativeRequestId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelApprovedAdsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ApprovedAdsByCreativeRequestIdQuery = {
  approvedAdsByCreativeRequestId?: {
    __typename: 'ModelApprovedAdsConnection';
    items: Array<{
      __typename: 'ApprovedAds';
      id: string;
      creativeRequestId: string;
      creativeRequest?: {
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null;
      identity_id?: string | null;
      item_id?: string | null;
      ad_id?: string | null;
      ad_group_id?: string | null;
      campaing_id?: string | null;
      advertiser_id?: string | null;
      user_profile_id?: string | null;
      accessToken?: string | null;
      ad_report?: string | null;
      ad_comment?: string | null;
      status?: string | null;
      adName?: string | null;
      approvedAdType?: ApprovedAdType | null;
      owner?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      isManuallyLinked?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ApprovedAdsByAd_idQueryVariables = {
  ad_id: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelApprovedAdsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ApprovedAdsByAd_idQuery = {
  approvedAdsByAd_id?: {
    __typename: 'ModelApprovedAdsConnection';
    items: Array<{
      __typename: 'ApprovedAds';
      id: string;
      creativeRequestId: string;
      creativeRequest?: {
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null;
      identity_id?: string | null;
      item_id?: string | null;
      ad_id?: string | null;
      ad_group_id?: string | null;
      campaing_id?: string | null;
      advertiser_id?: string | null;
      user_profile_id?: string | null;
      accessToken?: string | null;
      ad_report?: string | null;
      ad_comment?: string | null;
      status?: string | null;
      adName?: string | null;
      approvedAdType?: ApprovedAdType | null;
      owner?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      isManuallyLinked?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ApprovedAdsByAd_group_idQueryVariables = {
  ad_group_id: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelApprovedAdsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ApprovedAdsByAd_group_idQuery = {
  approvedAdsByAd_group_id?: {
    __typename: 'ModelApprovedAdsConnection';
    items: Array<{
      __typename: 'ApprovedAds';
      id: string;
      creativeRequestId: string;
      creativeRequest?: {
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null;
      identity_id?: string | null;
      item_id?: string | null;
      ad_id?: string | null;
      ad_group_id?: string | null;
      campaing_id?: string | null;
      advertiser_id?: string | null;
      user_profile_id?: string | null;
      accessToken?: string | null;
      ad_report?: string | null;
      ad_comment?: string | null;
      status?: string | null;
      adName?: string | null;
      approvedAdType?: ApprovedAdType | null;
      owner?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      isManuallyLinked?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ApprovedAdsByCampaing_idQueryVariables = {
  campaing_id: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelApprovedAdsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ApprovedAdsByCampaing_idQuery = {
  approvedAdsByCampaing_id?: {
    __typename: 'ModelApprovedAdsConnection';
    items: Array<{
      __typename: 'ApprovedAds';
      id: string;
      creativeRequestId: string;
      creativeRequest?: {
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null;
      identity_id?: string | null;
      item_id?: string | null;
      ad_id?: string | null;
      ad_group_id?: string | null;
      campaing_id?: string | null;
      advertiser_id?: string | null;
      user_profile_id?: string | null;
      accessToken?: string | null;
      ad_report?: string | null;
      ad_comment?: string | null;
      status?: string | null;
      adName?: string | null;
      approvedAdType?: ApprovedAdType | null;
      owner?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      isManuallyLinked?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ApprovedAdsByAdvertiser_idQueryVariables = {
  advertiser_id: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelApprovedAdsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ApprovedAdsByAdvertiser_idQuery = {
  approvedAdsByAdvertiser_id?: {
    __typename: 'ModelApprovedAdsConnection';
    items: Array<{
      __typename: 'ApprovedAds';
      id: string;
      creativeRequestId: string;
      creativeRequest?: {
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null;
      identity_id?: string | null;
      item_id?: string | null;
      ad_id?: string | null;
      ad_group_id?: string | null;
      campaing_id?: string | null;
      advertiser_id?: string | null;
      user_profile_id?: string | null;
      accessToken?: string | null;
      ad_report?: string | null;
      ad_comment?: string | null;
      status?: string | null;
      adName?: string | null;
      approvedAdType?: ApprovedAdType | null;
      owner?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      isManuallyLinked?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ApprovedAdsByUser_profile_idQueryVariables = {
  user_profile_id: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelApprovedAdsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ApprovedAdsByUser_profile_idQuery = {
  approvedAdsByUser_profile_id?: {
    __typename: 'ModelApprovedAdsConnection';
    items: Array<{
      __typename: 'ApprovedAds';
      id: string;
      creativeRequestId: string;
      creativeRequest?: {
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null;
      identity_id?: string | null;
      item_id?: string | null;
      ad_id?: string | null;
      ad_group_id?: string | null;
      campaing_id?: string | null;
      advertiser_id?: string | null;
      user_profile_id?: string | null;
      accessToken?: string | null;
      ad_report?: string | null;
      ad_comment?: string | null;
      status?: string | null;
      adName?: string | null;
      approvedAdType?: ApprovedAdType | null;
      owner?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      isManuallyLinked?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ApprovedAdsByStatusQueryVariables = {
  status: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelApprovedAdsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ApprovedAdsByStatusQuery = {
  approvedAdsByStatus?: {
    __typename: 'ModelApprovedAdsConnection';
    items: Array<{
      __typename: 'ApprovedAds';
      id: string;
      creativeRequestId: string;
      creativeRequest?: {
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null;
      identity_id?: string | null;
      item_id?: string | null;
      ad_id?: string | null;
      ad_group_id?: string | null;
      campaing_id?: string | null;
      advertiser_id?: string | null;
      user_profile_id?: string | null;
      accessToken?: string | null;
      ad_report?: string | null;
      ad_comment?: string | null;
      status?: string | null;
      adName?: string | null;
      approvedAdType?: ApprovedAdType | null;
      owner?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      isManuallyLinked?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetBestPracticesQueryVariables = {
  id: string;
};

export type GetBestPracticesQuery = {
  getBestPractices?: {
    __typename: 'BestPractices';
    id: string;
    headLine: string;
    description: string;
    urlPath: string;
    active: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBestPracticesId?: string | null;
  } | null;
};

export type ListBestPracticesQueryVariables = {
  filter?: ModelBestPracticesFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListBestPracticesQuery = {
  listBestPractices?: {
    __typename: 'ModelBestPracticesConnection';
    items: Array<{
      __typename: 'BestPractices';
      id: string;
      headLine: string;
      description: string;
      urlPath: string;
      active: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      userProfileBestPracticesId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type BestPracticesByActiveQueryVariables = {
  active: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelBestPracticesFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type BestPracticesByActiveQuery = {
  bestPracticesByActive?: {
    __typename: 'ModelBestPracticesConnection';
    items: Array<{
      __typename: 'BestPractices';
      id: string;
      headLine: string;
      description: string;
      urlPath: string;
      active: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      userProfileBestPracticesId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetBrandProfileQueryVariables = {
  id: string;
};

export type GetBrandProfileQuery = {
  getBrandProfile?: {
    __typename: 'BrandProfile';
    id: string;
    name?: string | null;
    toneVoice?: Array<string> | null;
    pillars?: Array<string> | null;
    description?: string | null;
    internalMission?: string | null;
    strapLine?: string | null;
    userEmail?: string | null;
    tiktokHandle?: string | null;
    vertical?: string | null;
    metaData?: string | null;
    userProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    briefs?: {
      __typename: 'ModelBrandBriefConnection';
      items: Array<{
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    hashtags?: Array<string> | null;
    personalDescription?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBrandId?: string | null;
    owner?: string | null;
  } | null;
};

export type ListBrandProfilesQueryVariables = {
  filter?: ModelBrandProfileFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListBrandProfilesQuery = {
  listBrandProfiles?: {
    __typename: 'ModelBrandProfileConnection';
    items: Array<{
      __typename: 'BrandProfile';
      id: string;
      name?: string | null;
      toneVoice?: Array<string> | null;
      pillars?: Array<string> | null;
      description?: string | null;
      internalMission?: string | null;
      strapLine?: string | null;
      userEmail?: string | null;
      tiktokHandle?: string | null;
      vertical?: string | null;
      metaData?: string | null;
      userProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      briefs?: {
        __typename: 'ModelBrandBriefConnection';
        items: Array<{
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      hashtags?: Array<string> | null;
      personalDescription?: string | null;
      createdAt: string;
      updatedAt: string;
      userProfileBrandId?: string | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type BrandProfilesByUserEmailQueryVariables = {
  userEmail: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelBrandProfileFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type BrandProfilesByUserEmailQuery = {
  brandProfilesByUserEmail?: {
    __typename: 'ModelBrandProfileConnection';
    items: Array<{
      __typename: 'BrandProfile';
      id: string;
      name?: string | null;
      toneVoice?: Array<string> | null;
      pillars?: Array<string> | null;
      description?: string | null;
      internalMission?: string | null;
      strapLine?: string | null;
      userEmail?: string | null;
      tiktokHandle?: string | null;
      vertical?: string | null;
      metaData?: string | null;
      userProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      briefs?: {
        __typename: 'ModelBrandBriefConnection';
        items: Array<{
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      hashtags?: Array<string> | null;
      personalDescription?: string | null;
      createdAt: string;
      updatedAt: string;
      userProfileBrandId?: string | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetBrandBriefQueryVariables = {
  id: string;
};

export type GetBrandBriefQuery = {
  getBrandBrief?: {
    __typename: 'BrandBrief';
    id: string;
    BriefName: string;
    TargetAudience?: string | null;
    vertical: string;
    objective: string;
    brandBriefDetails: string;
    brandBriefFilesUrl?: string | null;
    creativeInspirations?: Array<string> | null;
    active: boolean;
    brandName?: string | null;
    brandImageUrl?: string | null;
    tiktokAdvertiserId?: string | null;
    creativeRequests?: {
      __typename: 'ModelCreativeRequestConnection';
      items: Array<{
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandId: string;
    brandProfile?: {
      __typename: 'BrandProfile';
      id: string;
      name?: string | null;
      toneVoice?: Array<string> | null;
      pillars?: Array<string> | null;
      description?: string | null;
      internalMission?: string | null;
      strapLine?: string | null;
      userEmail?: string | null;
      tiktokHandle?: string | null;
      vertical?: string | null;
      metaData?: string | null;
      userProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      briefs?: {
        __typename: 'ModelBrandBriefConnection';
        items: Array<{
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      hashtags?: Array<string> | null;
      personalDescription?: string | null;
      createdAt: string;
      updatedAt: string;
      userProfileBrandId?: string | null;
      owner?: string | null;
    } | null;
    adText: string;
    country: string;
    type?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tikTokData: {
      __typename: 'tikTokData';
      adIdentityId?: string | null;
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      displayName?: string | null;
      tikTokSparkAds?: boolean | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    metaData: {
      __typename: 'metaData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    youtubeData: {
      __typename: 'youtubeData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    manualData: {
      __typename: 'manualData';
      isManual: boolean;
      adCaption?: string | null;
    };
    whitelist?: Array<{
      __typename: 'WhitelistEntry';
      email: string;
      isApprover: boolean;
    }> | null;
    creativeRequestsCount?: number | null;
    isCreationNotificationSent?: boolean | null;
    messaging?: string | null;
    goodPractices?: Array<string> | null;
    badPractices?: Array<string> | null;
    tags?: Array<string> | null;
    overview?: string | null;
    overviewVideoUrl?: string | null;
    tone?: string | null;
    isCompleted?: boolean | null;
    interestedUsers?: Array<{
      __typename: 'InterestedUser';
      id: string;
      isInterested: boolean;
      hasBeenShown: boolean;
    }> | null;
    commonRejectionReasons?: Array<string> | null;
    maybes?: Array<string> | null;
    owner?: string | null;
  } | null;
};

export type ListBrandBriefsQueryVariables = {
  filter?: ModelBrandBriefFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListBrandBriefsQuery = {
  listBrandBriefs?: {
    __typename: 'ModelBrandBriefConnection';
    items: Array<{
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type BrandBriefByUpdatedAtQueryVariables = {
  id: string;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelBrandBriefFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type BrandBriefByUpdatedAtQuery = {
  brandBriefByUpdatedAt?: {
    __typename: 'ModelBrandBriefConnection';
    items: Array<{
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type BrandBriefsByVerticalQueryVariables = {
  vertical: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelBrandBriefFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type BrandBriefsByVerticalQuery = {
  brandBriefsByVertical?: {
    __typename: 'ModelBrandBriefConnection';
    items: Array<{
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type BrandBriefsByTiktokAdvertiserIdQueryVariables = {
  tiktokAdvertiserId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelBrandBriefFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type BrandBriefsByTiktokAdvertiserIdQuery = {
  brandBriefsByTiktokAdvertiserId?: {
    __typename: 'ModelBrandBriefConnection';
    items: Array<{
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ByBrandQueryVariables = {
  brandId: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelBrandBriefFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ByBrandQuery = {
  byBrand?: {
    __typename: 'ModelBrandBriefConnection';
    items: Array<{
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type BrandBriefsByCountryAndCreationDateQueryVariables = {
  country: string;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelBrandBriefFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type BrandBriefsByCountryAndCreationDateQuery = {
  brandBriefsByCountryAndCreationDate?: {
    __typename: 'ModelBrandBriefConnection';
    items: Array<{
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type BrandBriefByDateQueryVariables = {
  type: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelBrandBriefFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type BrandBriefByDateQuery = {
  brandBriefByDate?: {
    __typename: 'ModelBrandBriefConnection';
    items: Array<{
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetCreativeRequestApprovalQueryVariables = {
  id: string;
};

export type GetCreativeRequestApprovalQuery = {
  getCreativeRequestApproval?: {
    __typename: 'CreativeRequestApproval';
    id: string;
    creativeRequestId: string;
    approverEmail: string;
    approvalStatus?: ApprovalStatus | null;
    reason?: string | null;
    updatedAt?: string | null;
    createdAt?: string | null;
  } | null;
};

export type ListCreativeRequestApprovalsQueryVariables = {
  filter?: ModelCreativeRequestApprovalFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCreativeRequestApprovalsQuery = {
  listCreativeRequestApprovals?: {
    __typename: 'ModelCreativeRequestApprovalConnection';
    items: Array<{
      __typename: 'CreativeRequestApproval';
      id: string;
      creativeRequestId: string;
      approverEmail: string;
      approvalStatus?: ApprovalStatus | null;
      reason?: string | null;
      updatedAt?: string | null;
      createdAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreativeRequestApprovalsByCreativeRequestIdQueryVariables = {
  creativeRequestId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCreativeRequestApprovalFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CreativeRequestApprovalsByCreativeRequestIdQuery = {
  creativeRequestApprovalsByCreativeRequestId?: {
    __typename: 'ModelCreativeRequestApprovalConnection';
    items: Array<{
      __typename: 'CreativeRequestApproval';
      id: string;
      creativeRequestId: string;
      approverEmail: string;
      approvalStatus?: ApprovalStatus | null;
      reason?: string | null;
      updatedAt?: string | null;
      createdAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetCreativeRequestQueryVariables = {
  id: string;
};

export type GetCreativeRequestQuery = {
  getCreativeRequest?: {
    __typename: 'CreativeRequest';
    id: string;
    brandBriefId: string;
    creatorId: string;
    creatorProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    brief?: {
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null;
    lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
    brandProfileImageUrl?: string | null;
    briefDescription?: string | null;
    ad_id?: string | null;
    status: CREATIVE_STATUS;
    tiktokCreativeUrl: string;
    creativePreviewUrl?: string | null;
    tiktokVideoCode: string;
    creativeTiktokHandle?: string | null;
    creativeYoutubeHandle?: string | null;
    creativeInstagramHandle?: string | null;
    approvedAds?: {
      __typename: 'ModelApprovedAdsConnection';
      items: Array<{
        __typename: 'ApprovedAds';
        id: string;
        creativeRequestId: string;
        creativeRequest?: {
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null;
        identity_id?: string | null;
        item_id?: string | null;
        ad_id?: string | null;
        ad_group_id?: string | null;
        campaing_id?: string | null;
        advertiser_id?: string | null;
        user_profile_id?: string | null;
        accessToken?: string | null;
        ad_report?: string | null;
        ad_comment?: string | null;
        status?: string | null;
        adName?: string | null;
        approvedAdType?: ApprovedAdType | null;
        owner?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        isManuallyLinked?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandComment?: Array<string> | null;
    adminComment?: Array<string> | null;
    creatorComment?: Array<string> | null;
    adminApproval?: ADMIN_STATUS | null;
    creatorVisibility?: CREATOR_VISIBILITY | null;
    BriefName?: string | null;
    type?: string | null;
    email?: string | null;
    uniqueId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    brandName?: string | null;
    approvals?: {
      __typename: 'ModelCreativeRequestApprovalConnection';
      items: Array<{
        __typename: 'CreativeRequestApproval';
        id: string;
        creativeRequestId: string;
        approverEmail: string;
        approvalStatus?: ApprovalStatus | null;
        reason?: string | null;
        updatedAt?: string | null;
        createdAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type ListCreativeRequestsQueryVariables = {
  filter?: ModelCreativeRequestFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCreativeRequestsQuery = {
  listCreativeRequests?: {
    __typename: 'ModelCreativeRequestConnection';
    items: Array<{
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreativeRequestsByBrandBriefIdQueryVariables = {
  brandBriefId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCreativeRequestFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CreativeRequestsByBrandBriefIdQuery = {
  creativeRequestsByBrandBriefId?: {
    __typename: 'ModelCreativeRequestConnection';
    items: Array<{
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreativeRequestsByCreatorIdQueryVariables = {
  creatorId: string;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCreativeRequestFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CreativeRequestsByCreatorIdQuery = {
  creativeRequestsByCreatorId?: {
    __typename: 'ModelCreativeRequestConnection';
    items: Array<{
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreativeRequestsByStatusQueryVariables = {
  status: CREATIVE_STATUS;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCreativeRequestFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CreativeRequestsByStatusQuery = {
  creativeRequestsByStatus?: {
    __typename: 'ModelCreativeRequestConnection';
    items: Array<{
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreativeRequestsByAdminApprovalQueryVariables = {
  adminApproval: ADMIN_STATUS;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCreativeRequestFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CreativeRequestsByAdminApprovalQuery = {
  creativeRequestsByAdminApproval?: {
    __typename: 'ModelCreativeRequestConnection';
    items: Array<{
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreativeRequestsByCreatorVisibilityQueryVariables = {
  creatorVisibility: CREATOR_VISIBILITY;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCreativeRequestFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CreativeRequestsByCreatorVisibilityQuery = {
  creativeRequestsByCreatorVisibility?: {
    __typename: 'ModelCreativeRequestConnection';
    items: Array<{
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreativeRequestsByDateQueryVariables = {
  type: string;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCreativeRequestFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CreativeRequestsByDateQuery = {
  creativeRequestsByDate?: {
    __typename: 'ModelCreativeRequestConnection';
    items: Array<{
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreativeRequestsByUniqueIdAndUpdatedAtQueryVariables = {
  uniqueId: string;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCreativeRequestFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CreativeRequestsByUniqueIdAndUpdatedAtQuery = {
  creativeRequestsByUniqueIdAndUpdatedAt?: {
    __typename: 'ModelCreativeRequestConnection';
    items: Array<{
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetCreativeRequestEarningsQueryVariables = {
  creatorId: string;
  creativeRequestEarningId: string;
};

export type GetCreativeRequestEarningsQuery = {
  getCreativeRequestEarnings?: {
    __typename: 'CreativeRequestEarnings';
    creativeRequestEarningId: string;
    creativeRequestId: string;
    creatorId: string;
    earningType: EARNING_TYPE;
    amount: number;
    creativeUniqueId: string;
    currentEarnings?: number | null;
    month: string;
    toDate: string;
    fromDate: string;
    createdAt: string;
    updatedAt?: string | null;
    importedAt?: string | null;
  } | null;
};

export type ListCreativeRequestEarningsQueryVariables = {
  creatorId?: string | null;
  creativeRequestEarningId?: ModelIDKeyConditionInput | null;
  filter?: ModelCreativeRequestEarningsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListCreativeRequestEarningsQuery = {
  listCreativeRequestEarnings?: {
    __typename: 'ModelCreativeRequestEarningsConnection';
    items: Array<{
      __typename: 'CreativeRequestEarnings';
      creativeRequestEarningId: string;
      creativeRequestId: string;
      creatorId: string;
      earningType: EARNING_TYPE;
      amount: number;
      creativeUniqueId: string;
      currentEarnings?: number | null;
      month: string;
      toDate: string;
      fromDate: string;
      createdAt: string;
      updatedAt?: string | null;
      importedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreativeRequestIdQueryVariables = {
  creativeRequestId: string;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCreativeRequestEarningsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CreativeRequestIdQuery = {
  creativeRequestId?: {
    __typename: 'ModelCreativeRequestEarningsConnection';
    items: Array<{
      __typename: 'CreativeRequestEarnings';
      creativeRequestEarningId: string;
      creativeRequestId: string;
      creatorId: string;
      earningType: EARNING_TYPE;
      amount: number;
      creativeUniqueId: string;
      currentEarnings?: number | null;
      month: string;
      toDate: string;
      fromDate: string;
      createdAt: string;
      updatedAt?: string | null;
      importedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateUserProfileSubscriptionVariables = {
  filter?: ModelSubscriptionUserProfileFilterInput | null;
  owner?: string | null;
};

export type OnCreateUserProfileSubscription = {
  onCreateUserProfile?: {
    __typename: 'UserProfile';
    id: string;
    uniqueId?: string | null;
    name: string;
    description?: string | null;
    brand?: {
      __typename: 'ModelBrandProfileConnection';
      items: Array<{
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    userType?: USER_TYPES | null;
    tiktokHandler?: string | null;
    instagramHandler?: string | null;
    youtubeHandler?: string | null;
    instagram_username?: string | null;
    tiktok_username?: string | null;
    instagram_followers_count?: number | null;
    instagram_media_count?: number | null;
    tiktok_follower_count?: number | null;
    tiktok_video_count?: number | null;
    bestPractices?: {
      __typename: 'ModelBestPracticesConnection';
      items: Array<{
        __typename: 'BestPractices';
        id: string;
        headLine: string;
        description: string;
        urlPath: string;
        active: string;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBestPracticesId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    tiktokAccountAccess?: {
      __typename: 'TiktokAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    facebookAccountAccess?: {
      __typename: 'FacebookAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    youtubeAccountAccess?: {
      __typename: 'YoutubeAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    userPaymentDetails?: {
      __typename: 'UserPaymentDetails';
      id: string;
      fullName: string;
      firstAddress: string;
      secondAddress?: string | null;
      country: string;
      accountNumber: string;
      postCode?: string | null;
      swiftCode: string;
      documentID?: string | null;
      owner?: string | null;
      userTransactions?: {
        __typename: 'ModelUserTransactionsConnection';
        items: Array<{
          __typename: 'UserTransactions';
          id: string;
          paymentStatus?: PAYMENT_STATUS | null;
          paymentAmount?: number | null;
          paymentAmountZar?: number | null;
          userProfileId?: string | null;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userPaymentDetailsUserTransactionsId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      confirmationLetter?: string | null;
      proofOfAddress?: string | null;
      branchCode?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    userWallet?: {
      __typename: 'UserWallet';
      id: string;
      currentBalance?: number | null;
      currentBalanceZar?: number | null;
      totalEarned?: number | null;
      totalEarnedZar?: number | null;
      extraBalance?: number | null;
      extraBalanceZar?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner?: string | null;
    } | null;
    lastLoginDate?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    country?: string | null;
    hashtags?: Array<string> | null;
    profileContent?: Array<string> | null;
    avatar?: string | null;
    vertical?: string | null;
    termsAndConditions?: boolean | null;
    isNotified?: boolean | null;
    isValid?: boolean | null;
    hubProgress?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateUserProfileSubscriptionVariables = {
  filter?: ModelSubscriptionUserProfileFilterInput | null;
  owner?: string | null;
};

export type OnUpdateUserProfileSubscription = {
  onUpdateUserProfile?: {
    __typename: 'UserProfile';
    id: string;
    uniqueId?: string | null;
    name: string;
    description?: string | null;
    brand?: {
      __typename: 'ModelBrandProfileConnection';
      items: Array<{
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    userType?: USER_TYPES | null;
    tiktokHandler?: string | null;
    instagramHandler?: string | null;
    youtubeHandler?: string | null;
    instagram_username?: string | null;
    tiktok_username?: string | null;
    instagram_followers_count?: number | null;
    instagram_media_count?: number | null;
    tiktok_follower_count?: number | null;
    tiktok_video_count?: number | null;
    bestPractices?: {
      __typename: 'ModelBestPracticesConnection';
      items: Array<{
        __typename: 'BestPractices';
        id: string;
        headLine: string;
        description: string;
        urlPath: string;
        active: string;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBestPracticesId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    tiktokAccountAccess?: {
      __typename: 'TiktokAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    facebookAccountAccess?: {
      __typename: 'FacebookAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    youtubeAccountAccess?: {
      __typename: 'YoutubeAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    userPaymentDetails?: {
      __typename: 'UserPaymentDetails';
      id: string;
      fullName: string;
      firstAddress: string;
      secondAddress?: string | null;
      country: string;
      accountNumber: string;
      postCode?: string | null;
      swiftCode: string;
      documentID?: string | null;
      owner?: string | null;
      userTransactions?: {
        __typename: 'ModelUserTransactionsConnection';
        items: Array<{
          __typename: 'UserTransactions';
          id: string;
          paymentStatus?: PAYMENT_STATUS | null;
          paymentAmount?: number | null;
          paymentAmountZar?: number | null;
          userProfileId?: string | null;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userPaymentDetailsUserTransactionsId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      confirmationLetter?: string | null;
      proofOfAddress?: string | null;
      branchCode?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    userWallet?: {
      __typename: 'UserWallet';
      id: string;
      currentBalance?: number | null;
      currentBalanceZar?: number | null;
      totalEarned?: number | null;
      totalEarnedZar?: number | null;
      extraBalance?: number | null;
      extraBalanceZar?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner?: string | null;
    } | null;
    lastLoginDate?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    country?: string | null;
    hashtags?: Array<string> | null;
    profileContent?: Array<string> | null;
    avatar?: string | null;
    vertical?: string | null;
    termsAndConditions?: boolean | null;
    isNotified?: boolean | null;
    isValid?: boolean | null;
    hubProgress?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteUserProfileSubscriptionVariables = {
  filter?: ModelSubscriptionUserProfileFilterInput | null;
  owner?: string | null;
};

export type OnDeleteUserProfileSubscription = {
  onDeleteUserProfile?: {
    __typename: 'UserProfile';
    id: string;
    uniqueId?: string | null;
    name: string;
    description?: string | null;
    brand?: {
      __typename: 'ModelBrandProfileConnection';
      items: Array<{
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    userType?: USER_TYPES | null;
    tiktokHandler?: string | null;
    instagramHandler?: string | null;
    youtubeHandler?: string | null;
    instagram_username?: string | null;
    tiktok_username?: string | null;
    instagram_followers_count?: number | null;
    instagram_media_count?: number | null;
    tiktok_follower_count?: number | null;
    tiktok_video_count?: number | null;
    bestPractices?: {
      __typename: 'ModelBestPracticesConnection';
      items: Array<{
        __typename: 'BestPractices';
        id: string;
        headLine: string;
        description: string;
        urlPath: string;
        active: string;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBestPracticesId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    tiktokAccountAccess?: {
      __typename: 'TiktokAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    facebookAccountAccess?: {
      __typename: 'FacebookAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    youtubeAccountAccess?: {
      __typename: 'YoutubeAccountAccess';
      access_token?: string | null;
      advertiser_id?: string | null;
      advertisers_list?: Array<{
        __typename: 'AdvertiserData';
        advertiser_id?: string | null;
        advertiser_name?: string | null;
      }> | null;
    } | null;
    userPaymentDetails?: {
      __typename: 'UserPaymentDetails';
      id: string;
      fullName: string;
      firstAddress: string;
      secondAddress?: string | null;
      country: string;
      accountNumber: string;
      postCode?: string | null;
      swiftCode: string;
      documentID?: string | null;
      owner?: string | null;
      userTransactions?: {
        __typename: 'ModelUserTransactionsConnection';
        items: Array<{
          __typename: 'UserTransactions';
          id: string;
          paymentStatus?: PAYMENT_STATUS | null;
          paymentAmount?: number | null;
          paymentAmountZar?: number | null;
          userProfileId?: string | null;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userPaymentDetailsUserTransactionsId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      confirmationLetter?: string | null;
      proofOfAddress?: string | null;
      branchCode?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    userWallet?: {
      __typename: 'UserWallet';
      id: string;
      currentBalance?: number | null;
      currentBalanceZar?: number | null;
      totalEarned?: number | null;
      totalEarnedZar?: number | null;
      extraBalance?: number | null;
      extraBalanceZar?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner?: string | null;
    } | null;
    lastLoginDate?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    country?: string | null;
    hashtags?: Array<string> | null;
    profileContent?: Array<string> | null;
    avatar?: string | null;
    vertical?: string | null;
    termsAndConditions?: boolean | null;
    isNotified?: boolean | null;
    isValid?: boolean | null;
    hubProgress?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateUserPaymentDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionUserPaymentDetailsFilterInput | null;
  owner?: string | null;
};

export type OnCreateUserPaymentDetailsSubscription = {
  onCreateUserPaymentDetails?: {
    __typename: 'UserPaymentDetails';
    id: string;
    fullName: string;
    firstAddress: string;
    secondAddress?: string | null;
    country: string;
    accountNumber: string;
    postCode?: string | null;
    swiftCode: string;
    documentID?: string | null;
    owner?: string | null;
    userTransactions?: {
      __typename: 'ModelUserTransactionsConnection';
      items: Array<{
        __typename: 'UserTransactions';
        id: string;
        paymentStatus?: PAYMENT_STATUS | null;
        paymentAmount?: number | null;
        paymentAmountZar?: number | null;
        userProfileId?: string | null;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userPaymentDetailsUserTransactionsId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    confirmationLetter?: string | null;
    proofOfAddress?: string | null;
    branchCode?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateUserPaymentDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionUserPaymentDetailsFilterInput | null;
  owner?: string | null;
};

export type OnUpdateUserPaymentDetailsSubscription = {
  onUpdateUserPaymentDetails?: {
    __typename: 'UserPaymentDetails';
    id: string;
    fullName: string;
    firstAddress: string;
    secondAddress?: string | null;
    country: string;
    accountNumber: string;
    postCode?: string | null;
    swiftCode: string;
    documentID?: string | null;
    owner?: string | null;
    userTransactions?: {
      __typename: 'ModelUserTransactionsConnection';
      items: Array<{
        __typename: 'UserTransactions';
        id: string;
        paymentStatus?: PAYMENT_STATUS | null;
        paymentAmount?: number | null;
        paymentAmountZar?: number | null;
        userProfileId?: string | null;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userPaymentDetailsUserTransactionsId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    confirmationLetter?: string | null;
    proofOfAddress?: string | null;
    branchCode?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteUserPaymentDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionUserPaymentDetailsFilterInput | null;
  owner?: string | null;
};

export type OnDeleteUserPaymentDetailsSubscription = {
  onDeleteUserPaymentDetails?: {
    __typename: 'UserPaymentDetails';
    id: string;
    fullName: string;
    firstAddress: string;
    secondAddress?: string | null;
    country: string;
    accountNumber: string;
    postCode?: string | null;
    swiftCode: string;
    documentID?: string | null;
    owner?: string | null;
    userTransactions?: {
      __typename: 'ModelUserTransactionsConnection';
      items: Array<{
        __typename: 'UserTransactions';
        id: string;
        paymentStatus?: PAYMENT_STATUS | null;
        paymentAmount?: number | null;
        paymentAmountZar?: number | null;
        userProfileId?: string | null;
        owner?: string | null;
        createdAt: string;
        updatedAt: string;
        userPaymentDetailsUserTransactionsId?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    confirmationLetter?: string | null;
    proofOfAddress?: string | null;
    branchCode?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateUserWalletSubscriptionVariables = {
  filter?: ModelSubscriptionUserWalletFilterInput | null;
  owner?: string | null;
};

export type OnCreateUserWalletSubscription = {
  onCreateUserWallet?: {
    __typename: 'UserWallet';
    id: string;
    currentBalance?: number | null;
    currentBalanceZar?: number | null;
    totalEarned?: number | null;
    totalEarnedZar?: number | null;
    extraBalance?: number | null;
    extraBalanceZar?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner?: string | null;
  } | null;
};

export type OnUpdateUserWalletSubscriptionVariables = {
  filter?: ModelSubscriptionUserWalletFilterInput | null;
  owner?: string | null;
};

export type OnUpdateUserWalletSubscription = {
  onUpdateUserWallet?: {
    __typename: 'UserWallet';
    id: string;
    currentBalance?: number | null;
    currentBalanceZar?: number | null;
    totalEarned?: number | null;
    totalEarnedZar?: number | null;
    extraBalance?: number | null;
    extraBalanceZar?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner?: string | null;
  } | null;
};

export type OnDeleteUserWalletSubscriptionVariables = {
  filter?: ModelSubscriptionUserWalletFilterInput | null;
  owner?: string | null;
};

export type OnDeleteUserWalletSubscription = {
  onDeleteUserWallet?: {
    __typename: 'UserWallet';
    id: string;
    currentBalance?: number | null;
    currentBalanceZar?: number | null;
    totalEarned?: number | null;
    totalEarnedZar?: number | null;
    extraBalance?: number | null;
    extraBalanceZar?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner?: string | null;
  } | null;
};

export type OnCreateUserTransactionsSubscriptionVariables = {
  filter?: ModelSubscriptionUserTransactionsFilterInput | null;
  owner?: string | null;
};

export type OnCreateUserTransactionsSubscription = {
  onCreateUserTransactions?: {
    __typename: 'UserTransactions';
    id: string;
    paymentStatus?: PAYMENT_STATUS | null;
    paymentAmount?: number | null;
    paymentAmountZar?: number | null;
    userProfileId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userPaymentDetailsUserTransactionsId?: string | null;
  } | null;
};

export type OnUpdateUserTransactionsSubscriptionVariables = {
  filter?: ModelSubscriptionUserTransactionsFilterInput | null;
  owner?: string | null;
};

export type OnUpdateUserTransactionsSubscription = {
  onUpdateUserTransactions?: {
    __typename: 'UserTransactions';
    id: string;
    paymentStatus?: PAYMENT_STATUS | null;
    paymentAmount?: number | null;
    paymentAmountZar?: number | null;
    userProfileId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userPaymentDetailsUserTransactionsId?: string | null;
  } | null;
};

export type OnDeleteUserTransactionsSubscriptionVariables = {
  filter?: ModelSubscriptionUserTransactionsFilterInput | null;
  owner?: string | null;
};

export type OnDeleteUserTransactionsSubscription = {
  onDeleteUserTransactions?: {
    __typename: 'UserTransactions';
    id: string;
    paymentStatus?: PAYMENT_STATUS | null;
    paymentAmount?: number | null;
    paymentAmountZar?: number | null;
    userProfileId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userPaymentDetailsUserTransactionsId?: string | null;
  } | null;
};

export type OnCreateApprovedAdsSubscriptionVariables = {
  filter?: ModelSubscriptionApprovedAdsFilterInput | null;
  owner?: string | null;
};

export type OnCreateApprovedAdsSubscription = {
  onCreateApprovedAds?: {
    __typename: 'ApprovedAds';
    id: string;
    creativeRequestId: string;
    creativeRequest?: {
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null;
    identity_id?: string | null;
    item_id?: string | null;
    ad_id?: string | null;
    ad_group_id?: string | null;
    campaing_id?: string | null;
    advertiser_id?: string | null;
    user_profile_id?: string | null;
    accessToken?: string | null;
    ad_report?: string | null;
    ad_comment?: string | null;
    status?: string | null;
    adName?: string | null;
    approvedAdType?: ApprovedAdType | null;
    owner?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    isManuallyLinked?: boolean | null;
  } | null;
};

export type OnUpdateApprovedAdsSubscriptionVariables = {
  filter?: ModelSubscriptionApprovedAdsFilterInput | null;
  owner?: string | null;
};

export type OnUpdateApprovedAdsSubscription = {
  onUpdateApprovedAds?: {
    __typename: 'ApprovedAds';
    id: string;
    creativeRequestId: string;
    creativeRequest?: {
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null;
    identity_id?: string | null;
    item_id?: string | null;
    ad_id?: string | null;
    ad_group_id?: string | null;
    campaing_id?: string | null;
    advertiser_id?: string | null;
    user_profile_id?: string | null;
    accessToken?: string | null;
    ad_report?: string | null;
    ad_comment?: string | null;
    status?: string | null;
    adName?: string | null;
    approvedAdType?: ApprovedAdType | null;
    owner?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    isManuallyLinked?: boolean | null;
  } | null;
};

export type OnDeleteApprovedAdsSubscriptionVariables = {
  filter?: ModelSubscriptionApprovedAdsFilterInput | null;
  owner?: string | null;
};

export type OnDeleteApprovedAdsSubscription = {
  onDeleteApprovedAds?: {
    __typename: 'ApprovedAds';
    id: string;
    creativeRequestId: string;
    creativeRequest?: {
      __typename: 'CreativeRequest';
      id: string;
      brandBriefId: string;
      creatorId: string;
      creatorProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      brief?: {
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null;
      lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
      brandProfileImageUrl?: string | null;
      briefDescription?: string | null;
      ad_id?: string | null;
      status: CREATIVE_STATUS;
      tiktokCreativeUrl: string;
      creativePreviewUrl?: string | null;
      tiktokVideoCode: string;
      creativeTiktokHandle?: string | null;
      creativeYoutubeHandle?: string | null;
      creativeInstagramHandle?: string | null;
      approvedAds?: {
        __typename: 'ModelApprovedAdsConnection';
        items: Array<{
          __typename: 'ApprovedAds';
          id: string;
          creativeRequestId: string;
          identity_id?: string | null;
          item_id?: string | null;
          ad_id?: string | null;
          ad_group_id?: string | null;
          campaing_id?: string | null;
          advertiser_id?: string | null;
          user_profile_id?: string | null;
          accessToken?: string | null;
          ad_report?: string | null;
          ad_comment?: string | null;
          status?: string | null;
          adName?: string | null;
          approvedAdType?: ApprovedAdType | null;
          owner?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          isManuallyLinked?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandComment?: Array<string> | null;
      adminComment?: Array<string> | null;
      creatorComment?: Array<string> | null;
      adminApproval?: ADMIN_STATUS | null;
      creatorVisibility?: CREATOR_VISIBILITY | null;
      BriefName?: string | null;
      type?: string | null;
      email?: string | null;
      uniqueId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      brandName?: string | null;
      approvals?: {
        __typename: 'ModelCreativeRequestApprovalConnection';
        items: Array<{
          __typename: 'CreativeRequestApproval';
          id: string;
          creativeRequestId: string;
          approverEmail: string;
          approvalStatus?: ApprovalStatus | null;
          reason?: string | null;
          updatedAt?: string | null;
          createdAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
    } | null;
    identity_id?: string | null;
    item_id?: string | null;
    ad_id?: string | null;
    ad_group_id?: string | null;
    campaing_id?: string | null;
    advertiser_id?: string | null;
    user_profile_id?: string | null;
    accessToken?: string | null;
    ad_report?: string | null;
    ad_comment?: string | null;
    status?: string | null;
    adName?: string | null;
    approvedAdType?: ApprovedAdType | null;
    owner?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    isManuallyLinked?: boolean | null;
  } | null;
};

export type OnCreateBestPracticesSubscriptionVariables = {
  filter?: ModelSubscriptionBestPracticesFilterInput | null;
  owner?: string | null;
};

export type OnCreateBestPracticesSubscription = {
  onCreateBestPractices?: {
    __typename: 'BestPractices';
    id: string;
    headLine: string;
    description: string;
    urlPath: string;
    active: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBestPracticesId?: string | null;
  } | null;
};

export type OnUpdateBestPracticesSubscriptionVariables = {
  filter?: ModelSubscriptionBestPracticesFilterInput | null;
  owner?: string | null;
};

export type OnUpdateBestPracticesSubscription = {
  onUpdateBestPractices?: {
    __typename: 'BestPractices';
    id: string;
    headLine: string;
    description: string;
    urlPath: string;
    active: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBestPracticesId?: string | null;
  } | null;
};

export type OnDeleteBestPracticesSubscriptionVariables = {
  filter?: ModelSubscriptionBestPracticesFilterInput | null;
  owner?: string | null;
};

export type OnDeleteBestPracticesSubscription = {
  onDeleteBestPractices?: {
    __typename: 'BestPractices';
    id: string;
    headLine: string;
    description: string;
    urlPath: string;
    active: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBestPracticesId?: string | null;
  } | null;
};

export type OnCreateBrandProfileSubscriptionVariables = {
  filter?: ModelSubscriptionBrandProfileFilterInput | null;
  owner?: string | null;
};

export type OnCreateBrandProfileSubscription = {
  onCreateBrandProfile?: {
    __typename: 'BrandProfile';
    id: string;
    name?: string | null;
    toneVoice?: Array<string> | null;
    pillars?: Array<string> | null;
    description?: string | null;
    internalMission?: string | null;
    strapLine?: string | null;
    userEmail?: string | null;
    tiktokHandle?: string | null;
    vertical?: string | null;
    metaData?: string | null;
    userProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    briefs?: {
      __typename: 'ModelBrandBriefConnection';
      items: Array<{
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    hashtags?: Array<string> | null;
    personalDescription?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBrandId?: string | null;
    owner?: string | null;
  } | null;
};

export type OnUpdateBrandProfileSubscriptionVariables = {
  filter?: ModelSubscriptionBrandProfileFilterInput | null;
  owner?: string | null;
};

export type OnUpdateBrandProfileSubscription = {
  onUpdateBrandProfile?: {
    __typename: 'BrandProfile';
    id: string;
    name?: string | null;
    toneVoice?: Array<string> | null;
    pillars?: Array<string> | null;
    description?: string | null;
    internalMission?: string | null;
    strapLine?: string | null;
    userEmail?: string | null;
    tiktokHandle?: string | null;
    vertical?: string | null;
    metaData?: string | null;
    userProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    briefs?: {
      __typename: 'ModelBrandBriefConnection';
      items: Array<{
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    hashtags?: Array<string> | null;
    personalDescription?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBrandId?: string | null;
    owner?: string | null;
  } | null;
};

export type OnDeleteBrandProfileSubscriptionVariables = {
  filter?: ModelSubscriptionBrandProfileFilterInput | null;
  owner?: string | null;
};

export type OnDeleteBrandProfileSubscription = {
  onDeleteBrandProfile?: {
    __typename: 'BrandProfile';
    id: string;
    name?: string | null;
    toneVoice?: Array<string> | null;
    pillars?: Array<string> | null;
    description?: string | null;
    internalMission?: string | null;
    strapLine?: string | null;
    userEmail?: string | null;
    tiktokHandle?: string | null;
    vertical?: string | null;
    metaData?: string | null;
    userProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    briefs?: {
      __typename: 'ModelBrandBriefConnection';
      items: Array<{
        __typename: 'BrandBrief';
        id: string;
        BriefName: string;
        TargetAudience?: string | null;
        vertical: string;
        objective: string;
        brandBriefDetails: string;
        brandBriefFilesUrl?: string | null;
        creativeInspirations?: Array<string> | null;
        active: boolean;
        brandName?: string | null;
        brandImageUrl?: string | null;
        tiktokAdvertiserId?: string | null;
        creativeRequests?: {
          __typename: 'ModelCreativeRequestConnection';
          nextToken?: string | null;
        } | null;
        brandId: string;
        brandProfile?: {
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null;
        adText: string;
        country: string;
        type?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        tikTokData: {
          __typename: 'tikTokData';
          adIdentityId?: string | null;
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          displayName?: string | null;
          tikTokSparkAds?: boolean | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        metaData: {
          __typename: 'metaData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        youtubeData: {
          __typename: 'youtubeData';
          adgroupId?: string | null;
          campaignId?: string | null;
          adCaption?: string | null;
          callToAction?: string | null;
          landingPageUrl?: string | null;
        };
        manualData: {
          __typename: 'manualData';
          isManual: boolean;
          adCaption?: string | null;
        };
        whitelist?: Array<{
          __typename: 'WhitelistEntry';
          email: string;
          isApprover: boolean;
        }> | null;
        creativeRequestsCount?: number | null;
        isCreationNotificationSent?: boolean | null;
        messaging?: string | null;
        goodPractices?: Array<string> | null;
        badPractices?: Array<string> | null;
        tags?: Array<string> | null;
        overview?: string | null;
        overviewVideoUrl?: string | null;
        tone?: string | null;
        isCompleted?: boolean | null;
        interestedUsers?: Array<{
          __typename: 'InterestedUser';
          id: string;
          isInterested: boolean;
          hasBeenShown: boolean;
        }> | null;
        commonRejectionReasons?: Array<string> | null;
        maybes?: Array<string> | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    hashtags?: Array<string> | null;
    personalDescription?: string | null;
    createdAt: string;
    updatedAt: string;
    userProfileBrandId?: string | null;
    owner?: string | null;
  } | null;
};

export type OnCreateBrandBriefSubscriptionVariables = {
  filter?: ModelSubscriptionBrandBriefFilterInput | null;
  owner?: string | null;
};

export type OnCreateBrandBriefSubscription = {
  onCreateBrandBrief?: {
    __typename: 'BrandBrief';
    id: string;
    BriefName: string;
    TargetAudience?: string | null;
    vertical: string;
    objective: string;
    brandBriefDetails: string;
    brandBriefFilesUrl?: string | null;
    creativeInspirations?: Array<string> | null;
    active: boolean;
    brandName?: string | null;
    brandImageUrl?: string | null;
    tiktokAdvertiserId?: string | null;
    creativeRequests?: {
      __typename: 'ModelCreativeRequestConnection';
      items: Array<{
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandId: string;
    brandProfile?: {
      __typename: 'BrandProfile';
      id: string;
      name?: string | null;
      toneVoice?: Array<string> | null;
      pillars?: Array<string> | null;
      description?: string | null;
      internalMission?: string | null;
      strapLine?: string | null;
      userEmail?: string | null;
      tiktokHandle?: string | null;
      vertical?: string | null;
      metaData?: string | null;
      userProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      briefs?: {
        __typename: 'ModelBrandBriefConnection';
        items: Array<{
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      hashtags?: Array<string> | null;
      personalDescription?: string | null;
      createdAt: string;
      updatedAt: string;
      userProfileBrandId?: string | null;
      owner?: string | null;
    } | null;
    adText: string;
    country: string;
    type?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tikTokData: {
      __typename: 'tikTokData';
      adIdentityId?: string | null;
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      displayName?: string | null;
      tikTokSparkAds?: boolean | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    metaData: {
      __typename: 'metaData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    youtubeData: {
      __typename: 'youtubeData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    manualData: {
      __typename: 'manualData';
      isManual: boolean;
      adCaption?: string | null;
    };
    whitelist?: Array<{
      __typename: 'WhitelistEntry';
      email: string;
      isApprover: boolean;
    }> | null;
    creativeRequestsCount?: number | null;
    isCreationNotificationSent?: boolean | null;
    messaging?: string | null;
    goodPractices?: Array<string> | null;
    badPractices?: Array<string> | null;
    tags?: Array<string> | null;
    overview?: string | null;
    overviewVideoUrl?: string | null;
    tone?: string | null;
    isCompleted?: boolean | null;
    interestedUsers?: Array<{
      __typename: 'InterestedUser';
      id: string;
      isInterested: boolean;
      hasBeenShown: boolean;
    }> | null;
    commonRejectionReasons?: Array<string> | null;
    maybes?: Array<string> | null;
    owner?: string | null;
  } | null;
};

export type OnUpdateBrandBriefSubscriptionVariables = {
  filter?: ModelSubscriptionBrandBriefFilterInput | null;
  owner?: string | null;
};

export type OnUpdateBrandBriefSubscription = {
  onUpdateBrandBrief?: {
    __typename: 'BrandBrief';
    id: string;
    BriefName: string;
    TargetAudience?: string | null;
    vertical: string;
    objective: string;
    brandBriefDetails: string;
    brandBriefFilesUrl?: string | null;
    creativeInspirations?: Array<string> | null;
    active: boolean;
    brandName?: string | null;
    brandImageUrl?: string | null;
    tiktokAdvertiserId?: string | null;
    creativeRequests?: {
      __typename: 'ModelCreativeRequestConnection';
      items: Array<{
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandId: string;
    brandProfile?: {
      __typename: 'BrandProfile';
      id: string;
      name?: string | null;
      toneVoice?: Array<string> | null;
      pillars?: Array<string> | null;
      description?: string | null;
      internalMission?: string | null;
      strapLine?: string | null;
      userEmail?: string | null;
      tiktokHandle?: string | null;
      vertical?: string | null;
      metaData?: string | null;
      userProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      briefs?: {
        __typename: 'ModelBrandBriefConnection';
        items: Array<{
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      hashtags?: Array<string> | null;
      personalDescription?: string | null;
      createdAt: string;
      updatedAt: string;
      userProfileBrandId?: string | null;
      owner?: string | null;
    } | null;
    adText: string;
    country: string;
    type?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tikTokData: {
      __typename: 'tikTokData';
      adIdentityId?: string | null;
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      displayName?: string | null;
      tikTokSparkAds?: boolean | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    metaData: {
      __typename: 'metaData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    youtubeData: {
      __typename: 'youtubeData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    manualData: {
      __typename: 'manualData';
      isManual: boolean;
      adCaption?: string | null;
    };
    whitelist?: Array<{
      __typename: 'WhitelistEntry';
      email: string;
      isApprover: boolean;
    }> | null;
    creativeRequestsCount?: number | null;
    isCreationNotificationSent?: boolean | null;
    messaging?: string | null;
    goodPractices?: Array<string> | null;
    badPractices?: Array<string> | null;
    tags?: Array<string> | null;
    overview?: string | null;
    overviewVideoUrl?: string | null;
    tone?: string | null;
    isCompleted?: boolean | null;
    interestedUsers?: Array<{
      __typename: 'InterestedUser';
      id: string;
      isInterested: boolean;
      hasBeenShown: boolean;
    }> | null;
    commonRejectionReasons?: Array<string> | null;
    maybes?: Array<string> | null;
    owner?: string | null;
  } | null;
};

export type OnDeleteBrandBriefSubscriptionVariables = {
  filter?: ModelSubscriptionBrandBriefFilterInput | null;
  owner?: string | null;
};

export type OnDeleteBrandBriefSubscription = {
  onDeleteBrandBrief?: {
    __typename: 'BrandBrief';
    id: string;
    BriefName: string;
    TargetAudience?: string | null;
    vertical: string;
    objective: string;
    brandBriefDetails: string;
    brandBriefFilesUrl?: string | null;
    creativeInspirations?: Array<string> | null;
    active: boolean;
    brandName?: string | null;
    brandImageUrl?: string | null;
    tiktokAdvertiserId?: string | null;
    creativeRequests?: {
      __typename: 'ModelCreativeRequestConnection';
      items: Array<{
        __typename: 'CreativeRequest';
        id: string;
        brandBriefId: string;
        creatorId: string;
        creatorProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        brief?: {
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null;
        lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
        brandProfileImageUrl?: string | null;
        briefDescription?: string | null;
        ad_id?: string | null;
        status: CREATIVE_STATUS;
        tiktokCreativeUrl: string;
        creativePreviewUrl?: string | null;
        tiktokVideoCode: string;
        creativeTiktokHandle?: string | null;
        creativeYoutubeHandle?: string | null;
        creativeInstagramHandle?: string | null;
        approvedAds?: {
          __typename: 'ModelApprovedAdsConnection';
          nextToken?: string | null;
        } | null;
        brandComment?: Array<string> | null;
        adminComment?: Array<string> | null;
        creatorComment?: Array<string> | null;
        adminApproval?: ADMIN_STATUS | null;
        creatorVisibility?: CREATOR_VISIBILITY | null;
        BriefName?: string | null;
        type?: string | null;
        email?: string | null;
        uniqueId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        brandName?: string | null;
        approvals?: {
          __typename: 'ModelCreativeRequestApprovalConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandId: string;
    brandProfile?: {
      __typename: 'BrandProfile';
      id: string;
      name?: string | null;
      toneVoice?: Array<string> | null;
      pillars?: Array<string> | null;
      description?: string | null;
      internalMission?: string | null;
      strapLine?: string | null;
      userEmail?: string | null;
      tiktokHandle?: string | null;
      vertical?: string | null;
      metaData?: string | null;
      userProfile?: {
        __typename: 'UserProfile';
        id: string;
        uniqueId?: string | null;
        name: string;
        description?: string | null;
        brand?: {
          __typename: 'ModelBrandProfileConnection';
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        userType?: USER_TYPES | null;
        tiktokHandler?: string | null;
        instagramHandler?: string | null;
        youtubeHandler?: string | null;
        instagram_username?: string | null;
        tiktok_username?: string | null;
        instagram_followers_count?: number | null;
        instagram_media_count?: number | null;
        tiktok_follower_count?: number | null;
        tiktok_video_count?: number | null;
        bestPractices?: {
          __typename: 'ModelBestPracticesConnection';
          nextToken?: string | null;
        } | null;
        tiktokAccountAccess?: {
          __typename: 'TiktokAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        facebookAccountAccess?: {
          __typename: 'FacebookAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        youtubeAccountAccess?: {
          __typename: 'YoutubeAccountAccess';
          access_token?: string | null;
          advertiser_id?: string | null;
        } | null;
        userPaymentDetails?: {
          __typename: 'UserPaymentDetails';
          id: string;
          fullName: string;
          firstAddress: string;
          secondAddress?: string | null;
          country: string;
          accountNumber: string;
          postCode?: string | null;
          swiftCode: string;
          documentID?: string | null;
          owner?: string | null;
          confirmationLetter?: string | null;
          proofOfAddress?: string | null;
          branchCode?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        userWallet?: {
          __typename: 'UserWallet';
          id: string;
          currentBalance?: number | null;
          currentBalanceZar?: number | null;
          totalEarned?: number | null;
          totalEarnedZar?: number | null;
          extraBalance?: number | null;
          extraBalanceZar?: number | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          owner?: string | null;
        } | null;
        lastLoginDate?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        country?: string | null;
        hashtags?: Array<string> | null;
        profileContent?: Array<string> | null;
        avatar?: string | null;
        vertical?: string | null;
        termsAndConditions?: boolean | null;
        isNotified?: boolean | null;
        isValid?: boolean | null;
        hubProgress?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      briefs?: {
        __typename: 'ModelBrandBriefConnection';
        items: Array<{
          __typename: 'BrandBrief';
          id: string;
          BriefName: string;
          TargetAudience?: string | null;
          vertical: string;
          objective: string;
          brandBriefDetails: string;
          brandBriefFilesUrl?: string | null;
          creativeInspirations?: Array<string> | null;
          active: boolean;
          brandName?: string | null;
          brandImageUrl?: string | null;
          tiktokAdvertiserId?: string | null;
          brandId: string;
          adText: string;
          country: string;
          type?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          creativeRequestsCount?: number | null;
          isCreationNotificationSent?: boolean | null;
          messaging?: string | null;
          goodPractices?: Array<string> | null;
          badPractices?: Array<string> | null;
          tags?: Array<string> | null;
          overview?: string | null;
          overviewVideoUrl?: string | null;
          tone?: string | null;
          isCompleted?: boolean | null;
          commonRejectionReasons?: Array<string> | null;
          maybes?: Array<string> | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      hashtags?: Array<string> | null;
      personalDescription?: string | null;
      createdAt: string;
      updatedAt: string;
      userProfileBrandId?: string | null;
      owner?: string | null;
    } | null;
    adText: string;
    country: string;
    type?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tikTokData: {
      __typename: 'tikTokData';
      adIdentityId?: string | null;
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      displayName?: string | null;
      tikTokSparkAds?: boolean | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    metaData: {
      __typename: 'metaData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    youtubeData: {
      __typename: 'youtubeData';
      adgroupId?: string | null;
      campaignId?: string | null;
      adCaption?: string | null;
      callToAction?: string | null;
      landingPageUrl?: string | null;
    };
    manualData: {
      __typename: 'manualData';
      isManual: boolean;
      adCaption?: string | null;
    };
    whitelist?: Array<{
      __typename: 'WhitelistEntry';
      email: string;
      isApprover: boolean;
    }> | null;
    creativeRequestsCount?: number | null;
    isCreationNotificationSent?: boolean | null;
    messaging?: string | null;
    goodPractices?: Array<string> | null;
    badPractices?: Array<string> | null;
    tags?: Array<string> | null;
    overview?: string | null;
    overviewVideoUrl?: string | null;
    tone?: string | null;
    isCompleted?: boolean | null;
    interestedUsers?: Array<{
      __typename: 'InterestedUser';
      id: string;
      isInterested: boolean;
      hasBeenShown: boolean;
    }> | null;
    commonRejectionReasons?: Array<string> | null;
    maybes?: Array<string> | null;
    owner?: string | null;
  } | null;
};

export type OnCreateCreativeRequestApprovalSubscriptionVariables = {
  filter?: ModelSubscriptionCreativeRequestApprovalFilterInput | null;
};

export type OnCreateCreativeRequestApprovalSubscription = {
  onCreateCreativeRequestApproval?: {
    __typename: 'CreativeRequestApproval';
    id: string;
    creativeRequestId: string;
    approverEmail: string;
    approvalStatus?: ApprovalStatus | null;
    reason?: string | null;
    updatedAt?: string | null;
    createdAt?: string | null;
  } | null;
};

export type OnUpdateCreativeRequestApprovalSubscriptionVariables = {
  filter?: ModelSubscriptionCreativeRequestApprovalFilterInput | null;
};

export type OnUpdateCreativeRequestApprovalSubscription = {
  onUpdateCreativeRequestApproval?: {
    __typename: 'CreativeRequestApproval';
    id: string;
    creativeRequestId: string;
    approverEmail: string;
    approvalStatus?: ApprovalStatus | null;
    reason?: string | null;
    updatedAt?: string | null;
    createdAt?: string | null;
  } | null;
};

export type OnDeleteCreativeRequestApprovalSubscriptionVariables = {
  filter?: ModelSubscriptionCreativeRequestApprovalFilterInput | null;
};

export type OnDeleteCreativeRequestApprovalSubscription = {
  onDeleteCreativeRequestApproval?: {
    __typename: 'CreativeRequestApproval';
    id: string;
    creativeRequestId: string;
    approverEmail: string;
    approvalStatus?: ApprovalStatus | null;
    reason?: string | null;
    updatedAt?: string | null;
    createdAt?: string | null;
  } | null;
};

export type OnCreateCreativeRequestSubscriptionVariables = {
  filter?: ModelSubscriptionCreativeRequestFilterInput | null;
  owner?: string | null;
};

export type OnCreateCreativeRequestSubscription = {
  onCreateCreativeRequest?: {
    __typename: 'CreativeRequest';
    id: string;
    brandBriefId: string;
    creatorId: string;
    creatorProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    brief?: {
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null;
    lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
    brandProfileImageUrl?: string | null;
    briefDescription?: string | null;
    ad_id?: string | null;
    status: CREATIVE_STATUS;
    tiktokCreativeUrl: string;
    creativePreviewUrl?: string | null;
    tiktokVideoCode: string;
    creativeTiktokHandle?: string | null;
    creativeYoutubeHandle?: string | null;
    creativeInstagramHandle?: string | null;
    approvedAds?: {
      __typename: 'ModelApprovedAdsConnection';
      items: Array<{
        __typename: 'ApprovedAds';
        id: string;
        creativeRequestId: string;
        creativeRequest?: {
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null;
        identity_id?: string | null;
        item_id?: string | null;
        ad_id?: string | null;
        ad_group_id?: string | null;
        campaing_id?: string | null;
        advertiser_id?: string | null;
        user_profile_id?: string | null;
        accessToken?: string | null;
        ad_report?: string | null;
        ad_comment?: string | null;
        status?: string | null;
        adName?: string | null;
        approvedAdType?: ApprovedAdType | null;
        owner?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        isManuallyLinked?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandComment?: Array<string> | null;
    adminComment?: Array<string> | null;
    creatorComment?: Array<string> | null;
    adminApproval?: ADMIN_STATUS | null;
    creatorVisibility?: CREATOR_VISIBILITY | null;
    BriefName?: string | null;
    type?: string | null;
    email?: string | null;
    uniqueId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    brandName?: string | null;
    approvals?: {
      __typename: 'ModelCreativeRequestApprovalConnection';
      items: Array<{
        __typename: 'CreativeRequestApproval';
        id: string;
        creativeRequestId: string;
        approverEmail: string;
        approvalStatus?: ApprovalStatus | null;
        reason?: string | null;
        updatedAt?: string | null;
        createdAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type OnUpdateCreativeRequestSubscriptionVariables = {
  filter?: ModelSubscriptionCreativeRequestFilterInput | null;
  owner?: string | null;
};

export type OnUpdateCreativeRequestSubscription = {
  onUpdateCreativeRequest?: {
    __typename: 'CreativeRequest';
    id: string;
    brandBriefId: string;
    creatorId: string;
    creatorProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    brief?: {
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null;
    lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
    brandProfileImageUrl?: string | null;
    briefDescription?: string | null;
    ad_id?: string | null;
    status: CREATIVE_STATUS;
    tiktokCreativeUrl: string;
    creativePreviewUrl?: string | null;
    tiktokVideoCode: string;
    creativeTiktokHandle?: string | null;
    creativeYoutubeHandle?: string | null;
    creativeInstagramHandle?: string | null;
    approvedAds?: {
      __typename: 'ModelApprovedAdsConnection';
      items: Array<{
        __typename: 'ApprovedAds';
        id: string;
        creativeRequestId: string;
        creativeRequest?: {
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null;
        identity_id?: string | null;
        item_id?: string | null;
        ad_id?: string | null;
        ad_group_id?: string | null;
        campaing_id?: string | null;
        advertiser_id?: string | null;
        user_profile_id?: string | null;
        accessToken?: string | null;
        ad_report?: string | null;
        ad_comment?: string | null;
        status?: string | null;
        adName?: string | null;
        approvedAdType?: ApprovedAdType | null;
        owner?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        isManuallyLinked?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandComment?: Array<string> | null;
    adminComment?: Array<string> | null;
    creatorComment?: Array<string> | null;
    adminApproval?: ADMIN_STATUS | null;
    creatorVisibility?: CREATOR_VISIBILITY | null;
    BriefName?: string | null;
    type?: string | null;
    email?: string | null;
    uniqueId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    brandName?: string | null;
    approvals?: {
      __typename: 'ModelCreativeRequestApprovalConnection';
      items: Array<{
        __typename: 'CreativeRequestApproval';
        id: string;
        creativeRequestId: string;
        approverEmail: string;
        approvalStatus?: ApprovalStatus | null;
        reason?: string | null;
        updatedAt?: string | null;
        createdAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type OnDeleteCreativeRequestSubscriptionVariables = {
  filter?: ModelSubscriptionCreativeRequestFilterInput | null;
  owner?: string | null;
};

export type OnDeleteCreativeRequestSubscription = {
  onDeleteCreativeRequest?: {
    __typename: 'CreativeRequest';
    id: string;
    brandBriefId: string;
    creatorId: string;
    creatorProfile?: {
      __typename: 'UserProfile';
      id: string;
      uniqueId?: string | null;
      name: string;
      description?: string | null;
      brand?: {
        __typename: 'ModelBrandProfileConnection';
        items: Array<{
          __typename: 'BrandProfile';
          id: string;
          name?: string | null;
          toneVoice?: Array<string> | null;
          pillars?: Array<string> | null;
          description?: string | null;
          internalMission?: string | null;
          strapLine?: string | null;
          userEmail?: string | null;
          tiktokHandle?: string | null;
          vertical?: string | null;
          metaData?: string | null;
          hashtags?: Array<string> | null;
          personalDescription?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBrandId?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      userType?: USER_TYPES | null;
      tiktokHandler?: string | null;
      instagramHandler?: string | null;
      youtubeHandler?: string | null;
      instagram_username?: string | null;
      tiktok_username?: string | null;
      instagram_followers_count?: number | null;
      instagram_media_count?: number | null;
      tiktok_follower_count?: number | null;
      tiktok_video_count?: number | null;
      bestPractices?: {
        __typename: 'ModelBestPracticesConnection';
        items: Array<{
          __typename: 'BestPractices';
          id: string;
          headLine: string;
          description: string;
          urlPath: string;
          active: string;
          owner?: string | null;
          createdAt: string;
          updatedAt: string;
          userProfileBestPracticesId?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      tiktokAccountAccess?: {
        __typename: 'TiktokAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      facebookAccountAccess?: {
        __typename: 'FacebookAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      youtubeAccountAccess?: {
        __typename: 'YoutubeAccountAccess';
        access_token?: string | null;
        advertiser_id?: string | null;
        advertisers_list?: Array<{
          __typename: 'AdvertiserData';
          advertiser_id?: string | null;
          advertiser_name?: string | null;
        }> | null;
      } | null;
      userPaymentDetails?: {
        __typename: 'UserPaymentDetails';
        id: string;
        fullName: string;
        firstAddress: string;
        secondAddress?: string | null;
        country: string;
        accountNumber: string;
        postCode?: string | null;
        swiftCode: string;
        documentID?: string | null;
        owner?: string | null;
        userTransactions?: {
          __typename: 'ModelUserTransactionsConnection';
          nextToken?: string | null;
        } | null;
        confirmationLetter?: string | null;
        proofOfAddress?: string | null;
        branchCode?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      userWallet?: {
        __typename: 'UserWallet';
        id: string;
        currentBalance?: number | null;
        currentBalanceZar?: number | null;
        totalEarned?: number | null;
        totalEarnedZar?: number | null;
        extraBalance?: number | null;
        extraBalanceZar?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner?: string | null;
      } | null;
      lastLoginDate?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      country?: string | null;
      hashtags?: Array<string> | null;
      profileContent?: Array<string> | null;
      avatar?: string | null;
      vertical?: string | null;
      termsAndConditions?: boolean | null;
      isNotified?: boolean | null;
      isValid?: boolean | null;
      hubProgress?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    brief?: {
      __typename: 'BrandBrief';
      id: string;
      BriefName: string;
      TargetAudience?: string | null;
      vertical: string;
      objective: string;
      brandBriefDetails: string;
      brandBriefFilesUrl?: string | null;
      creativeInspirations?: Array<string> | null;
      active: boolean;
      brandName?: string | null;
      brandImageUrl?: string | null;
      tiktokAdvertiserId?: string | null;
      creativeRequests?: {
        __typename: 'ModelCreativeRequestConnection';
        items: Array<{
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      brandId: string;
      brandProfile?: {
        __typename: 'BrandProfile';
        id: string;
        name?: string | null;
        toneVoice?: Array<string> | null;
        pillars?: Array<string> | null;
        description?: string | null;
        internalMission?: string | null;
        strapLine?: string | null;
        userEmail?: string | null;
        tiktokHandle?: string | null;
        vertical?: string | null;
        metaData?: string | null;
        userProfile?: {
          __typename: 'UserProfile';
          id: string;
          uniqueId?: string | null;
          name: string;
          description?: string | null;
          owner?: string | null;
          userType?: USER_TYPES | null;
          tiktokHandler?: string | null;
          instagramHandler?: string | null;
          youtubeHandler?: string | null;
          instagram_username?: string | null;
          tiktok_username?: string | null;
          instagram_followers_count?: number | null;
          instagram_media_count?: number | null;
          tiktok_follower_count?: number | null;
          tiktok_video_count?: number | null;
          lastLoginDate?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          country?: string | null;
          hashtags?: Array<string> | null;
          profileContent?: Array<string> | null;
          avatar?: string | null;
          vertical?: string | null;
          termsAndConditions?: boolean | null;
          isNotified?: boolean | null;
          isValid?: boolean | null;
          hubProgress?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        briefs?: {
          __typename: 'ModelBrandBriefConnection';
          nextToken?: string | null;
        } | null;
        hashtags?: Array<string> | null;
        personalDescription?: string | null;
        createdAt: string;
        updatedAt: string;
        userProfileBrandId?: string | null;
        owner?: string | null;
      } | null;
      adText: string;
      country: string;
      type?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tikTokData: {
        __typename: 'tikTokData';
        adIdentityId?: string | null;
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        displayName?: string | null;
        tikTokSparkAds?: boolean | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      metaData: {
        __typename: 'metaData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      youtubeData: {
        __typename: 'youtubeData';
        adgroupId?: string | null;
        campaignId?: string | null;
        adCaption?: string | null;
        callToAction?: string | null;
        landingPageUrl?: string | null;
      };
      manualData: {
        __typename: 'manualData';
        isManual: boolean;
        adCaption?: string | null;
      };
      whitelist?: Array<{
        __typename: 'WhitelistEntry';
        email: string;
        isApprover: boolean;
      }> | null;
      creativeRequestsCount?: number | null;
      isCreationNotificationSent?: boolean | null;
      messaging?: string | null;
      goodPractices?: Array<string> | null;
      badPractices?: Array<string> | null;
      tags?: Array<string> | null;
      overview?: string | null;
      overviewVideoUrl?: string | null;
      tone?: string | null;
      isCompleted?: boolean | null;
      interestedUsers?: Array<{
        __typename: 'InterestedUser';
        id: string;
        isInterested: boolean;
        hasBeenShown: boolean;
      }> | null;
      commonRejectionReasons?: Array<string> | null;
      maybes?: Array<string> | null;
      owner?: string | null;
    } | null;
    lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
    brandProfileImageUrl?: string | null;
    briefDescription?: string | null;
    ad_id?: string | null;
    status: CREATIVE_STATUS;
    tiktokCreativeUrl: string;
    creativePreviewUrl?: string | null;
    tiktokVideoCode: string;
    creativeTiktokHandle?: string | null;
    creativeYoutubeHandle?: string | null;
    creativeInstagramHandle?: string | null;
    approvedAds?: {
      __typename: 'ModelApprovedAdsConnection';
      items: Array<{
        __typename: 'ApprovedAds';
        id: string;
        creativeRequestId: string;
        creativeRequest?: {
          __typename: 'CreativeRequest';
          id: string;
          brandBriefId: string;
          creatorId: string;
          lastUpdatedBy?: CREATIVE_REQUEST_LAST_UPDATED_BY | null;
          brandProfileImageUrl?: string | null;
          briefDescription?: string | null;
          ad_id?: string | null;
          status: CREATIVE_STATUS;
          tiktokCreativeUrl: string;
          creativePreviewUrl?: string | null;
          tiktokVideoCode: string;
          creativeTiktokHandle?: string | null;
          creativeYoutubeHandle?: string | null;
          creativeInstagramHandle?: string | null;
          brandComment?: Array<string> | null;
          adminComment?: Array<string> | null;
          creatorComment?: Array<string> | null;
          adminApproval?: ADMIN_STATUS | null;
          creatorVisibility?: CREATOR_VISIBILITY | null;
          BriefName?: string | null;
          type?: string | null;
          email?: string | null;
          uniqueId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          brandName?: string | null;
          owner?: string | null;
        } | null;
        identity_id?: string | null;
        item_id?: string | null;
        ad_id?: string | null;
        ad_group_id?: string | null;
        campaing_id?: string | null;
        advertiser_id?: string | null;
        user_profile_id?: string | null;
        accessToken?: string | null;
        ad_report?: string | null;
        ad_comment?: string | null;
        status?: string | null;
        adName?: string | null;
        approvedAdType?: ApprovedAdType | null;
        owner?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        isManuallyLinked?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    brandComment?: Array<string> | null;
    adminComment?: Array<string> | null;
    creatorComment?: Array<string> | null;
    adminApproval?: ADMIN_STATUS | null;
    creatorVisibility?: CREATOR_VISIBILITY | null;
    BriefName?: string | null;
    type?: string | null;
    email?: string | null;
    uniqueId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    brandName?: string | null;
    approvals?: {
      __typename: 'ModelCreativeRequestApprovalConnection';
      items: Array<{
        __typename: 'CreativeRequestApproval';
        id: string;
        creativeRequestId: string;
        approverEmail: string;
        approvalStatus?: ApprovalStatus | null;
        reason?: string | null;
        updatedAt?: string | null;
        createdAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type OnCreateCreativeRequestEarningsSubscriptionVariables = {
  filter?: ModelSubscriptionCreativeRequestEarningsFilterInput | null;
};

export type OnCreateCreativeRequestEarningsSubscription = {
  onCreateCreativeRequestEarnings?: {
    __typename: 'CreativeRequestEarnings';
    creativeRequestEarningId: string;
    creativeRequestId: string;
    creatorId: string;
    earningType: EARNING_TYPE;
    amount: number;
    creativeUniqueId: string;
    currentEarnings?: number | null;
    month: string;
    toDate: string;
    fromDate: string;
    createdAt: string;
    updatedAt?: string | null;
    importedAt?: string | null;
  } | null;
};

export type OnUpdateCreativeRequestEarningsSubscriptionVariables = {
  filter?: ModelSubscriptionCreativeRequestEarningsFilterInput | null;
};

export type OnUpdateCreativeRequestEarningsSubscription = {
  onUpdateCreativeRequestEarnings?: {
    __typename: 'CreativeRequestEarnings';
    creativeRequestEarningId: string;
    creativeRequestId: string;
    creatorId: string;
    earningType: EARNING_TYPE;
    amount: number;
    creativeUniqueId: string;
    currentEarnings?: number | null;
    month: string;
    toDate: string;
    fromDate: string;
    createdAt: string;
    updatedAt?: string | null;
    importedAt?: string | null;
  } | null;
};

export type OnDeleteCreativeRequestEarningsSubscriptionVariables = {
  filter?: ModelSubscriptionCreativeRequestEarningsFilterInput | null;
};

export type OnDeleteCreativeRequestEarningsSubscription = {
  onDeleteCreativeRequestEarnings?: {
    __typename: 'CreativeRequestEarnings';
    creativeRequestEarningId: string;
    creativeRequestId: string;
    creatorId: string;
    earningType: EARNING_TYPE;
    amount: number;
    creativeUniqueId: string;
    currentEarnings?: number | null;
    month: string;
    toDate: string;
    fromDate: string;
    createdAt: string;
    updatedAt?: string | null;
    importedAt?: string | null;
  } | null;
};
