import { BestPractices as IBestPractice } from 'API';
import { getActiveBestPractice } from 'hooks';
import { useState, useEffect } from 'react';
import './practice.css';
import PillarConstruction from 'components/BestPracticePillars/PillarConstruction';
import { Storage } from 'aws-amplify';

export default function BestPractice() {
  const { getActivePractice, data, loading } = getActiveBestPractice();
  const [practices, setPractices] = useState<Array<IBestPractice | null>>([]);

  useEffect(() => {
    getActivePractice({ variables: { active: 'true' } });
  }, []);



  useEffect(() => {
    if (!loading && data?.[0]) setPractices(data);
  }, [data, loading]);

  const pillarsData = [
    {
      "pillar": 1,
      "mainHeading": "How to be an Everyday Creator",
      "steps": [
        {
          "step": 1,
          "subHeading": "Intro",
          "videoSrc": "A1 - How to be an Everyday Creator - Intro - EDC Squared 1.mp4",
          "thumbnail": "A1 - How to be an Everyday Creator - Intro - EDC Squared 1.png",
          "keyPoints": [
            "EDC is different from traditional content creation",
            "Access to brands for anyone immediately",
            "Earn based on performance"
          ]
        },
        {
          "step": 2,
          "subHeading": "Where do you start?",
          "videoSrc": "A2 - How to be an Everyday Creator - Where do you start - EDC Squared 2.mp4",
          "thumbnail": "A2 - How to be an Everyday Creator - Where do you start - EDC Squared 2.png",
          "keyPoints": [
            "Ensure profile details are correct",
            "Add a summary and tag words to describe yourself",
            "Upload previous content if available"
          ]
        },
        {
          "step": 3,
          "subHeading": "Why is EDC the answer?",
          "videoSrc": "A3 - How to be an Everyday Creator - Why is EDC the answer - EDC Squared 3.mp4",
          "thumbnail": "A3 - How to be an Everyday Creator - Why is EDC the answer - EDC Squared 3.png",
          "keyPoints": [
            "Main Dashboard Overview",
            "Understanding Brand Activations, Creatives, Wallet",
            "Why EDC is the answer"
          ]
        }
      ]
    },
    {
      "pillar": 2,
      "mainHeading": "How to start earning with EDC",
      "steps": [
        {
          "step": 1,
          "subHeading": "Brand Activations",
          "videoSrc": "B4 - How to start earning with EDC - Brand Activations - EDC Squared 4.mp4",
          "thumbnail": "B4 - How to start earning with EDC - Brand Activations - EDC Squared 4.png",
          "keyPoints": [
            "Each activation includes a video brief",
            "Do’s and Don’ts",
            "You must read all sections before submitting content"
          ]
        },
        {
          "step": 2,
          "subHeading": "Once you've submitted",
          "videoSrc": "B5 - How to start earning with EDC - Once you_ve submitted - EDC Squared 5.mp4",
          "thumbnail": "B5 - How to start earning with EDC - Once you've submitted - EDC Squared 5.png",
          "keyPoints": [
            "Revisions are part of the process",
            "Feedback from the EDC team and clients",
            "Minimizing revisions for a smoother experience"
          ]
        },
        {
          "step": 3,
          "subHeading": "Once you're approved",
          "videoSrc": "B6 - How to start earning with EDC - Once you're approved - EDC Squared 6.mp4",
          "thumbnail": "B6 - How to start earning with EDC - Once you're approved - EDC Squared 6.png",
          "keyPoints": [
            "Approved content is promoted as ads on TikTok, Instagram, and YouTube Shorts",
            "Earnings are updated weekly",
            "Payouts happen in the first week of every month"
          ]
        },
        {
          "step": 4,
          "subHeading": "How to earn more",
          "videoSrc": "B7 - How to start earning with EDC - How to earn more - EDC Squared 7.mp4",
          "thumbnail": "B7 - How to start earning with EDC - How to earn more - EDC Squared 7.png",
          "keyPoints": [
            "Submit early",
            "Submit multiple pieces of content",
            "Work across multiple brands (avoid competitor conflicts)",
            "Follow activation details to minimize revisions"
          ]
        }
      ]
    },
    {
      "pillar": 3,
      "mainHeading": "How to shoot content",
      "steps": [
        {
          "step": 1,
          "subHeading": "Intro to shooting content",
          "videoSrc": "C8 - How to shoot content - Intro to shooting content - EDC Squared 8.mp4",
          "thumbnail": "C8 - How to shoot content - Intro to shooting content - EDC Squared 8.png",
          "keyPoints": [
            "Start with a simple concept",
            "Experiment and have fun",
            "You don’t need expensive gear"
          ]
        },
        {
          "step": 2,
          "subHeading": "Let's talk about cameras",
          "videoSrc": "C9 - How to shoot content - Let's talk about cameras - EDC Squared 9.mp4",
          "thumbnail": "C9 - How to shoot content - Let's talk about cameras - EDC Squared 9.png",
          "keyPoints": [
            "Use your phone for vertical videos",
            "Ideal resolution: 1080p (Full HD)",
            "Shoot in a 9:16 ratio for best results"
          ]
        },
        {
          "step": 3,
          "subHeading": "How to set up your shot",
          "videoSrc": "C10 - How to shoot content - How to set up your shot - EDC Squared 10.mp4",
          "thumbnail": "C10 - How to shoot content - How to set up your shot - EDC Squared 10.png",
          "keyPoints": [
            "Choose a clean background",
            "Use a tripod or household items for stability",
            "Experiment with different angles (POV, high shot, tight shot, etc.)"
          ]
        },
        {
          "step": 4,
          "subHeading": "How to use angles",
          "videoSrc": "C11 - How to shoot content - How to use angles - EDC Squared 11.mp4",
          "thumbnail": "C11 - How to shoot content - How to use angles - EDC Squared 11.png",
          "keyPoints": [
            "Choose the right framing",
            "Use the rule of thirds",
            "Experiment with movement"
          ]
        },
        {
          "step": 5,
          "subHeading": "Getting your audio right",
          "videoSrc": "C12 - How to shoot content - Getting your audio right - EDC Squared 12.mp4",
          "thumbnail": "C12 - How to shoot content - Getting your audio right - EDC Squared 12.png",
          "keyPoints": [
            "Record clear voiceovers",
            "Use non-copyright music",
            "Minimize background noise"
          ]
        }
      ]
    },
    {
      "pillar": 4,
      "mainHeading": "How to edit content",
      "steps": [
        {
          "step": 1,
          "subHeading": "Editing software",
          "videoSrc": "D13 - How to edit - Editing software - EDC Squared 13.mp4",
          "thumbnail": "D13 - How to edit - Editing software - EDC Squared 13.png",
          "keyPoints": [
            "Recommended programs for beginners",
            "Editing on mobile vs desktop",
            "Essential editing tools"
          ]
        },
        {
          "step": 2,
          "subHeading": "Safezones",
          "videoSrc": "D14 - How to edit - Safezones - EDC Squared 14.mp4",
          "thumbnail": "D14 - How to edit - Safezones - EDC Squared 14.png",
          "keyPoints": [
            "Avoid placing text too close to edges",
            "Use overlays to guide placement",
            "Keep the focus on the subject"
          ]
        },
        {
          "step": 3,
          "subHeading": "Edit flow and transitions",
          "videoSrc": "D15 - How to edit - Edit flow and transitions - EDC Squared 15.mp4",
          "thumbnail": "D15 - How to edit - Edit flow and transitions - EDC Squared 15.png",
          "keyPoints": [
            "Smooth transitions between clips",
            "How to use jump cuts effectively",
            "Maintaining engagement with pacing"
          ]
        },
        {
          "step": 4,
          "subHeading": "Subtitles",
          "videoSrc": "D16 - How to edit - Subtitles - EDC Squared 16.mp4",
          "thumbnail": "D16 - How to edit - Subtitles - EDC Squared 16.png",
          "keyPoints": [
            "Use readable fonts",
            "Ensure correct placement",
            "Enhancing accessibility for wider reach"
          ]
        }
      ]
    }
  ];




  return (
    <>
      {/* Pillar Animation Overlay */}
      <PillarConstruction pillars={pillarsData} />
    </>
  );
}
