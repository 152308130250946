import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

interface VideoThumbnailProps {
  videoUrl: string;
  withPlayButton?: boolean;
  loadingClassNames?: string;
  playButtonClassNames?: string;
  thumbnailWrapperClassNames?: string;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  videoUrl,
  withPlayButton = true,
  loadingClassNames,
  playButtonClassNames,
  thumbnailWrapperClassNames,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
  const [resolvedVideoUrl, setResolvedVideoUrl] = useState<string>('');

  // Transform URL for thumbnails
  function transformUrl(originalUrl: string): string {
    const baseUrl = originalUrl.split('?')[0]; // Remove query string
    const newUrl = `${baseUrl}_thumbnail.jpg`;
    return newUrl.replace('/creative/', '/watermarked_creatives/creative/');
  }

  // Transform and resolve preview URL
  async function transformPreviewUrl(originalUrl: string): Promise<string> {
    const baseUrl = originalUrl?.split('?')[0];

    if (!baseUrl) {
      throw new Error('Invalid URL: Unable to extract base URL');
    }

    // Replace `/creative/` with `/watermarked_creatives/creative/`
    const watermarkedUrl = baseUrl.replace(
      '/creative/',
      '/watermarked_creatives/creative/'
    );

    // Match URLs with one or two extensions
    const match = watermarkedUrl.match(
      /(.*)\.([a-zA-Z0-9]+)(?:\.([a-zA-Z0-9]+))?$/
    );

    if (!match) {
      throw new Error('Invalid URL: Unable to parse filename and extensions');
    }

    const [filename, mainExt, secondExt] = match;

    // Construct the compressed URL
    const compressedUrl = `${mainExt}_compressed.${secondExt}`;

    async function urlExists(url: string) {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        return response.ok;
      } catch {
        return false;
      }
    }

    // Check if the compressed URL exists
    if (await urlExists(compressedUrl)) {
      return compressedUrl;
    } else {
      return videoUrl;
    }
  }

  const captureThumbnail = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      const context = canvas.getContext('2d');
      if (context) {
        video.currentTime = 1;
        video.onseeked = () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          const thumbnail = canvas.toDataURL('image/png');
          console.log('Thumbnail URL:', transformUrl(videoUrl)); // Log transformed thumbnail URL
          setThumbnailUrl(thumbnail);
        };
      }
    }
  };

  useEffect(() => {
    const resolveUrl = async () => {
      try {
        const resolvedUrl = await transformPreviewUrl(videoUrl);
        setResolvedVideoUrl(resolvedUrl);
      } catch (err) {
        console.error('Error resolving video URL:', err.message);
      }
    };

    if (videoUrl) {
      resolveUrl();
    }
  }, [videoUrl]);

  return (
    <div>
      {resolvedVideoUrl && (
        <video
          autoPlay
          playsInline
          preload="auto"
          muted={true}
          ref={videoRef}
          src={resolvedVideoUrl} // Use resolved video URL
          onLoadedMetadata={captureThumbnail}
          style={{ display: 'none' }}
          crossOrigin="anonymous"
        />
      )}
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      <div
        className={classNames(
          'w-[100px] h-[150px] relative',
          thumbnailWrapperClassNames
        )}
      >
        {thumbnailUrl && (
          <div
            className="absolute inset-0 flex items-center justify-center"
            style={{ pointerEvents: 'none' }} // Prevent click interception
          >
            <PlayArrowRoundedIcon
              sx={{ color: 'white', cursor: 'pointer', marginLeft: '-8px' }} // Adjust margin for slight left shift
              fontSize="large"
            />
          </div>
        )}
        {thumbnailUrl && withPlayButton && (
          <img
            className={classNames(
              'object-cover w-full h-full rounded-3xl',
              playButtonClassNames
            )}
            src={thumbnailUrl}
            alt="Video Thumbnail"
          />
        )}
        {!thumbnailUrl && (
          <CircularProgress
            className={classNames(
              'w-4 h-4 text-black absolute top-[45%] left-[35%]',
              loadingClassNames
            )}
          />
        )}
      </div>
    </div>
  );
};

export default VideoThumbnail;
