import { CircularProgress } from '@mui/material';
import { ReactNode } from 'react';
import styled from 'styled-components';
import * as S from '../styles/auth.styled';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  type?: string;
  withCheckbox?: boolean;
  title?: string;
  customComponent?: ReactNode;
  isLoading?: boolean;
  canSkip?: boolean;
  onSkip?: () => void;
}

export default function Modal({
  title,
  handleClose,
  isOpen,
  type,
  withCheckbox,
  customComponent,
  isLoading = false,
  canSkip = false,
  onSkip,
}: Props) {
  if (!isOpen) return null;

  return (
    <S.ModalOverlay>
      <S.ModalContent $type={type || ''} $withCheckBox={!!withCheckbox}>
        <S.ModalTopBar>
          <CloseIcon
            src="/images/cross.svg"
            alt="Close"
            onClick={handleClose}
          />
        </S.ModalTopBar>

        <FlexContainer>
          {title && <Title>{title}</Title>}

          {isLoading ? (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          ) : (
            <>
              {customComponent && (
                <ContentWrapper>{customComponent}</ContentWrapper>
              )}
            </>
          )}
        </FlexContainer>

        {canSkip && (
          <SkipContainer>
            <SkipButton onClick={onSkip}>Skip</SkipButton>
          </SkipContainer>
        )}
      </S.ModalContent>
    </S.ModalOverlay>
  );
}

// 🎨 Styled Components for Better Positioning & Readability
const CloseIcon = styled.img`
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 22px;
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 50px;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

// ✅ Container to position Skip button bottom left
const SkipContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
`;

// ✅ Updated Skip button styling
const SkipButton = styled.button`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #007bff;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: #0056b3;
  }
`;
