import { SortOrder } from 'API';
import { Bounce, ToastOptions } from 'react-toastify';

export const apiInitialState = {
  isLoading: false,
  data: null,
  error: null,
  success: false,
};

export const initialAuthContext = {
  isLoggedIn: null,
  email: null,
  isVerified: null,
  userId: null,
  isLoading: false,
};

export const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const SWIFT_CODE_VALIDATION_REGEX =
  /^[A-Za-z]{4}[A-Za-z]{2}[0-9A-Za-z]{2}([0-9A-Za-z]{3})?$/;
export const ONLY_NUMBERS_VALIDATION_REGEX = /^\d+$/;

//eslint-disable-next-line
export const POSTAL_CODE_VALIDATION_REGEX = /^[0-9]{4}$/;

export enum HttpStatus {
  SUCCESS = 200,
  INTERNAL_SERVER_ERROR = 500,
}

export const LONG_DATE_FORMAT = 'EEE MMM dd yyyy';

export const MONTH_OPTIONS: { value: string; text: string }[] = [
  { value: 'JAN', text: 'January' },
  { value: 'FEB', text: 'February' },
  { value: 'MAR', text: 'March' },
  { value: 'APR', text: 'April' },
  { value: 'MAY', text: 'May' },
  { value: 'JUN', text: 'June' },
  { value: 'JUL', text: 'July' },
  { value: 'AUG', text: 'August' },
  { value: 'SEP', text: 'September' },
  { value: 'OCT', text: 'October' },
  { value: 'NOV', text: 'November' },
  { value: 'DEC', text: 'December' },
];

export const MONTH_INDICES = {
  JAN: 0,
  FEB: 1,
  MAR: 2,
  APR: 3,
  MAY: 4,
  JUN: 5,
  JUL: 6,
  AUG: 7,
  SEP: 8,
  OCT: 9,
  NOV: 10,
  DEC: 11,
};

export const NORMAL_DATE_FORMAT = 'EEE MMM dd yyyy';
export const SHORT_DATE_FORMAT = 'yyyy-mm-dd';
export const DATE_FORMAT = 'dd/MM/yy HH:mm';
export const TWO_DAYS_IN_SECONDS = 48 * 3600;

export const verticalOptions = [
  {
    text: 'Healthcare and Pharmaceuticals',
    value: 'healthcare-and-pharmaceuticals',
  },
  { text: 'Consumer goods and Retail', value: 'consumer-goods-and-retail' },
  { text: 'Food and Beverage', value: 'food-and-beverage' },
  { text: 'Technology and Electronics', value: 'technology-and-electronics' },
  { text: 'Financial Services', value: 'financial-services' },
  { text: 'Education and E-Learning', value: 'education-and-e-Learning' },
  { text: 'Hospitality and Tourism', value: 'hospitality-and-tourism' },
  {
    text: 'Automotive and Transportation',
    value: 'automotive-and-transportatio',
  },
  {
    text: 'Real Estate and Construction',
    value: 'real-estate-and-construction',
  },
  { text: 'Media and Entertainment', value: 'media-and-entertainment' },
  { text: 'Energy and Utilities', value: 'energy-and-utilities' },
  {
    text: 'Manufacturing and Industrial',
    value: 'manufacturing-and-industrial',
  },
  { text: 'Agriculture and Farming', value: 'agriculture-and-farming' },
  { text: 'Sports and Fitness', value: 'sports-and-fitness' },
  { text: 'Beauty and Personal Care', value: 'beauty-and-personal-care' },
  { text: 'Nonprofit and Social Impact', value: 'nonprofit-and-social-impact' },
  { text: 'Fashion and Apparel', value: 'fashion-and-apparel' },
  {
    text: 'Government and Public Services',
    value: 'government-and-public-services',
  },
  {
    text: 'Telecommunications and Communication',
    value: 'telecommunications-and-communication',
  },
  { text: 'Logistics and Supply Chain.', value: 'logistics-and-supply-chain' },
];

export const TOAST_CONFIG: ToastOptions = {
  position: 'top-center',
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  theme: 'light',
  transition: Bounce,
};

export const META_CALL_TO_ACTION_LIST = [
  { text: 'Page Likes', value: 'PAGE_LIKES' },
  { text: 'Lead Generation', value: 'LEAD_GENERATION' },
  { text: 'Local Awareness', value: 'LOCAL_AWARENESS' },
  { text: 'Link Clicks', value: 'LINK_CLICKS' },
  { text: 'Conversions', value: 'CONVERSIONS' },
  { text: 'App Installs', value: 'APP_INSTALLS' },
  { text: 'Video Views', value: 'VIDEO_VIEWS' },
  { text: 'Brand Awareness', value: 'BRAND_AWARENESS' },
];

export const CALL_TO_ACTION_OPTIONS = [
  { text: 'Apply now', value: 'APPLY_NOW' },
  { text: 'Book now', value: 'BOOK_NOW' },
  { text: 'Call now', value: 'CALL_NOW' },
  { text: 'Contact us', value: 'CONTACT_US' },
  { text: 'Download', value: 'DOWNLOAD_NOW' },
  { text: 'Experience now', value: 'EXPERIENCE_NOW' },
  { text: 'Get quote', value: 'GET_QUOTE' },
  { text: 'Get showtimes', value: 'GET_SHOWTIMES' },
  { text: 'Get tickets now', value: 'GET_TICKETS_NOW' },
  { text: 'Install now', value: 'INSTALL_NOW' },
  { text: 'Interested', value: 'INTERESTED' },
  { text: 'Learn more', value: 'LEARN_MORE' },
  { text: 'Listen now', value: 'LISTEN_NOW' },
  { text: 'Order now', value: 'ORDER_NOW' },
  { text: 'Play game', value: 'PLAY_GAME' },
  { text: 'Pre-order now', value: 'PREORDER_NOW' },
  { text: 'Read more', value: 'READ_MORE' },
  { text: 'Send message', value: 'SEND_MESSAGE' },
  { text: 'Shop now', value: 'SHOP_NOW' },
  { text: 'Sign up', value: 'SIGN_UP' },
  { text: 'Subscribe', value: 'SUBSCRIBE' },
  { text: 'View now', value: 'VIEW_NOW' },
  {
    text: 'View profile (Only supported in ads with the advertising objective ENGAGEMENT)',
    value: 'VIEW_PROFILE',
  },
  { text: 'Visit store', value: 'VISIT_STORE' },
  { text: 'Watch LIVE (Only supported in LIVE ads)', value: 'WATCH_LIVE' },
  { text: 'Watch now', value: 'WATCH_NOW' },
  {
    text: 'Join this hashtag (Only supported in R&F ads)',
    value: 'JOIN_THIS_HASHTAG',
  },
  {
    text: 'Shoot with this effect (Only supported in R&F ads)',
    value: 'SHOOT_WITH_THIS_EFFECT',
  },
  {
    text: 'View video with this effect (Only supported in R&F ads)',
    value: 'VIEW_VIDEO_WITH_THIS_EFFECT',
  },
];

export const BRIEF_NAME_MIN_LENGTH = 3;
export const BRIEF_INSPIRATIONS_MIN_COUNT = 4;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS = 500;
export const CSV_EXPORT_HEADERS = [
  { label: 'Unique Id', key: 'uniqueId' },
  { label: 'Full name', key: 'fullName' },
  { label: 'Email', key: 'email' },
  { label: 'Sign up', key: 'signUp' },
];
export const DATA_GRID_ROW_OPTIONS = [5, 10];

export const CREATOR_PROFILE_SUCCESS_MESSAGES = {
  VideoDeleted: 'Your content video was successfully deleted',
  VideosAdded: 'Your content videos successfully added',
  CopiedSuccessfully: 'Copied successfully',
  AvatarUpdated: 'Your profile avatar successfully changed',
  ProfileUpdated: 'Your profile information was successfully updated.',
};

export const AVATAR_IMAGE_SIZE_LIMIT_IN_BYTES = 2097152;
export const PORTFOLIO_VIDEO_SIZE_LIMIT_IN_BYTES = 31457280;
export const MOBILE_DEVICE_ASPECT_RATIO = 9 / 16;
export const ALPHANUMERIC_STRING_VALIDATION_REGEX = /^[a-zA-Z0-9_-]+$/;
export const INVALID_ID_MESSAGE =
  'String should be compose of characters a-Z 0-9 _ -';

export const ENTER_KEY = 'Enter';
export const ENTER_KEY_CODE = 13;
export const OTP_LENGTH = 6;
export const OTP_TOOLTIP_DURATION = 5000;
export const EMAIL_VALIDATION_REGEX =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const NUMERIC_INPUT_VALIDATION_REGEX = /^\d$/;

export const DAYS_IN_WEEK = 7;
export const DIFFERENCE_BETWEEN_START_AND_END_OF_WEEK = 6;
export const DEFAULT_SORTING_MODEL = {
  sortKey: 'updatedAt',
  sortOrder: SortOrder.desc,
};
